import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { ModalContext } from '../ModalContext'

export default function ExerciseAssessmentInfoModal() {
    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const { modalData } = useContext(ModalContext)

    return (
        <div className='space-y-4'> 

            <div className="text-center text-2xl font-semibold text-gray-900">{modalData.name}</div>

            {/* Importance */}
            <div className="space-y-2">
                <p className="text-left text-sm text-gray-600">{modalData.importance}</p>
            </div>

            {/* Build-Up Exercises */}
            {modalData.build_up_exercises && modalData.build_up_exercises.length > 0 && (
                <div className="space-y-4">
                    <h3 className="text-lg font-semibold text-gray-800">Build-Up Exercises</h3>
                    <div className="space-y-3">
                        {modalData.build_up_exercises.map((exercise, index) => (
                            <div key={index} className="space-y-2">
                                <div className="font-medium text-gray-700">{index+1}. {exercise.name}</div>
                                <p className="text-sm text-gray-600 text-start"><strong>Reason:</strong> {exercise.reason}</p>
                                <p className="text-sm text-gray-600 text-start"><strong>How to:</strong> {exercise.how_to}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {/* Weakness Impact */}
            {modalData.weakness_impact && (
                <div className="space-y-2">
                    <h3 className="text-lg font-semibold text-gray-800">Weakness Impact</h3>
                    <p className="text-left text-sm text-gray-600">{modalData.weakness_impact}</p>
                </div>
            )}
            
        </div>
    )
}