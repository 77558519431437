import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { ChatBubbleBottomCenterTextIcon, PhoneIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { ArrowDownTrayIcon, PlayIcon } from '@heroicons/react/20/solid'

import CustomHeader from 'components/Header/CustomHeader'

export default function HelpCenter() {
    const navigate = useNavigate()
    const { ShowModal } = useContext(ModalContext)

    const helpCenterButtons = [
        {
            icon: ChatBubbleBottomCenterTextIcon,
            text: 'Submit Feedback',
            action: () => {
                const modalData = {
                    key: ModalKey.Feedback,
                    fullscreen: false,
                }

                ShowModal(modalData)
            },
        },
        {
            icon: ArrowDownTrayIcon,
            text: 'Add App To Home Screen',
            action: () => navigate('/profile/helpCenter/addToHomeScreen'),
        },
        {
            icon: QuestionMarkCircleIcon,
            text: 'Frequently Asked Questions',
            action: () => navigate('/profile/helpCenter/frequentlyAskedQuestions'),
        },
        {
            icon: PhoneIcon,
            text: 'Contact Support',
            action: () => navigate('/profile/helpCenter/contact'),
        },
    ]

    const helpCenterVideos = [
        {
            title: 'Setting Up Profile',
            thumbnailUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/1_setting_up_profile.png',
            videoUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/1_setting_up_profile.mp4',
            description: 'Here is an example on how to edit your fitness profile. Keeping your fitness profile up to date is the best way to make sure your programming is personalized to you',
        },
        {
            title: 'Setting Up Races',
            thumbnailUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/2_setting_up_races.png',
            videoUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/2_setting_up_races.mp4',
            description: 'Here is how you can add a new a race. This will make the app start generating programming',
        },
        {
            title: 'Adding Multiple Races',
            thumbnailUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/3_adding_multiple_races.png',
            videoUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/3_adding_multiple_races.mp4',
            description: 'Add additional races to further personalize your programming',
        },
        /*
        {
            title: 'Recalculated Program',
            thumbnailUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/4_recalculated_program.png',
            videoUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/4_recalculated_program.mp4',
            description: ''
        },
        */
        {
            title: 'Workout Overview',
            thumbnailUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/5_workout_overview.png',
            videoUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/5_workout_overview.mp4',
            description: 'Before each workout you will get a preview of all the exercises you will be completing',
        },
        {
            title: 'Compact Mode',
            thumbnailUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/6_compact_mode.png',
            videoUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/6_compact_mode.mp4',
            description: 'Compact mode allows you to see more details about your workout in one place. You can check off each set of the workout like you would in a spreadsheet',
        },
        {
            title: 'Difficult Modifier',
            thumbnailUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/7_difficulty_modifier.png',
            videoUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/7_difficulty_modifier.mp4',
            description: 'After each set we capture some difficulty data and immediately use that data to adjust your program',
        },
        {
            title: 'Overrides',
            thumbnailUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/8_overrides.png',
            videoUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/8_overrides.mp4',
            description: 'If a WhichWeight recommendation is inaccurate you can correct it by overriding the weight recommendation',
        },
        {
            title: 'Mobility Swaps',
            thumbnailUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/9_mobility_swaps.png',
            videoUrl: 'https://which-weight-public.s3.us-east-2.amazonaws.com/help_center_media/9_mobility_swaps.mp4',
            description: 'Before starting a workout you can swap different warm up and mobility exercises to target specific areas of the body',
        },
    ]

    const ShowVideoPlayerModal = (title, videoUrl, description) => {
        const modalData = {
            key: ModalKey.VideoPlayer,
            fullscreen: true,
            title: title,
            videoUrl: videoUrl,
            description: description,
        }

        ShowModal(modalData)
    }

    return (
        <>
            {/* Header */}
            <CustomHeader text="Help Center" backButtonAction={() => navigate('/home')} />

            <div className="p-4 space-y-4">
                {helpCenterButtons.map((button, buttonIndex) => (
                    <div key={buttonIndex} className="flex items-center px-4 py-2 gap-x-4 border border-gray-400 rounded-lg text-lg" onClick={() => button.action()}>
                        <button.icon className="h-5 text-gray-500" />
                        <div className="flex-grow ">{button.text}</div>
                    </div>
                ))}

                <div className="grid grid-cols-2 gap-4">
                    {helpCenterVideos.map((video, videoIndex) => (
                        <div
                            key={videoIndex}
                            className="w-full h-48 bg-gray-200 rounded-lg overflow-hidden border border-gray-600 relative"
                            onClick={() => ShowVideoPlayerModal(video.title, video.videoUrl, video.description)}
                        >
                            <img src={video.thumbnailUrl} alt="Thumbnail" className="w-full h-full object-cover" />

                            <div className="absolute w-full bottom-20 opacity-75">
                                <div className="mx-auto h-12 w-12 bg-gray-400 rounded-lg">
                                    <PlayIcon className=" text-white" />
                                </div>
                            </div>

                            <div className="w-full py-1 text-sm text-center bg-gray-100 border-t border-gray-600 absolute bottom-0 left-0">{video.title}</div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
