import { useSelector } from 'react-redux'
import { WeekOfTheYearKey } from 'classes/WeekOfTheYearKey'

export const GetPresentWeekOverview = () => {

    const presentWeekOfTheYearKey = useSelector((state) => state.user.presentWeekOfTheYearKey)
    const presentWeek = useSelector((state) => state.whichWeightCache.entireWeeklyCalendarJSON?.find((weekOverview) => new WeekOfTheYearKey(weekOverview.weekOfTheYearKey).equals(new WeekOfTheYearKey(presentWeekOfTheYearKey))))

    return presentWeek

}