import { useSelector } from 'react-redux'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { GetActiveWorkoutSliceKey } from 'functions/sliceUtils'
import { useGetFitnessProfile } from 'redux/customHooks/useGetFitnessProfile'

import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import ExerciseBody from 'components/ActiveWorkout/Body/ExerciseBody'
import RoundInfoPill from 'components/ActiveWorkout/Pills/RoundInfoPill'
import ExerciseInfoPill from 'components/ActiveWorkout/Pills/ExerciseInfoPill'
import SetInfoPill from 'components/ActiveWorkout/Pills/SetInfoPill'
import RestBody from 'components/ActiveWorkout/Body/RestBody'

export default function ExpandedModeBody({ 
    numberOfPhases, 
    phaseIndex, 
    phase, 
    exerciseIndex, 
    exercise, 
    setIndex, 
    set, 
    ShowEDSModalForCurrentSet, 
    ExpandedModeNavigateToNextSet, 
    showRestScreen 
}) {

    const fitnessProfile = useGetFitnessProfile() // TODO CHANGE THIS!! Need to make sure this is loaded in before this component is rendered

    const activeWorkout = useSelector((state) => state.activeWorkout)
    const activeAssessment = useSelector((state) => state.activeAssessment)
    var workoutTypeKey = GetActiveWorkoutSliceKey(activeWorkout, activeAssessment)

    if (fitnessProfile === null) return <MediumLoadingComponent />

    return (
        <>
            {/* Sub Header */}
            <div className="p-4 space-y-4 text-center text-white">

                {workoutTypeKey === 'active-workout' && 
                    <div className="text-center">
                        <div className="flex items-center justify-center text-3xl font-semibold gap-x-2">
                            {phase.completed && <CheckCircleIcon className="h-6 text-white" />}
                            <div>{phase.name}</div>
                        </div>
                        <div className="text-gray-200 font-semibold">
                            Phase {parseInt(phaseIndex) + 1} of {numberOfPhases}
                        </div>
                    </div>
                }

                {/* Straight Info Pills */}
                {phase.type === 'straight' && <SetInfoPill sets={exercise.sets.length} setIndex={setIndex} />}

                {/* Circuit Info Pills */}
                {phase.type === 'circuit' && (
                    <div>
                        {/* Rounds Info Pill */}
                        <RoundInfoPill circuitRounds={phase.circuitRounds} setIndex={setIndex} />

                        {/* Exercise Info Pill */}
                        <ExerciseInfoPill phase={phase} exerciseIndex={exerciseIndex} />
                    </div>
                )}
            </div>

            {/* Exercise Body */}
            {!showRestScreen && (
                <ExerciseBody
                    phase={phase}
                    phaseIndex={phaseIndex}
                    exerciseIndex={exerciseIndex}
                    exercise={exercise}
                    setIndex={setIndex}
                    set={set}
                    ShowEDSModalForCurrentSet={ShowEDSModalForCurrentSet}
                    ExpandedModeNavigateToNextSet={ExpandedModeNavigateToNextSet}
                />
            )}

            {/* Rest Body */}
            {showRestScreen && (
                <RestBody
                    phaseIndex={phaseIndex}
                    exerciseIndex={exerciseIndex}
                    exercise={exercise}
                    setIndex={setIndex}
                    ExpandedModeNavigateToNextSet={ExpandedModeNavigateToNextSet}
                />
            )}
            
        </>
    )
}
