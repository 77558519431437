import UAParser from "ua-parser-js";
import moment from "moment";
import * as Sentry from '@sentry/react';

import { useAuth0 } from "@auth0/auth0-react";

import { GetBrandStyleConfig } from "redux/slices/brand";
import { useDispatch, useSelector } from 'react-redux';
import { setBrandStyleConfig } from "redux/slices/brand";
import { setUserInfo, resetUserState } from './redux/slices/user';
import { resetUserInterfaceState } from "redux/slices/userInterface";
import { resetOnboardingState } from "redux/slices/onboarding";
import { resetProgramAssignmentState } from "redux/slices/programAssignment";
import { resetSubscriptionState } from "redux/slices/subscription";
import { resetTrainerState } from "redux/slices/trainer";
import { clearWhichWeightCache } from "redux/slices/whichWeightCache";

import { WhichWeightAPI } from "./WhichWeightAPI";
import { WeekOfTheYearKey } from "classes/WeekOfTheYearKey";

import MediumLoadingComponent from "components/Loading/MediumLoadingComponent";
import FullscreenLoading from "components/Loading/FullscreenLoading";
import TraineeApp from "views/TraineeApp";
import IncorrectAccountTypeView from "views/IncorrectAccountTypeView";

function App() {

  // Gets the device info for the track login API call
  const parser = new UAParser();
  const deviceInfo = parser.getResult();

  /*
  const state = useSelector(state => state);

  // Function to calculate the size of an object in bytes
  const calculateSizeInKB = (obj) => {
    const str = JSON.stringify(obj);
    const bytes = new Blob([str]).size;
    const kilobytes = bytes / 1024; // Convert bytes to kilobytes
    return kilobytes;
  };

  console.log(calculateSizeInKB(state) + " kb");
  */

  const brandStyleConfig = useSelector(state => state.brand.styleConfig)

  const InitializeBranding = () => {

    const brandStyleConfig = GetBrandStyleConfig(process.env.REACT_APP_BRAND_KEY)

    // console.log(brandConfig)

    dispatch(setBrandStyleConfig(brandStyleConfig))

  }

  // Auth0 variables
  const { user, isAuthenticated, getAccessTokenSilently, isLoading, logout } = useAuth0();
  const dispatch = useDispatch();

  const TrySilentAuthentication = async () => {
    
    // getAccessTokenSilently will throw an error if missed
    try {
      await getAccessTokenSilently();
    }
    catch (error){
      // console.warn(error)
    }

  }
  
  // User Variables
  const userId = useSelector(state => state.user.id);
  const userType = useSelector(state => state.user.type);
  const userOnboarded = useSelector(state => state.user.onboarded);
  const membershipActive = useSelector(state => state.user.membershipActive);

  const ResetReduxCache = () => {

    dispatch(resetUserState()); 
    dispatch(resetUserInterfaceState());
    dispatch(clearWhichWeightCache()); 
    dispatch(resetOnboardingState()); 
    dispatch(resetProgramAssignmentState());
    dispatch(resetSubscriptionState());
    dispatch(resetTrainerState());

  }

  // Converts Auth0 email into WhichWeight user id
  const GetWhichWeightUserInfo = async () => {

    ResetReduxCache();

    const jsonBody = {
      email: user.email
    }

    const result = await WhichWeightAPI("BILLING", "GET", "/user_info", jsonBody);

    // TODO ADD ERROR HANDLING HERE
    
    const userInfo = result.data.data.userInfo
    const userMembershipInfo = result.data.data.userMembershipInfo

    // console.log(userInfo)
    // console.log(userMembershipInfo)

    // Data redux store

    const reduxUserInfo = {
      // From Auth0
      email: user.email,
      picture: user.picture,
      // Device timestamps
      presentDate: moment.utc().local().format('YYYY-MM-DD'),
      presentWeekOfTheYearKey: new WeekOfTheYearKey(moment.utc().local()).toString(),
      // User Info
      id: parseInt(userInfo.id),
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      type: userInfo.accountType,
      measurementSystem: userInfo.measurementSystem,
      allowMonthlyReminders: userInfo.allowMonthlyReminders,
      onboarded: userInfo.onboarded,
      onboardingStepKey: userInfo.onboardingStepKey,
      sendWelcomeEmail: userInfo.sendWelcomeEmail,
      // User Membership Info
      organizationId: userMembershipInfo.organizationId,
      organizationName: userMembershipInfo.organizationName,
      organizationLogo: userMembershipInfo.organizationLogo,
      organizationHandlesMembership: userMembershipInfo.organizationHandlesMembership,
      membershipActive: userMembershipInfo.membershipActive,
      subscriptionStatus: userMembershipInfo.subscriptionStatus,
      usedFreeTrial: userMembershipInfo.usedFreeTrial,
      // Misc
      newFitnessProfileVariablesPopulated: result.data.data.newFitnessProfileVariablesPopulated,
    }

    // console.log(reduxUserInfo)

    dispatch(setUserInfo(reduxUserInfo));

    // Track login

    const loginInfoJson = { 
      userId: parseInt(userInfo.id), 
      userType: userInfo.accountType,
      browser: deviceInfo.browser.name + ' ' + deviceInfo.browser.version,
      device: deviceInfo.device.model,
      operatingSystem: deviceInfo.os.name + ' ' + deviceInfo.os.version
    }

    WhichWeightAPI("CORE", "POST", "/track_login", loginInfoJson);

    // Send welcome email if required
    if (userInfo.sendWelcomeEmail) {
      const emailJson = {
        recipient: user.email,
        subject: "Welcome to WhichWeight!",
        emailTemplateKey: "welcome_email_template"
      };
      await WhichWeightAPI("EMAIL","POST","/send_email",emailJson);
    }

    // Setting Sentry user info

    const sentryUserInfo = {
      id: parseInt(userInfo.id),
      email: user.email
    }

    Sentry.setUser(sentryUserInfo);

  };

  // Set branding config based on URL params
  if (brandStyleConfig == null){ InitializeBranding(); return <MediumLoadingComponent />}

  // If a user is unauthenticated and Auth0 detects we are not loading an authentication, show the login screen
  // Reset the redux user store here
  if (!isAuthenticated && !isLoading){ TrySilentAuthentication(); return <TraineeApp viewGroup="public-user" /> }

  // If a user is unauthenticated and Auth0 detects we are loading, that means a user is trying to complete the auth process
  // Show the loading screen
  if (!isAuthenticated && isLoading) { return <FullscreenLoading loadingText="" /> }

  // If we have not yet gotten the which weight user info, still show loading
  if (userId == null || userType == null || userOnboarded == null || membershipActive == null){ GetWhichWeightUserInfo(); return <FullscreenLoading loadingText="Getting User Info..." showQuote={false} /> }

  // Once we have all the user info, show the trainer app if the account type is trainer
  if (userType === "trainer") { return <IncorrectAccountTypeView /> }

  // Once we have all the user info, show the trainee app if the account type is trainee
  if (userType === "trainee") { return <TraineeApp viewGroup="authenticated-user" /> }

  // Invalid user type UI

  const OnLogout = () => { logout({ logoutParams: { returnTo: window.location.origin + "/login" } }); }

  // Otherwise show them an error message!
  return (
    <div className="p-4 space-y-4 text-lg text-center">
        <div>"{userType}" is not a valid app type</div>
        <div>Please contact WhichWeight support for help</div>
        <div className="bg-gray-200 p-2 rounded-full" onClick={() => { OnLogout() }}>Logout</div>
    </div>
  );

}

export default App;