// Code comes from this component!
// https://react-select.com/home

import React from 'react'

import Select from 'react-select'

export default ({ defaultValue, value, options, onChange }) => {
    return (
        <>
            <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={defaultValue}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
                name="color"
                options={options}
                onChange={onChange}
                clearValue={true}
                value={value}
            />
        </>
    )
}
