import { createSlice } from '@reduxjs/toolkit'

// Initial state
const initialState = {
    id: null,
    firstName: null,
    lastName: null,
    type: null,
    email: null,
    picture: null,
    onboarded: null,
    onboardingStepKey: null,
    membershipActive: null,
    subscriptionStatus: null,
    usedFreeTrial: null,
    newFitnessProfileVariablesPopulated: null,
    presentDate: null,
    presentWeekOfTheYearKey: null,
    organizationId: null,
    organizationName: null,
    organizationLogo: null,
    organizationHandlesMembership: null,
    measurementSystem: null,
    allowMonthlyReminders: null,
    fitnessProfile: null
}

// User Slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {

      state.id = action.payload.id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.type = action.payload.type;
      state.email = action.payload.email;
      state.picture = action.payload.picture;
      state.onboarded = action.payload.onboarded;
      state.onboardingStepKey = action.payload.onboardingStepKey;
      state.membershipActive = action.payload.membershipActive;
      state.subscriptionStatus = action.payload.subscriptionStatus;
      state.usedFreeTrial = action.payload.usedFreeTrial;
      state.newFitnessProfileVariablesPopulated = action.payload.newFitnessProfileVariablesPopulated;
      state.presentDate = action.payload.presentDate;
      state.presentWeekOfTheYearKey = action.payload.presentWeekOfTheYearKey;
      state.organizationId = action.payload.organizationId;
      state.organizationName = action.payload.organizationName;
      state.organizationLogo = action.payload.organizationLogo;
      state.organizationHandlesMembership = action.payload.organizationHandlesMembership;
      state.measurementSystem = action.payload.measurementSystem;
      state.allowMonthlyReminders = action.payload.allowMonthlyReminders;

    },
    setMembershipActive: (state, action) => { state.membershipActive = action.payload },
    setSubscriptionStatus: (state, action) => { state.subscriptionStatus = action.payload },
    setUsedFreeTrial: (state, action) => { state.usedFreeTrial = action.payload },
    setNewFitnessProfileVariablesPopulated: (state, action) => { state.newFitnessProfileVariablesPopulated = action.payload },
    updateOrganization: (state, action) => {

      state.organizationId = action.payload.organizationId
      state.organizationName = action.payload.organizationName
      state.organizationLogo = action.payload.organizationLogo
      state.organizationHandlesMembership = action.payload.organizationHandlesMembership

    },
    updateUserMeasurementSystem: (state, action) => { state.measurementSystem = action.payload },
    updateAllowMonthlyReminderPreference: (state, action) => { state.allowMonthlyReminders = action.payload },
    setReduxFitnessProfile: (state, action) => { state.fitnessProfile = action.payload },
    resetUserState: () => initialState
  },
});

export const { 
  setUserInfo,
  setMembershipActive,
  setSubscriptionStatus,
  setUsedFreeTrial,
  setNewFitnessProfileVariablesPopulated,
  updateOrganization,
  updateUserMeasurementSystem,
  updateAllowMonthlyReminderPreference,
  setReduxFitnessProfile,
  resetUserState 
} = userSlice.actions;

export default userSlice.reducer;