import { Fragment, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useGetExercises } from 'redux/customHooks/useGetExercises'
import { ChevronLeftIcon, ChevronRightIcon, CheckCircleIcon } from '@heroicons/react/20/solid'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { GetActiveWorkoutSliceKey } from 'functions/sliceUtils'
import { useGetFitnessProfile } from 'redux/customHooks/useGetFitnessProfile'

import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import CompactExerciseSetCard from 'components/ActiveWorkout/Trainee/CompactExerciseSetCard'
import LoadingButton from 'components/LoadingButton'

export default function CompactModeBody({ 
    numberOfPhases, 
    phaseIndex, 
    phase, 
    ShowEDSModalForCurrentSet, 
    SaveCompletedSet, 
    ActiveWorkoutNavigate, 
    setTriggerFinalCompletionAction 
}) {
    
    const allDatabaseExercises = useGetExercises()
    const fitnessProfile = useGetFitnessProfile() // TODO CHANGE THIS!! Need to make sure this is loaded in before this component is rendered
    const { ShowModal, HideModal } = useContext(ModalContext)

    const activeWorkout = useSelector((state) => state.activeWorkout)
    const activeAssessment = useSelector((state) => state.activeAssessment)
    var workoutTypeKey = GetActiveWorkoutSliceKey(activeWorkout, activeAssessment)

    const OnCompletePhaseButton = async () => {
        // Count number of incomplete sets in the phase
        var numberOfIncompleteSetsInThePhase = 0

        // Loop over all exercises
        phase.exercises.map((exercise) => {
            // For all sets
            exercise.sets.map((set) => {
                if (!set.completedSetData.completed) numberOfIncompleteSetsInThePhase++
            })
        })

        // If all sets are complete then complete the phase
        if (numberOfIncompleteSetsInThePhase == 0) {
            ActiveWorkoutNavigate('complete-phase')
        }
        // Otherwise show options modal
        // Gives options for staying on current phase, auto completing sets, or discarding sets
        else {
            const modalData = {
                key: ModalKey.MultiActionWarning,
                fullscreen: false,
                titleText: 'Complete this phase?',
                bodyText: ['Not all sets in this phase have been completed yet. What would you like to do?'],
                actions: [
                    {
                        buttonText: 'Stay On Current Phase',
                        buttonAction: () => HideModal(),
                    },
                    {
                        buttonText: 'Auto-Complete Remaining Sets',
                        buttonAction: async () => OnAutoCompleteWarning(),
                    },
                    {
                        buttonText: 'Discard Remaining Sets',
                        buttonAction: () => {
                            HideModal()
                            ActiveWorkoutNavigate('complete-phase')
                        },
                    },
                ],
            }

            ShowModal(modalData)
        }
    }

    const OnEndAssessmentButton = () => {

        const modalData = {
            key: ModalKey.ActionWarning,
            fullscreen: false,
            titleText: 'End Workout?',
            bodyText: ['Ending the workout now will discard any incomplete sets. Continue?'],
            buttonText: 'End Workout',
            buttonAction: () => ActiveWorkoutNavigate('complete-phase'),
        }

        ShowModal(modalData)
        
    }

    const OnAutoCompleteWarning = () => {
        const modalData = {
            key: ModalKey.ActionWarning,
            fullscreen: false,
            titleText: 'Auto-complete Warning',
            bodyText: ['Auto-complete will save all remaining sets as neutral difficulty'],
            buttonText: 'Continue',
            buttonAction: async () => {
                await AutocompleteRemainingSets()
            },
        }

        ShowModal(modalData)
    }

    const AutocompleteRemainingSets = async () => {
        // For straight phase type
        if (phase.type === 'straight') {
            // Loop over all exercises
            for (let exerciseIndex = 0; exerciseIndex < phase.exercises.length; exerciseIndex++) {
                // For all sets
                for (let setIndex = 0; setIndex < phase.exercises[exerciseIndex].sets.length; setIndex++) {
                    // Complete all incomplete sets
                    if (!phase.exercises[exerciseIndex].sets[setIndex].completedSetData.completed) {
                        // Get the number of set units completed based on set unit type. Default to reps
                        var setUnitsCompleted = phase.exercises[exerciseIndex].sets[setIndex].reps
                        if (phase.exercises[exerciseIndex].setUnit === 'seconds') setUnitsCompleted = phase.exercises[exerciseIndex].sets[setIndex].seconds

                        await SaveCompletedSet(parseInt(phaseIndex), parseInt(exerciseIndex), parseInt(setIndex), setUnitsCompleted, 0, 0)
                    }
                }
            }
        }

        // For circuit phase type
        if (phase.type === 'circuit') {
            // Loop over each circuit round
            for (let setIndex = 0; setIndex < phase.circuitRounds; setIndex++) {
                // Loop over all exercises
                for (let exerciseIndex = 0; exerciseIndex < phase.exercises.length; exerciseIndex++) {
                    // Complete all incomplete sets
                    if (!phase.exercises[exerciseIndex].sets[setIndex].completedSetData.completed) {
                        // Get the number of set units completed based on set unit type. Default to reps
                        var setUnitsCompleted = phase.exercises[exerciseIndex].sets[setIndex].reps
                        if (phase.exercises[exerciseIndex].setUnit === 'seconds') setUnitsCompleted = phase.exercises[exerciseIndex].sets[setIndex].seconds

                        await SaveCompletedSet(parseInt(phaseIndex), parseInt(exerciseIndex), parseInt(setIndex), setUnitsCompleted, 0, 0)
                    }
                }
            }
        }

        // If there are more phases to be done in the workout
        // The useSelector hook will have to time refire, can just navigate to next phase normally
        if (parseInt(phaseIndex) < numberOfPhases - 1) {
            ActiveWorkoutNavigate('complete-phase')
        }
        // If we are on the last phase, need to use hacky way of completing phase to make sure
        // useSeletor data is up to date
        else {
            // Hacky way to trigger complete phase function so activeWorkout useSelector data is up to date
            setTriggerFinalCompletionAction(true)
        }
    }

    if (fitnessProfile === null) return <MediumLoadingComponent />

    return (
        <div className="p-4 space-y-4">
            
            {workoutTypeKey === 'active-workout' && 
                <div className="flex items-center px-4 text-white">
                    {/* Previous Phase Chevron Button */}
                    <div className="flex-1">{phaseIndex > 0 && <ChevronLeftIcon onClick={() => ActiveWorkoutNavigate('compact-previous-phase')} className="h-10 text-gray-200" />}</div>

                    {/* Text Info */}
                    <div className="text-center">
                        <div className="flex items-center justify-center text-3xl font-semibold gap-x-2">
                            {phase.completed && <CheckCircleIcon className="h-6 text-white" />}
                            <div>{phase.name}</div>
                        </div>
                        <div className="text-gray-200 font-semibold">
                            Phase {parseInt(phaseIndex) + 1} of {numberOfPhases}
                        </div>
                    </div>

                    {/* Next Phase Chevron Button */}
                    <div className="flex-1">
                        {phase.completed && phaseIndex < numberOfPhases - 1 && <ChevronRightIcon onClick={() => ActiveWorkoutNavigate('compact-next-phase')} className="ml-auto text-gray-200 h-10" />}
                    </div>
                </div>
            }

            {/* For straight workouts */}
            {phase.type === 'straight' && (
                <div className="space-y-2">
                    {phase.exercises.map((exercise, exerciseIndex) => (
                        <Fragment key={exerciseIndex}>
                            {exercise.sets.map((set, setIndex) => (
                                <CompactExerciseSetCard
                                    key={setIndex}
                                    allDatabaseExercises={allDatabaseExercises}
                                    phaseIndex={phaseIndex}
                                    phase={phase}
                                    exerciseIndex={exerciseIndex}
                                    exercise={exercise}
                                    setIndex={setIndex}
                                    set={set}
                                    ShowEDSModalForCurrentSet={ShowEDSModalForCurrentSet}
                                />
                            ))}
                        </Fragment>
                    ))}
                </div>
            )}

            {/* For circuit workouts */}
            {phase.type === 'circuit' && (
                <div className="space-y-2">
                    {Array.from({ length: phase.circuitRounds }, (_, i) => i).map((roundIndex) => (
                        <Fragment key={roundIndex}>

                            {workoutTypeKey === 'active-workout' && 
                                <div className="text-center text-white text-xl border-gray-500">Set {roundIndex + 1}</div>
                            }

                            {/* Loop over each exercise */}
                            {phase.exercises.map((exercise, exerciseIndex) => (
                                <CompactExerciseSetCard
                                    key={exerciseIndex}
                                    allDatabaseExercises={allDatabaseExercises}
                                    phaseIndex={phaseIndex}
                                    phase={phase}
                                    exerciseIndex={exerciseIndex}
                                    exercise={exercise}
                                    setIndex={roundIndex}
                                    set={exercise.sets[roundIndex]}
                                    ShowEDSModalForCurrentSet={ShowEDSModalForCurrentSet}
                                />
                            ))}
                        </Fragment>
                    ))}
                </div>
            )}

            {/* Complete Phase Button (Active Assessment) */}
            {!phase.completed && workoutTypeKey === 'active-assessment' && (
                <div className="pb-4">
                    <LoadingButton
                        buttonText="End Assessment"
                        action={() => OnEndAssessmentButton()}
                        className="w-full flex justify-center items-center gap-x-4 bg-white rounded-full text-gray-600 font-semibold py-2"
                        backgroundColor={'#fafafa'}
                    />
                </div>
            )}

            {/* Complete Phase Button (Active Workout) */}
            {!phase.completed && workoutTypeKey === 'active-workout' && (
                <div className="py-6">
                    <LoadingButton
                        buttonText="Complete All"
                        action={() => OnCompletePhaseButton()}
                        className="w-full flex justify-center items-center gap-x-4 bg-white rounded-full text-gray-600 font-semibold py-2"
                        backgroundColor={'#fafafa'}
                    />
                </div>
            )}

        </div>
    )
}
