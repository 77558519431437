import { WhichWeightAPI } from 'WhichWeightAPI';
import { useEffect, useState, useContext } from 'react';
import { ModalKey, ModalContext } from 'components/Modals/ModalContext';
import { useSelector, useDispatch } from 'react-redux';
import { setWhichWeightCacheEntireWeeklyCalendarJSON } from 'redux/slices/whichWeightCache';
import moment from 'moment';

export const useGetEntireWeeklyCalendarJSON = () => {

    const userId = useSelector(state => state.user.id);
    const presentWeekOfTheYearKey = useSelector(state => state.user.presentWeekOfTheYearKey);

    const newFitnessProfileVariablesPopulated = useSelector(state => state.user.newFitnessProfileVariablesPopulated);

    const dispatch = useDispatch();

    // Initialize hook based on what is in the redux store
    const cachedEntireWeeklyCalendarJSON = useSelector(state => state.whichWeightCache.entireWeeklyCalendarJSON);
    const [entireWeeklyCalendarJSON, setEntireWeeklyCalendarJSON] = useState(cachedEntireWeeklyCalendarJSON);

    const { ShowModal } = useContext(ModalContext)

    const CheckToPromptForNPS = (calendarData) => {

        // console.log(calendarData)

        var currentWeekData = calendarData.find(item => item.weekOfTheYearKey === presentWeekOfTheYearKey)

        // console.log(currentWeekData)
        // console.log(currentWeekData.promptForNPS)

        if (currentWeekData !== null && currentWeekData !== undefined && currentWeekData.hasOwnProperty("promptForNPS")){

            if (currentWeekData.promptForNPS){

                const modalData = {
                    key: ModalKey.NetPromoterScore,
                    fullscreen: false
                }
        
                ShowModal(modalData)

            }

        }
        
    }

    useEffect(() => {

        const fetchEntireWeeklyCalendarJSON = async () => {

            // If the redux store does not already have the data
            if (cachedEntireWeeklyCalendarJSON === null) {

                // If a user if missing new fitness profile variables
                // Do not generate a week
                if (!newFitnessProfileVariablesPopulated){

                    // console.log("Missing fitness profile variables. Skipping program generation")

                    // Otherwise set data from backend
                    dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON([]));
                    setEntireWeeklyCalendarJSON([]);

                    return;

                }

                // Grab data and store it
                const jsonBody = {
                    userId: userId,
                    presentWeekOfTheYearKey: presentWeekOfTheYearKey,
                    localDate: moment.utc().local().format('YYYY-MM-DD')
                }
        
                // console.log(jsonBody);
        
                const result = await WhichWeightAPI("PROGRAM", "GET", "/athlete_app_weekly_calendar_data", jsonBody);
        
                // console.log(result);
                // console.log(result.data.errorType)

                if (result.data.errorType === "LambdaTimeoutError"){
                    dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON('timeout'));
                    setEntireWeeklyCalendarJSON('timeout');
                    return;
                }
                
                // If API failed set blank values
                if (result.data.status === "failure"){
                    console.error(result);
                    dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON([]));
                    setEntireWeeklyCalendarJSON([]);
                    return;
                }

                // Otherwise set data from backend
                dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON(result.data.data));
                setEntireWeeklyCalendarJSON(result.data.data);
                CheckToPromptForNPS(result.data.data)

            }
            
        };

        fetchEntireWeeklyCalendarJSON();

    }, [cachedEntireWeeklyCalendarJSON, dispatch]);

    return entireWeeklyCalendarJSON;

};
