import { WhichWeightAPI } from 'WhichWeightAPI';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setReduxFitnessProfile } from 'redux/slices/user';

export const useGetFitnessProfile = () => {

    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.id)

    const reduxFitnessProfile = useSelector(state => state.user.fitnessProfile);

    const [fitnessProfile, setFitnessProfile] = useState(reduxFitnessProfile);

    useEffect(() => {

        const fetchFitnessProfile = async () => {

            if (reduxFitnessProfile === null) {
                
                const jsonBody = {
                    traineeId: userId,
                }

                const result = await WhichWeightAPI('CORE', 'GET', '/trainee_fitness_profile', jsonBody)

                dispatch(setReduxFitnessProfile(result.data.data));

                setFitnessProfile(result.data.data);
            }
            
        };

        fetchFitnessProfile();

    }, [reduxFitnessProfile, dispatch]);

    return fitnessProfile;

};
