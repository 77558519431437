import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { equipmentOptions } from 'functions/equipmentUtils'
import { CheckIcon } from '@heroicons/react/24/solid'
import { InformationCircleIcon, ShoppingCartIcon } from '@heroicons/react/24/outline'
import { setWhichWeightCacheEntireWeeklyCalendarJSON } from 'redux/slices/whichWeightCache'
import moment from 'moment'

import CustomHeader from 'components/Header/CustomHeader'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import LoadingButton from 'components/LoadingButton'

export default function EquipmentView() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { ShowModal } = useContext(ModalContext)

    const userId = useSelector((state) => state.user.id)
    const presentWeekOfTheYearKey = useSelector((state) => state.user.presentWeekOfTheYearKey);

    const [equipmentBlacklist, setEquipmentBlacklist] = useState(null)
    const [saveLoading, setSaveLoading] = useState(false)

    const FetchEquipmentBlacklistFromBackend = async () => {
        const jsonBody = {
            userId: userId,
        }

        const result = await WhichWeightAPI('CORE', 'GET', '/equipment_blacklist', jsonBody)

        // console.log(result.data.data.equipmentBlacklist);

        if (result.data.data.equipmentBlacklist.length === 0) {
            setEquipmentBlacklist([])
        }

        if (result.data.data.equipmentBlacklist.length > 0) {
            const resultArray = result.data.data.equipmentBlacklist.split(',')
            // console.log(resultArray)
            setEquipmentBlacklist(resultArray)
        }
    }

    const OnEquipmentOptionToggled = (equipmentKey) => {
        var tempEquipmentBlacklist = JSON.parse(JSON.stringify(equipmentBlacklist))

        // If equipment key is not in the array add it
        if (!tempEquipmentBlacklist.includes(equipmentKey)) {
            tempEquipmentBlacklist.push(equipmentKey)
            tempEquipmentBlacklist.sort()

            // Otherwise remove it
        } else {
            const index = tempEquipmentBlacklist.indexOf(equipmentKey)
            if (index !== -1) {
                tempEquipmentBlacklist.splice(index, 1)
            }
        }

        setEquipmentBlacklist(tempEquipmentBlacklist)
    }

    const OnSaveEquipmentBlacklist = async () => {
        setSaveLoading(true)

        const jsonBody = {
            userId: userId,
            equipmentBlacklist: equipmentBlacklist.join(','),
        }

        // console.log(jsonBody)

        const result = await WhichWeightAPI('CORE', 'PATCH', '/equipment_blacklist', jsonBody)

        // console.log(result)

        if (result.data.status === 'failure') {
            console.error(result)
            setSaveLoading(false)
            return
        }

        const jsonBody2 = {
            userId: userId,
            presentWeekOfTheYearKey: presentWeekOfTheYearKey,
            localDate: moment.utc().local().format('YYYY-MM-DD')
        }

        const clear_cache_result = await WhichWeightAPI('PROGRAM', 'POST', '/delete_entire_program_cache', jsonBody2)

        // console.log(clear_cache_result)

        if (clear_cache_result.data.status === 'failure') {
            console.error(clear_cache_result)
        }

        setSaveLoading(false)

        dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON(null)) // Reset calendar data

        navigate('/home')
    }

    const OnOptionalEquipmentInfo = () => {
        const modalData = {
            key: ModalKey.Text,
            fullscreen: false,
            titleText: 'Optional Equipment',
            bodyText: [
                'To get the best strength training possible you should be using the best equipment.',
                'However we understand that not everyone has access to gym equipment. We will adjust your programming to fit the equipment available',
            ],
        }

        ShowModal(modalData)
    }

    const OnRequiredEquipmentInfo = () => {
        const modalData = {
            key: ModalKey.Text,
            fullscreen: false,
            titleText: 'Required Equipment',
            bodyText: [
                'Bodyweight only programming can only take you so far. Good strength training requires atleast a set of dumbbells along with basic equipment made for home workouts',
                'Check out the links below for our recommended at-home products.',
            ],
        }

        ShowModal(modalData)
    }

    useEffect(() => {
        FetchEquipmentBlacklistFromBackend()
    }, [])

    return (
        <>
            <CustomHeader text="Equipment" backButtonAction={() => navigate('/home')} />

            {equipmentBlacklist === null && <MediumLoadingComponent />}

            {equipmentBlacklist !== null && (
                <div className="p-4 space-y-4">
                    {/* Optional Equipment */}
                    <div className="border border-gray-400 rounded-xl overflow-hidden">
                        <div className="flex items-center px-4 py-2 gap-x-2 bg-gray-50 border-b border-gray-400" onClick={() => OnOptionalEquipmentInfo()}>
                            <div>Optional Equipment</div>
                            <InformationCircleIcon className="h-4 w-4 mt-0.5" />
                        </div>

                        <div className="divide-y divide-gray-400">
                            {equipmentOptions
                                .filter((equipment) => !equipment.required)
                                .map((equipment, index) => (
                                    <div
                                        key={index}
                                        className={`p-4 flex items-center gap-x-4 ${equipmentBlacklist.includes(equipment.key) ? 'opacity-50 bg-gray-50' : ''}`}
                                        onClick={() => OnEquipmentOptionToggled(equipment.key)}
                                    >
                                        <img src={equipment.imageURL} alt="Equipment Icon" className="h-10" />
                                        <div className="flex-grow">
                                            <div className="text-lg">{equipment.name}</div>
                                            <div className="text-xs text-gray-500">{equipment.description}</div>
                                        </div>
                                        <div className="h-6 w-6 p-0.5 border-2 border-gray-500 rounded">{!equipmentBlacklist.includes(equipment.key) && <CheckIcon className="h-4" />}</div>
                                    </div>
                                ))}
                        </div>

                        <div className="p-2">
                            <LoadingButton buttonText="Save" action={() => OnSaveEquipmentBlacklist()} isLoading={saveLoading} />
                        </div>
                    </div>

                    {/* Required Equipment */}
                    <div className="border border-gray-400 rounded-xl overflow-hidden">
                        <div className="flex items-center px-4 py-2 gap-x-2 bg-gray-50 border-b border-gray-400" onClick={() => OnRequiredEquipmentInfo()}>
                            <div>Required Equipment</div>
                            <InformationCircleIcon className="h-4 w-4 mt-0.5" />
                        </div>

                        <div className="divide-y divide-gray-400">
                            {equipmentOptions
                                .filter((equipment) => equipment.required)
                                .map((equipment, index) => (
                                    <a
                                        key={index}
                                        className={`p-4 flex items-center gap-x-4 ${equipmentBlacklist.includes(equipment.key) ? 'opacity-75 bg-gray-200' : ''}`}
                                        href={equipment.shoppingLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={equipment.imageURL} alt="Equipment Icon" className="h-10" />
                                        <div className="flex-grow">
                                            <div className="text-lg">{equipment.name}</div>
                                            <div className="text-xs text-gray-500">{equipment.description}</div>
                                        </div>
                                        <div className="p-2 border border-gray-500 text-gray-500 rounded">
                                            <ShoppingCartIcon className="h-5" />
                                        </div>
                                    </a>
                                ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
