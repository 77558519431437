import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ConvertPoundsToNearestFiveKilograms } from 'functions/measurementSystemUtils'
import { GetDifficultyIconFromValue } from 'functions/iconUtils'
import { ChartBarSquareIcon } from '@heroicons/react/24/outline'

import MiniLinearDial from 'components/Dials/MiniLinearDial'

export default function WorkoutSummaryExerciseSetListItem({
    completed,
    exerciseId,
    exerciseName,
    exerciseType,
    setIndex,
    setsAssigned,
    setUnit,
    repsAssigned,
    setUnitsCompleted,
    weightRecommendation,
    weightRecommendationRounded,
    movementDifficulty,
    difficulty,
}) {

    const navigate = useNavigate()
    const measurementSystem = useSelector((state) => state.user.measurementSystem)

    const OnChartButtonPressed = () => {
    
        if (!completed) return

        const navigationProps = {
            state: {
                exerciseId: exerciseId,
                exerciseName: exerciseName,
                exerciseType: exerciseType,
            },
        }

        navigate('/reports/exerciseWeightHistory', navigationProps)

    }

    return (
        <div className="p-4">

            {/* Title */}
            <div className="pb-2">{exerciseName}</div>

            <div className={`
                grid grid-cols-9
                ${completed ? 'opacity-100' : 'opacity-40'}
            `}>

                {/* Set Number */}
                <div className="flex items-center font-extrabold text-gray-600">
                    <span className="text-2xl">{setIndex + 1}</span>
                    <span className="text-sm">/{setsAssigned}</span>
                </div>

                {/* Load Recommendation */}
                <div className="px-0.5 col-span-2 flex items-center justify-center">

                    {completed && 
                        <>

                            {/* Recommendation */}
                            {exerciseType === 'recommendation' && (
                                <div className="flex-grow text-center py-1 rounded-lg border border-gray-400 text-sm">
                                    {measurementSystem === 'imperial' && <>{weightRecommendationRounded} lbs</>}
                                    {measurementSystem === 'metric' && <>{ConvertPoundsToNearestFiveKilograms(weightRecommendation)} kgs</>}
                                </div>
                            )}

                            {/* Bodyweight */}
                            {exerciseType === 'bodyweight' && 
                                <div className="flex-grow text-center py-1 rounded-lg border border-gray-400 text-sm">
                                    Body
                                </div>
                            }

                        </>
                    }

                    {!completed && 
                        <div className="flex-grow text-center py-1 rounded-lg border border-gray-400 text-sm">
                            --
                        </div>
                    }

                </div>

                {/* Reps Completed */}
                <div className="pl-3 -mr-1 col-span-3 flex items-center justify-center">
                    <MiniLinearDial label={setUnitsCompleted + ' / ' + repsAssigned + ' ' + setUnit} fillPercentage={(setUnitsCompleted / repsAssigned) * 100} />
                </div>

                {/* Icons (recommendation sets only) */}
                <div className="col-span-3 flex items-center justify-end gap-x-2 text-gray-600" onClick={() => OnChartButtonPressed()}>
                    
                    {/* Movement Difficulty */}
                    <div className='space-y-1 text-center'>
                        <img 
                            src={GetDifficultyIconFromValue(movementDifficulty)} 
                            className="h-6 w-6" 
                            alt="Movement Difficulty Icon" 
                        />
                        <div className='text-[8px]'>MDS</div>
                    </div>
                    
                    {/* Load Difficulty */}
                    <div className='space-y-1 text-center'>
                        <img 
                            src={GetDifficultyIconFromValue(difficulty)} 
                            className="h-6 w-6" 
                            alt="Load Difficulty Icon" 
                        />
                        <div className='text-[8px]'>LDS</div>
                    </div>

                    {/* Reports Icon */}
                    <ChartBarSquareIcon className="h-7 w-7" />
                </div>

            </div>

        </div>
    )

}
