import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import ScreenFrame from 'components/Frame/ScreenFrame'
import LogoHeader from 'components/Header/LogoHeader'
import BodyFrame from 'components/Frame/BodyFrame'
import EventsHolder from 'components/ProgramAssignment/EventsHolder'
import NextButton from 'components/Onboarding/NextButton'

export default function ProgramAssignmentEvents() {

    const navigate = useNavigate()
    const events = useSelector((state) => state.programAssignment.events)

    const [eventsErrorMessage, setEventsErrorMessage] = useState("")

    const OnNextButton = () => {
        const justARaces = events.filter((event) => event.eventType === 'a-race')

        if (justARaces.length === 0) {
            setEventsErrorMessage("Please add atleast one A Race.")
            return
        }

        if (justARaces.length > 1) {
            setEventsErrorMessage("Please start your program with only one A Race")
            return
        }

        navigate('/program/assignment/loading')
    }

    useEffect(() => { setEventsErrorMessage("") }, [events])

    return (
        <ScreenFrame>
            <LogoHeader backButtonAction={() => navigate('/program/assignment/intro')} />

            <BodyFrame>

                <div className="space-y-1 text-center">
                    <h1 className="text-xl font-bold">Add Your Events Below</h1>
                    <div className="text-gray-600 text-xs italic">You can always edit your events later</div>
                </div>

                <EventsHolder eventType="a-race" />

                <EventsHolder eventType="b-race" />

                <EventsHolder eventType="c-race" />

                {eventsErrorMessage !== "" && <p className="text-center text-sm text-red-600">{eventsErrorMessage}</p>}

                <div className='mt-auto space-y-4'>
                    <div className="text-center text-sky-600 underline" onClick={() => navigate('/program/assignment/lengthSelection')}>
                        What if I don't have any events coming up?
                    </div>

                    <NextButton 
                        text="Create My Program" 
                        action={() => OnNextButton()} 
                    />
                </div>

            </BodyFrame>

        </ScreenFrame>
    )
}
