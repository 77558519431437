import { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { ModalContext } from '../ModalContext'

import LoadingButton from 'components/LoadingButton'

import AppleIcon from 'media/misc/Apple.png'
import AndroidIcon from 'media/misc/Android.png'
import SafariLogo from 'media/misc/SafariLogo.png'
import ChromeLogo from 'media/misc/GoogleChrome.png'
import SafariShareIconLocation from 'media/misc/SafariShareIconLocation.png'
import SafariAddToHomeScreenLocation from 'media/misc/SafariAddToHomeScreenLocation.png'
import AndroidSettingsButtonLocation from 'media/misc/AndroidSettingsButtonLocation.png'
import AndroidInstallButtonLocation from 'media/misc/AndroidInstallButtonLocation.png'

export default function AddToHomeScreenModal() {

    const styleConfig = useSelector((state) => state.brand.styleConfig)

    const { modalData, HideModal } = useContext(ModalContext)

    const tabs = [
        { name: 'iPhone', icon: AppleIcon },
        { name: 'Android', icon: AndroidIcon },
    ]

    const [selectedTab, setSelectedTab] = useState('iPhone')

    return (
        <div className='flex flex-col flex-1 gap-y-2'>
            
            <div className="text-center font-semibold leading-6 text-gray-900">One Quick Step!!</div>

            <div className='text-sm text-center text-gray-600'>
                Before getting started, add the app to your phone home screen. It will 
                {' '}<strong><em><u>GREATLY</u></em></strong> 
                {' '}improve the app experience
            </div>

            <div className="my-auto border border-gray-400 space-y-2 text-center rounded-xl bg-white overflow-hidden">

                <div>
                    <nav className="-mb-px flex" aria-label="Tabs">
                        {tabs.map((tab) => (
                            <a
                                key={tab.name}
                                onClick={() => {
                                    setSelectedTab(tab.name)
                                }}
                                className={`
                                    w-1/2 py-3 px-1 text-center font-medium flex justify-center items-center gap-x-2 text-sm
                                    ${tab.name === selectedTab ? 'text-gray-500' : 'bg-gray-100 text-gray-500'}
                                `}
                            >
                                <img src={tab.icon} alt={tab.name} className="w-6" />
                                <div>{tab.name}</div>
                            </a>
                        ))}
                    </nav>
                </div>

                {selectedTab === 'iPhone' && 
                    <div className='space-y-2 divide-y divide-gray-200 text-sm'>

                        <div className="flex items-center justify-center gap-x-1">
                            <div>Open</div>
                            <div className="flex items-center gap-x-1 bg-white px-1 rounded-full">
                                <img src={SafariLogo} className="h-5" />
                                <div>Safari</div>
                            </div>
                        </div>

                        <div className="space-y-2 p-2">
                            <div className="flex items-center justify-center gap-x-1">
                                <div>Go to</div>
                                <a href={styleConfig.text.appURL} className="text-blue-600 hover:text-blue-800 underline">
                                    {styleConfig.text.appURL}
                                </a>
                            </div>
                        </div>

                        <div className="space-y-2 p-2 rounded-lg">
                            <div>Press the Share button at the bottom of the screen</div>
                            <img src={SafariShareIconLocation} className="rounded-lg h-12 mx-auto" />
                        </div>

                        <div className="space-y-2 p-2 rounded-lg">
                            <div>Select Add to Home Screen</div>
                            <img src={SafariAddToHomeScreenLocation} className="rounded-lg h-28 mx-auto" />
                        </div>
                    </div>
                }

                {selectedTab === 'Android' && 
                    <div className='space-y-2 divide-y divide-gray-200 text-sm'>

                        <div className="flex items-center justify-center gap-x-1">
                            <div>Open</div>
                            <div className="flex items-center gap-x-1 bg-white px-1 rounded-full">
                                <img src={ChromeLogo} className="h-5" />
                                <div>Google Chrome</div>
                            </div>
                        </div>

                        <div className="space-y-2 p-2">
                            <div className="flex items-center justify-center gap-x-1">
                                <div>Go to </div>
                                <a href={styleConfig.text.appURL} className="text-blue-600 hover:text-blue-800 underline">
                                    {styleConfig.text.appURL}
                                </a>
                            </div>
                        </div>

                        <div className="space-y-2 p-2 rounded-lg">
                            <div>Press the Settings button at the top of the screen</div>
                            <img src={AndroidSettingsButtonLocation} className="rounded-lg h-12 mx-auto" />
                        </div>

                        <div className="space-y-2 p-2 rounded-lg">
                            <div>Select Install App</div>
                            <img src={AndroidInstallButtonLocation} className="rounded-lg h-28 mx-auto" />
                        </div>
                    </div>
                }

            </div>

            {/* Button */}
            <div className='mt-auto'>
                <LoadingButton
                    buttonText={modalData.buttonText}
                    action={() => {
                        modalData.buttonAction()
                        HideModal()
                    }}
                />
            </div>

        </div>
    )
}
