import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { resetUserState } from 'redux/slices/user'
import { resetSubscriptionState } from 'redux/slices/subscription'
import { clearWhichWeightCache } from 'redux/slices/whichWeightCache'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { IsUserPartOfAnOrganization } from 'functions/userUtils'

import ScreenFrame from 'components/Frame/ScreenFrame'
import LogoHeader from 'components/Header/LogoHeader'
import BodyFrame from 'components/Frame/BodyFrame'
import LoadingButton from 'components/LoadingButton'

export default function JoinOrganization() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userId = useSelector((state) => state.user.id)
    
    const [organizationCode, setOrganizationCode] = useState('')
    const HandleOrganizationCodeChange = (event) => {
        setOrganizationCode(event.target.value.toLowerCase().replace(' ', '-'))
    }

    const [organizationCodeVerifyLoading, setOrganizationCodeVerifyLoading] = useState(false)
    const [verifyErrorMessage, setVerifyErrorMessage] = useState('')

    const [organizationId, setOrganizationId] = useState(null)
    const [organizationName, setOrganizationName] = useState(null)
    const [organizationLogo, setOrganizationLogo] = useState(null)
    const [organizationHandlesMembership, setOrganizationHandlesMembership] = useState(false)

    const [joinLoading, setJoinLoading] = useState(false)
    
    const VerifyOrganizationCode = async () => {

        setOrganizationCodeVerifyLoading(true)
        setVerifyErrorMessage('')

        // Check that the user even entered a code
        if (organizationCode === '') {
            setOrganizationCodeVerifyLoading(false)
            setVerifyErrorMessage('Please enter an organization code')
            return
        }

        const jsonBody = {
            organizationCode: organizationCode.toLowerCase(),
        }

        // console.log(jsonBody); return;

        const result = await WhichWeightAPI('CORE', 'GET', '/organization_details_by_code', jsonBody)

        // console.log(result);

        // Check for API failure
        if (result.data.status === 'failure') {
            setOrganizationCodeVerifyLoading(false)
            setVerifyErrorMessage('An error has occured. Please try again')
            console.error(result)
            return
        }

        // Check for no organization found
        if (!result.data.data.organizationFound) {
            setOrganizationCodeVerifyLoading(false)
            setVerifyErrorMessage('No organization found with code ' + organizationCode)
            return
        }

        setOrganizationCodeVerifyLoading(false)

        setOrganizationId(result.data.data.organizationId)
        setOrganizationName(result.data.data.organizationName)
        setOrganizationLogo(result.data.data.organizationLogo)
        setOrganizationHandlesMembership(result.data.data.organizationHandlesMembership)

    }

    const OnRemoveOrganizationButton = () => {
        setOrganizationCode('')
        setOrganizationId(null)
        setOrganizationName(null)
        setOrganizationLogo(null)
        setOrganizationHandlesMembership(null)
    }

    const OnNextButton = async () => {
    
        const jsonBody2 = {
            userId: userId,
            organizationId: organizationId,
        }

        // console.log(jsonBody2)

        const result = await WhichWeightAPI('CORE', 'PATCH', '/attach_user_to_organization', jsonBody2)

        setJoinLoading(false)

        // console.log(result2)

        // Check for API failure
        if (result.data.status === 'failure') {
            // setJoinErrorMessage('Unable to join organization. Please try again')
            console.error(result)
            return
        }

        // Reset redux
        dispatch(resetUserState())
        dispatch(resetSubscriptionState())
        dispatch(clearWhichWeightCache())

        // Navigate home
        if (organizationHandlesMembership) navigate('/home')
        else navigate('/profile/membership')
    
    }

    return (
        <ScreenFrame>

            <LogoHeader backButtonAction={() => navigate(-1)} />

            <BodyFrame>

                {/* Text */}
                <div className="space-y-2">
                    <h1 className="text-xl font-bold text-gray-800 text-center">Join Your Organization</h1>
                    <div className="text-sm text-center text-gray-800">Please enter your organization code below</div>
                </div>

                {/* Verify Organization Code */}
                {!IsUserPartOfAnOrganization(organizationId, organizationName, organizationLogo) && (
                    <>
                        {/* Code Input */}
                        <div className="space-y-4">
                            <input
                                type="text"
                                name="organizationCode"
                                id="organizationCode"
                                className="w-full px-4 py-2 block rounded-md text-gray-900 text-center shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400"
                                placeholder="Enter Organization Code"
                                value={organizationCode}
                                onChange={HandleOrganizationCodeChange}
                            />

                            {verifyErrorMessage !== '' && <div className="text-sm font-semibold text-center text-red-400">{verifyErrorMessage}</div>}

                            <LoadingButton
                                buttonText="Join"
                                isLoading={organizationCodeVerifyLoading}
                                className="flex items-center justify-center gap-x-2 w-full text-center text-white rounded-full py-2 px-4 text-lg font-semibold"
                                action={() => VerifyOrganizationCode()}
                            />

                        </div>
                    </>
                )}

                {/* Display Organization Details */}
                {IsUserPartOfAnOrganization(organizationId, organizationName, organizationLogo) && (
                    <>
                        <div className="space-y-4">
                            {/* Organization Card */}
                            <div className="flex items-center p-4 gap-x-2 border border-gray-400 rounded-xl">
                                <img src={organizationLogo} alt="Organization Logo" className="w-10 h-10 rounded-full border object-cover" />

                                <div className="text-lg flex-grow">{organizationName}</div>

                                <div className="flex w-20 justify-end py-4 pr-4 -m-4" onClick={() => OnRemoveOrganizationButton()}>
                                    <XCircleIcon className="h-8 text-gray-400" />
                                </div>
                            </div>

                            {!organizationHandlesMembership && (
                                <div className="text-center italic text-gray-600">
                                    WhichWeight membership is still managed<br/> by the individual                                    
                                </div>
                            )}

                            {organizationHandlesMembership && (
                                <div className="text-center italic text-gray-600">
                                    WhichWeight membership is managed by<br />{organizationName}
                                </div>
                            )}

                        </div>

                        {/* Footer */}
                        <div className="mt-auto">
                            <LoadingButton
                                buttonText="Join"
                                isLoading={joinLoading}
                                className="flex items-center justify-center gap-x-2 w-full text-center text-white rounded-full py-2 px-4 text-lg font-semibold"
                                action={() => OnNextButton()} 
                            />
                        </div>
                    </>
                )}

            </BodyFrame>

        </ScreenFrame>
    )
}
