import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setReduxSelectedPricingOption, setReduxStripeCustomerId, setReduxStripeSubscriptionId, setReduxStripeClientSecret, resetSubscriptionState } from 'redux/slices/subscription'
import { DoesCouponTakeFullAmountOff } from 'functions/subscriptionUtils'

import Header from 'components/ProgramAssignment/Header'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import PricingOptions from 'components/Billing/PricingOptions'
import PromoCode from 'components/Billing/PromoCode'
import Subtotal from 'components/Billing/Subtotal'
import LoadingButton from 'components/LoadingButton'

export default function SubscriptionOptions() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const organizationId = useSelector((state) => state.user.organizationId)
    const email = useSelector((state) => state.user.email)
    const usedFreeTrial = useSelector((state) => state.user.usedFreeTrial)
    const couponData = useSelector((state) => state.subscription.couponData)

    // Pricing option variables
    const [pricingOptions, setPricingOptions] = useState(null)
    const [selectedPricingOption, setSelectedPricingOption] = useState(null)
    const [purchaseButtonLoading, setPurchaseButtonLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const GetPricingInfoFromStripe = async () => {
        const jsonBody = {
            organizationId: organizationId,
        }

        // console.log(jsonBody)

        const result = await WhichWeightAPI('BILLING', 'GET', '/prices', jsonBody)

        // console.log(result.data.data);

        setPricingOptions(result.data.data)
        setSelectedPricingOption(result.data.data[0])
    }

    const OnPurchaseSubscriptionButton = async () => {
        setPurchaseButtonLoading(true)
        setErrorMessage('')

        // Store selected pricing option in redux store
        dispatch(setReduxSelectedPricingOption(selectedPricingOption))

        const jsonBody = {
            email: email,
        }

        const getCustomerIdResult = await WhichWeightAPI('BILLING', 'GET', '/customer_id', jsonBody)

        // console.log(result.data.data)

        if (getCustomerIdResult.data.status === 'failure') {
            setPurchaseButtonLoading(false)
            dispatch(resetSubscriptionState())
            setErrorMessage('Could not get customer id. Please try again and contact support if issue persists')
            console.error(getCustomerIdResult)
            return
        }

        // Store stripe customer id in redux store
        dispatch(setReduxStripeCustomerId(getCustomerIdResult.data.data.stripeCustomerId))

        // SCENARIO 1 (tag: full-off-coupon)
        // COUPON TAKES FULL AMOUNT OFF
        // Just create the subscription with no billing info and make sure the subscription is set to cancel at end of period
        if (DoesCouponTakeFullAmountOff(couponData, selectedPricingOption.unit_amount)) {
            const jsonBody = {
                scenarioTag: 'full-off-coupon',
                email: email,
                stripeCustomerId: getCustomerIdResult.data.data.stripeCustomerId,
                priceId: selectedPricingOption.id,
                freeTrialLength: 0,
                couponData: couponData, // Optional. Can be null
            }

            // console.log(jsonBody);

            const createSubscriptionResult = await WhichWeightAPI('BILLING', 'POST', '/create_subscription', jsonBody)

            setPurchaseButtonLoading(false)

            if (createSubscriptionResult.data.status === 'failure') {
                dispatch(resetSubscriptionState())
                setErrorMessage('Could not create subscription. Please try again. If issue persists please contact support')
                console.error(createSubscriptionResult)
                return
            }

            navigate('/subscription/success')

            return
        }

        // SCENARIO 2 (tag: create-with-free-trial)
        // SIGNING UP FOR A FREE TRIAL
        // Take them to a screen to capture billing info
        // Only create their subscription after billing info has been captured
        if (!usedFreeTrial) {
            setPurchaseButtonLoading(false)

            navigate('/subscription/paymentMethod')
        }

        // SCENARIO 3 (tag: create-without-free-trial)
        // SIGNING UP WITH NO FREE TRIAL
        // Create the subscription here and take them to a checkout screen
        // They must pay the first invoice now
        if (usedFreeTrial) {
            // Create subscription

            const jsonBody = {
                scenarioTag: 'create-without-free-trial',
                email: email,
                stripeCustomerId: getCustomerIdResult.data.data.stripeCustomerId,
                priceId: selectedPricingOption.id,
                freeTrialLength: 0,
                couponData: couponData, // Optional. Can be null
            }

            // console.log(jsonBody);

            const createSubscriptionResult = await WhichWeightAPI('BILLING', 'POST', '/create_subscription', jsonBody)

            // console.log(createSubscriptionResult);

            if (createSubscriptionResult.data.status === 'failure') {
                setPurchaseButtonLoading(false)
                dispatch(resetSubscriptionState())
                setErrorMessage('Could not create subscription. Please try again. If issue persists please contact support')
                console.error(createSubscriptionResult)
                return
            }

            dispatch(setReduxStripeSubscriptionId(createSubscriptionResult.data.data.stripeSubscriptionId))
            dispatch(setReduxStripeClientSecret(createSubscriptionResult.data.data.clientSecret))

            setPurchaseButtonLoading(false)

            navigate('/subscription/checkout')
        }
    }

    useEffect(() => {
        dispatch(resetSubscriptionState())

        GetPricingInfoFromStripe()
    }, [])

    return (
        <>
            <Header
                backButtonAction={() => {
                    navigate(-1)
                }}
            />

            <div className="p-4 space-y-4">

                {/* Loading Animation */}
                {pricingOptions === null && <MediumLoadingComponent />}

                {/* Subscription Options */}
                {pricingOptions !== null && selectedPricingOption !== null && (
                    <>

                        <div className="text-xl font-semibold text-gray-600 py-2 text-center">Membership Options</div>

                        <PricingOptions pricingOptions={pricingOptions} selectedPricingOption={selectedPricingOption} setSelectedPricingOption={setSelectedPricingOption} />

                        <PromoCode />

                        <Subtotal interval={selectedPricingOption.recurring.interval_count} amountDue={selectedPricingOption.unit_amount} />

                        {/* Error Message */}
                        {errorMessage.length > 0 && <div className="text-center font-semibold text-red-400">Error: {errorMessage}</div>}

                        {/* Purchase Button */}
                        <div className="pt-2">
                            <LoadingButton
                                buttonText={usedFreeTrial ? 'Checkout' : 'Start Free Trial'}
                                isLoading={purchaseButtonLoading}
                                className="w-full flex justify-center gap-x-4 items-center text-center text-white rounded-full py-2 px-4 text-lg font-semibold bg-gradient-to-r from-orange-600 to-orange-400"
                                action={OnPurchaseSubscriptionButton}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    )
}
