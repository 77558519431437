import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { CapitalizeFirstLetter } from 'functions/textUtils'
import { GetEquipmentIcon } from 'functions/iconUtils'
import { GetPrimaryColorHex } from 'redux/slices/brand'

export default function EquipmentDetails({ equipmentDetails, roundedRecommendation, style = 'expanded' }) {
    const styleConfig = useSelector((state) => state.brand.styleConfig)

    var formattedNames = {
        barbell: 'Barbell',
        'hex-bar': 'Hex Bar',
    }

    if (equipmentDetails === undefined || equipmentDetails === null) return null

    if (style === 'expanded') {
        return (
            <div className="p-4 space-y-4 mb-4">
                <div className="text-2xl font-bold opacity-75">Equipment</div>

                {/* Unilateral info */}
                {equipmentDetails.lateral === 'unilateral' && (
                    <>
                        {/* Map over each equipment option. Most likely dumbbell,kettlebell */}
                        {equipmentDetails.equipment.map((equipmentOption, index) => (
                            <div 
                                key={index}
                                className="flex items-center justify-center px-4 py-2 rounded-xl text-xl"
                                style={{
                                    backgroundColor: GetPrimaryColorHex(styleConfig)
                                }}
                            >
                                {/* Equipment icon */}
                                <img className="h-5 w-5 flex-none rounded-full" src={GetEquipmentIcon(equipmentOption)} alt={equipmentOption} />

                                {/* Single equipment quantity */}
                                {equipmentDetails.equipmentQuantity === 'single' && (
                                    <p className="text-gray-200">
                                        {roundedRecommendation} lb {equipmentOption}
                                    </p>
                                )}

                                {/* Double equipment quantity */}
                                {equipmentDetails.equipmentQuantity === 'double' && (
                                    <p className="text-gray-200">
                                        2 x {roundedRecommendation / 2} lb {equipmentOption}s
                                    </p>
                                )}
                            </div>
                        ))}
                    </>
                )}

                {/* Bilateral info */}
                {equipmentDetails.lateral === 'bilateral' && (
                    <>
                        {/* Map over each equipment option */}
                        {equipmentDetails.equipment.map((equipmentOption, index) => (
                            <div 
                                key={index} 
                                className="px-4 py-2 rounded-xl text-gray-200"
                                style={{
                                    backgroundColor: GetPrimaryColorHex(styleConfig)
                                }}
                            >
                                {/* Barbell or hex bar weight type */}
                                {(equipmentOption === 'barbell' || equipmentOption === 'hex-bar') && (
                                    <>
                                        {/* If weight recommendation less than 45 lbs (the weight of the bar) */}
                                        {roundedRecommendation <= 45 && (
                                            <div className="flex justify-center items-center gap-x-2">
                                                {/* Equipment Icon */}
                                                <img className="h-6 w-6 rounded-full" src={GetEquipmentIcon(equipmentOption)} alt={equipmentOption} />

                                                <p className="text-xl text-gray-200">{roundedRecommendation} lb fixed weight barbell</p>
                                            </div>
                                        )}

                                        {/* If weight recommendation at or above 45 lbs (the weight of the bar) */}
                                        {45 < roundedRecommendation && (
                                            <>
                                                <div className="flex justify-center items-center gap-x-2 font-semibold">
                                                    {/* Equipment Icon */}
                                                    <img className="ml-2 h-6 w-6 flex-none rounded-full" src={GetEquipmentIcon(equipmentOption)} alt={equipmentOption} />

                                                    {/* Equipment Name */}
                                                    <p className="text-gray-200 text-xl">{formattedNames[equipmentOption]} (45 lb bar)</p>
                                                </div>

                                                <p>On each side of the bar add the following:</p>

                                                {/* Weights on each side */}
                                                {equipmentDetails.bilateralOnly.weightsCountOnEachSide !== undefined && (
                                                    <div>
                                                        {equipmentDetails.bilateralOnly.weightsCountOnEachSide[45] !== 0 && (
                                                            <div>{equipmentDetails.bilateralOnly.weightsCountOnEachSide[45]} x 45 lbs</div>
                                                        )}
                                                        {equipmentDetails.bilateralOnly.weightsCountOnEachSide[25] !== 0 && (
                                                            <div>{equipmentDetails.bilateralOnly.weightsCountOnEachSide[25]} x 25 lbs</div>
                                                        )}
                                                        {equipmentDetails.bilateralOnly.weightsCountOnEachSide[10] !== 0 && (
                                                            <div>{equipmentDetails.bilateralOnly.weightsCountOnEachSide[10]} x 10 lbs</div>
                                                        )}
                                                        {equipmentDetails.bilateralOnly.weightsCountOnEachSide[5] !== 0 && (
                                                            <div>{equipmentDetails.bilateralOnly.weightsCountOnEachSide[5]} x 5 lbs</div>
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}

                                {/* Dumbbell or kettlebell weight type */}
                                {(equipmentOption === 'dumbbell' || equipmentOption == 'kettlebell') && (
                                    <div className="flex justify-center gap-x-2 py-1 items-center">
                                        {/* Equipment icon */}
                                        <img className="h-6 w-6 rounded-full" src={GetEquipmentIcon(equipmentOption)} alt={equipmentOption} />

                                        {/* Single equipment quantity */}
                                        {equipmentDetails.equipmentQuantity === 'single' && (
                                            <p className="text-gray-200">
                                                {roundedRecommendation} lb {equipmentOption}
                                            </p>
                                        )}

                                        {/* Double equipment quantity */}
                                        {equipmentDetails.equipmentQuantity === 'double' && (
                                            <p className="text-gray-200">
                                                2 x {roundedRecommendation / 2} lb {equipmentOption}s
                                            </p>
                                        )}
                                    </div>
                                )}

                                {/* Machine weight type */}
                                {equipmentOption === 'machine' && (
                                    <div div className="flex justify-center gap-x-2 py-1 items-center">
                                        {/* Equipment icon */}
                                        <img className="h-6 w-6 rounded-full" src={GetEquipmentIcon(equipmentOption)} alt={equipmentOption} />

                                        <p className="text-gray-200">{roundedRecommendation} lb machine setting</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </div>
        )
    }

    if (style === 'modal') {
        return (
            <>
                {/* Unilateral info */}
                {equipmentDetails.lateral === 'unilateral' && (
                    <>
                        {/* Map over each equipment option. Most likely dumbbell,kettlebell */}
                        {equipmentDetails.equipment.map((equipmentOption, index) => (
                            <div key={index} className="flex">
                                {/* Left Hand Side name and icon */}
                                <div className="flex-grow flex items-center gap-x-2">
                                    <img className="h-4" alt="Equipment Icon" src={GetEquipmentIcon(equipmentOption)} />

                                    <div>{CapitalizeFirstLetter(equipmentOption)}</div>
                                </div>

                                {/* Dumbbell or kettlebell weight type */}
                                {(equipmentOption === 'dumbbell' || equipmentOption == 'kettlebell') && (
                                    <>
                                        {equipmentDetails.equipmentQuantity === 'single' && <p>{roundedRecommendation} lbs</p>}

                                        {equipmentDetails.equipmentQuantity === 'double' && <p>{roundedRecommendation / 2} lbs / side</p>}
                                    </>
                                )}

                                {/* Machine weight type */}
                                {equipmentOption === 'machine' && <p>{roundedRecommendation} lb setting</p>}
                            </div>
                        ))}
                    </>
                )}

                {/* Bilateral info */}
                {equipmentDetails.lateral === 'bilateral' && (
                    <>
                        {/* Map over each equipment option */}
                        {equipmentDetails.equipment.map((equipmentOption, index) => (
                            <Fragment key={index}>
                                {(equipmentOption === 'dumbbell' || equipmentOption == 'kettlebell' || equipmentOption === 'machine') && (
                                    <div className="flex">
                                        {/* Left Hand Side name and icon */}
                                        <div className="flex-grow flex items-center gap-x-2">
                                            <img className="h-4" alt="Equipment Icon" src={GetEquipmentIcon(equipmentOption)} />

                                            <div>{CapitalizeFirstLetter(equipmentOption)}</div>
                                        </div>

                                        {/* Dumbbell or kettlebell weight type */}
                                        {(equipmentOption === 'dumbbell' || equipmentOption == 'kettlebell') && (
                                            <>
                                                {equipmentDetails.equipmentQuantity === 'single' && <p>{roundedRecommendation} lbs</p>}

                                                {equipmentDetails.equipmentQuantity === 'double' && <p>{roundedRecommendation / 2} lbs / side</p>}
                                            </>
                                        )}

                                        {/* Machine weight type */}
                                        {equipmentOption === 'machine' && <p>{roundedRecommendation} lb setting</p>}
                                    </div>
                                )}

                                {/* Barbell or hex bar weight type */}
                                {(equipmentOption === 'barbell' || equipmentOption === 'hex-bar') && (
                                    <>
                                        <div className="flex-grow flex items-center gap-x-2">
                                            <img className="h-4" alt="Equipment Icon" src={GetEquipmentIcon(equipmentOption)} />

                                            <div>
                                                {CapitalizeFirstLetter(equipmentOption)} {roundedRecommendation >= 45 ? '(45 lb bar)' : ''}
                                            </div>
                                        </div>

                                        {roundedRecommendation <= 45 && <p>{roundedRecommendation} lb fixed barbell</p>}

                                        {45 < roundedRecommendation && (
                                            <>
                                                <div>Add the following weights to each side</div>

                                                <div className="text-center">
                                                    {equipmentDetails.bilateralOnly.weightsCountOnEachSide !== undefined && (
                                                        <>
                                                            {equipmentDetails.bilateralOnly.weightsCountOnEachSide[45] !== 0 && (
                                                                <div>{equipmentDetails.bilateralOnly.weightsCountOnEachSide[45]} x 45 lbs</div>
                                                            )}
                                                            {equipmentDetails.bilateralOnly.weightsCountOnEachSide[25] !== 0 && (
                                                                <div>{equipmentDetails.bilateralOnly.weightsCountOnEachSide[25]} x 25 lbs</div>
                                                            )}
                                                            {equipmentDetails.bilateralOnly.weightsCountOnEachSide[10] !== 0 && (
                                                                <div>{equipmentDetails.bilateralOnly.weightsCountOnEachSide[10]} x 10 lbs</div>
                                                            )}
                                                            {equipmentDetails.bilateralOnly.weightsCountOnEachSide[5] !== 0 && (
                                                                <div>{equipmentDetails.bilateralOnly.weightsCountOnEachSide[5]} x 5 lbs</div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </Fragment>
                        ))}
                    </>
                )}
            </>
        )
    }
}
