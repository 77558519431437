import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GetPrimaryColorHex } from 'redux/slices/brand'

export default function RestBody({ phaseIndex, exerciseIndex, exercise, setIndex, ExpandedModeNavigateToNextSet }) {
    
    const styleConfig = useSelector((state) => state.brand.styleConfig)

    // Timer variables
    const [timerDuration, setTimerDuration] = useState(exercise.restSecondsAfterExercise)
    const [progress, setProgress] = useState(100) // Progress in percentage

    const radius = 145 // Radius of the circle
    const circumference = 2 * Math.PI * radius // Circumference of the circle

    // Reset timer progress to 100% whenever we change exercise sets
    useEffect(() => {
        // Reset timer variables
        setTimerDuration(exercise.restSecondsAfterExercise)
        setProgress(100)
    }, [phaseIndex, exerciseIndex, setIndex, exercise.restSecondsAfterExercise])

    // Start the timer if progress is reset
    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                const newProgress = oldProgress - 1 / timerDuration
                if (newProgress < 0) {
                    clearInterval(interval)
                    return 0
                }
                return newProgress
            })
        }, 10)

        return () => clearInterval(interval)
    }, [timerDuration, progress])

    return (
        <>
            {/* Absolute positioning */}
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
                {/* Text */}
                <div className="flex flex-col h-[310px] items-center justify-center space-y-2">
                    <div className="text-5xl font-bold">Rest</div>
                    <div className="text-3xl font-semibold">{Math.round((progress / 100) * timerDuration)} seconds</div>
                </div>
            </div>

            {/* Absolute positioning */}
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                {/* Radial Dial */}
                <svg width="350" height="350">
                    {/* Background Circle */}
                    <circle cx="175" cy="175" r={radius} stroke="#dedede" opacity={0.25} strokeWidth="20" fill="transparent" />

                    {/* Timer Circle */}
                    <circle
                        cx="175"
                        cy="175"
                        r={radius}
                        stroke={GetPrimaryColorHex(styleConfig, -1)}
                        strokeWidth="20"
                        fill="transparent"
                        strokeDasharray={`${circumference} ${circumference}`}
                        strokeDashoffset={(-(100 - progress) / 100) * circumference}
                        transform="rotate(-90 175 175)"
                    />
                </svg>
            </div>

            {/* Footer Buttons */}
            <div className="mt-auto pb-8 px-4 space-y-4 w-full">
                {/* Next Button */}
                <button 
                    className="w-full text-center text-gray-600 rounded-full py-2 px-4 text-md bg-white font-semibold shadow-sm" 
                    onClick={() => ExpandedModeNavigateToNextSet()}
                >
                    Next
                </button>
            </div>
        </>
    )
}
