export const equipmentMedia = {
    'band': {
        'name': 'Resistance Bands',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/band.png'
    },
    'barbell': {
        'name': 'Barbell & Plates',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/barbell.png'
    },
    'dumbbell': {
        'name': 'Dumbbells',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/dumbbell.png'
    },
    'exercise-ball': {
        'name': 'Exercise Ball',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/exercise-ball.png'
    },
    'foam-roller': {
        'name': 'Foam Roller',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/foam-roller.png'
    },
    'hex-bar': {
        'name': 'Hex Bar & Plates',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/hex-bar.png'
    },
    'kettlebell': {
        'name': 'Kettlebells',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/kettlebell.png'
    },
    'light-bar': {
        'name': 'Dowel',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/light-bar.png'
    },
    'machine': {
        'name': 'Cable Machine',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/machine.png'
    },
    'massage-ball': {
        'name': 'Massage Ball',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/massage-ball.png'
    },
    'medicine-ball': {
        'name': 'Medicine Ball',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/medicine-ball.png'
    },
    'slider': {
        'name': 'Slider',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/slider.png'
    },
    'trx': {
        'name': 'TRX Cables',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/trx.png'
    }
}

export const equipmentOptions = [
    {
        'key': 'band',
        'name': 'Resistance Bands',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/band.png',
        'description': 'Elastic bands with handles on each end',
        'required': true,
        'shoppingLink': 'https://www.amazon.com/VEICK-Resistance-Exercise-Portable-Training/dp/B086X4PN48/ref=sr_1_5?crid=33R6I9WKK5LKK&dib=eyJ2IjoiMSJ9.kmwXw78i3kNxW4v-QUMaZ3ktfU77kKModyVD7CAbWQCtYH2rlQiEpgAre2AZY5Hg4ipPujQBVtLvWrfKUZA4Zt_taK6mBmvF-1wTqhUqyaayFXb9fJ8Aef8n444oPwx31IIcpMrSwQYrJrgoNvqQByyyXpPBft006rTn4P4mMnXD8EN7L0uLIOz653jCRyyIEwoZhVT4L7Hdd4EkIsc31IS0gSOWz6jWXBr9ImMo7P2Zepj4KY8KHItirQEJKJZ5tWxYTaPzLeBeUI5C_aHUAK0Ah_zOejqBaKs8G-EJzUg.ggloTol9mOxSazdU6wtFlhE3mVmaxAm88wrT-bX3MwE&dib_tag=se&keywords=At%2BHome%2Bresistance%2Bband&qid=1723752982&s=sporting-goods&sprefix=at%2Bhome%2Bresistance%2Bband%2Csporting%2C121&sr=1-5&th=1'
    },
    {
        'key': 'barbell',
        'name': 'Barbell & Plates',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/barbell.png',
        'description': '45 lb barbell with a range of plates',
        'required': false,
        'shoppingLink': ''
    },
    {
        'key': 'dumbbell',
        'name': 'Dumbbells',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/dumbbell.png',
        'description': 'A range of handheld weights',
        'required': true,
        'shoppingLink': 'https://www.amazon.com/FEIERDUN-Adjustable-Dumbbells-Connector-Kettlebells/dp/B0CSYJV73G/?_encoding=UTF8&pd_rd_w=VmP0s&content-id=amzn1.sym.255b3518-6e7f-495c-8611-30a58648072e%3Aamzn1.symc.a68f4ca3-28dc-4388-a2cf-24672c480d8f&pf_rd_p=255b3518-6e7f-495c-8611-30a58648072e&pf_rd_r=Z0VE0553DKF8A66JTS0W&pd_rd_wg=VCYOH&pd_rd_r=9a5c97dc-ffb9-4e0e-88c6-8a98591ab58f&ref_=pd_hp_d_atf_ci_mcx_mr_ca_hp_atf_d&th=1'
    },
    {
        'key': 'exercise-ball',
        'name': 'Exercise Ball',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/exercise-ball.png',
        'description': 'Inflatable ball for core and stability',
        'required': true,
        'shoppingLink': 'https://www.amazon.com/APEXUP-Exercise-Stability-Fitness-Physical/dp/B0CGLSXPVF/ref=sr_1_2_sspa?crid=2E0SM85TE6R76&dib=eyJ2IjoiMSJ9.nub55UUx8jGC5ykx1jKrZTzAIS8AT7ARLk4_7ZwBCoSZecvGJf3V7swwHdb4jsos_7UvuZgKmOl6K7dTLQAJl7h6vmIxgaeQRyu8DtK9OBRHEDa0nGFNiZJSl5Fsc7qcNR_EXJUhDwPvmEVVlkqeqa6LT8mc9R1BCX2rGyHRS8thG-WmdbofYVCPYcc8DjbNaRRN4fGGZTfiBjXRsJ1V9KJG0EG9qlpeaNl6dDxF5sxcmaRkg7H35pSRz1eTpCeCQ-IHkoNZYaQ3x6X0pLH_YntEixsqyzfXSJXpIbJlNdo.ZWLAxkQo5axN2zY7-SbQwYguZ1tMqLpwaKwduLB4_Os&dib_tag=se&keywords=exercise%2Bball&qid=1723753027&s=sporting-goods&sprefix=exercise%2Bball%2Csporting%2C121&sr=1-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&psc=1'
    },
    {
        'key': 'foam-roller',
        'name': 'Foam Roller',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/foam-roller.png',
        'description': 'Cylindrical foam tool for recovery',
        'required': true,
        'shoppingLink': 'https://www.amazon.com/AmazonBasics-High-Density-Round-Roller-24-inches/dp/B071P2MQ5D/ref=sr_1_1_ffob_sspa?crid=3E42CABTVMQ4Z&dib=eyJ2IjoiMSJ9.b0GMVqdrhl6M9mj_xhl3W-H2NkayTKiRVgBiuaatFuyWuHYC_3Tb1mBaIjAKEskuutIPUCJrE6gk3FoDvFkArKS5bUtOIW8Pc_za3V6OG7lBYnQQM-9K2TRyBAXE9dUNbnk4ArBbjD3JuJXVaqElAgzznLwbvs1RwjmVRtr64-iqgnw2om58nzZY3y5X3bhezK1By1z-OiCfCoKPM6PE6L80m3RDDSe33vw5DCpY-xK6M577_8feKrKJ32PFVdbtGTrSRLaPr550ixyLt7tZf4jqFR0mqWUX1vNxz4YkiOs.j3cB8HBLFO0CKA-kLOSyahmRCDcOOstkcDb7cw4en7w&dib_tag=se&keywords=foam+roller&qid=1723753056&s=sporting-goods&sprefix=foam+roller%2Csporting%2C113&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1'
    },
    {
        'key': 'hex-bar',
        'name': 'Hex Bar & Plates',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/hex-bar.png',
        'description': 'Hexagonal-shaped barbell',
        'required': false,
        'shoppingLink': ''
    },
    {
        'key': 'kettlebell',
        'name': 'Kettlebells',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/kettlebell.png',
        'description': 'Cast iron weights with a handle',
        'required': false,
        'shoppingLink': ''
    },
    {
        'key': 'light-bar',
        'name': 'Dowel',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/light-bar.png',
        'description': 'Lightweight bar used for flexibility',
        'required': true,
        'shoppingLink': 'https://www.amazon.com/Stretch-Flexibility-Physical-Exercise-Corrector/dp/B0CZF8QH31/ref=sr_1_4?crid=NKQAABJ1BRLL&dib=eyJ2IjoiMSJ9.6SoG5uiuzw8F7PV3gEDb8pnYP3moBEJlfjV33CTeI4864p5Otphla-UOJEd0_yTSWkI-mrr7WBZi_vZt1fOo_ygq1nBJABejrYCNvjsbRriNHBJDGO08PgeGk3ZT-07Ce61KzX4-ASeVl4ofN7NGzg8YJ8GK0o194FoiDsl45MDgys6nUsWqWraikhgIFq9G1vJ_kc4ElEMC8Juf7yb-1p7UXnXF93vQ2R6PgI9NSkUkDW9FHdh-7hNsWoyM0vjbTqaJ3kaWAqU6EXPbYPLBVaSx-XHe5URROqBDvZVyXnk.E82tKki_AIePM2yGpjxbdy3xcZ6t4bVbekiIYIAupd4&dib_tag=se&keywords=stretch%2Bdowel&qid=1724112421&sprefix=stretchdowel%2Caps%2C117&sr=8-4&th=1'
    },
    {
        'key': 'machine',
        'name': 'Cable Machine',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/machine.png',
        'description': 'Versatile machine with cables',
        'required': false,
        'shoppingLink': ''
    },
    {
        'key': 'massage-ball',
        'name': 'Massage Ball',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/massage-ball.png',
        'description': 'Small ball used to pinpoint muscle areas',
        'required': true,
        'shoppingLink': 'https://www.amazon.com/Massage-Lacrosse-Myofascial-Release-Therapy/dp/B017V7UKW2/ref=sr_1_1_sspa?crid=2182ZM323UISW&dib=eyJ2IjoiMSJ9.2QO6pTPtqUHUtnNDsT5vUTTXLfXHe8e9XDlaSzqV3hJ8-im3w2kWX1aqz7CBlvZoszcfbPDCuq5nsoavTan4YUBsJ6A1Ox1B9wN9yQSyzxHw-GLklTn3qAtnRmcGh_HUZ9Ye3a3Wnb31StIxsla3FLwJW-va52dxK7UwDUeTHBVgxdQM1HvQppffrRkj70W_jHnAhiQlSs0sc60YdzyuxY9p1O3MaYhyFT4NJFd3hG1KdUB5hXydcN13_jY6erM_xmpiopCTO2WlDJT3Wvbl_asQPapEETeW4ZlClhQsMQA.SqUxuQbyDbAhK5HtZJJDP_2Pp-mZaPZvm8MqqxRf-Ss&dib_tag=se&keywords=massage+ball&qid=1723753078&s=sporting-goods&sprefix=massage+ball%2Csporting%2C114&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1'
    },
    {
        'key': 'medicine-ball',
        'name': 'Medicine Ball',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/medicine-ball.png',
        'description': 'Weighted ball used for strength',
        'required': true,
        'shoppingLink': 'https://www.amazon.com/ProSource-Medicine-Textured-Conditioning-Exercises/dp/B07FQW95VG/ref=sr_1_7?crid=29HQORSA5IELG&dib=eyJ2IjoiMSJ9.JuJX45jLmE-xOiHUmGpT8zFj8K9S4upKMAJVfVUy0KGPtV_DmKyTMm5CRsRn1qsj9ItkXXKdN5WqRGPkZ1s6CqigEWcmpIkhjM4bwCrY2RVu3RJxBhNBJva8XR-JcMLBtUTosWzHPbp3aaV7L8Fim0_e1m2CUDAUS-yHUDUq3BVC3c-j-dUDy5jp2WBBky1Cg4V9eTl6V7rREcxdjn2fzPpL2PSRlBM2CE5PGc1ZulQYvc9a4d-D6hNL7CEge5eCW6g1sWm1gpCl0kWrcI14xYxLm26bXTZ1DqiA6oH61jw.umLUfRQm8A9xIZZ5jS0MW36aSL5jZ5rwe8x3NChrKHU&dib_tag=se&keywords=medicine+ball&qid=1724112451&sprefix=medicine+ball%2Caps%2C133&sr=8-7'
    },
    {
        'key': 'slider',
        'name': 'Slider',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/slider.png',
        'description': 'Disc that helps you slide on carpet',
        'required': true,
        'shoppingLink': 'https://www.amazon.com/AZURELIFE-Exercise-Surfaces-Portable-Abdominal/dp/B07M96HMDJ/ref=sr_1_1_sspa?crid=3JB1OVBNQUX0K&dib=eyJ2IjoiMSJ9.Xu3W8ZGSGhzXT8p2LDd-quBeLZvPrzBRBFPuY3NaozFH8p7PLHF1sujfBykEPPojNOofuftFak0qjmcQRAh08nmssaM6UAlWFJRPyzSGNj_k-INHXPzbfsw3-M96LafKrIUdK101ljJ79EZv4-rPHzJD4RBtycy7FBij57J7KQPAqNuZJFOuJWJZkJ2odW2RJoyer8q1Kaegr978S-xvnyXY9XlFYYymKTf4RumwbK06Nm69oIOCM42Cp98lfOCjfCPHwEG4DsnspBlPALP83YDd7MkN9FK9iuqrboo4_OA.Yc3Ho5Mn0TTFDz1rxU11ZOiXL6hjs63ELMP6AD65sVc&dib_tag=se&keywords=sliders+for+working+out&qid=1723753116&s=sporting-goods&sprefix=slider%2Csporting%2C126&sr=1-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1'
    },
    {
        'key': 'trx',
        'name': 'TRX Cables',
        'imageURL': 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/trx.png',
        'description': 'Suspension training system',
        'required': true,
        'shoppingLink': 'https://www.amazon.com/TRX-Suspension-Training-System-Travel/dp/B01LXL27XI/ref=sr_1_2_sspa?crid=1FNC9K1JSS973&dib=eyJ2IjoiMSJ9.6h3WEiJTCikzHlXScPSMuuU-He7gPU9jZw1OlLuxSb6xDI2b9m0gKKBX9MPAoi1CuLYt9ON-Dr5FmegLiZrTQGXvUj89nPC-dH87CkbWfvZCvBVPUiOysfYp2yS6Hic3Td-F73BFZ__9qARxFpJV7Ey__ceGZE_6bzaG2qEt8kjfpBzMI3hj80D9W2JwdFadaA8CeNgCCXOER_aq3L4qX3Uf_xDlabU8aWD43OPKCVle09WjPJEjGB6mN7tY6j2MVU_Q33ghkqZEK4eWzJzszCsUOUXRQTBKI5ITYCC4Hco.Glmjf_QKQHZWniRjfhmXIrclN3cQSr6eg1oYRK9E6Vg&dib_tag=se&keywords=trx+cables&qid=1724112473&sprefix=TRX+ca%2Caps%2C133&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1&smid=A3IZK3KMZE3924'
    }
]