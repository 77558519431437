import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetUserState } from 'redux/slices/user'
import { clearWhichWeightCache } from 'redux/slices/whichWeightCache'
import { setMembershipActive, setUsedFreeTrial, setSubscriptionStatus } from 'redux/slices/user'
import { resetSubscriptionState } from 'redux/slices/subscription'
import { TrackAndClearPromoCode } from 'functions/subscriptionUtils'
import iconLogo from 'media/icons/logo192.png'

export default function SubscriptionPurchaseSuccess() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const email = useSelector((state) => state.user.email)
    const couponData = useSelector((state) => state.subscription.couponData)

    const CleanSubscriptionReduxValues = () => {
        dispatch(setMembershipActive(true))
        dispatch(setUsedFreeTrial(true))
        dispatch(setSubscriptionStatus('active')) // TODO Change this?? Not 100% accurate. Will stop action center warnings though

        dispatch(resetSubscriptionState())

        TrackAndClearPromoCode(dispatch, email, couponData)
    }

    const OnNextButton = () => {

        dispatch(resetUserState())
        dispatch(clearWhichWeightCache())

        navigate('/home')

    }

    useEffect(() => {
        CleanSubscriptionReduxValues()
    }, [])

    return (
        <>
            <div className="p-2 space-y-2">
                {/* Icon Logo */}
                <img className="mx-auto" src={iconLogo} alt="WhichWeight Icon" />

                <div className="text-center text-orange-600 font-bold text-3xl">Congratulations!</div>

                <div className="text-center text-lg">You are now a WhichWeight Member</div>

                <div className="text-center text-gray-600 text-base">All content has been unlocked</div>
            </div>

            <div className="p-4 space-y-4">
                <button
                    type="button"
                    className="w-full flex justify-center gap-x-4 items-center text-center text-white rounded-full py-2 px-4 text-lg font-semibold bg-gradient-to-r from-orange-600 to-orange-400"
                    onClick={() => OnNextButton()}
                >
                    Start My WhichWeight Journey
                </button>
            </div>
        </>
    )
}
