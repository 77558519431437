const exerciseInfo = [
    {
      "exerciseId": 59,
      "name": "Sit to Stand",
      "importance": "Sit-to-stand strengthens your quads (agonist) as the main movers, while your hamstrings and glutes (antagonists) support stability and control 🍑. This is essential for strong and stable movements in running and cycling, which rely on balance and controlled power.",
      "build_up_exercises": [
        {
          "name": "Goblet Squats",
          "reason": "Builds quad and glute strength, focusing on the main movers in sit-to-stand.",
          "how_to": "Hold a weight at your chest, squat down as if you’re sitting, and stand up strongly 💪."
        },
        {
          "name": "Step-Ups",
          "reason": "Focuses on each leg independently, strengthening quads and glutes.",
          "how_to": "Step up onto a platform or sturdy surface, alternating legs 🦵."
        }
      ],
      "weakness_impact": "Weak quads and glutes can lead to imbalances in your movement, often overloading the hamstrings and knees. This can result in knee pain, hamstring strains, and even lower back discomfort due to lack of stability in the glutes 😬. Strengthening these muscles helps prevent these injuries and improves efficiency in each stride and pedal push."
    },
    {
      "exerciseId": 64,
      "name": "Copenhagen Plank",
      "importance": "The Copenhagen plank strengthens the inner thighs (adductors as agonists) and the outer thighs (abductors as antagonists), which work together to keep your hips and knees stable during running and cycling 🏃‍♂️. Strong inner thighs help align your legs and reduce strain on the knees.",
      "build_up_exercises": [
        {
          "name": "Adductor Eccentric",
          "reason": "Builds core and hip strength, focusing on the inner thigh muscles that stabilize the hips.",
          "how_to": "Lie on your back, with a band pulling away from the body. Squeeze knees together. 🧘‍♂️"
        },
        {
          "name": "Lateral Lunges",
          "reason": "Strengthens the hip adductors through a full range of motion, helping balance the inner thigh strength.",
          "how_to": "Step out laterally to one side and bend through the knee and hip on that side while straightening out the other side"
        }
      ],
      "weakness_impact": "Weakness in the adductors can lead to poor knee alignment, causing knee pain and overuse injuries. The imbalance also stresses the IT band, which may cause discomfort or pain around the knee or hip. Strengthening these muscles helps prevent these injuries and improves running efficiency 🏃‍♀️."
    },
    {
      "exerciseId": 65,
      "name": "Star Side Plank",
      "importance": "The star side plank works the obliques (agonist) for core stability and glute medius (antagonist) for hip stabilization 🌠. This keeps your body balanced during athletic movements, reducing stress on your knees and lower back.",
      "build_up_exercises": [
        {
          "name": "Regular Side Plank",
          "reason": "Builds core stability, focusing on the obliques to support the side plank position.",
          "how_to": "Lie on your side, lift your hips up, and hold a straight line 🧘."
        },
        {
          "name": "Leg Raises from Side Plank",
          "reason": "Activates the glute medius for hip stability.",
          "how_to": "From a side plank, raise your top leg up and down slowly 💥."
        }
      ],
      "weakness_impact": "Weak obliques and glutes can lead to poor balance, putting extra strain on the knees, hips, and even lower back. This imbalance can cause IT band syndrome or hip pain due to inadequate support around the hip and core 😬. Strong obliques and glutes improve stability and reduce injury risk."
    },
    {
      "exerciseId": 87,
      "name": "Body Saw",
      "importance": "Body saw primarily targets the rectus abdominis (agonist) for core strength and erector spinae (antagonist) for back stability 🏋️. This creates a balanced core that can withstand impacts and maintain posture during long runs or rides.",
      "build_up_exercises": [
        {
          "name": "Forearm Plank",
          "reason": "Builds core stability in the rectus abdominis.",
          "how_to": "Get into a plank on your forearms, holding your body straight 🛠️."
        },
        {
          "name": "Plank Walkouts",
          "reason": "Engages both the core and back to hold posture with slight movement.",
          "how_to": "Start in a plank, slowly walk your hands out and back in 🔄."
        }
      ],
      "weakness_impact": "Weakness in the core, especially the abdominals and back, can lead to poor posture and lower back pain, especially during long-distance running or biking. Without a stable core, other muscles compensate, leading to potential injuries in the back, hips, and legs 📏."
    },
    {
      "exerciseId": 272,
      "name": "Single Leg Calf Raise",
      "importance": "Single leg calf raises work the calf muscles (gastrocnemius and soleus as agonists) and tibialis anterior (antagonist), which helps stabilize the ankles and propel you forward 🦵. Strong calves reduce stress on your knees and ankles by absorbing impact forces during running and cycling.",
      "build_up_exercises": [
        {
          "name": "Double Leg Calf Raise",
          "reason": "Strengthens both calves together for basic stability.",
          "how_to": "Stand on both feet, rise on your toes, then lower down 🌄."
        },
        {
          "name": "Seated Calf Raise",
          "reason": "Strengthens the soleus while reducing the load on the entire leg.",
          "how_to": "Sit with feet flat, rise on your toes while seated 🪑."
        }
      ],
      "weakness_impact": "Weak calves can lead to poor foot stability, causing excessive strain on the knees, ankles, and shins, potentially leading to shin splints or Achilles tendonitis. Strong calves help absorb impact and improve stride efficiency 💥."
    },
    {
      "exerciseId": 95,
      "name": "Single Leg Glute Bridges",
      "importance": "Single leg glute bridges strengthen the glutes (agonist) and hip flexors (antagonist), which are key for stability in the hips and balance in each stride 🚀. Strong glutes support powerful, balanced movement in running and cycling.",
      "build_up_exercises": [
        {
          "name": "Regular Glute Bridge",
          "reason": "Builds a strong base in both glutes, preparing for single-leg movements.",
          "how_to": "Lie on your back with knees bent, lift your hips up, then lower down 🍑."
        },
        {
          "name": "Marching Glute Bridge",
          "reason": "Introduces single-leg balance to glute bridges.",
          "how_to": "Lift into a glute bridge, then alternate lifting one knee at a time 🚶‍♂️."
        }
      ],
      "weakness_impact": "Weak glutes can lead to poor hip alignment, straining the knees, hips, and even lower back. This often causes hip pain or IT band issues due to instability. Strong glutes provide stability and improve running form 🏆."
    },
    {
      "exerciseId": 267,
      "name": "Active Single Leg Raise",
      "importance": "This exercise improves control and flexibility in the hip flexors (agonist) and the hamstrings (antagonist), helping with leg alignment during running and cycling 🦶.",
      "build_up_exercises": [
        {
          "name": "Lying Leg Raise",
          "reason": "Strengthens the hip flexors and improves basic flexibility.",
          "how_to": "Lie on your back, lift one leg straight up, then lower it down slowly 🛏️."
        },
        {
          "name": "Knee to Chest Stretch",
          "reason": "Stretches the hip flexors and hamstrings for better flexibility.",
          "how_to": "Lie on your back, bring one knee to your chest, then switch legs 🦵."
        }
      ],
      "weakness_impact": "Weak hip flexors or tight hamstrings can limit leg movement, affecting running form and stride length. This imbalance may lead to lower back pain or hip issues, impacting performance. Strong and flexible hip flexors and hamstrings reduce the risk of these injuries 🚴‍♀️."
    },  
    {
      "exerciseId": 269,
      "name": "Push-Ups",
      "why_important": "Push-ups target the chest and triceps (agonists) for strength, with shoulders and core providing stability (antagonists). They’re fundamental for upper body strength and endurance 💪.",
      "build_up_exercises": [
        {
          "name": "Incline Push-Ups",
          "reason": "Reduces load while building the same movement pattern.",
          "how_to": "Place hands on an elevated surface, lower your chest, and push up."
        },
        {
          "name": "Plank Holds",
          "reason": "Strengthens the core for stability during push-ups.",
          "how_to": "Hold a straight-arm plank, keeping your body in a straight line 🧘‍♀️."
        }
      ],
      "weakness_impact": "Weak chest and triceps muscles can lead to poor posture and imbalances, reducing efficiency in activities requiring upper body strength. Strengthening these muscles prevents injuries and improves endurance 🏋️‍♂️."
    },
    {
      "exerciseId": 268,
      "name": "Hip Rails",
      "importance": "This exercise targets the hip flexors (agonist), particularly the iliopsoas, while the glutes (antagonist) help stabilize the pelvis. Strengthening the hip flexors is essential for athletes in endurance sports like running and cycling, where a strong hip flexor allows for efficient leg movement and better stride length 🦵.",
      "build_up_exercises": [
        {
          "name": "Lying Leg Raise",
          "reason": "Builds hip flexor strength in a supported position, reducing load on the lower back.",
          "how_to": "ie on your back and lift one leg straight up, keeping the other leg flat on the floor, then slowly lower it down 🛏️."
        },
        {
          "name": "Knee-to-Chest Holds",
          "reason": "Increases flexibility and strength in the hip flexors with minimal resistance.",
          "how_to": "Sit on the floor with your legs extended, then pull one knee toward your chest, holding for a few seconds, then release and switch legs 🤲."
        }
      ],
      "weakness_impact": "Weak hip flexors can limit stride length and reduce power in each leg swing, making running and cycling more difficult and less efficient. This imbalance can cause overcompensation from the lower back or glutes, often leading to lower back pain or hip strain. Strong hip flexors contribute to improved movement mechanics, enhancing endurance and performance while reducing the risk of injury 🏃‍♂️🚴."
    },
    {
      "exerciseId": 1,
      "name": "Quadruped Row",
      "importance": "The quadruped row engages the latissimus dorsi (agonist) and pectorals (antagonist), building upper back strength and core stability. This combination supports a strong, stable posture and balance, which is crucial for preventing rounded shoulders and improving efficiency in athletic movements 🏋️.",
      "build_up_exercises": [
        {
          "name": "Bird Dog",
          "reason": "Engages the core and helps improve balance in the quadruped position.",
          "how_to": "On hands and knees, extend opposite arm and leg, then switch sides 🐦."
        },
        {
          "name": "Bent Over Dumbbell Row",
          "reason": "Focuses on the upper back muscles, strengthening the lats.",
          "how_to": "Bend slightly at the hips, hold a dumbbell, and pull it toward your torso, keeping your back straight 💪."
        }
      ],
      "weakness_impact": "Weakness in the lats and core can lead to poor posture and imbalanced movement, often resulting in lower back strain or shoulder impingement. Strengthening these muscles helps prevent these injuries and supports efficient upper body movement and alignment 📏."
    },

    {
      "exerciseId": 25,
      "name": "Overhead Press",
      "importance": "The overhead press primarily targets the deltoids (agonist) and latissimus dorsi (antagonist), building shoulder strength and stability. This is key for maintaining good posture and shoulder stability, which supports strong, efficient upper body form in various sports 🏋️.",
      "build_up_exercises": [
        {
          "name": "Shoulder Press with Light Weights",
          "reason": "Builds shoulder stability and strength, focusing on the deltoids.",
          "how_to": "Start with lighter weights, press them overhead, and lower back down slowly 💪."
        },
        {
          "name": "Wall Slides",
          "reason": "Works on shoulder mobility and activates stabilizing muscles.",
          "how_to": "Stand with your back against the wall, raise your arms up and down along the wall in a sliding motion 🧱"
        }
      ],
      "weakness_impact": "Weak deltoids and lats can lead to shoulder instability, which increases the risk of shoulder injuries like rotator cuff strains or impingement. Strong shoulders also improve arm movement efficiency, making endurance activities more comfortable and reducing fatigue 🌟"
    },
    {
      "exerciseId": 74,
      "name": "Single Leg Slider Curls",
      "importance": "This exercise strengthens the hamstrings (agonist), particularly during the eccentric phase, while the quadriceps (antagonist) control knee stability. Strong hamstrings are critical for endurance athletes as they absorb impact forces and help maintain proper hip and knee alignment during running and cycling",
      "build_up_exercises": [
        {
          "name": "Glute Bridge Walkouts",
          "reason": "Builds hamstring strength by challenging the muscles in a similar eccentric manner but with less load.",
          "how_to": 'Lie on your back, lift your hips into a bridge position, and slowly "walk" your feet out until your legs are nearly straight, then walk them back in.'
        },
        {
          "name": "Hamstring Curl on Stability Ball",
          "reason": "Strengthens the hamstrings with more support and control compared to sliders.",
          "how_to": "Place your feet on a stability ball, lift your hips, and curl the ball toward you by bending your knees, then slowly roll it back out "
        }
      ],
      "weakness_impact": "Weak hamstrings can lead to poor knee stability and over-reliance on the quads, increasing the risk of injuries such as hamstring strains, knee pain, or even ACL injuries. This imbalance can also cause lower back discomfort due to overcompensation during running or cycling. Strengthening the hamstrings improves power, efficiency, and injury prevention, ensuring endurance athletes can perform at their best while staying resilient over time "
    },
    {
      "exerciseId": 177,
      "name": "Assissted Pull Up",
      "importance": "The assisted pull-up primarily works the latissimus dorsi (agonist) and biceps (antagonist), building upper body and back strength. This movement helps create a strong base for endurance athletes by improving posture and upper body strength, both important for balanced form 🏋️‍♂️.",
      "build_up_exercises": [
        {
          "name": "Lat Pulldown",
          "reason": "Focuses on the lats, helping you build strength for a pull-up movement.",
          "how_to": 'Pull a bar down toward your chest, focusing on engaging your upper back and lats 💪.'
        },
        {
          "name": "Australian Rows",
          "reason": "Works the same pulling muscles in a horizontal motion.",
          "how_to": "Set a bar at waist height, lean back under it, and pull your chest up to the bar while keeping your body straight 🚠."
        }
      ],
      "weakness_impact": "Weak lats and biceps can lead to poor upper body strength, which often causes rounded shoulders, reduced posture, and even upper back or shoulder pain. By strengthening these muscles, athletes can improve posture, enhance core stability, and reduce strain on the shoulders, making movement more efficient and less tiring 🏆"
    }
    
]



export const getExerciseInfo = (exerciseId) => {

  const exercise = exerciseInfo.find(item => item.exerciseId === exerciseId);
  return exercise ? exercise : null;

}

export const CalculateAssessmentScore = (assessmentWorkoutTemplate) => {

  var totalReps = 0  
  var totalRepsCompleted = 0

  assessmentWorkoutTemplate.phases.map((phase) => {
      phase.exercises.map((exercise) => {
          exercise.sets.map((set) => {

              totalReps += set.reps

              if (set.completedSetData.completed) {
                  totalRepsCompleted += set.completedSetData.setUnitsCompleted
              }

          });
      });
  });

  return Math.round((totalRepsCompleted / totalReps) * 100)

}

// const ExtrapolateCategories = () => {

//   const temp_weakCategories = []
//   const temp_strongCategories = []

//   // Loop through each phase
//   assessmentWorkoutTemplate.phases.forEach(phase => {
      
//       // Loop over each exercise in the phase
//       phase.exercises.forEach(exercise => {
//           var exerciseCategory = databaseExerciseData.find(e => e.id === exercise.id).category;

//           // Loop over each set in the exercise
//           exercise.sets.forEach(set => {
//               const summaryTag = GetSummaryTagForEachExerciseSet(set);

//               // Update the count for the respective category based on weak or strong
//               if (summaryTag === 'weak') {
//                   const categoryIndex = temp_weakCategories.findIndex(item => item.category === exerciseCategory);
//                   if (categoryIndex === -1) {
//                       temp_weakCategories.push({ category: exerciseCategory, count: 1 });
//                   } else {
//                       temp_weakCategories[categoryIndex].count += 1;
//                   }
//               }

//               if (summaryTag === 'strong') {
//                   const categoryIndex = temp_strongCategories.findIndex(item => item.category === exerciseCategory);
//                   if (categoryIndex === -1) {
//                       temp_strongCategories.push({ category: exerciseCategory, count: 1 });
//                   } else {
//                       temp_strongCategories[categoryIndex].count += 1;
//                   }
//               }
//           })

//       })
//   })

//   temp_weakCategories.sort((a, b) => a.count - b.count);
//   temp_strongCategories.sort((a, b) => b.count - a.count);

//   setWeakestCategories(temp_weakCategories);
//   setStrongestCategories(temp_strongCategories);
// }

{/* Weakest vs. Strongest Muscle Groups */}
{/* 
<div className="text-center space-y-2 text-sm">
    <div className='font-semibold'>Weakest vs. Strongest Muscle Groups</div>
    <div className="grid grid-cols-2 text-center gap-4">
        <div className="space-y-2">
            {weakestCategories !== null && weakestCategories.length > 0 && 
                weakestCategories.slice(0, 4).map((category, index) => (
                    <div  key={`weakest-${index}`} className="bg-red-200 rounded-lg text-xs text-red-600 p-1">
                        {category.category}
                    </div>
                ))
            }
        </div>

        <div className="space-y-2">
            {strongestCategories !== null && strongestCategories.length > 0 &&
                strongestCategories.slice(0, 4).map((category, index) => (
                    <div key={`strongest-${index}`} className="bg-green-200 rounded-lg text-xs text-green-600 p-1">
                        {category.category}
                    </div>
                ))
            }
        </div>
    </div>
</div> 
*/}