export const ConvertInchesToCentimeters = (inches) => {

    return inches * 2.54

}

export const ConvertCentimetersToInches = (centimeters) => {

    return centimeters * 0.393701

}

export const ConvertPoundsToKilograms = (pounds) => {

    return pounds * 0.453592

}

export const ConvertKilogramsToPounds = (kilograms) => {

    return kilograms * 2.20462

}

export const ConvertPoundsToNearestFiveKilograms = (pounds) => {

    var kilograms = pounds * 0.453592

    kilograms = Math.round(kilograms / 5) * 5;

    return Math.max(5, kilograms)

}