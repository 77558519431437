import { useContext } from 'react'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { GetPresentWeekOverview } from 'redux/selectors/PresentWeekOverviewSelector'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

export default function TrainingStatus() {
    const presentWeek = GetPresentWeekOverview()

    const { ShowModal } = useContext(ModalContext)

    const ShowTrainingStatusModal = () => {
        const modalData = {
            key: ModalKey.TrainingStatus,
            fullscreen: false,
            trainingStatusKey: presentWeek.trainingStatusKey,
        }

        ShowModal(modalData)
    }

    const GetStatusTagStyle = (status) => {
        switch (status) {
            case 'healthy':
                return {
                    text: 'Healthy',
                    textColor: 'text-green-600',
                    bgColor: 'bg-green-200',
                }

            case 'maintaining':
                return {
                    text: 'Maintaining',
                    textColor: 'text-yellow-600',
                    bgColor: 'bg-yellow-100',
                }

            case 'unhealthy':
                return {
                    text: 'Unhealthy',
                    textColor: 'text-red-500',
                    bgColor: 'bg-red-200',
                }

            default:
                return {
                    text: 'Unknown',
                    textColor: 'text-gray-400',
                    bgColor: 'bg-gray-100',
                }
        }
    }

    const trainingStatus = GetStatusTagStyle(presentWeek.trainingStatusKey)

    return (
        <div className="flex-1 flex-grow flex-col items-center pt-1 pb-2 px-2 space-y-2" onClick={() => ShowTrainingStatusModal()}>
            <div className="flex items-center justify-center gap-x-1">
                <div className="text-center text-xs text-gray-600">Training Status</div>
                <InformationCircleIcon className="h-3 text-gray-600" />
            </div>

            <div className={`mx-2 py-0.5 text-sm text-center font-semibold rounded-full ${trainingStatus.bgColor} ${trainingStatus.textColor}`}>{trainingStatus.text}</div>
        </div>
    )
}
