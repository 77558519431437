import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setReduxCouponData } from 'redux/slices/subscription'
import { GetMoneyFormattedString } from 'functions/subscriptionUtils'
import loading from 'media/loading.svg'

export default function PromoCode() {
    const dispatch = useDispatch()

    const email = useSelector((state) => state.user.email)
    const couponData = useSelector((state) => state.subscription.couponData)

    const [promoCode, setPromoCode] = useState('')
    const [promoCodeLoading, setPromoCodeLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const handlePromoCodeChange = (event) => {
        setPromoCode(event.target.value.toUpperCase())
    }

    const OnApplyPromoCode = async () => {
        // Reset promo code error message
        setErrorMessage('')

        if (promoCode.length === 0) {
            setErrorMessage('Please enter a promo code')
            return
        }

        const jsonBody = {
            email: email,
            promoCode: promoCode,
        }

        // console.log(jsonBody)

        setPromoCodeLoading(true)

        const result = await WhichWeightAPI('BILLING', 'POST', '/check_promo_code', jsonBody)

        setPromoCodeLoading(false)

        // console.log(result.data.data);

        if (result.data.status === 'failure') {
            setErrorMessage('Error applying promo. Please try again')
            return
        }

        if (result.data.data.showPromoCodeErrorMessage) {
            setErrorMessage(result.data.data.promoCodeErrorMessage)
            return
        }

        dispatch(setReduxCouponData(result.data.data))
    }

    // Enter Coupon UI
    if (couponData === null) {
        return (
            <div className="space-y-2">
                <div className="flex gap-x-4 h-8">
                    {/* Promo Code Input */}
                    <input
                        type="text"
                        name="promoCode"
                        id="promoCode"
                        className="w-full p-2 block rounded-md text-gray-900 shadow-sm border border-gray-300"
                        placeholder="Promo Code"
                        value={promoCode}
                        onChange={handlePromoCodeChange}
                    />

                    {/* Apply button */}
                    {!promoCodeLoading && (
                        <button type="button" className="w-28 text-center text-sm border border-gray-300 rounded-full shadow-sm" onClick={() => OnApplyPromoCode()}>
                            Apply
                        </button>
                    )}

                    {/* Loading spinner */}
                    {promoCodeLoading && (
                        <div className="flex w-28 spinner bg-gray-100 justify-center items-center border border-gray-300 rounded-full shadow-sm">
                            <img src={loading} alt="Loading" className="w-5" />
                        </div>
                    )}
                </div>

                {/* Promo Code Error Message */}
                {errorMessage !== '' && <div className="text-sm text-red-600">{errorMessage}</div>}
            </div>
        )
    }

    // Applied Coupon UI
    if (couponData !== null) {
        return (
            <div className="flex px-2 text-gray-500">
                <div className="flex-grow">{couponData.name}</div>
                {couponData.amountOff !== null && <div>{GetMoneyFormattedString(couponData.amountOff)} off</div>}
                {couponData.percentOff !== null && <div>{couponData.percentOff}% off</div>}
            </div>
        )
    }
}
