import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { GetFreeTrialLengthBasedOnInterval } from 'functions/subscriptionUtils'

import PaymentMethod from 'components/Billing/PaymentMethod'

export default function PaymentMethodWrapper() {
    // Load publishable key from env variables
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

    const navigate = useNavigate()

    const email = useSelector((state) => state.user.email)
    const selectedPricingOption = useSelector((state) => state.subscription.selectedPricingOption)
    const stripeCustomerId = useSelector((state) => state.subscription.stripeCustomerId)
    const couponData = useSelector((state) => state.subscription.couponData)

    const [buttonLoading, setButtonLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const PostCreationAction = async (paymentMethod) => {
        // Attach payment method to customer

        const jsonBody1 = {
            email: email,
            stripePaymentMethodId: paymentMethod.id,
        }

        // console.log(jsonBody);

        const attachBillingResult = await WhichWeightAPI('BILLING', 'POST', '/attach_payment_method_to_customer', jsonBody1)

        // console.log(attachBillingResult);

        if (attachBillingResult.data.status !== 'success') {
            setButtonLoading(false)
            console.error(attachBillingResult)
            setErrorMessage('Could not attach billing. Please try again. If issue persists please contact support')
            return
        }

        // Create subscription

        const jsonBody2 = {
            scenarioTag: 'create-with-free-trial',
            email: email,
            stripeCustomerId: stripeCustomerId,
            priceId: selectedPricingOption.id,
            freeTrialLength: GetFreeTrialLengthBasedOnInterval(selectedPricingOption.recurring.interval_count),
            couponData: couponData, // Optional. Can be null
        }

        // console.log(jsonBody);

        const createSubscriptionResult = await WhichWeightAPI('BILLING', 'POST', '/create_subscription', jsonBody2)

        // console.log(subscriptionIdPatchResult);

        if (createSubscriptionResult.data.status === 'failure') {
            setButtonLoading(false)
            console.error(createSubscriptionResult)
            setErrorMessage('Could not create subscription. Please try again. If issue persists please contact support')
            return
        }

        setButtonLoading(false)

        navigate('/subscription/success')
    }

    return (
        <>
            {/* Stripe Card Element */}
            <Elements stripe={stripePromise}>
                <PaymentMethod
                    titleText="Please Enter a Payment Method"
                    subTitleText="In order to start your free trial, please enter a payment method. You will not be charged until your free trial has ended"
                    buttonText="Submit"
                    PostCreationAction={PostCreationAction}
                    buttonLoading={buttonLoading}
                    setButtonLoading={setButtonLoading}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            </Elements>
        </>
    )
}
