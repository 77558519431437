import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useGetEntireWeeklyCalendarJSON } from 'redux/customHooks/useGetEntireWeeklyCalendarJSON'
import { CheckCircleIcon, FireIcon } from '@heroicons/react/20/solid'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { GetPrimaryColorHex } from 'redux/slices/brand'

export default function WeeklyProgress() {
    const { ShowModal } = useContext(ModalContext)

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const entireWeeklyCalendarJSON = useGetEntireWeeklyCalendarJSON()
    const presentWeekOfTheYearKey = useSelector((state) => state.user.presentWeekOfTheYearKey)
    const activeWeek = entireWeeklyCalendarJSON.find((record) => record.weekOfTheYearKey === presentWeekOfTheYearKey)

    const calculateProgress = (completionData) => {
        let completionScore = 0

        Object.keys(completionData).forEach((workout) => {
            if (completionData[workout].completed) {
                if (workout.includes('mobility')) {
                    completionScore += 20
                } else if (workout.includes('strength')) {
                    completionScore += 30
                }
            }
        })

        return completionScore
    }

    const score = calculateProgress(activeWeek.completionData)

    const ShowWeeklyProgressInfo = () => {
        const modalData = {
            key: ModalKey.Text,
            fullscreen: false,
            titleText: 'Current Week Progress',
            bodyText: [
                'See how far along you are in your current week of workouts. Completing workouts will add to your progress bar',
                'Strength workouts add 30% to your progress bar',
                'Mobility workouts add 20% to your progress bar',
                'To keep your weekly streak alive, you only have to complete 50% of a week'
            ],
            tinyText: presentWeekOfTheYearKey
        }

        ShowModal(modalData)
    }

    return (
        <div className="border border-gray-400 space-y-2 pt-2 pl-2 pr-3 pb-6 rounded-lg" onClick={() => ShowWeeklyProgressInfo()}>
            <div className="flex items-center justify-center gap-x-1.5">
                <div className="text-sm text-center text-gray-600">Current Week Progress</div>
                <InformationCircleIcon className="mt-0.5 h-3 text-gray-600" />
            </div>

            <div className="relative w-full h-3 bg-gray-100 rounded-full">

                {/* Filled portion of the progress bar */}
                <div 
                    className='ml-1 absolute inset-0 rounded-full' 
                    style={{ 
                        width: `${score}%`, 
                        height: '50%', 
                        top: '25%',
                        backgroundColor: GetPrimaryColorHex(styleConfig)
                    }} 
                />

                {/* Incomplete Label */}
                <div 
                    className="text-left text-[10px] text-gray-400 absolute w-20 h-5 transform translate-y-4" 
                    style={{ left: '0%' }}
                >
                    Incomplete
                </div>

                {/* Half Complete Icon */}
                <div
                    className='absolute flex items-center justify-center w-5 h-5 rounded-full text-xs transform -translate-x-1/2 -translate-y-1/4'
                    style={{ 
                        left: '50%',
                        backgroundColor: score >= 50 ? GetPrimaryColorHex(styleConfig) : '#f4f4f5'
                    }}
                >
                    <FireIcon className={`h-3 w-3 ${score >= 50 ? 'text-white' : 'text-zinc-400'}`} />
                </div>

                {/* Half Complete Label */}
                <div 
                    className="absolute text-center text-[10px] text-gray-400 flex items-center justify-center rounded-full transform -translate-x-1/2 translate-y-4" 
                    style={{ left: '50%' }}
                >
                    Half Complete
                </div>

                {/* Fully Complete Icon */}
                <div
                    className='absolute flex items-center justify-center w-5 h-5 rounded-full text-xs transform -translate-x-1/2 -translate-y-1/4 '
                    style={{ 
                        left: '99%',
                        backgroundColor: score >= 100 ? GetPrimaryColorHex(styleConfig) : '#f4f4f5'
                    }}
                >
                    <CheckCircleIcon className={`h-3 w-3 ${score >= 100 ? 'text-white' : 'text-zinc-400'}`} />
                </div>

                {/* Fully Complete Label */}
                <div 
                    className="text-right text-[10px] text-gray-400 absolute w-20 h-5 transform translate-y-4" 
                    style={{ right: '0%' }}
                >
                    Complete
                </div>

            </div>

        </div>
    )
}
