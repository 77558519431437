import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IsActiveWorkoutSliceInCurrentFormat, IsActiveWorkoutSliceInInitialState, resetActiveWorkoutState } from 'redux/slices/activeWorkout'
import { IsActiveAssessmentSliceInCurrentFormat, IsActiveAssessmentSliceInInitialState, resetActiveAssessmentState } from 'redux/slices/activeAssessment'
import { setViewWorkoutId } from 'redux/slices/viewWorkout'
import { setWhichWeightCacheEntireWeeklyCalendarJSON } from 'redux/slices/whichWeightCache'
import { GetPrimaryColorHex } from 'redux/slices/brand'
import { useGetFitnessProfile } from 'redux/customHooks/useGetFitnessProfile'
// import { GetPresentWeekOverview } from 'redux/selectors/PresentWeekOverviewSelector'
import loading from 'media/loading.svg'
import moment from 'moment'

import FullscreenLoading from 'components/Loading/FullscreenLoading'

export default function ResumeWorkoutHeader() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const fitnessProfile = useGetFitnessProfile() // TODO CHANGE THIS! Need fitness profile loaded to resume assessment
    
    const styleConfig = useSelector((state) => state.brand.styleConfig)
    // const presentWeekOfTheYearKey = useSelector((state) => state.user.presentWeekOfTheYearKey)
    // const presentWeek = GetPresentWeekOverview()
    const userId = useSelector((state) => state.user.id)
    const email = useSelector((state) => state.user.email)
    const activeWorkout = useSelector((state) => state.activeWorkout)
    const activeAssessment = useSelector((state) => state.activeAssessment)

    const [savingWorkout, setSavingWorkout] = useState(false)

    const AutoEndOldReduxIncompleteWorkout = async () => {
        try {
            const jsonBody = {
                userId: userId,
                completedWorkoutId: activeWorkout.id,
                weekOfTheYearKey: activeWorkout.weekOfTheYearKey,
                workoutTemplateKey: activeWorkout.workoutTemplateKey,
                completedWorkoutTemplateJSON: activeWorkout.workoutTemplate,
                uiMode: 'compact',
            }

            // console.log(jsonBody)

            const result = await WhichWeightAPI('CORE', 'PATCH', '/complete_workout', jsonBody)

            // console.log(result);

            if (result.data.status == 'failure') console.error(result)
        } catch (error) {
            console.error('Error:', error)
        }

        dispatch(resetActiveWorkoutState())
        dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON(null))
    }

    const AutoEndOldReduxIncompleteAssessment = async () => {
        try{

            const jsonBody = {
                email: email,
                uiMode: activeAssessment.uiMode,
                assessmentUIFlowKey: activeAssessment.assessmentUIFlowKey,
                assessmentTemplateData: activeAssessment.workoutTemplate,
            }
    
            // console.log(jsonBody)
    
            const result = await WhichWeightAPI('CORE', 'POST', '/assessment_complete', jsonBody)

            if (result.data.status == 'failure') console.error(result)

        } catch (error) {
            console.error('Error:', error)
        }

        dispatch(resetActiveAssessmentState())
    }

    const OnResumeIncompleteWorkoutButton = () => {
        // Make sure active assessment is set to default before starting a active workout
        dispatch(resetActiveAssessmentState())
        navigate('/active/workout')
    }

    const OnEndIncompleteWorkoutButton = async () => {
        setSavingWorkout(true)

        const jsonBody = {
            userId: userId,
            completedWorkoutId: activeWorkout.id,
            weekOfTheYearKey: activeWorkout.weekOfTheYearKey,
            workoutTemplateKey: activeWorkout.workoutTemplateKey,
            completedWorkoutTemplateJSON: activeWorkout.workoutTemplate,
            uiMode: activeWorkout.uiMode,
        }

        // console.log(jsonBody)

        const result = await WhichWeightAPI('CORE', 'PATCH', '/complete_workout', jsonBody)

        // console.log(result);

        setSavingWorkout(false)

        if (result.data.status == 'failure') {
            console.error(result)
            return
        }

        const props = {
            state: {
                weekOfTheYearKey: activeWorkout.weekOfTheYearKey,
                workoutTemplateKey: activeWorkout.workoutTemplateKey,
                doneNavigation: 'home',
            },
        }

        dispatch(setViewWorkoutId(activeWorkout.id))
        dispatch(resetActiveWorkoutState())
        dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON(null))

        navigate('/workout/summary', props)
    }

    const OnResumeIncompleteAssessmentButton = () => {

        navigate('/assessment/active')

    }

    // const OnEndIncompleteAssessmentButton = async () => {
    //     setSavingWorkout(true)

    //     const jsonBody = {
    //         email: email,
    //         uiMode: activeAssessment.uiMode,
    //         assessmentUIFlowKey: activeAssessment.assessmentUIFlowKey,
    //         assessmentTemplateData: activeAssessment.workoutTemplate,
    //     }

    //     // console.log(jsonBody)

    //     const result = await WhichWeightAPI('CORE', 'POST', '/assessment_complete', jsonBody)

    //     if (result.data.status == 'failure') console.error(result)

           // TODO FIX BUG HERE
           // SOMETHING IS OFF WITH THE WAY WE ARE CLEARING THE PROGRAM CACHE
           // I BELIEVE IT IS BECAUSE WE ARE TECHNICALLY STILL ON HOME SCREEN
    //     // If we are prompting for an assessment, that means a user is at the beginning of a new program
    //     // and has not assessed in a while.
    //     // If this is the case, we should clear the program cache so that the assessment gets applied
    //     if (
    //         presentWeek && 
    //         (
    //             presentWeek.programWeekIndex === 0 
    //             || presentWeek.programWeekIndex === 1
    //         )
    //     ) {

    //         const jsonBody2 = {
    //             userId: userId,
    //             presentWeekOfTheYearKey: presentWeekOfTheYearKey,
    //             localDate: moment.utc().local().format('YYYY-MM-DD')
    //         }

    //         const clear_cache_result = await WhichWeightAPI('PROGRAM', 'POST', '/delete_entire_program_cache', jsonBody2)

    //         if (clear_cache_result.data.status == 'failure') console.error(clear_cache_result)

    //     }

    //     const props = {
    //         state: {
    //             assessmentUIFlowKey: activeAssessment.assessmentUIFlowKey,
    //             assessmentWorkoutTemplate: activeAssessment.workoutTemplate
    //         }
    //     }

    //     setSavingWorkout(false)

    //     dispatch(resetActiveAssessmentState())
    //     dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON(null)) // Reset calendar data

    //     // console.log(props)

    //     navigate('/assessment/summary', props)

    // }

    // If the cached active workout json is in an old unsupported format, reset the activeWorkout slice
    if (!IsActiveWorkoutSliceInCurrentFormat(activeWorkout)) {
        AutoEndOldReduxIncompleteWorkout()

        return <FullscreenLoading loadingText="Ending Old Workout" />
    }

    // If the cached assessment json is in an old unsupported format, reset the active assessment slice
    if (!IsActiveAssessmentSliceInCurrentFormat(activeAssessment)) {
        AutoEndOldReduxIncompleteAssessment()

        return <FullscreenLoading loadingText="Ending Old Assessment" />
    }

    // If the activeWorkoutSlice is in the correct format and there is data in there, allow a user to resume
    if (IsActiveWorkoutSliceInCurrentFormat(activeWorkout) && !IsActiveWorkoutSliceInInitialState(activeWorkout)) {
        return (
            <div 
                className="p-4 space-y-2 h-24"
                style={{
                    backgroundImage: `linear-gradient(to bottom, ${GetPrimaryColorHex(styleConfig)}, ${GetPrimaryColorHex(styleConfig, 2)})`,
                }}
            >
                {/* Incomplete Workout Info */}
                {!savingWorkout && (
                    <>
                        {/* Name */}
                        {activeWorkout !== null && activeWorkout.workoutTemplate !== null && (
                            <div className="text-lg text-center text-white font-bold">{activeWorkout.workoutTemplate.name} is incomplete!</div>
                        )}
                        {(activeWorkout === null || activeWorkout.workoutTemplate === null) && <div className="text-lg text-center text-white font-bold">Workout is incomplete!</div>}

                        <div className="flex gap-x-4">
                            {/* End Button */}
                            <button onClick={() => OnEndIncompleteWorkoutButton()} className="flex-1 flex-grow py-1 bg-white rounded-full">
                                End
                            </button>

                            {/* Resume Button */}
                            {activeWorkout !== null && activeWorkout.workoutTemplate !== null && (
                                <button onClick={() => OnResumeIncompleteWorkoutButton()} className="flex-1 flex-grow py-1 bg-white rounded-full">
                                    Resume
                                </button>
                            )}
                        </div>
                    </>
                )}

                {/* Loading Spinner */}
                {savingWorkout && (
                    <div className="flex items-center justify-center">
                        <img src={loading} alt="Loading" className="spinner w-16 h-16" />
                    </div>
                )}
            </div>
        )
    }

    // Assessment resume functionaility 
    if (
        IsActiveWorkoutSliceInInitialState(activeWorkout) &&
        IsActiveAssessmentSliceInCurrentFormat(activeAssessment) &&
        !IsActiveAssessmentSliceInInitialState(activeAssessment)
    ) {
        return (
            <div 
                className="p-4 space-y-2 h-24"
                style={{
                    backgroundImage: `linear-gradient(to bottom, ${GetPrimaryColorHex(styleConfig)}, ${GetPrimaryColorHex(styleConfig, 2)})`,
                }}
            >
                {/* Incomplete Workout Info */}
                {!savingWorkout && (
                    <>
                        {/* Name */}
                        <div className="text-lg text-center text-white font-bold">Assessment is incomplete!</div>

                        <div className="flex gap-x-4">
                            {/* End Button
                            <button onClick={() => OnEndIncompleteAssessmentButton()} className="flex-1 flex-grow py-1 bg-white rounded-full">
                                End
                            </button> */}

                            {/* Resume Button */}
                            {activeAssessment !== null && activeAssessment.workoutTemplate !== null && (
                                <button onClick={() => OnResumeIncompleteAssessmentButton()} className="flex-1 flex-grow py-1 bg-white rounded-full">
                                    Resume
                                </button>
                            )}
                        </div>
                    </>
                )}

                {/* Loading Spinner */}
                {savingWorkout && (
                    <div className="flex items-center justify-center">
                        <img src={loading} alt="Loading" className="spinner w-16 h-16" />
                    </div>
                )}

            </div>
        )
    }

}
