import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'
import logo192 from 'media/icons/logo192.png'

export default function Header({ backButtonType = 'arrow', backButtonAction = () => {} }) {
    return (
        <div className="bg-gray-100 shadow flex items-center p-4">
            {/* Back Button */}
            <div className="flex-1" onClick={backButtonAction}>
                {backButtonType === 'arrow' && <ChevronLeftIcon className="h-5 w-5" />}
                {backButtonType === 'x' && <XMarkIcon className="h-5 w-5" />}
            </div>

            {/* Logo */}
            <img src={logo192} alt="WhichWeight" className="h-12 -m-2" />

            <div className="flex-1" />
        </div>
    )
}
