import { CalendarDaysIcon, ChartBarIcon, FlagIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GetPrimaryColorHex } from 'redux/slices/brand'

import ScreenFrame from 'components/Frame/ScreenFrame'
import LogoHeader from 'components/Header/LogoHeader'
import BodyFrame from 'components/Frame/BodyFrame'
import NextButton from 'components/Onboarding/NextButton'

export default function ProgramAssignmentIntro() {
    const navigate = useNavigate()
    const styleConfig = useSelector((state) => state.brand.styleConfig)

    const features = [
        {
            name: 'It Starts With Events',
            description: `Whether your race is coming up in 6 weeks or 6 months, ${styleConfig.text.appName} can craft a personal program to fit into your schedule`,
            icon: CalendarDaysIcon,
        },
        {
            name: 'No More Guesswork',
            description: 'Periodization, training goals, exercises progressions, weight recommendations, and everything else you need are built right into your program',
            icon: ChartBarIcon,
        },
        {
            name: "Get Race Ready",
            description: 'Training tapers are automatically built in to the program, ensuring that you are fresh and ready to perform on the start line',
            icon: FlagIcon,
        },
    ]

    return (
        <ScreenFrame>

            <LogoHeader />

            <BodyFrame>

                <div className='my-auto'>

                    {/* Key Features */}
                    <dl className="grid grid-cols-1 p-4 gap-y-4 border border-gray-400 shadow-md rounded-xl">
                        
                        <h1 className="text-2xl font-semibold italic text-gray-800 text-center py-2">Build Your Program!</h1>

                        <div className='space-y-4'>
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-12">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div 
                                        className="absolute left-0 top-0 flex h-8 w-8 items-center justify-center rounded-lg"
                                        style={{
                                            backgroundImage: `linear-gradient(to right, ${GetPrimaryColorHex(styleConfig, 1)}, ${GetPrimaryColorHex(styleConfig)})`
                                        }}
                                    >
                                        <feature.icon className="h-5 w-5 text-white" aria-hidden="true" />
                                    </div>
                                    <div className="-mt-1">{feature.name}</div>
                                </dt>

                                <dd className="text-base leading-7 text-gray-600">{feature.description}</dd>
                            </div>
                        ))}
                        </div>

                    </dl>
                </div>

                <div>
                    <NextButton 
                        action={() => navigate('/program/assignment/events')} 
                    />
                </div>

            </BodyFrame>
        </ScreenFrame>
    )
}
