import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import AppleIcon from 'media/misc/Apple.png'
import AndroidIcon from 'media/misc/Android.png'

import IOSAddToHomeScreenInstructions from 'components/HelpCenter/IOSAddToHomeScreenInstrcutions'
import AndroindAddToHomeScreenInstructions from 'components/HelpCenter/AndroidAddToHomeScreenInstructions'

import CustomHeader from 'components/Header/CustomHeader'

export default function AddToHomeScreen() {
    // Function used to navigate to new pages
    let navigate = useNavigate()
    const OnNavigationButtonPressed = (route, props) => {
        navigate(route, props)
    }

    const tabs = [
        { name: 'Apple', icon: AppleIcon },
        { name: 'Android', icon: AndroidIcon },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [selectedTab, setSelectedTab] = useState('Apple')

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera

        // Check for Android in User-Agent string
        if (/android/i.test(userAgent)) setSelectedTab('Android')
        // Check for iOS in User-Agent string
        else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) setSelectedTab('Apple')
        // Default to Apple if neither
        else setSelectedTab('Apple')
    }, [])

    return (
        <>
            {/* Header */}
            <CustomHeader text="Add App To Home Screen" backButtonAction={() => OnNavigationButtonPressed('/profile/helpCenter')} />

            <div>
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex" aria-label="Tabs">
                        {tabs.map((tab) => (
                            <a
                                key={tab.name}
                                onClick={() => {
                                    setSelectedTab(tab.name)
                                }}
                                className={classNames(
                                    tab.name === selectedTab ? 'border-gray-600 text-gray-500' : 'border-gray-300 text-gray-500',
                                    'w-1/2 border-b-2 py-2 px-1 text-center font-medium flex justify-center items-center gap-x-2'
                                )}
                            >
                                <img src={tab.icon} alt={tab.name} className="w-6" />
                                <div>{tab.name}</div>
                            </a>
                        ))}
                    </nav>
                </div>
            </div>

            <div className="p-4 space-y-4">
                {selectedTab === 'Apple' && <IOSAddToHomeScreenInstructions />}

                {selectedTab === 'Android' && <AndroindAddToHomeScreenInstructions />}
            </div>
        </>
    )
}
