export const IsUserPartOfAnOrganization = (id, name, logo) => {

    if (id === null || name === null || logo === null) return false
    return true

}

export const IsUserWhichWeightAdmin = (email) => {

    if (email === "jacob@whichweight.com" || email === "chris@kinesisintegrated.com") return true
    return false

}