import { useNavigate } from 'react-router-dom'

import CustomHeader from 'components/Header/CustomHeader'

export default function ContactSupport() {
    // Function used to navigate to new pages
    let navigate = useNavigate()
    const OnNavigationButtonPressed = (route, props) => {
        navigate(route, props)
    }

    return (
        <>
            {/* Header */}
            <CustomHeader text="Contact Support" backButtonAction={() => OnNavigationButtonPressed('/profile/helpCenter')} />

            <div className="p-4 space-y-4">
                <div>Please reach out to us with any issues you are having and we will get back to you as soon as possible!</div>

                <div>
                    <div className="text-lg font-semibold">Support Email</div>
                    <div>support@whichweight.com</div>
                </div>
            </div>
        </>
    )
}
