import { WhichWeightAPI } from 'WhichWeightAPI'
import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { swapActiveWorkoutExercise, undoExerciseSwap, resetActiveWorkoutIncompleteSetInfo } from 'redux/slices/activeWorkout'
import { ArrowPathIcon, Bars3BottomLeftIcon, ArrowUturnLeftIcon } from '@heroicons/react/24/solid'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { CapitalizeFirstLetter, GetMovementDifficultyScaleText, GetLoadDifficultyScaleText } from 'functions/textUtils'
import { ConvertPoundsToNearestFiveKilograms } from 'functions/measurementSystemUtils'
import { IsLoadDifficultyFeedbackEnabledForExercise } from 'functions/activeWorkoutUtils'
import { GetExerciseTypeIcon } from 'functions/iconUtils'
import { GetDifficultyIconFromValue } from 'functions/iconUtils'
import { GetActiveWorkoutSliceKey } from 'functions/sliceUtils'

import EquipmentDetails from 'components/ActiveWorkout/EquipmentDetails'
import WeightOverride from 'components/ActiveWorkout/WeightOverride'

export default function WorkoutSetInformationModal() {
    const dispatch = useDispatch()
    const { modalData, ShowModal, HideModal } = useContext(ModalContext)

    const measurementSystem = useSelector((state) => state.user.fitnessProfile.measurementSystem)
    
    const activeWorkout = useSelector((state) => state.activeWorkout)
    const activeAssessment = useSelector((state) => state.activeAssessment)
    var workoutTypeKey = GetActiveWorkoutSliceKey(activeWorkout, activeAssessment)
    
    var activeReduxSlice = null
    if (workoutTypeKey === 'active-workout') activeReduxSlice = activeWorkout
    if (workoutTypeKey === 'active-assessment') activeReduxSlice = activeAssessment

    const taperData = activeReduxSlice.taperData
    const weightRecommendation = activeReduxSlice.workoutTemplate.phases[modalData.phaseIndex].exercises[modalData.exerciseIndex].sets[modalData.setIndex].recommendationData.weightRecommendation
    const weightRecommendationRounded = activeReduxSlice.workoutTemplate.phases[modalData.phaseIndex].exercises[modalData.exerciseIndex].sets[modalData.setIndex].recommendationData.weightRecommendationRounded
    const phaseCompleted = activeReduxSlice.workoutTemplate.phases[modalData.phaseIndex].completed
    const phaseKey = activeReduxSlice.workoutTemplate.phases[modalData.phaseIndex].phaseKey
    const setCompleted = activeReduxSlice.workoutTemplate.phases[modalData.phaseIndex].exercises[modalData.exerciseIndex].sets[modalData.setIndex].completedSetData.completed
    const setData = activeReduxSlice.workoutTemplate.phases[modalData.phaseIndex].exercises[modalData.exerciseIndex].sets[modalData.setIndex]
    const originalExercise = activeReduxSlice.originalExerciseStorage[modalData.phaseIndex][modalData.exerciseIndex]

    const ShowBodyLocationOptions = () => {
        const subModalData = {
            key: ModalKey.ListSelection,
            fullscreen: false,
            headerText: 'What area of the body would you like to work on?',
            noListItemsText: 'No body locations are available at this time',
            getListItems: async () => {
                return [
                    { name: 'Adductors', key: 'adductors' },
                    { name: 'Ankles', key: 'ankles' },
                    { name: 'Glutes', key: 'glute-max' },
                    { name: 'Hamstrings', key: 'hamstrings' },
                    { name: 'Hip Flexors', key: 'hip-flexors' },
                    { name: 'ITB / TFL', key: 'itb' },
                    { name: 'Quads', key: 'quads' },
                    { name: 'Shoulders / Chest', key: 'shoulders' },
                    { name: 'Thoracic Spine', key: 'thoracic-spine' },
                ]
            },
            selectAction: async (bodyLocation) => ShowExerciseSwapOptionsFromBodyLocation(bodyLocation),
        }

        ShowModal(subModalData)
    }

    const ShowExerciseSwapOptionsFromBodyLocation = (bodyLocation) => {
        const subModalData = {
            key: ModalKey.ListSelection,
            fullscreen: false,
            headerText: 'Select an alternate exercise',
            noListItemsText: 'No mobility swaps are available at this time',
            getListItems: async () => {
                const jsonBody = {
                    swapType: 'mobility',
                    bodyLocation: bodyLocation.key,
                }

                // console.log(jsonBody)

                const result = await WhichWeightAPI('CORE', 'GET', '/exercise_swap_options', jsonBody)

                // console.log(result);

                return result.data.data
            },
            selectAction: async (swapExercise) => SwapReduxStoreExercise('mobility-swap', swapExercise),
        }

        ShowModal(subModalData)
    }

    const OnHomeSwapExerciseButton = () => {
        const subModalData = {
            key: ModalKey.ListSelection,
            fullscreen: false,
            headerText: 'Select an Alternate Exercise',
            noListItemsText: 'There are no home exercises available to replace ' + modalData.exercise.name + ' with at this time',
            getListItems: async () => {
                const jsonBody = {
                    swapType: 'home',
                    exerciseId: modalData.exercise.id,
                }

                // console.log(jsonBody)

                const result = await WhichWeightAPI('CORE', 'GET', '/exercise_swap_options', jsonBody)

                // console.log(result);

                return result.data.data
            },
            selectAction: async (swapExercise) => SwapReduxStoreExercise('home-swap', swapExercise),
        }

        ShowModal(subModalData)
    }

    const SwapReduxStoreExercise = (swapType, swapExercise) => {
        const swapExercisePayload = {
            phaseIndex: modalData.phaseIndex,
            exerciseIndex: modalData.exerciseIndex,
            swapType: swapType,
            swapExercise: swapExercise,
        }

        // console.log(swapExercisePayload);

        dispatch(swapActiveWorkoutExercise(swapExercisePayload))

        // For weight recommendation exercises, reset info and then fetch weight recommendations
        modalData.exercise.sets.map((set, setIndex) => {
            const resetSetPayload = {
                phaseIndex: modalData.phaseIndex,
                exerciseIndex: modalData.exerciseIndex,
                setIndex: setIndex,
            }

            // console.log(resetSetPayload)

            dispatch(resetActiveWorkoutIncompleteSetInfo(resetSetPayload))
        })

        HideModal()
    }

    const UndoExerciseSwap = () => {
        const undoPayload = {
            phaseIndex: modalData.phaseIndex,
            exerciseIndex: modalData.exerciseIndex,
        }

        dispatch(undoExerciseSwap(undoPayload))

        // For weight recommendation exercises, reset info and then fetch weight recommendations
        modalData.exercise.sets.map((set, setIndex) => {
            const resetSetPayload = {
                phaseIndex: modalData.phaseIndex,
                exerciseIndex: modalData.exerciseIndex,
                setIndex: setIndex,
            }

            // console.log(resetSetPayload)

            dispatch(resetActiveWorkoutIncompleteSetInfo(resetSetPayload))
        })

        HideModal()
    }

    const OnShowExerciseInstructions = () => {
        const subModalData = {
            key: ModalKey.ExerciseInstructions,
            fullscreen: true,
            exerciseId: modalData.exercise.id,
        }

        ShowModal(subModalData)
    }

    return (
        <>

            <div className="pt-2 pb-4 text-xl leading-6 text-gray-900">{modalData.exercise.name}</div>

            <div className="space-y-4">

                {/* Movement Information */}
                <div className="text-left bg-gray-100 px-4 py-2 space-y-1 border border-gray-300 rounded-lg">

                    <div className="font-semibold">Movement Information</div>

                    {/* Movement Difficulty Feedback Status */}
                    <div className="flex text-gray-500">
                        <div className="flex-grow">Movement Difficulty Feedback</div>
                        <div className="text-green-400">Enabled</div>
                    </div>

                </div>

                {/* Load Information */}
                <div className="text-left bg-gray-100 px-4 py-2 space-y-1 border border-gray-300 rounded-lg">

                    <div className="font-semibold">Load Information</div>

                    {/* Exercise Type */}
                    <div className="flex text-gray-500">

                        <div className="flex-grow">Type</div>

                        <div className="flex items-center gap-x-2">
                            
                            <img 
                                className="h-4" 
                                alt="Exercise Type Icon" 
                                src={GetExerciseTypeIcon(modalData.exercise.exerciseType)} 
                            />

                            <div>{CapitalizeFirstLetter(modalData.exercise.exerciseType)}</div>

                        </div>

                    </div>

                    {/* Taper */}
                    {taperData.useTaper && (
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Taper</div>
                            <div>{taperData.taperDampener * 100}% of Normal Load</div>
                        </div>
                    )}

                    {/* Weight Recommendation */}
                    {modalData.exercise.exerciseType === 'recommendation' &&
                        <>
                        
                            {/* Imperial */}
                            {measurementSystem === 'imperial' && (
                                <>
                                    {!modalData.exercise.continuousOverride && (
                                        <div className="flex text-gray-500">
                                            <div className="flex-grow">Weight</div>
                                            <div>{weightRecommendationRounded} lbs</div>
                                        </div>
                                    )}

                                    {modalData.exercise.continuousOverride && (
                                        <div className="flex text-gray-500">
                                            <div className="flex-grow">Weight</div>
                                            <div>{modalData.exercise.continuousOverrideWeight} lbs</div>
                                        </div>
                                    )}
                                </>
                            )}

                            {/* Metric */}
                            {measurementSystem === 'metric' && (
                                <>
                                    {!modalData.exercise.continuousOverride && (
                                        <div className="flex text-gray-500">
                                            <div className="flex-grow">Weight</div>
                                            <div>{ConvertPoundsToNearestFiveKilograms(weightRecommendation)} kgs</div>
                                        </div>
                                    )}

                                    {modalData.exercise.continuousOverride && (
                                        <div className="flex text-gray-500">
                                            <div className="flex-grow">Weight</div>
                                            <div>{ConvertPoundsToNearestFiveKilograms(modalData.exercise.continuousOverrideWeight)} kgs</div>
                                        </div>
                                    )}
                                </>
                            )}

                        </>
                    }

                    {/* Load Difficulty Feedback Status */}
                    <div className="flex text-gray-500">
                        <div className="flex-grow">Load Difficulty Feedback</div>
                        {IsLoadDifficultyFeedbackEnabledForExercise(modalData.exercise) && <div className="text-green-400">Enabled</div>}
                        {!IsLoadDifficultyFeedbackEnabledForExercise(modalData.exercise) && <div className="text-red-400">Disabled</div>}
                    </div>

                </div>

                {/* Equipment Details (Imperial Only) */}
                {modalData.set.recommendationData.equipmentDetails !== null && measurementSystem === 'imperial' && (
                    <div className="text-left bg-gray-100 px-4 py-2 space-y-1 border border-gray-300">
                        <div className="font-semibold">Equipment</div>

                        <EquipmentDetails equipmentDetails={modalData.set.recommendationData.equipmentDetails} roundedRecommendation={weightRecommendationRounded} style="modal" />
                    </div>
                )}

                {/* User Recommendation Override */}
                {!phaseCompleted && !setCompleted && IsLoadDifficultyFeedbackEnabledForExercise(modalData.exercise) && (
                    <div className="text-left bg-gray-100 px-4 py-2 space-y-1 border border-gray-300">
                        <div className="font-semibold">Weight Override</div>

                        <div className="text-xs text-gray-500">You may want to override weight if our recommendation is far off or you do not have the necessary equipment in front of you.</div>

                        <WeightOverride phaseIndex={modalData.phaseIndex} exerciseIndex={modalData.exerciseIndex} setIndex={modalData.setIndex} />
                    </div>
                )}

                {/* Completed Set Data */}
                {setCompleted && (
                    <div className="text-left bg-gray-100 px-4 py-2 space-y-1 border border-gray-300">

                        <div className="font-semibold">Result</div>

                        <div className="flex">
                            <div className="flex-grow">Reps Completed</div>
                            
                            {workoutTypeKey === 'active-assessment' && 
                                <>

                                    {modalData.exercise.id === 267 && 
                                        <div>1 / side</div>
                                    }

                                    {modalData.exercise.id !== 267 && 
                                        <div>{setData.completedSetData.setUnitsCompleted}/{setData.reps}</div>
                                    }

                                </>
                            }

                            {workoutTypeKey === 'active-workout' && 
                                <div>
                                    {setData.completedSetData.setUnitsCompleted}/{setData.reps}
                                </div>
                            }

                        </div>

                        <div className="flex items-center">
                            <div className="flex-grow">Movement Difficulty</div>
                            <img src={GetDifficultyIconFromValue(setData.completedSetData.movementDifficulty)} alt="Difficulty Icon" className="h-4 pr-1" />
                            <div>{GetMovementDifficultyScaleText(setData.completedSetData.movementDifficulty)}</div>
                        </div>

                        {IsLoadDifficultyFeedbackEnabledForExercise(modalData.exercise) &&
                            <div className="flex items-center">
                                <div className="flex-grow">Load Difficulty</div>
                                <img src={GetDifficultyIconFromValue(setData.completedSetData.difficulty)} alt="Difficulty Icon" className="h-4 pr-1" />
                                <div>{GetLoadDifficultyScaleText(setData.completedSetData.difficulty)}</div>
                            </div>
                        }

                    </div>
                )}
            </div>

            {/* Footer Buttons */}
            <div className="mt-auto pt-4 space-y-4">
                {/* Mobility Swap Exercise Button */}
                {!phaseCompleted && !setCompleted && phaseKey === 'warm-up' && (
                    <button className="w-full flex justify-center items-center border border-gray-400 py-2 rounded-full gap-x-2" onClick={() => ShowBodyLocationOptions()}>
                        <ArrowPathIcon className="h-4" />
                        <div className="text-lg">Swap Mobility Exercise</div>
                    </button>
                )}

                {/* Home Swap Exercise Button */}
                {workoutTypeKey === 'active-workout' && !phaseCompleted && !setCompleted && phaseKey !== 'warm-up' && (
                    <>
                        {/* Swap Button */}
                        {originalExercise === null && (
                            <button className="w-full flex justify-center items-center border border-gray-400 py-2 rounded-full gap-x-2" onClick={() => OnHomeSwapExerciseButton()}>
                                <ArrowPathIcon className="h-4" />
                                <div className="text-lg">Swap For Home Exercise</div>
                            </button>
                        )}

                        {/* Undo Swap Button */}
                        {originalExercise !== null && (
                            <button className="w-full flex justify-center items-center border border-gray-400 py-2 rounded-full gap-x-2" onClick={() => UndoExerciseSwap()}>
                                <ArrowUturnLeftIcon className="h-4" />
                                <div className="text-lg">Undo Swapped Exercise</div>
                            </button>
                        )}
                    </>
                )}

                {/* Instructions Button */}
                <button className="w-full flex justify-center items-center border border-gray-400 py-2 rounded-full gap-x-2" onClick={() => OnShowExerciseInstructions()}>
                    <Bars3BottomLeftIcon className="h-4" />
                    <div className="text-lg">Exercise Instructions</div>
                </button>

            </div>
        </>
    )
}
