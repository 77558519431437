import { useSelector } from 'react-redux'
import { GetPrimaryColorHex } from 'redux/slices/brand'

export default function NextButton({ 
    text = 'Next', 
    subtext = '', 
    action = () => {} 
}) {

    const styleConfig = useSelector((state) => state.brand.styleConfig)

    return (
        <div>
            {/* Subtext will render HTML tags */}
            {subtext !== '' && <p className="text-sm mb-2 text-gray-500" dangerouslySetInnerHTML={{ __html: subtext }} />}

            <button
                className="w-full text-center text-white rounded-full py-2 px-4 text-lg font-semibold" 
                style={{ backgroundImage: `linear-gradient(to right, ${GetPrimaryColorHex(styleConfig, 2)}, ${GetPrimaryColorHex(styleConfig)})` }}
                onClick={action}
            >
                {text}
            </button>
        </div>

    )
}
