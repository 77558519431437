import { WhichWeightAPI } from "WhichWeightAPI";
import { setReduxCouponData } from "redux/slices/subscription";

export const GetMembershipNameBasedOnInterval = (interval) => {

    switch(interval) {
        
        case 4: return "Block Membership";
        case 24: return "Season Membership";
        case 52: return "Annual Membership";
        default: return "Membership";

    }

}

export const GetFreeTrialLengthBasedOnInterval = (interval) => {

    switch(interval) {
        
        case 4: return 7;
        case 24: return 7;
        case 52: return 14;
        default: return 7;

    }

}

export const GetMoneyFormattedString = (input) => {

    const floored = Math.max(input, 0) / 100;

    return new Intl.NumberFormat('en-US', 
    { 
        style: 'currency', 
        currency: 'USD', 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2,
    }
    ).format(floored)

}

export const TrackAndClearPromoCode = async (dispatch, email, couponData) => {

    if (couponData !== null){

        const jsonBody = {
            email: email,
            promoCode: couponData.promoCode
        }

        const result = await WhichWeightAPI("BILLING", "POST", "/track_promo_code_apply", jsonBody)

        // console.log(result);

    }

    dispatch(setReduxCouponData(null));

}

export const DoesCouponTakeFullAmountOff = (couponData, unitAmount) => {

    if (couponData === null) return false

    if ((unitAmount - couponData.amountOff) <= 0) return true

    return false

}