import { TrashIcon } from '@heroicons/react/24/outline'
import moment from 'moment'

const EventTypeNames = {
    'a-race': 'A Race',
    'b-race': 'B Race',
    'c-race': 'C Race',
}

export default function EventCard({ event, ShowDeleteEventModal }) {
    return (
        <div className="p-4 space-y-1 border border-gray-400 rounded-xl">
            {/* Header */}
            <div className="flex items-center gap-x-2">
                <div className="text-sm font-semibold">{EventTypeNames[event.eventType]}</div>

                <div className="h-1 w-1 rounded-full bg-gray-600" />

                <div className="text-sm italic text-gray-600">{moment(event.eventDate).format('MMMM DD, YYYY')}</div>
            </div>

            <div className="flex">
                {/* Name */}
                <div className="flex-grow text-xl">{event.name}</div>

                {/* Delete Button */}
                <TrashIcon className="h-6 text-gray-400" onClick={(e) => ShowDeleteEventModal(e, event)} />
            </div>
        </div>
    )
}
