import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setProgramAssignmentPreview } from 'redux/slices/programAssignment'
import { GetPrimaryColorHex } from 'redux/slices/brand'

import DotsLoadingComponent from 'components/Loading/DotsLoadingComponent'

export default function ProgramAssignmentLoading() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [loadingText, setLoadingText] = useState('Analyzing Fitness Profile')

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const events = useSelector((state) => state.programAssignment.events)

    const GetProgramPreviewFromBackend = async () => {
        const jsonBody = {
            events: JSON.stringify(events),
        }

        // console.log(jsonBody);

        const result = await WhichWeightAPI('PROGRAM', 'GET', '/generate_program_weeks_preview', jsonBody)

        // console.log(result.data.data);

        dispatch(setProgramAssignmentPreview(result.data.data))
    }

    useEffect(() => {
        const createProgram = async () => {
            setLoadingText('Analyzing Fitness Profile')

            await new Promise((resolve) => setTimeout(resolve, 1500))

            setLoadingText('Adding Events')

            await new Promise((resolve) => setTimeout(resolve, 1500))

            setLoadingText('Building Your Program')

            await GetProgramPreviewFromBackend()

            await new Promise((resolve) => setTimeout(resolve, 500))

            navigate('/program/assignment/overview')
        }

        createProgram()
    }, []) // Empty dependency array to execute the effect only once

    return (
        <div 
            className="w-full h-screen flex flex-col items-center justify-center"
            style={{
                backgroundImage: `linear-gradient(to top, ${GetPrimaryColorHex(styleConfig, 2)}, ${GetPrimaryColorHex(styleConfig)})`
            }}
        >
            <DotsLoadingComponent />
            <h1 className="text-2xl font-bold text-white text-center mb-20">{loadingText}</h1>
        </div>
    )
}
