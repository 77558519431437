export const GetEventTerrainOptionsBasedOnSport = (sportId) => {

    // Triathlon
    if (sportId === 12) {
        return [
            { key: 'road', title: 'Road' },
            { key: 'xterra', title: 'Xterra' },
            { key: 'other', title: 'Other' }
        ]
    }

    // Cycling
    if (sportId === 10) {
        return [
            { key: 'road', title: 'Road' },
            { key: 'gravel', title: 'Gravel' },
            { key: 'mtb', title: 'MTB' },
            { key: 'other', title: 'Other' }
        ]
    }

    if (
        sportId === 13 || // Trail Running
        sportId === 15 || // 5k
        sportId === 16 || // 10k
        sportId === 17 || // Cross Country Running
        sportId === 18 || // Half Marathon
        sportId === 19 || // Marathon
        sportId === 20 // Ultra Marathon
    ) {
        return [
            { key: 'road-track', title: 'Road / Track' },
            { key: 'trail', title: 'Trail' },
            { key: 'other', title: 'Other' }
        ]
    }

    return [
        { key: 'other', title: 'Other' }
    ]

}

export const GetEventDistanceOptionsBasedOnSport = (sportId, terrainKey) => {

    // Triathlon
    if (sportId === 12) {

        if (terrainKey === 'road') {

            return [
                { key: 'ironman', title: 'Ironman' },
                { key: 'half-ironman', title: 'Half Ironman' },
                { key: 'long-dist', title: 'Long Dist (hybrid dist)' },
                { key: 'olympic', title: 'Olympic' },
                { key: 'sprint-distance', title: 'Sprint Distance' },
                { key: 'super-sprint', title: 'Super Sprint' },
                { key: 'other', title: 'Other' }
            ]

        }

        if (terrainKey === 'xterra') {

            return [
                { key: 'full-distance', title: 'Full Distance' },
                { key: 'short-course', title: 'Short Course' },
                { key: 'sport-distance', title: 'Sport distance' },
                { key: 'other', title: 'Other' }
            ]

        }

        return [
            { key: 'other', title: 'Other' }
        ]

    }

    // Cycling
    if (sportId === 10) {

        return [
            { key: 'other', title: 'Other' }
        ]

    }

    if (
        sportId === 13 || // Trail Running
        sportId === 15 || // 5k
        sportId === 16 || // 10k
        sportId === 17 || // Cross Country Running
        sportId === 18 || // Half Marathon
        sportId === 19 || // Marathon
        sportId === 20 // Ultra Marathon
    ) {

        if (terrainKey === 'road-track') {

            return [
                { key: '5k', title: '5k' },
                { key: '10k', title: '10k' },
                { key: 'half-marathon', title: 'Half Marathon' },
                { key: 'marathon', title: 'Marathon' },
                { key: 'ultra-marathon', title: 'Ultra Marathon' },
                { key: 'other', title: 'Other' }
            ]

        }

        if (terrainKey === 'trail') {

            return [
                { key: '5k', title: '5k' },
                { key: '10k', title: '10k' },
                { key: '20k', title: '20k' },
                { key: '40k', title: '40k' },
                { key: '50k', title: '50k' },
                { key: '50-miles', title: '50 miles' },
                { key: '100k', title: '100k' },
                { key: '100-miles', title: '100 miles' },
                { key: 'other', title: 'Other' }
            ]

        }

        return [
            { key: 'other', title: 'Other' }
        ]

    }

    return [
        { key: 'other', title: 'Other' }
    ]

}