import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { ModalContext } from '../ModalContext'
import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline'
import { GetPrimaryColorHex } from 'redux/slices/brand'

export default function TextModal() {
    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const { modalData } = useContext(ModalContext)

    return (
        <>
            {/* Bars Icon */}
            <div 
                className="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full"
                style={{
                    backgroundColor: GetPrimaryColorHex(styleConfig),
                }}
            >
                <Bars3BottomLeftIcon className="h-8 w-8 text-white" aria-hidden="true" />
            </div>

            {/* Title */}
            <div className="text-center text-lg font-semibold leading-6 text-gray-900">{modalData.titleText}</div>

            {/* Text */}
            <div className="space-y-2">
                {modalData.bodyText.map((text, index) => (
                    <p key={index} className="text-sm text-gray-600">
                        {text}
                    </p>
                ))}
            </div>

            {modalData.tinyText && (
                <div className="text-xs text-gray-400">{modalData.tinyText}</div>
            )}
            
        </>
    )
}
