import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore,  persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import activeAssessment from './slices/activeAssessment';
import activeWorkout from './slices/activeWorkout';
import brand from './slices/brand';
import onboarding from './slices/onboarding';
import programAssignment from './slices/programAssignment';
import subscription from './slices/subscription';
import trainer from './slices/trainer';
import user from './slices/user';
import userInterface from './slices/userInterface';
import viewWorkout from './slices/viewWorkout';
import whichWeightCache from './slices/whichWeightCache';

const rootReducer = combineReducers({
    activeAssessment,
    activeWorkout,
    brand,
    onboarding,
    programAssignment,
    subscription,
    trainer,
    user,
    userInterface,
    viewWorkout,
    whichWeightCache
});

// Persisted reducer configuration
const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['someReducer'],   // Specify which reducers you want to persist
  blacklist: ['brand', 'trainer', 'user', 'whichWeightCache'], // Specify which reducers you don't want to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export default store;