import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setSidebarOpen } from 'redux/slices/userInterface'
import { resetActiveWorkoutState } from 'redux/slices/activeWorkout'
import { resetActiveAssessmentState } from 'redux/slices/activeAssessment'
import { useAuth0 } from '@auth0/auth0-react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { UserIcon } from '@heroicons/react/20/solid'
import { FlagIcon, CalendarDaysIcon, IdentificationIcon, QuestionMarkCircleIcon, ArrowRightOnRectangleIcon, ChartBarSquareIcon, EnvelopeIcon, FunnelIcon , ClipboardDocumentListIcon, QueueListIcon} from '@heroicons/react/24/outline'
import { PlusSmallIcon } from '@heroicons/react/24/solid'
import { IsUserPartOfAnOrganization } from 'functions/userUtils'
import strengthIcon from 'media/icons/strength_2.png'

import OrganizationCard from 'components/Organization/OrganizationCard'

export default function TraineeSidebar() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { logout } = useAuth0()

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const firstName = useSelector((state) => state.user.firstName)
    const lastName = useSelector((state) => state.user.lastName)
    const organizationId = useSelector((state) => state.user.organizationId)
    const organizationName = useSelector((state) => state.user.organizationName)
    const organizationLogo = useSelector((state) => state.user.organizationLogo)

    const buttons = [
        {
            text: 'Membership',
            icon: styleConfig.logo,
            isHeroIcon: false,
            isComingSoon: false,
            action: () => navigate('/profile/membership'),
        },
        {
            text: 'Events',
            icon: FlagIcon,
            isHeroIcon: true,
            isComingSoon: false,
            action: () => navigate('/events'),
        },
        {
            text: 'Assessments',
            icon: ClipboardDocumentListIcon,
            isHeroIcon: true,
            isComingSoon: false,
            action: () => navigate('/assessment/list'),
        },
        {
            text: 'Edit Weekly Schedule',
            icon: CalendarDaysIcon,
            isHeroIcon: true,
            isComingSoon: false,
            action: () => navigate('/weeklySchedule/edit'),
        },
        {
            text: 'Fitness Profile',
            icon: IdentificationIcon,
            isHeroIcon: true,
            isComingSoon: false,
            action: () => navigate('/fitnessProfile'),
        },
        {
            text: 'Equipment',
            icon: strengthIcon,
            isHeroIcon: false,
            isComingSoon: false,
            action: () => navigate('/equipment'),
        },
        {
            text: 'Reports',
            icon: ChartBarSquareIcon,
            isHeroIcon: true,
            isComingSoon: false,
            action: () => navigate('/reports/list'),
        },
        {
            text: 'Exercise Library',
            icon: QueueListIcon,
            isHeroIcon: true,
            isComingSoon: false,
            action: () => navigate('/exercise/library'),
        },
        {
            text: 'Email Preferences',
            icon: EnvelopeIcon,
            isHeroIcon: true,
            isComingSoon: false,
            action: () => navigate('/emailPreferences'),
        },
        /*
        { 
            text: 'Completed Workouts', 
            icon: ChartBarIcon,
            isHeroIcon: true,
            isComingSoon: false,
            action: () => navigate("/profile/completedWorkouts")
        },
        */
        /*
        { 
            text: 'Coach Workouts', 
            icon: UsersIcon,
            isHeroIcon: true,
            isComingSoon: false,
            action: () => navigate("/profile/coachWorkouts")
        },
        */
        {
            text: 'Help Center',
            icon: QuestionMarkCircleIcon,
            isHeroIcon: true,
            isComingSoon: false,
            action: () => navigate('/profile/helpCenter'),
        },
        // {
        //     text: 'Test Modal',
        //     icon: QueueListIcon,
        //     isHeroIcon: true,
        //     isComingSoon: false,
        //     action: () => navigate('/test/modal'),
        // },
    ]

    const CloseSidebarMenu = () => dispatch(setSidebarOpen(false))

    const OnLogout = () => {
        dispatch(resetActiveWorkoutState())
        dispatch(resetActiveAssessmentState())
        logout({ logoutParams: { returnTo: window.location.origin + '/login' } })
    }

    return (
        <div className="absolute top-0 w-full">
            {/* Background blur */}
            <div className="absolute z-10 w-screen h-screen bg-gray-200 opacity-50" onClick={() => CloseSidebarMenu()} />

            {/* Sidebar Menu Content */}
            <div className="absolute z-20 flex flex-col h-screen w-10/12 border-r-2 border-gray-400 bg-white">
                {/* Profile Header */}
                <div className="p-4 space-y-3 bg-gray-100 shadow text-xl font-semibold text-gray-900">
                    <div className="flex items-center gap-x-2">
                        {/* User Icon */}
                        <div className="h-8 w-8 bg-white rounded-full items-center">
                            <UserIcon className="h-6 text-gray-400 m-1" />
                        </div>

                        {/* User Name */}
                        <h3 className="text-xl font-semibold leading-6 text-gray-900 text-4xl flex-grow">
                            {firstName} {lastName}
                        </h3>

                        <div>
                            <XMarkIcon className="h-6" onClick={() => CloseSidebarMenu()} />
                        </div>
                    </div>

                    {styleConfig.brandKey === 'which-weight' && 
                        <>

                            {/* Organization Card */}
                            {IsUserPartOfAnOrganization(organizationId, organizationName, organizationLogo) && <OrganizationCard organizationName={organizationName} organizationLogo={organizationLogo} />}

                            {/* Join Orgranization Button */}
                            {!IsUserPartOfAnOrganization(organizationId, organizationName, organizationLogo) && (
                                <div className="h-10 flex-grow bg-white bg-opacity-90 flex items-center px-2 py-1 gap-x-2 border border-gray-400 rounded-xl" onClick={() => navigate('/profile/membership/organization/join')}>
                                    <PlusSmallIcon className="h-6 rounded-full text-gray-600" />
                                    <div className="text-sm">Join An Organization</div>
                                </div>
                            )}
                            
                        </>
                    }

                </div>

                {/* Profile button options */}
                <div className="divide-y border border-gray-200">
                    {buttons.map((button, index) => (
                        <div key={index} className="flex px-4 py-3 text-lg items-center gap-x-4" onClick={button.action}>
                            {!button.isHeroIcon && button.text === 'Membership' && <img src={button.icon} alt="" className="h-5" />}
                            {!button.isHeroIcon && button.text === 'Equipment' && <img src={button.icon} alt="" className="h-5 opacity-40" />}
                            {button.isHeroIcon && <button.icon className="h-5 text-gray-500" />}

                            <p className="text-gray-600 flex-grow">{button.text}</p>

                            {button.isComingSoon && <div className="text-gray-400">Coming Soon</div>}
                        </div>
                    ))}
                </div>

                {/* Logout Button */}
                <div className="pt-3 px-3 pb-8 mt-auto">
                    <div className="flex items-center px-4 py-3 gap-x-2 mt-auto bg-gray-200 rounded-lg" onClick={() => OnLogout()}>
                        <ArrowRightOnRectangleIcon className="h-5 text-gray-600" />
                        <div className="text-lg text-gray-600">Logout</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
