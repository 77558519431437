import { useSelector } from 'react-redux'
import iconLogo from 'media/icons/logo192.png'
import { GetMoneyFormattedString, GetMembershipNameBasedOnInterval, GetFreeTrialLengthBasedOnInterval } from 'functions/subscriptionUtils'

export default function PricingOptions({ pricingOptions, selectedPricingOption, setSelectedPricingOption }) {
    const usedFreeTrial = useSelector((state) => state.user.usedFreeTrial)

    return (
        <div className="border rounded-lg divide-y divide-gray-400 border-gray-400">
            {pricingOptions.map((pricingOption, index) => (
                <div key={index} className="flex p-4 items-center gap-x-4" onClick={() => setSelectedPricingOption(pricingOption)}>
                    {/* Logo */}
                    <img src={iconLogo} alt="WhichWeight Logo" className="ml-2 h-10" />

                    {/* Details */}
                    <div className="flex-grow text-xl">
                        {/* Name */}
                        <div className="text-base">{GetMembershipNameBasedOnInterval(pricingOption.recurring.interval_count)}</div>

                        {/* Price and duration */}
                        <div className="text-sm text-gray-600">
                            {GetMoneyFormattedString(pricingOption.unit_amount)} / {pricingOption.recurring.interval_count} weeks
                        </div>

                        {/* Show free trial info text */}
                        {!usedFreeTrial && (
                            <div className="text-sm italic font-semibold text-orange-400">Try {GetFreeTrialLengthBasedOnInterval(pricingOption.recurring.interval_count)} Days Free!</div>
                        )}
                    </div>

                    {/* Radio */}
                    {pricingOption.lookup_key !== selectedPricingOption.lookup_key && <div className="h-4 w-4 rounded-full ring-gray-300 ring-2 border-2 border-white" />}
                    {pricingOption.lookup_key === selectedPricingOption.lookup_key && <div className="h-4 w-4 rounded-full bg-orange-600 ring-orange-500 ring-2 border-2 border-white" />}
                </div>
            ))}
        </div>
    )
}
