import { useState, useEffect } from 'react'
import { useGetExercises } from 'redux/customHooks/useGetExercises'
import { useNavigate, useLocation } from 'react-router-dom'

import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import CustomHeader from 'components/Header/CustomHeader'
import AssessmentSummaryExerciseSetListItem from 'components/WorkoutSummary/AssessmentSummaryExerciseSetListItem'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

export default function CompletedAssessmentSummary() {
    const navigate = useNavigate()
    const location = useLocation()

    const databaseExerciseData = useGetExercises()
    const { assessmentUIFlowKey, assessmentWorkoutTemplate } = location.state || {};

    const [assessmentScore, setAssessmentScore] = useState(null)
    const [processedAssessmentWorkoutTemplate, setProcessedAssessmentWorkoutTemplate] = useState(null)

    const ProcessAssessmentWorkoutTemplate = () => {

        if (assessmentWorkoutTemplate === null) setProcessedAssessmentWorkoutTemplate(null)

        var totalReps = 0
        var totalRepsCompleted = 0

        var temp_processedAssessmentWorkoutTemplate = JSON.parse(JSON.stringify(assessmentWorkoutTemplate))

        temp_processedAssessmentWorkoutTemplate.phases.map((phase) => {
            phase.exercises.map((exercise) => {
                exercise.sets.map((set) => {
                    
                    // Calculate and store current percentage and tag for current set
                    var currentSetRepsCompletedPercentage = (set.completedSetData.setUnitsCompleted / set.reps) * 100
                    set.completedSetData.percentageScore = currentSetRepsCompletedPercentage

                    if (currentSetRepsCompletedPercentage < 75) set.completedSetData.summaryTag = 'weak'
                    if (75 <= currentSetRepsCompletedPercentage) set.completedSetData.summaryTag = 'strong'

                    // Aggregate total reps completed
                    totalReps += set.reps
                    if (set.completedSetData.completed) totalRepsCompleted += set.completedSetData.setUnitsCompleted

      
                });
            });
        });

        // Sort exercises by percentageScore ascending
        temp_processedAssessmentWorkoutTemplate.phases.forEach(phase => {
            phase.exercises.sort((a, b) => {
                const aScore = a.sets[0].completedSetData.percentageScore
                const bScore = b.sets[0].completedSetData.percentageScore
                return aScore - bScore
            })
        })

        setProcessedAssessmentWorkoutTemplate(temp_processedAssessmentWorkoutTemplate)
        setAssessmentScore(Math.round((totalRepsCompleted / totalReps) * 100))

    }

    const OnDoneButton = () => {

        if (assessmentUIFlowKey === 'onboarding') navigate('/program/assignment/intro')
        if (assessmentUIFlowKey === 'list') navigate('/assessment/list')
        
    }

    useEffect(() => {

        if (assessmentWorkoutTemplate !== null) ProcessAssessmentWorkoutTemplate()

    }, [assessmentWorkoutTemplate])

    if (assessmentScore === null || databaseExerciseData.length === 0) return <MediumLoadingComponent />

    return (
        <>

            {assessmentUIFlowKey === 'onboarding' && (
                <CustomHeader text='Assessment Summary' backButtonType='none' />
            )}

            {assessmentUIFlowKey === 'list' && (
                <CustomHeader text='Assessment Summary' backButtonType='arrow' backButtonAction={() => navigate('/assessment/list')} />
            )}

            {processedAssessmentWorkoutTemplate === null && <div className="p-4 text-center">Unable to find assessment workout template</div>}

            {processedAssessmentWorkoutTemplate !== null && (
                <div className="p-4 space-y-4">

                    {/* Assessment Score Bar */}
                    <div className='relative space-y-1'>

                    <div 
                        className={`
                            ${assessmentScore < 75 ? 'text-red-600' : 'text-green-600'}
                            text-center font-bold flex items-center justify-center space-x-2
                        `}
                    >
                        <span>Total Assessment Score</span>
                        {assessmentScore < 50 && (
                            <span>
                                <ExclamationTriangleIcon className='mt-1 text-red-600 h-4 w-4' />
                            </span>
                        )}
                    </div>

                        <div 
                            className='pt-[18px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-center font-bold'
                            style={{ textShadow: '1px 1px 0 #666, -1px -1px 0 #666, 1px -1px 0 #666, -1px 1px 0 #666, 1px 1px 0 #666' }}
                        >
                            {assessmentScore}%
                        </div>

                        <div className="bg-gray-300 w-full h-6 rounded-lg overflow-hidden">
                            <div 
                                className={`
                                    h-6
                                    ${assessmentScore < 75 ? 'bg-red-400' : 'bg-green-400'}
                                `} 
                                style={{ 
                                    width: assessmentScore + '%',
                                }} 
                            />
                        </div>

                    </div>

                    {processedAssessmentWorkoutTemplate.phases.map((phase) => (
                        <div 
                            key={phase.phaseKey} 
                            className="border border-gray-400 rounded-lg overflow-hidden"
                        >                     

                            {/* CIRCUIT Phase Type */}
                            {phase.type === 'circuit' && (
                                <>
                                    {Array.from({ length: phase.circuitRounds }).map((_, setIndex) => (
                                        <div 
                                            key={setIndex} 
                                            className={`${setIndex < phase.circuitRounds - 1 ? 'border-b border-gray-400' : ''}`}
                                        >
                                            {phase.exercises.map((exercise, exerciseIndex) => (
                                                <div 
                                                    key={exerciseIndex} 
                                                    className={`${exerciseIndex < phase.exercises.length - 1 ? 'border-b border-gray-400' : ''}`}
                                                >

                                                    {/* <pre className="text-xs p-2 bg-gray-100 border-b border-gray-400 overflow-x-auto">
                                                        {JSON.stringify(exercise.sets[setIndex], null, 2)}
                                                    </pre> */}
                                                    
                                                    <AssessmentSummaryExerciseSetListItem
                                                        exerciseId={exercise.id}
                                                        exerciseName={exercise.name}
                                                        percentageScore={exercise.sets[setIndex].completedSetData.percentageScore}
                                                        summaryTag={exercise.sets[setIndex].completedSetData.summaryTag}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </>
                            )}

                        </div>
                    ))}

                    <div
                        className="w-full flex justify-center items-center gap-x-4 bg-white rounded-full py-2 border border-gray-400"
                        onClick={() => OnDoneButton()}
                    >
                        {assessmentUIFlowKey === 'onboarding' && 'Finish Assessment'}
                        {assessmentUIFlowKey === 'list' && 'Done'}
                    </div>

                </div>
                    
                
            )}

        </>
    )
}
