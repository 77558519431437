import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { setMembershipActive, setSubscriptionStatus } from 'redux/slices/user'

import moment from 'moment'

import CustomHeader from 'components/Header/CustomHeader'
import LoadingButton from 'components/LoadingButton'

/*

1. Direction
2. Accountability
3. Encouragement
4. Community
5. Results

*/

const cancellationSurveyOptions = [
    {
        id: 1,
        key: 'direction',
        text: 'The app is hard to use',
    },
    {
        id: 2,
        key: 'direction',
        text: 'I do not like the exercises',
    },
    {
        id: 3,
        key: 'direction',
        text: 'Inaccurate weight recommendations',
    },
    {
        id: 4,
        key: 'direction',
        text: 'I completed my race / the program ended',
    },
    {
        id: 5,
        key: 'accountability',
        text: 'I do not have time to strength train',
    },
    {
        id: 6,
        key: 'encouragement',
        text: 'I am not motivated to keep training',
    },
    {
        id: 7,
        key: 'community',
        text: 'It is difficult to share my progress',
    },
    {
        id: 8,
        key: 'results',
        text: 'The training is ineffective',
    },
    {
        id: 9,
        key: 'results',
        text: 'I got injured',
    },
    {
        id: 10,
        key: 'budget',
        text: 'Too expensive',
    },
    {
        id: 11,
        key: 'other',
        text: 'Other',
    },
]

export default function SubscriptionCancel() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const { status, periodEndDate } = location.state

    const userId = useSelector((state) => state.user.id)
    const email = useSelector((state) => state.user.email)

    const [selectedSurveyOptions, setSelectedSurveyOptions] = useState([])
    const [additionalNote, setAdditionalNote] = useState('')
    const [errorText, setErrorText] = useState('')
    const [isCancelSubscriptionLoading, setIsCancelSubscriptionLoading] = useState(false)

    const HandleSurveyOptionChange = (option) => {
        setErrorText('')

        var temp_selectedOptions = JSON.parse(JSON.stringify(selectedSurveyOptions))

        // If the option is already present, remove it
        if (temp_selectedOptions.some((item) => item === option.id)) temp_selectedOptions = temp_selectedOptions.filter((item) => item !== option.id)
        // If the option is not present, add it
        else temp_selectedOptions.push(option.id)

        // console.log(temp_selectedOptions)

        setSelectedSurveyOptions(temp_selectedOptions)
    }

    const HandleAdditionalNotesChange = (event) => {
        setErrorText('')
        if (event.target.value.length < 150) setAdditionalNote(event.target.value)
    }

    const OnCancelSubscription = async () => {
        setErrorText('')

        if (selectedSurveyOptions.length === 0) {
            setErrorText('Please select at lease one cancellation option')
            return
        }

        setIsCancelSubscriptionLoading(true)

        // Cancel Stripe subscription

        const jsonBody = {
            email: email,
            statusUponCancellation: status,
        }

        // console.log(jsonBody); return

        const result = await WhichWeightAPI('BILLING', 'POST', '/cancel_subscription', jsonBody)

        // console.log(result);

        if (result.data.status === 'failure') {
            setIsCancelSubscriptionLoading(false)
            console.error(result)
            return
        }

        // Track user feedback

        const jsonBody2 = {
            userId: userId,
            source: 'cancellation',
            detailsJSON: {
                statusUponCancellation: status,
                selectedSurveyOptions: selectedSurveyOptions,
                periodEndDate: moment.unix(periodEndDate).format('YYYY-MM-DD'),
            },
            additionalNote: additionalNote,
        }

        await WhichWeightAPI('CORE', 'POST', '/user_feedback_item', jsonBody2)

        // Send cancellation email

        const emailBody = {
            recipient: email,
            subject: 'Cancellation Confirmation',
            emailTemplateKey: 'cancellation_email_template',
        }

        await WhichWeightAPI('EMAIL', 'POST', '/send_email', emailBody)

        setIsCancelSubscriptionLoading(false)

        // Reset user store variables if the insta cancel from past_due went through
        if (status === 'past_due' || status === 'unpaid') {
            dispatch(setMembershipActive(false))
            dispatch(setSubscriptionStatus('canceled'))
        }

        navigate('/profile/membership')
    }

    return (
        <>
            {/* Header */}
            <CustomHeader text="Cancel Subscription" backButtonAction={() => navigate('/profile/membership')} />

            <div className="p-4 space-y-2 text-center">
                <div>We are sorry to see you go!</div>

                <div className="px-2">Can you please provide us with a reason as to why you are cancelling your membership?</div>

                <div className="text-xs italic text-gray-600">Select all that apply</div>

                {/* Cancellation Survey Options */}
                <div className="border border-gray-400 divide-y divide-gray-400 rounded-lg overflow-hidden">
                    {cancellationSurveyOptions.map((option) => (
                        <div key={option.id} className="py-3 px-4 flex items-center text-sm" onClick={() => HandleSurveyOptionChange(option)}>
                            <div className="text-left flex-grow">{option.text}</div>

                            {selectedSurveyOptions.length === 0 && <div className="h-4 w-4 border border-gray-400 rounded-full" />}
                            {selectedSurveyOptions.length > 0 && !selectedSurveyOptions.some((item) => item === option.id) && <div className="h-4 w-4 border border-gray-400 rounded-full" />}
                            {selectedSurveyOptions.length > 0 && selectedSurveyOptions.some((item) => item === option.id) && <div className="h-4 w-4 bg-orange-400 rounded-full" />}
                        </div>
                    ))}
                </div>

                {/* Additional Notes Box */}
                <textarea
                    type="text"
                    min="0"
                    name="additionalNotes"
                    id="additionalNotes"
                    className="h-20 w-full py-2 px-4 text-sm rounded-lg border border-gray-400 placeholder:text-gray-400"
                    placeholder={'Please provide us with any additional details.. '}
                    value={additionalNote}
                    onChange={(event) => HandleAdditionalNotesChange(event)}
                />

                {status === 'active' || (status === 'trialing' && <div className="px-4 text-gray-600 text-sm">You will remain a member until {moment.unix(periodEndDate).format('MMMM D')}</div>)}

                {errorText !== '' && <div className="text-sm text-red-400">{errorText}</div>}

                <LoadingButton
                    buttonText="Cancel"
                    isLoading={isCancelSubscriptionLoading}
                    className="w-full flex justify-center items-center rounded-full border-2 border-red-400 py-2 text-md font-semibold text-red-400 shadow-sm gap-x-4"
                    action={OnCancelSubscription}
                />
            </div>
        </>
    )
}
