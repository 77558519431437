import { useContext } from 'react'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { GetPresentWeekOverview } from 'redux/selectors/PresentWeekOverviewSelector'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { FireIcon } from '@heroicons/react/20/solid'

export default function WeeklyStreak() {
    const presentWeek = GetPresentWeekOverview()
    const streak = presentWeek.streak

    const { ShowModal } = useContext(ModalContext)

    const ShowStreakInfoModal = () => {
        const modalData = {
            key: ModalKey.Text,
            fullscreen: false,
            titleText: 'Workout Streak',
            bodyText: ['Keep track of how many weeks in a row you have kept up with your WhichWeight programming', 'You only need to complete 50% of a given week to keep your streak alive!'],
        }

        ShowModal(modalData)
    }

    const getStreakState = () => {
        // No streak
        if (streak === 0) {
            return {
                text: 'weeks',
                iconColor: 'text-zinc-400',
            }
        }

        // 1 week streak
        if (streak === 1) {
            return {
                text: `week`,
                iconColor: 'text-orange-400',
            }
        }

        if (1 < streak && streak <= 10) {
            // 2-10 week streak
            return {
                text: `weeks`,
                iconColor: 'text-orange-400',
            }
        }

        if (10 < streak) {
            // 11+ week streak
            return {
                text: `week`,
                iconColor: 'text-orange-500',
            }
        }

        // Default case
        return {
            text: 'No streak history',
            iconColor: 'text-gray-500',
        }
    }

    const streakState = getStreakState()

    return (
        <div className="flex-1 flex-grow flex-col items-center pt-1 pb-2 px-2 space-y-1 border-r border-gray-600" onClick={() => ShowStreakInfoModal()}>
            <div className="flex items-center justify-center gap-x-1">
                <div className="text-center text-xs text-gray-600">Streak</div>
                <InformationCircleIcon className="h-3 text-gray-600" />
            </div>

            {/* Icon */}
            <div className="flex items-center justify-center gap-x-1">
                <FireIcon className={`h-8 w-8 ${streakState.iconColor}`} />
                <div className="text-zinc-700 text-2xl text-center">{streak}</div>
                <div className="text-xs mt-2 text-gray-600">{streakState.text}</div>
            </div>
        </div>
    )
}
