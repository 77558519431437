import React, { useContext } from 'react'
import { ModalContext, ModalKey } from './ModalContext'

import CloseButtonHeader from './Components/CloseButtonHeader'

import ActionWarningModal from './Popups/ActionWarningModal'
import ActivateMembershipModal from './Popups/ActivateMembershipModal'
import AddToHomeScreenModal from './Popups/AddToHomeScreenModal'
import ExerciseAssessmentInfoModal from './Popups/ExerciseAssessmentInfoModal'
import ExerciseInstructionsModal from './Popups/ExerciseInstructionsModal'
import FeedbackModal from './Popups/FeedbackModal'
import ListSelectionModal from './Popups/ListSelectionModal'
import MoveWorkoutWeekDayModal from './Popups/MoveWorkoutWeekDayModal'
import MultiActionWarningModal from './Popups/MultiActionWarningModal'
import NetPromoterScoreModal from './Popups/NetPromoterScoreModal'
import SelectExerciseModal from './Popups/SelectExerciseModal'
import TextInputModal from './Popups/TextInputModal'
import TextModal from './Popups/TextModal'
import TrainingStatus from './Popups/TrainingStatusModal'
import VideoPlayerModal from './Popups/VideoPlayerModal'
import WorkoutSetFeedbackModal from './Popups/WorkoutSetFeedbackModal'
import WorkoutSetInformationModal from './Popups/WorkoutSetInformationModal'
export default function ModalOverlay() {
    const { modalData, HideModal } = useContext(ModalContext)

    if (modalData === null) return null

    return (
        <div className="relative z-10" onClick={HideModal}>
            {/* Background Blur */}
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={(e) => e.stopPropagation()} />

            {/* Modal */}
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex h-full items-end justify-center p-4 text-center sm:items-center">
                    <div
                        className={`w-full lg:w-1/3 p-4 relative transform overflow-auto rounded-lg bg-white shadow-xl transition-all ${modalData.fullscreen ? 'flex flex-col h-full lg:h-96' : 'space-y-4 mb-4'}`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <CloseButtonHeader />

                        {(() => {
                            switch (modalData.key) {
                                case ModalKey.ActionWarning:
                                    return <ActionWarningModal />

                                case ModalKey.ActivateMembership:
                                    return <ActivateMembershipModal />

                                case ModalKey.AddToHomeScreen:
                                    return <AddToHomeScreenModal />
                                
                                case ModalKey.ExerciseAssessmentInfo:
                                    return <ExerciseAssessmentInfoModal />

                                case ModalKey.ExerciseInstructions:
                                    return <ExerciseInstructionsModal />

                                case ModalKey.Feedback:
                                    return <FeedbackModal />

                                case ModalKey.ListSelection:
                                    return <ListSelectionModal />

                                case ModalKey.MoveWorkoutWeekDay:
                                    return <MoveWorkoutWeekDayModal />

                                case ModalKey.MultiActionWarning:
                                    return <MultiActionWarningModal />

                                case ModalKey.NetPromoterScore:
                                    return <NetPromoterScoreModal />

                                case ModalKey.SelectExercise:
                                    return <SelectExerciseModal />

                                case ModalKey.Text:
                                    return <TextModal />

                                case ModalKey.TextInput:
                                    return <TextInputModal />

                                case ModalKey.TrainingStatus:
                                    return <TrainingStatus />

                                case ModalKey.VideoPlayer:
                                    return <VideoPlayerModal />

                                case ModalKey.WorkoutSetFeedback:
                                    return <WorkoutSetFeedbackModal />

                                case ModalKey.WorkoutSetInformation:
                                    return <WorkoutSetInformationModal />

                                default:
                                    return <>Invalid Modal Key {modalData.key}</>
                            }
                        })()}
                    </div>
                </div>
            </div>
        </div>
    )
}
