import { useContext } from 'react'
import { ModalContext } from '../ModalContext'
import { useNavigate } from 'react-router-dom'
import iconLogo from 'media/icons/logo192.png'

import NextButton from 'components/ProgramAssignment/NextButton'

export default function ActivateMembershipModal() {
    const navigate = useNavigate()
    const { HideModal } = useContext(ModalContext)

    const OnBecomeAMemberButton = () => {
        navigate('/profile/membership')
        HideModal()
    }

    return (
        <>
            <div>
                {/* WhichWeight Logo */}
                <img src={iconLogo} className="-mt-4 mx-auto h-30" />

                {/* Text */}
                <div className="-mt-4 text-lg font-medium">
                    You must be a WhichWeight
                    <br />
                    Member to continue!
                </div>
            </div>

            {/* Become a member button */}
            <NextButton text="Become A Member" action={() => OnBecomeAMemberButton()} />
        </>
    )
}
