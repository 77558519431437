import { WhichWeightAPI } from 'WhichWeightAPI'
import { Fragment, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ModalContext } from '../ModalContext'
import { useGetEntireWeeklyCalendarJSON } from 'redux/customHooks/useGetEntireWeeklyCalendarJSON'
import { GetWorkoutTemplateNameFromKey } from 'functions/textUtils'
import { CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { setWhichWeightCacheEntireWeeklyCalendarJSON } from 'redux/slices/whichWeightCache'
import moment from 'moment'

export default function MoveWorkoutWeekDayModal() {
    const dispatch = useDispatch()
    const { HideModal } = useContext(ModalContext)

    const userId = useSelector((state) => state.user.id)
    const presentWeekOfTheYearKey = useSelector((state) => state.user.presentWeekOfTheYearKey)
    const selectedWeekDayIndex = useSelector((state) => state.userInterface.selectedWeekDayIndex)
    const presentOverviewFileContents = useGetEntireWeeklyCalendarJSON().filter((item) => item.weekOfTheYearKey === presentWeekOfTheYearKey)[0]
    const weekDaySchedule = presentOverviewFileContents.weekDaySchedule
    const completionData = presentOverviewFileContents.completionData

    const OnWorkoutSelected = async (workoutTemplateKey) => {
        // console.log(workoutTemplateKey);

        // console.log("Before")
        // console.log(JSON.stringify(weekDaySchedule, null, 2));

        var newWeekDaySchedule = JSON.parse(JSON.stringify(weekDaySchedule))

        // Set the week day where the workout currently is at to rest
        Object.keys(newWeekDaySchedule).map((key) => {
            if (newWeekDaySchedule[key] === workoutTemplateKey) newWeekDaySchedule[key] = 'rest'
        })

        // Set the new week day for workout
        newWeekDaySchedule[selectedWeekDayIndex] = workoutTemplateKey

        // console.log("After")
        // console.log(JSON.stringify(newWeekDaySchedule, null, 2))

        const jsonBody = {
            userId: userId,
            presentWeekOfTheYearKey: presentWeekOfTheYearKey,
            weekDaySchedule: newWeekDaySchedule,
        }

        // console.log(jsonBody)

        await WhichWeightAPI('PROGRAM', 'POST', '/present_week_day_schedule_json', jsonBody)

        dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON(null)) // Reset calendar data
    }

    return (
        <>
            <div className="text-center text-lg font-semibold leading-6 text-gray-900">
                Select a Workout to
                <br />
                Move to {moment.utc().local().startOf('isoWeek').add(selectedWeekDayIndex, 'days').format('dddd')}
            </div>

            <div className="border-t border-x border-gray-400 rounded-lg overflow-hidden">
                <div className="w-full overflow-auto">
                    {Object.keys(weekDaySchedule).map((key) => (
                        <Fragment key={key}>
                            {weekDaySchedule[key] !== 'rest' && (
                                <>
                                    {/* Complete Option */}
                                    {completionData[weekDaySchedule[key]].completed && (
                                        <div className="flex gap-x-2 items-center p-4 border-b border-gray-400 bg-gray-100 text-left">
                                            <div className="flex-grow">
                                                <p>{GetWorkoutTemplateNameFromKey(weekDaySchedule[key])}</p>
                                                <div className="text-gray-400 text-sm italic">Completed on {moment.utc().local().startOf('isoWeek').add(key, 'days').format('dddd')}</div>
                                            </div>

                                            <CheckCircleIcon className="h-6 text-green-400" />
                                        </div>
                                    )}

                                    {/* Incomplete Option */}
                                    {!completionData[weekDaySchedule[key]].completed && (
                                        <div
                                            className="p-4 flex border-b border-gray-400 items-center text-left"
                                            onClick={() => {
                                                OnWorkoutSelected(weekDaySchedule[key])
                                                HideModal()
                                            }}
                                        >
                                            <div className="flex-grow">
                                                <p>{GetWorkoutTemplateNameFromKey(weekDaySchedule[key])}</p>
                                                <div className="text-gray-400 text-sm italic">Scheduled for {moment.utc().local().startOf('isoWeek').add(key, 'days').format('dddd')}</div>
                                            </div>

                                            <ChevronRightIcon className="h-6 text-gray-600" />
                                        </div>
                                    )}
                                </>
                            )}
                        </Fragment>
                    ))}
                </div>
            </div>
        </>
    )
}
