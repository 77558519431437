export default function CancelSubscriptionButton({ subscriptionData, OnCancelSubscriptionButton }) {
    if (!subscriptionData.cancel_at_period_end) {
        return (
            <button
                type="button"
                className="w-full text-center rounded-full py-2 text-md font-semibold text-red-400 border border-red-400"
                onClick={() => OnCancelSubscriptionButton(subscriptionData.current_period_end)}
            >
                Cancel Subscription
            </button>
        )
    }
}
