import { createSlice } from '@reduxjs/toolkit'

const initialState =  {
    assessmentUIFlowKey: null,        
    uiMode: 'expanded',
    taperData: {
        useTaper: false,
        taperDampener: 1,
        taperLevel: 'taper-none',
        taperType: 'none',
        progressionLevelRegression: 0
    },
    workoutTemplate: null,
    phaseIndex: 0,
    exerciseIndex: 0,
    setIndex: 0,
    originalExerciseStorage: null
}

const activeAssessmentSlice = createSlice({
  name: 'activeAssessment',
  initialState,
  reducers: {
    initializeActiveAssessment: (state, action) => { 

        state.assessmentUIFlowKey = action.payload.assessmentUIFlowKey

        // Initializing workout template

        state.workoutTemplate = action.payload.workoutTemplate

        state.phaseIndex = 0
        state.exerciseIndex = 0
        state.setIndex = 0

        // Initialize orgrinalExerciseStorage
        // Not used for active assessment but required for the weight recommendation info modal

        state.originalExerciseStorage = []

        // Loop over each phase
        action.payload.workoutTemplate.phases.map((phase) => {

            var storageExercises = []

            // Add a null object for each exercise
            phase.exercises.map(() => { storageExercises.push(null) })

            state.originalExerciseStorage.push(storageExercises)
            
        })

    },
    setActiveAssessmentPhaseIndex: (state, action) => { state.phaseIndex = parseInt(action.payload) },
    setActiveAssessmentExerciseIndex: (state, action) => { state.exerciseIndex = parseInt(action.payload) },
    setActiveAssessmentSetIndex: (state, action) => { state.setIndex = parseInt(action.payload) },
    setActiveAssessmentIncompleteSetInfo: (state, action) => {

        const currentSet = state.workoutTemplate.phases[action.payload.phaseIndex].exercises[action.payload.exerciseIndex].sets[action.payload.setIndex];

        currentSet.recommendationData.weightRecommendationLoaded = true;
        currentSet.recommendationData.weightRecommendation = action.payload.weightRecommendation;
        currentSet.recommendationData.weightRecommendationRounded = action.payload.weightRecommendationRounded;
        currentSet.recommendationData.formulaTag = action.payload.formulaTag;
        currentSet.recommendationData.equipmentDetails = action.payload.equipmentDetails;

    },
    resetActiveAssessmentIncompleteSetInfo: (state, action) => {
        
        const currentSet = state.workoutTemplate.phases[action.payload.phaseIndex].exercises[action.payload.exerciseIndex].sets[action.payload.setIndex];

        currentSet.recommendationData.weightRecommendationLoaded = false;
        currentSet.recommendationData.weightRecommendation = 0;
        currentSet.recommendationData.weightRecommendationRounded = 0;
        currentSet.recommendationData.formulaTag = "";
        currentSet.recommendationData.equipmentDetails = null;

    },
    updateActiveAssessmentExerciseSetWeightOverride: (state, action) => {

        const currentSet = state.workoutTemplate.phases[action.payload.phaseIndex].exercises[action.payload.exerciseIndex].sets[action.payload.setIndex];

        currentSet.recommendationData.weightRecommendation += action.payload.weightUpdate;
        currentSet.recommendationData.weightRecommendationRounded += action.payload.weightUpdateRounded;

        // Make sure values cannot go below 10
        currentSet.recommendationData.weightRecommendation = Math.max(10, currentSet.recommendationData.weightRecommendation)
        currentSet.recommendationData.weightRecommendationRounded = Math.max(10, currentSet.recommendationData.weightRecommendationRounded)

        currentSet.completedSetData.override += action.payload.override;

    },
    setActiveAssessmentExerciseSetSavingResultBoolean: (state, action) => {

        const currentSet = state.workoutTemplate.phases[action.payload.phaseIndex].exercises[action.payload.exerciseIndex].sets[action.payload.setIndex];

        currentSet.completedSetData.savingResult = action.payload.savingResult;

    },
    setActiveAssessmentCompleteSetInfo: (state, action) => {
        
        const currentSet = state.workoutTemplate.phases[action.payload.phaseIndex].exercises[action.payload.exerciseIndex].sets[action.payload.setIndex];

        currentSet.completedSetData.completed = true;
        currentSet.completedSetData.databaseId = action.payload.databaseId;
        currentSet.completedSetData.setUnitsCompleted = action.payload.setUnitsCompleted;
        currentSet.completedSetData.movementDifficulty = action.payload.movementDifficulty;
        currentSet.completedSetData.difficulty = action.payload.difficulty;

    },
    setActiveAssessmentPhaseCompletionBoolean: (state, action) => {

        const currentPhase = state.workoutTemplate.phases[action.payload.phaseIndex]

        currentPhase.completed = action.payload.completed;

    },
    toggleUIModeAssessment: (state) => {

        switch (state.uiMode){

            case "compact":

                state.uiMode = "expanded"

                // Toggle onto the next incomplete set

                const currentPhase = state.workoutTemplate.phases[state.phaseIndex]

                // Default new indices to end of a phase
                var newExerciseIndex = currentPhase.exercises.length - 1
                var newSetIndex = currentPhase.exercises[0].sets.length - 1
    
                // For straight phase type
                if (currentPhase.type === 'straight') {

                    // Loop over all exercises
                    outerLoop: for (let exerciseIndex = 0; exerciseIndex < currentPhase.exercises.length; exerciseIndex++) {

                        // For all sets
                        for (let setIndex = 0; setIndex < currentPhase.exercises[exerciseIndex].sets.length; setIndex++) {
                            
                            // On the first incomplete set
                            if (!currentPhase.exercises[exerciseIndex].sets[setIndex].completedSetData.completed) {
                                
                                newExerciseIndex = exerciseIndex
                                newSetIndex = setIndex
    
                                break outerLoop

                            }
                            
                        }

                    }
                }
    
                // For circuit phase type
                if (currentPhase.type === 'circuit') {

                    // Loop over each circuit round
                    outerLoop: for (let setIndex = 0; setIndex < currentPhase.circuitRounds; setIndex++) {

                        // Loop over all exercises
                        for (let exerciseIndex = 0; exerciseIndex < currentPhase.exercises.length; exerciseIndex++) {

                            // On the first incomplete set
                            if (!currentPhase.exercises[exerciseIndex].sets[setIndex].completedSetData.completed) {

                                newExerciseIndex = exerciseIndex
                                newSetIndex = setIndex
    
                                break outerLoop
                            }
                        }
                    }
                }

                state.exerciseIndex = newExerciseIndex
                state.setIndex = newSetIndex

                break

            case "expanded": 

                state.uiMode = "compact"

                break

            default:
                console.warn(state.uiMode + " is not a valid uiMode!! Defaulting to compact")
                state.uiMode = "compact"
                break

        }

    },
    resetActiveAssessmentState: () => initialState
  },
});

export const IsActiveAssessmentSliceInCurrentFormat = (activeAssessment) => {

    return (
        activeAssessment.hasOwnProperty("assessmentUIFlowKey") &&
        activeAssessment.hasOwnProperty("uiMode") &&
        activeAssessment.hasOwnProperty("taperData") &&
        activeAssessment.hasOwnProperty("workoutTemplate") &&
        activeAssessment.hasOwnProperty("phaseIndex") &&
        activeAssessment.hasOwnProperty("exerciseIndex") &&
        activeAssessment.hasOwnProperty("setIndex") &&
        activeAssessment.hasOwnProperty("originalExerciseStorage")
    )

}

export const IsActiveAssessmentSliceInInitialState = (activeAssessment) => {
    return (
        activeAssessment.assessmentUIFlowKey === initialState.assessmentUIFlowKey &&
        activeAssessment.uiMode === initialState.uiMode && 
        activeAssessment.taperData.useTaper === initialState.taperData.useTaper && 
        activeAssessment.taperData.taperDampener === initialState.taperData.taperDampener && 
        activeAssessment.taperData.taperLevel === initialState.taperData.taperLevel && 
        activeAssessment.taperData.taperType === initialState.taperData.taperType && 
        activeAssessment.taperData.progressionLevelRegression === initialState.taperData.progressionLevelRegression && 
        activeAssessment.workoutTemplate === initialState.workoutTemplate && 
        activeAssessment.phaseIndex === initialState.phaseIndex && 
        activeAssessment.exerciseIndex === initialState.exerciseIndex && 
        activeAssessment.setIndex === initialState.setIndex && 
        activeAssessment.originalExerciseStorage === initialState.originalExerciseStorage
    );
};

export const { 
    initializeActiveAssessment,
    setActiveAssessmentPhaseIndex,
    setActiveAssessmentExerciseIndex,
    setActiveAssessmentSetIndex,
    setActiveAssessmentIncompleteSetInfo,
    resetActiveAssessmentIncompleteSetInfo,
    updateActiveAssessmentExerciseSetWeightOverride,
    setActiveAssessmentExerciseSetSavingResultBoolean,
    setActiveAssessmentCompleteSetInfo,
    setActiveAssessmentPhaseCompletionBoolean,
    toggleUIModeAssessment,
    resetActiveAssessmentState 
} = activeAssessmentSlice.actions;

export default activeAssessmentSlice.reducer;