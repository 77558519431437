import { useSelector } from 'react-redux'
import SpinnerWhite from 'media/icons/spinner-white.png'
import { GetPrimaryColorHex } from 'redux/slices/brand'

export default function LoadingButton({
    buttonText = 'Button Text',
    isLoading = false,
    spinnerColor = 'white',
    className = 'w-full flex justify-center items-center gap-x-4 rounded-full text-white font-semibold py-2',
    backgroundColor = null,
    action = () => {
        console.log('Add a button action')
    },
}) {

    const styleConfig = useSelector((state) => state.brand.styleConfig)

    return (
        <button 
            className={className} 
            style={{ backgroundColor: backgroundColor !== null ? backgroundColor : GetPrimaryColorHex(styleConfig) }}
            onClick={action} 
            disabled={isLoading}
        >
            {!isLoading && (
                <>
                    <div>{buttonText}</div>
                </>
            )}

            {isLoading && (
                <>
                    <div>Loading</div>

                    {spinnerColor === 'white' && <img src={SpinnerWhite} alt="Spinner" className="animate-spin h-4 w-4" />}
                </>
            )}
        </button>
    )
}
