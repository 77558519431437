export default function TextInputLine({ placeholder = 'Enter Here', value = '', handleChange = () => {}, isValid = true, invalidMessage = 'Invalid', isDisabled = false }) {
    return (
        <div className="space-y-1 flex-1">

            <input
                type="text"
                className="w-full px-1 py-2 text-gray-900 placeholder:text-gray-400 border-b border-gray-900 rounded-none focus:outline-none"
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                disabled={isDisabled}
            />

            {/* Error Message */}
            {isValid ? null : <p className="text-xs text-red-600">{invalidMessage}</p>}

        </div>
    )
}
