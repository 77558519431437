import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import CustomHeader from 'components/Header/CustomHeader'
import PaymentMethod from 'components/Billing/PaymentMethod'

export default function UpdatePaymentMethod() {
    const navigate = useNavigate()

    // Load publishable key from env variables
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

    const email = useSelector((state) => state.user.email)

    const [buttonLoading, setButtonLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const PostCreationAction = async (paymentMethod) => {
        const jsonBody = {
            email: email,
            stripePaymentMethodId: paymentMethod.id,
        }

        // console.log(jsonBody);

        const attachBillingResult = await WhichWeightAPI('BILLING', 'POST', '/attach_payment_method_to_customer', jsonBody)

        // console.log(attachBillingResult);

        if (attachBillingResult.data.status !== 'success') {
            setButtonLoading(false)
            console.error(attachBillingResult)
            setErrorMessage('Could not attach billing. Please try again. If issue persists please contact support')
            return
        }

        navigate('/profile/membership')
    }

    return (
        <>
            <CustomHeader text="Update Payment Method" backButtonAction={() => navigate('/profile/membership')} />

            {/* Stripe Card Element */}
            <Elements stripe={stripePromise}>
                <PaymentMethod
                    subTitleText="Please enter your new payment method below"
                    buttonText="Update Payment Method"
                    PostCreationAction={PostCreationAction}
                    buttonLoading={buttonLoading}
                    setButtonLoading={setButtonLoading}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                />
            </Elements>
        </>
    )
}
