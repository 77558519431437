import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { BuildingStorefrontIcon, UserPlusIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { IsUserPartOfAnOrganization } from 'functions/userUtils'
import { resetUserState } from 'redux/slices/user'
import { resetSubscriptionState } from 'redux/slices/subscription'
import { clearWhichWeightCache } from 'redux/slices/whichWeightCache'

import ScreenFrame from 'components/Frame/ScreenFrame'
import CustomHeader from 'components/Header/CustomHeader'
import BodyFrame from 'components/Frame/BodyFrame'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import SubscriptionDetails from 'components/Billing/SubscriptionDetails'

export default function Membership() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const email = useSelector((state) => state.user.email)
    const onboarded = useSelector((state) => state.user.onboarded)
    const organizationId = useSelector((state) => state.user.organizationId)
    const organizationName = useSelector((state) => state.user.organizationName)
    const organizationLogo = useSelector((state) => state.user.organizationLogo)
    const organizationHandlesMembership = useSelector((state) => state.user.organizationHandlesMembership)

    const [subscriptionInfo, setSubscriptionInfo] = useState(null)
    const [triggerRefresh, setTriggerRefresh] = useState(false)

    const subscriptionFeatures = ['Access to your custom program', 'Instructions of every exercise', 'Smart weight recommendations', 'Data storage and progress tracking']

    const IsMembershipActive = () => {

        if (parseInt(organizationHandlesMembership) === 1) return true

        if (parseInt(organizationHandlesMembership) === 0) {
            // console.log(subscriptionInfo)

            // If no stripe subscription exists for this user yet, take them straight to the pricing page
            if (subscriptionInfo.stripeSubscriptionIdPresent === false) return false

            // If stripe subscription exists for this user
            if (subscriptionInfo.stripeSubscriptionIdPresent === true) {
                
                // If status is canceled, that means current subscription has fully expired. Take them to pricing page to create new subscription
                if (subscriptionInfo.subscriptionData.status === 'canceled') return false

                // If status is incomplete, they never finished paying for a subscription. Take them to the pricing page to create a new subscription
                else if (subscriptionInfo.subscriptionData.status === 'incomplete') return false

                // If status is incomplete_expired, they never finished paying for a subscription. Take them to the pricing page to create a new subscription
                else if (subscriptionInfo.subscriptionData.status === 'incomplete_expired') return false

                return true

            }

        }

    }

    const GetSubscriptionInfoFromBackend = async () => {
        setSubscriptionInfo(null)

        const jsonBody = {
            email: email,
        }

        const result = await WhichWeightAPI('BILLING', 'GET', '/subscription_details', jsonBody)

        // console.log(result.data.data);

        setSubscriptionInfo(result.data.data)
    }

    const OnBackButton = () => {

        if (!onboarded) {
            dispatch(resetUserState())
            dispatch(resetSubscriptionState())
            dispatch(clearWhichWeightCache())
        }

        navigate('/home')

    }

    useEffect(() => { GetSubscriptionInfoFromBackend() }, [triggerRefresh])

    return (
        <ScreenFrame>
            <CustomHeader text="Membership" backButtonAction={() => OnBackButton()} />

            <BodyFrame>

                {subscriptionInfo === null && <MediumLoadingComponent />}

                {subscriptionInfo !== null && (
                    <>

                        {/* Inactive Membership Info */}
                        {!IsMembershipActive() && 
                            <>
                                
                                {/* Title text */}
                                <div className="text-3xl font-bold text-center">
                                    <div className="text-orange-600">Become A</div>
                                    <div className="tracking-tight text-transparent bg-clip-text bg-gradient-to-r from-orange-600 to-orange-400">WhichWeight Member</div>
                                </div>

                                {/* Subscription Benefits */}
                                <ul className="p-4 rounded-lg border border-gray-400 shadow-md space-y-2">
                                    {subscriptionFeatures.map((feaure, index) => (
                                        <li key={index} className="flex gap-x-4 items-center">
                                            <CheckCircleIcon className="h-4 w-4" />
                                            {feaure}
                                        </li>
                                    ))}
                                </ul>

                                <div className='pt-4 space-y-4'>
                                    <div className='text-center text-sm text-gray-600'>How would you like to setup your membership?</div>
                                    <div className='w-full border border-gray-400 overflow-hidden rounded-xl'>

                                        {/* Create Organization Option */}
                                        {!IsUserPartOfAnOrganization(organizationId, organizationName, organizationLogo) && 
                                            <div 
                                                onClick={() => navigate('/profile/membership/organization/join')}
                                                className='flex items-center px-4 py-6 gap-x-4 text-gray-600 border-b border-gray-400'
                                            >

                                                <BuildingStorefrontIcon className='text-gray-500 w-8' />

                                                <div className='flex-grow'>
                                                    <div className='font-semibold'>Join an Organization</div>
                                                    <div className='text-xs'>I have a code to join an organization</div>
                                                </div>

                                                <ChevronRightIcon className='w-6' />

                                            </div>
                                        }

                                        {/* Individual option */}
                                        <div 
                                            onClick={() => navigate('/subscription/options')}
                                            className='flex items-center px-4 py-6 gap-x-4 text-gray-600 border-gray-600'
                                        >

                                            <UserPlusIcon className='text-gray-500 w-8' />

                                            <div className='flex-grow'>
                                                <div className='font-semibold'>Individual Membership</div>
                                                {!IsUserPartOfAnOrganization(organizationId, organizationName, organizationLogo) && <div className='text-xs'>I am using WhichWeight myself</div>}
                                                {IsUserPartOfAnOrganization(organizationId, organizationName, organizationLogo) && <div className='text-xs'>{organizationName} has individuals manage subscription</div>}
                                            </div>

                                            <ChevronRightIcon className='w-6' />

                                        </div>

                                    </div>
                                </div>

                            </>
                        }

                        {/* Active Membership Info */}
                        {IsMembershipActive() && 
                            <>
                                
                                {/* Logo */}
                                <div className="mx-auto w-40 h-40 border border-gray-300 rounded-3xl">
                                    <img src={styleConfig.logo} alt="WhichWeight Logo" className="mx-auto mt-8 h-24" />
                                </div>
                                {/* Organizational Membership Info */}
                                {parseInt(organizationHandlesMembership) === 1 && (
                                    <div className="text-lg">
                                        <div className="flex border-b py-3">
                                            <div className="pl-2 flex-grow text-gray-600">Subscription</div>
                                            <div className="flex gap-x-1 items-center text-green-500">
                                                <CheckCircleIcon className="h-5" />
                                                <div className="font-bold">Active</div>
                                            </div>
                                        </div>

                                        <div className="flex border-b py-3">
                                            <div className="pl-2 flex-grow text-gray-600">Organization</div>
                                            <div className="flex gap-x-1 items-center">{organizationName}</div>
                                        </div>

                                        <div className="text-sm text-center pt-4 text-gray-500 italic">Your subscription is managed by your organization.</div>

                                        <div className="text-sm text-center pt-4 text-gray-500 italic">Please reach out to the leader of your organization for instructions on how to cancel your membership</div>
                                    </div>
                                )}

                                {/* Individual Membership Info */}
                                {parseInt(organizationHandlesMembership) === 0 && (
                                    <SubscriptionDetails
                                        subscriptionData={subscriptionInfo.subscriptionData}
                                        defaultPaymentMethod={subscriptionInfo.defaultPaymentMethod}
                                        GetSubscriptionInfoFromBackend={GetSubscriptionInfoFromBackend}
                                        triggerRefresh={triggerRefresh}
                                        setTriggerRefresh={setTriggerRefresh}
                                    />
                                )}

                            </>
                        }

                    </>
                )}

            </BodyFrame>

        </ScreenFrame>
    )
}
