import { WhichWeightAPI } from "WhichWeightAPI"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { GetWeekDayWorkoutIcon } from 'functions/weeklyCalendarUtils'
import { resetOnboardingState } from "redux/slices/onboarding"
import moment from "moment"

import ScreenFrame from "components/Frame/ScreenFrame"
import StepHeader from "components/Header/StepHeader"
import BodyFrame from "components/Frame/BodyFrame"
import LoadingButton from 'components/LoadingButton'

export default function FitnessProfileReview () {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userId = useSelector((state) => state.user.id)

    const sportId = useSelector((state) => state.onboarding.sportId)
    const sportName = useSelector((state) => state.onboarding.sportName)
    const daysOfTraining = useSelector((state) => state.onboarding.daysOfTraining)
    const monthsOfTraining = useSelector((state) => state.onboarding.monthsOfTraining)
    const yearsOfTraining = useSelector((state) => state.onboarding.yearsOfTraining)
    const weekDaySchedule = useSelector((state) => state.onboarding.weekDaySchedule)
    
    const [formErrorMessage, setFormErrorMessage] = useState("")
    const [isFormLoading, setIsFormLoading] = useState(false)

    const OnNextButton = async () => {

        setFormErrorMessage("")
        setIsFormLoading(true)

        const jsonBody = {
            userId: userId,
            sportId: sportId,
            sportName: sportName,
            daysOfTraining: daysOfTraining,
            monthsOfTraining: monthsOfTraining,
            yearsOfTraining: yearsOfTraining,
            weekDaySchedule: weekDaySchedule
        }

        // console.log(jsonBody)

        const result = await WhichWeightAPI('CORE', 'POST', '/athlete_onboard_fitness_profile', jsonBody)

        setIsFormLoading(false)

        if (result.data.status === 'failure'){
            setFormErrorMessage("Error saving fitness profile. Please try again")
            return
        }

        dispatch(resetOnboardingState())
        
        navigate('/onboarding/assessmentPreview')

    }

    return(
        <ScreenFrame>

            <StepHeader title='Fitness Profile' startPercentage={80} endPercentage={100} />

            <BodyFrame>

                <div className="text-center text-xl font-semibold">Fitness Profile Complete!</div>

                <div className="space-y-1">
                    <div className="bg-gray-100 p-4 space-y-2">

                        {/* Sport */}
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Sport</div>
                            <div>
                                {sportName}
                            </div>
                        </div>

                        {/* Days Of Training */}
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Avg. Training Days Per Week</div>
                            <div>
                                {daysOfTraining} day{daysOfTraining !== 1 ? 's' : ''}
                            </div>
                        </div>

                        {/* Months Of Training */}
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Months Trained (Past Year)</div>
                            <div>
                                {monthsOfTraining} month{monthsOfTraining !== 1 ? 's' : ''}
                            </div>
                        </div>

                        {/* Years Of Training */}
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Years of Strength Training</div>
                            <div>
                                {yearsOfTraining} year{yearsOfTraining !== 1 ? 's' : ''}
                            </div>
                        </div>

                    </div>

                    {/* Week Day Schedule Preview */}
                    <div className="bg-gray-100 pt-2">
                        <div className="text-center text-xs pb-1 text-gray-500">Weekly Schedule</div>
                        <div className="grid grid-cols-7 text-center">
                            {Array.from({ length: 7 }, (_, i) => i).map((dayOfTheWeekIndex) => (
                                <div key={dayOfTheWeekIndex} className="pt-2 ">
                                    <div className="text-xs font-semibold text-gray-900">{moment.utc().local().startOf('isoWeek').add(dayOfTheWeekIndex, 'days').format('ddd')}</div>
                                    <div className={`py-2 ${dayOfTheWeekIndex === 0 ? 'rounded-l-lg' : dayOfTheWeekIndex === 6 ? 'rounded-r-lg' : ''}`}>
                                        <img src={GetWeekDayWorkoutIcon({ weekDaySchedule: weekDaySchedule }, dayOfTheWeekIndex)} className="mt-1 h-6 mx-auto text-gray-400 opacity-75" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="space-y-4 text-center text-sm pt-4">
                    <div>WhichWeight is designed to meet you at this starting place and take you to the next level</div>
                    <div>Based on the information you have told us, we have placed you in an accurate starting place.</div>
                    <div>WhichWeight will select the exercises most pertinent to you that we know are imperative for {sportName}.</div>
                    <div>This is the exciting starting point from which we will progress!</div>
                </div>

                <div className='mt-auto space-y-4'>
                    
                    {formErrorMessage !== "" && <p className="text-center text-xs text-red-600">{formErrorMessage}</p>}

                    <div 
                        className="text-center py-2 border border-gray-400 text-gray-600 rounded-full"
                        onClick={() => navigate('/onboarding/sport')}
                    >
                        Change Responses
                    </div>

                    <LoadingButton 
                        buttonText='Next'
                        className="flex items-center justify-center gap-x-2 w-full text-center text-white rounded-full py-2 px-4 text-lg font-semibold"
                        action={() => OnNextButton()}
                        isLoading={isFormLoading}
                    />

                </div>

            </BodyFrame>

        </ScreenFrame>
    )

}