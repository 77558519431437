import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GetPrimaryColorHex } from 'redux/slices/brand'
import whichWeightLogo from 'media/icons/logo192.png'

export default function FullscreenLoading({ 
    loadingText = 'Program Loading...', 
    showQuote = true 
}) {

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    
    const athleteQuotes = [
        { quote: "The only one who can tell you 'you can't win' is you and you don't have to listen.", author: "Jessica Ennis-Hill" },
        { quote: "I'd rather regret the risks that didn't work out than the chances I didn't take at all.", author: "Simone Biles" },
        { quote: "I just knew if it could be done, it had to be done, and I did it.", author: "Gertrude Ederle" },
        { quote: "Overpower. Overtake. Overcome.", author: "Serena Williams" },
        { quote: "The five S's of sports training are: stamina, speed, strength, skill, and spirit; but the greatest of these is spirit.", author: "Ken Doherty" },
        { quote: "An athlete cannot run with money in his pockets. He must run with hope in his heart and dreams in his head.", author: "Emil Zatopek" },
        { quote: "Somebody gives you an opportunity, say yes to it. So what if you fail? you won't know if you fail or succeed unless you try.", author: "Ann Meyers" },
        { quote: "The hardest skill to acquire in this sport is the one where you compete all out, give it all you have, and you are still getting beat no matter what you do. When you have the killer instinct to fight through that, it is very special.", author: "Eddie Reese" },
        { quote: "Make sure your worst enemy doesn't live between your own two ears.", author: "Laird Hamilton" },
        { quote: "What makes something special is not just what you have to gain, but what you feel there is to lose.", author: "Andre Agassi" },
        { quote: "You win some, you lose some, and some get rained out, but you gotta suit up for them all.", author: "J. Askenberg" },
        { quote: "Always work hard, never give up, and fight until the end because it's never really over until the whistle blows.", author: "Alex Morgan" },
        { quote: "I believe in the impossible because no one else does.", author: "Florence Griffith Joyner" },
        { quote: "Many times, I had to dig deep and perform. All of that adversity helped me and drove me to want to be the best.", author: "Hayley Wickenheiser" },
        { quote: "Age is no barrier. It's a limitation you put on your mind.", author: "Jackie Joyner-Kersee" },
        { quote: "No matter the circumstances that you may be going through, just push through it.", author: "Ray Lewis" },
        { quote: "You've got to be confident when you're competing. You've got to be a beast.", author: "Gabby Douglas" },
        { quote: "It's going to be a journey. It's not a sprint to get in shape.", author: "Kerri Walsh Jennings" },
        { quote: "Passion first and everything will fall into place.", author: "Holly Holm" },
        { quote: "You were born to be a player. You were meant to be here. This moment is yours.", author: "Herb Brooks" },
        { quote: "The past doesn't matter. Take today.", author: "Becky Sauerbrunn" },
        { quote: "You can motivate by fear, and you can motivate by reward. But both those methods are only temporary. The only lasting thing is self-motivation.", author: "Homer Rice" },
        { quote: "Once you give them the power to tell you you're great, you've also given them the power to tell you you're unworthy. Once you start caring about people's opinions of you, you give up control.", author: "Ronda Rousey" },
        { quote: "My motto was always to keep swinging. Whether I was in a slump or feeling badly or having trouble off the field, the only thing to do was keep swinging.", author: "Hank Aaron" },
        { quote: "The ability to conquer one's self is no doubt the most precious of all things that sports bestows.", author: "Olga Korbut" },
        { quote: "There is always going to be a reason why you can't do something; your job is to constantly look for the reasons why you can achieve your dreams.", author: "Shannon Miller" },
        { quote: "You control your own wins and losses.", author: "Maria Sharapova" },
        { quote: "I don't want to be the next Michael Jordan, I only want to be Kobe Bryant.", author: "Kobe Bryant" },
        { quote: "You must not only have competitiveness but ability, regardless of the circumstance you face, to never quit.", author: "Abby Wambach" },
        { quote: "Just believe in yourself. Even if you don't pretend that you do and, and some point, you will.", author: "Venus Williams" },
        { quote: "I always felt that my greatest asset was not my physical ability, it was my mental ability.", author: "Bruce Jenner" },
        { quote: "I've missed more than 9,000 shots in my career. I've lost almost 300 games. Twenty-six times I've been trusted to take the game-winning shot and missed. I've failed over and over and over again in my life. And that is why I succeed.", author: "Michael Jordan" },
        { quote: "Don't be afraid of failure. This is the way to succeed.", author: "LeBron James" },
        { quote: "You dream. You plan. You reach. There will be obstacles. There will be doubters. There will be mistakes. But with hard work, with belief, with confidence and trust in yourself and those around you, there are no limits.", author: "Michael Phelps" },
        { quote: "If something stands between you and your success, move it. Never be denied.", author: "Dwayne \"The Rock\" Johnson" },
        { quote: "You're playing a game, whether it's Little League or Game 7 of the World Series. It's impossible to do well unless you're having a good time. People talk about pressure. Yeah, there's pressure. But I just look at it as fun.", author: "Derek Jeter" },
        { quote: "Winners never quit and quitters never win.", author: "Vince Lombardi" },
        { quote: "Believe me, the reward is not so great without the struggle.", author: "Wilma Rudolph" },
        { quote: "Sports serve society by providing vivid examples of excellence.", author: "George F. Will" },
        { quote: "You are never really playing an opponent. You are playing yourself, your own highest standards, and when you reach your limits, that is real joy.", author: "Arthur Ashe" },
        { quote: "Life begins at the end of your comfort zone.", author: "Neale Donald Walsh" },
        { quote: "If you can't outplay them, outwork them.", author: "Ben Hogan" },
        { quote: "It is action that creates motivation.", author: "Steve Backley" },
        { quote: "Leadership, like coaching, is fighting for the hearts and souls of men and getting them to believe in you.", author: "Eddie Robinson" },
        { quote: "In a growth mindset, challenges are exciting rather than threatening. So rather than thinking, oh, I'm going to reveal my weaknesses, you say, wow, here's a chance to grow.", author: "Carol Dweck" },
        { quote: "If you want to be happy, set a goal that commands your thoughts, liberates your energy, and inspires your hopes.", author: "Andrew Carnegie" },
        { quote: "I've learned that something constructive comes from every defeat.", author: "Tom Landry" },
        { quote: "The difference between the impossible and the possible lies in a person's determination.", author: "Tommy Lasorda" },
        { quote: "Being nervous is not something you should be ashamed of. Nervous means you care, you really want to do well.", author: "Paula Creamer" },
        { quote: "Persistence can change failure into extraordinary achievement.", author: "Matt Biondi" },
        { quote: "I hated every minute of training, but I said, don't quit, suffer now and live the rest of your life as a champion.", author: "Muhammad Ali" },
        { quote: "They say discipline and dedication and respect are the key factors, but patience is a virtue that is absolutely essential!", author: "Ria Ramnarine" },
        { quote: "You should never stay at the same level. Always push yourself to the next.", author: "Marnelli Dimzon" },
        { quote: "It's not the will to win that matters—everyone has that. It's the will to prepare to win that matters.", author: "Paul \"Bear\" Bryant" },
        { quote: "If you fail to prepare, you're prepared to fail.", author: "Mark Spitz" },
        { quote: "The principle is competing against yourself. It's about self-improvement, about being better than you were the day before.", author: "Steve Young" },
        { quote: "I never left the field saying I could have done more to get ready and that gives me peace of mind.", author: "Peyton Manning" },
        { quote: "When you fall, get right back up. Just keep going, keep pushing it.", author: "Lindsey Vonn" },
        { quote: "If you think you're done, you always have at least 40 percent more.", author: "Lauren Crandall" },
        { quote: "I always try to start out with some type of goal. Then I work backward and think of what I need to do to get there and give myself smaller goals that are more immediate.", author: "Kristi Yamaguchi" },
        { quote: "You have to remember that the hard days are what make you stronger. The bad days make you realize what a good day is. If you never had any bad days, you would never have that sense of accomplishment!", author: "Aly Raisman" },
        { quote: "It is not the mountain we conquer but ourselves.", author: "Sir Edmund Hillary" },
        { quote: "Sport has the power to change the world.", author: "Nelson Mandela" },
        { quote: "You are your only limit.", author: "Unknown" },
        { quote: "The best time to plant a tree was 20 years ago. The second-best time is now.", author: "Chinese Proverb" },
        { quote: "The body achieves what the mind believes.", author: "Napoleon Hill" },
        { quote: "Champions keep playing until they get it right.", author: "Billie Jean King" },
        { quote: "Sports do not build character. They reveal it.", author: "Heywood Broun" },
        { quote: "To be the best, you must be able to handle the worst.", author: "Wilson Kanadi" },
        { quote: "The only way to discover the limits of the possible is to go beyond them into the impossible.", author: "Arthur C. Clarke" },
        { quote: "What you get by achieving your goals is not as important as what you become by achieving your goals.", author: "Zig Ziglar" },
        { quote: "Gold medals aren't really made of gold. They're made of sweat, determination, and a hard-to-find alloy called guts.", author: "Dan Gable" },
        { quote: "The more difficult the victory, the greater the happiness in winning.", author: "Pelé" },
        { quote: "It's not about having time. It's about making time.", author: "Unknown" },
        { quote: "What makes you different or weird, that's your strength.", author: "Meryl Streep" },
        { quote: "Your love needs to be bigger than your fear of failure.", author: "Billie Jean King" },
        { quote: "If you can imagine it, you can achieve it. If you can dream it, you can become it.", author: "William Arthur Ward" },
        { quote: "Success is not final, failure is not fatal: It is the courage to continue that counts.", author: "Winston S. Churchill" },
        { quote: "It's not the triumph but the struggle.", author: "Pierre de Coubertin" },
        { quote: "Never limit your challenges, challenge your limits.", author: "Jerry Dunn" },
        { quote: "It's not about being better than someone else, it's about being better than you were the day before.", author: "Unknown" },
        { quote: "The best way to predict your future is to create it.", author: "Abraham Lincoln" },
        { quote: "You don't drown by falling in the water; you drown by staying there.", author: "Edwin Louis Cole" },
        { quote: "Life is like riding a bicycle. To keep your balance, you must keep moving.", author: "Albert Einstein" },
        { quote: "The greatest pleasure in life is doing what people say you cannot do.", author: "Walter Bagehot" },
        { quote: "Failure is only the opportunity to begin again, this time more intelligently.", author: "Henry Ford" },
        { quote: "You can't control the wind, but you can adjust the sails.", author: "Unknown" },
        { quote: "It always seems impossible until it's done.", author: "Nelson Mandela" },
        { quote: "The best project you'll ever work on is you.", author: "Unknown" },
        { quote: "Victory is in having done your best. If you've done your best, you've won.", author: "Billy Bowerman" },
        { quote: "A trophy carries dust. Memories last forever.", author: "Mary Lou Retton" },
        { quote: "Do not wait; the time will never be 'just right.' Start where you stand, and work with whatever tools you may have at your command, and better tools will be found as you go along.", author: "George Herbert" },
        { quote: "One man practicing sportsmanship is far better than 50 preaching it.", author: "Knute Rockne" },
        { quote: "The most important attribute a player must have is mental toughness.", author: "Mia Hamm" },
        { quote: "Perfection is not attainable, but if we chase perfection we can catch excellence.", author: "Vince Lombardi" },
        { quote: "Fall seven times, stand up eight.", author: "Japanese Proverb" },
        { quote: "Don't measure yourself by what you have accomplished, but by what you should have accomplished with your ability.", author: "John Wooden" },
        { quote: "I am not a product of my circumstances. I am a product of my decisions.", author: "Stephen Covey" },
        { quote: "I don't count my situps. I only start counting when it starts hurting because those are the only ones that count.", author: "Muhammad Ali" },
        { quote: "Talent wins games, but teamwork and intelligence wins championships.", author: "Michael Jordan" },
        { quote: "Do not let the fear of losing be greater than the excitement of winning.", author: "Robert Kiyosaki" },
        { quote: "A champion is afraid of losing. Everyone else is afraid of winning.", author: "Billie Jean King" },
        { quote: "Only those who dare to fail greatly can ever achieve greatly.", author: "Robert F. Kennedy" },
        { quote: "The road to success is always under construction.", author: "Lily Tomlin" },
        { quote: "Sweat is the cologne of accomplishment.", author: "Heywood Hale Broun" },
        { quote: "The only limit to our realization of tomorrow will be our doubts of today.", author: "Franklin D. Roosevelt" },
        { quote: "If you're going through hell, keep going.", author: "Winston Churchill" },
        { quote: "Only he who can see the invisible can do the impossible.", author: "Frank L. Gaines" },
        { quote: "The harder the battle, the sweeter the victory.", author: "Les Brown" },
        { quote: "Obstacles don't have to stop you. If you run into a wall, don't turn around and give up. Figure out how to climb it, go through it, or work around it.", author: "Michael Jordan" },
        { quote: "A champion is someone who gets up when they can't.", author: "Jack Dempsey" },
        { quote: "Success is where preparation and opportunity meet.", author: "Bobby Unser" },
        { quote: "Courage doesn't mean you don't get afraid. Courage means you don't let fear stop you.", author: "Bethany Hamilton" },
        { quote: "The will to win is important, but the will to prepare is vital.", author: "Joe Paterno" },
        { quote: "There may be people that have more talent than you, but there's no excuse for anyone to work harder than you do.", author: "Derek Jeter" },
        { quote: "Discipline is the bridge between goals and accomplishment.", author: "Jim Rohn" },
        { quote: "Don't let what you cannot do interfere with what you can do.", author: "Bill Bowerman" },
        { quote: "If you dream and you allow yourself to dream you can do anything.", author: "Clara Hughes" },
        { quote: "Never say never because limits, like fears, are often just an illusion.", author: "Michael Jordan" },
        { quote: "Continuous effort — not strength or intelligence — is the key to unlocking our potential.", author: "Liane Cardes" },
        { quote: "It's not the size of the dog in the fight, but the size of the fight in the dog.", author: "Archie Griffin" },
        { quote: "The most rewarding things you do in life are often the ones that look like they cannot be done.", author: "Arnold Palmer" },
        { quote: "Perseverance can transform failure into extraordinary achievement.", author: "Marv Levy" },
        { quote: "What you lack in talent can be made up with desire, hustle, and giving 110% all the time.", author: "Don Zimmer" },
        { quote: "Your biggest opponent isn't the other guy. It's human nature.", author: "Bobby Knight" },
        { quote: "Winning means you're willing to go longer, work harder, and give more than anyone else.", author: "Vince Lombardi" },
        { quote: "Excellence is not a singular act but a habit. You are what you repeatedly do.", author: "Shaquille O'Neal" },
        { quote: "You can't let success diminish the desire to be a winner. There's room for improvement in every sportsman.", author: "Lionel Messi" },
        { quote: "To uncover your true potential you must first find your own limits and then you have to have the courage to blow past them.", author: "Picabo Street" },
        { quote: "Nobody's a natural. You work hard to get good and then work to get better. It's hard to stay on top.", author: "Paul Coffey" },
        { quote: "Hard work beats talent when talent doesn't work hard.", author: "Tim Notke" },
        { quote: "The only limit to your impact is your imagination and commitment.", author: "Tony Robbins" },
        { quote: "Pain is temporary. It may last a minute, or an hour, or a day, or a year, but eventually it will subside and something else will take its place. If I quit, however, it lasts forever.", author: "Lance Armstrong" },
        { quote: "To give any less than your best is to sacrifice a gift.", author: "Steve Prefontaine" },
        { quote: "You can't put a limit on anything. The more you dream, the farther you get.", author: "Michael Phelps" },
        { quote: "Strength does not come from winning. Your struggles develop your strengths.", author: "Arnold Schwarzenegger" },
        { quote: "I am building a fire, and everyday I train, I add more fuel. At just the right moment, I light the match.", author: "Mia Hamm" },
        { quote: "The road to Easy Street goes through the sewer.", author: "John Madden" },
        { quote: "Success isn't owned, it's leased. And rent is due every day.", author: "J.J. Watt" },
        { quote: "The mind is the limit. As long as the mind can envision the fact that you can do something, you can do it.", author: "Arnold Schwarzenegger" },
        { quote: "Don't count the days, make the days count.", author: "Muhammad Ali" },
        { quote: "Make each day your masterpiece.", author: "John Wooden" },
        { quote: "Nobody who ever gave his best regretted it.", author: "George Halas" },
        { quote: "Today I will do what others won't, so tomorrow I can accomplish what others can't.", author: "Jerry Rice" },
        { quote: "It does not matter how slowly you go as long as you do not stop.", author: "Confucius" },
        { quote: "If you aren't going all the way, why go at all?", author: "Joe Namath" },
        { quote: "You have to expect things of yourself before you can do them.", author: "Michael Jordan" },
        { quote: "The highest compliment that you can pay me is to say that I work hard every day.", author: "Wayne Gretzky" }
    ];

    const [randomQuote, setRandomQuote] = useState({});

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * athleteQuotes.length);
        setRandomQuote(athleteQuotes[randomIndex]);
    }, []);

    return (
        <div 
            className="fixed flex flex-col h-full w-full overflow-auto"
            style={{
                background: `linear-gradient(to bottom, ${GetPrimaryColorHex(styleConfig)}, ${GetPrimaryColorHex(styleConfig, 2)})`
            }}
        >

            {styleConfig.brandKey === "which-weight" && 
                <>
                    {/* Spinner */}
                    <div 
                        className='animate-pulse bg-white shadow-lg p-4 rounded-xl absolute left-1/2 top-[40%] transform -translate-x-1/2 -translate-y-1/2'
                    >
                        <img 
                            src={styleConfig.logo} 
                            alt="WhichWeight Logo" 
                            className="w-12" 
                        />
                    </div>

                    {/* Loading Text */}
                    <div 
                        className="w-full absolute left-1/2 top-[50%] transform -translate-x-1/2 -translate-y-1/2 px-4 text-center text-white font-bold italic text-2xl opacity-50"
                    >
                        {loadingText}
                    </div>

                    {/* Quote */}
                    {showQuote && (
                        <div className='mt-auto text-center px-8 pb-20 text-orange-300 font-semibold text-sm space-y-4'>
                            <p>"{randomQuote.quote}"</p>
                            <p className="mt-2">- {randomQuote.author}</p>
                        </div>
                    )}

                </>
            }

            {styleConfig.brandKey === "blue-collar" && 
                <>
                    {/* Spinner */}
                    <div className='animate-pulse bg-gray-200 shadow-lg p-2 rounded-xl absolute left-1/2 top-[40%] transform -translate-x-1/2 -translate-y-1/2'>
                        <img 
                            src={styleConfig.logo} 
                            alt="WhichWeight Logo" 
                            className="w-16" 
                        />
                    </div>

                    {/* Loading Text */}
                    <div 
                        className="w-full absolute left-1/2 top-[50%] transform -translate-x-1/2 -translate-y-1/2 px-4 text-center text-white font-bold italic text-2xl opacity-50"
                    >
                        {loadingText}
                    </div>

                    <div className="text-center text-lg pb-2 font-extrabold px-4 mt-10 opacity-50">
                        <span className="text-white italic text-transparent bg-clip-text">
                            Work Wins, Let's Work
                        </span>
                    </div>

                    <div className="mt-auto flex items-center justify-center text-center pb-6 gap-x-2">
                        <img 
                            src={whichWeightLogo} 
                            alt="WhichWeight Logo" 
                            className="w-6 opacity-75" 
                        />
                        <div className='text-white text-xs font-bold opacity-50'>Powered by WhichWeight</div>
                    </div>
                    
                </>
            }

        </div>
    )
}