export default function MiniLinearDial({ fillPercentage = 50, label = 'Label' }) {
    const GetBarColor = () => {
        if (fillPercentage <= 40)
            return 'bg-red-400' // "bg-orange-200"
        else if (40 < fillPercentage && fillPercentage <= 66)
            return 'bg-yellow-400' // "bg-orange-300"
        else if (66 < fillPercentage) return 'bg-green-400' // "bg-orange-400"
    }

    return (
        <div className="w-full bg-gray-300 w-full h-6 rounded-full overflow-hidden">
            {/* Fill Amount */}
            <div className={`h-6 w-1/3 ${GetBarColor()}`} style={{ width: fillPercentage + '%' }} />

            {/* Label */}
            <div className="text-white text-xs text-center -my-5 font-bold" style={{ textShadow: '1px 1px 0 #666, -1px -1px 0 #666, 1px -1px 0 #666, -1px 1px 0 #666, 1px 1px 0 #666' }}>
                {label}
            </div>
        </div>
    )
}
