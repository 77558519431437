import { useSelector } from 'react-redux'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'

export default function LogoHeader({ backButtonAction = null }) {

    const styleConfig = useSelector((state) => state.brand.styleConfig)

    return(
        <div className="flex items-center bg-gray-100 shadow-md z-20">
            {backButtonAction === null && 
                <div className="flex-1 py-4 pl-4">
                    <ChevronLeftIcon className="h-5 w-5 text-gray-100" />
                </div>
            }

            {backButtonAction !== null && 
                <div className="flex-1 py-4 pl-4" onClick={backButtonAction}>
                    <ChevronLeftIcon className="h-5 w-5" />
                </div>
            }

            <img src={styleConfig.logo} alt="WhichWeight" className="h-10 -my-2" />

            <div className="flex-1 pr-4" />
        </div> 
    )

}