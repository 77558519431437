import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GetPrimaryColorHex } from 'redux/slices/brand'

export default function StepHeader({ 
    backButtonAction = null,
    title = "", 
    startPercentage = 0,
    endPercentage = 0
}) {

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const [currentPercentage, setCurrentPercentage] = useState(startPercentage)

    useEffect(() => {
        
        const animationDuration = 500 // Reduced to 0.5 seconds
        const startTime = Date.now() - 1000 // Get now timestamp minus second

        const easeOutQuad = (t) => t * (2 - t) // Easing function

        const animateProgress = () => {
            const elapsedTime = Date.now() - startTime
            const progress = Math.min(elapsedTime / animationDuration, 1)
            const easedProgress = easeOutQuad(progress) // Apply easing
            const newPercentage = startPercentage + (endPercentage - startPercentage) * easedProgress

            setCurrentPercentage(newPercentage)

            if (progress < 1) {
                requestAnimationFrame(animateProgress)
            }
        }

        requestAnimationFrame(animateProgress)

    }, [startPercentage, endPercentage]) // Added dependencies

    return (
        <div className="bg-gray-100 shadow-md z-20">
            
            <div className="flex items-center">

                {backButtonAction === null && 
                    <div className="flex-1 py-4 pl-4">
                        <ChevronLeftIcon className="h-5 w-5 text-gray-100" />
                    </div>}

                {backButtonAction !== null && 
                    <div className="flex-1 py-4 pl-4" onClick={backButtonAction}>
                        <ChevronLeftIcon className="h-5 w-5" />
                    </div>
                }

                {/* Logo */}
                <img src={styleConfig.logo} alt="WhichWeight" className="h-10" />

                <div className="flex-1 pr-4" />
            </div>

            <div className="text-center italic font-semibold text-gray-600 -mt-2 mb-2">{title}</div>
            
            {/* Progress Bar */}
            <div className="flex items-center h-2 bg-gray-300">
                <div 
                    className="h-2 transition-all duration-1000 ease-in-out" 
                    style={{ 
                        backgroundColor: GetPrimaryColorHex(styleConfig),
                        width: `${currentPercentage}%` 
                    }} 
                />
            </div>
             
        </div>
    )
}
