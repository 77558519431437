import { useState, useContext } from 'react'
import { ModalContext } from '../ModalContext'

import LoadingButton from 'components/LoadingButton'

export default function TextInputModal() {
    const { modalData, HideModal } = useContext(ModalContext)

    const [inputText, setInputText] = useState('')

    const OnInputTextChanged = (event) => setInputText(event.target.value)

    return (
        <>
            {/* Title */}
            <div className="text-center text-lg font-semibold leading-6 text-gray-900">{modalData.titleText}</div>

            {/* Text */}
            <div className="space-y-2">
                {modalData.bodyText.map((text, index) => (
                    <p key={index} className="text-sm text-gray-600">
                        {text}
                    </p>
                ))}
            </div>

            <div className="w-full sm:max-w-xs">
                <label htmlFor="email" className="sr-only">
                    Email
                </label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="you@example.com"
                    value={inputText}
                    onChange={OnInputTextChanged}
                />
            </div>

            {/* Button */}
            <LoadingButton
                buttonText={modalData.buttonText}
                action={() => {
                    modalData.buttonAction(inputText)
                    HideModal()
                }}
            />
        </>
    )
}
