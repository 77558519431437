import axios from 'axios';

export const WhichWeightAPI = async (SERVICE, METHOD, PATH, PARAMETERS = {}, retry = false) => {

    try {

        // console.log("Calling " + PATH);

        var BEARER = await GetBearerTokenFromLocalStorage();

        // If bearer is null, grab a new token
        if (BEARER === null){

            // console.log("Bearer token is null!");

            const jsonBody = {
                "client_id": process.env.REACT_APP_BEARER_CLIENT_ID,
                "client_secret": process.env.REACT_APP_BEARER_CLIENT_SECRET,
                "audience": process.env.REACT_APP_BEARER_AUDIENCE,
                "grant_type": process.env.REACT_APP_BEARER_GRANT_TYPE
            }

            const headerJson = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            const result = await axios.post(process.env.REACT_APP_BEARER_URL, jsonBody, headerJson);

            // console.log(result);

            await SetBearerTokenInLocalStorage(result.data.access_token);
            BEARER = result.data.access_token;

            // console.log("Got new bearer token! Storing encrypted version in local storage") // + BEARER);

            // Generate log on backend for token quota tracking
            axios.post(process.env.REACT_APP_CORE_URL + "/bearer_token_log", { }, { headers: { Authorization: `Bearer ${result.data.access_token}`, 'Content-Type': 'application/json' }, } );

        }

        // Get Base URL based on service
        var baseURL = "";
        switch(SERVICE)
        {   
            case "BILLING": baseURL = process.env.REACT_APP_BILLING_URL; break;
            case "CORE": baseURL = process.env.REACT_APP_CORE_URL; break;
            case "EMAIL": baseURL = process.env.REACT_APP_EMAIL_URL; break;
            case "PROGRAM": baseURL = process.env.REACT_APP_PROGRAM_URL; break;
            case "RECOMMENDATION": baseURL = process.env.REACT_APP_RECOMMENDATION_URL; break;
            case "REPORTING": baseURL = process.env.REACT_APP_REPORTING_URL; break;
            default: throw new Error(SERVICE + " is not a valid service for WhichWeightAPI! Defaulting to core");
        }

        // Use correct method to send query
        switch(METHOD)
        {

            case "GET":
                
                const get_result = await axios.get(
                    baseURL + PATH, 
                    { 
                        headers: { Authorization: `Bearer ${BEARER}` }, 
                        params : PARAMETERS 
                    } 
                );

                if (get_result.data.status == 'failure') console.error(get_result.data);

                return get_result;

            case "POST":

                const post_result = await axios.post(
                    baseURL + PATH,
                    PARAMETERS, 
                    { 
                        headers: { 
                            Authorization: `Bearer ${BEARER}`,
                            'Content-Type': 'application/json'
                        }, 
                    } 
                );

                if (post_result.data.status == 'failure') console.error(post_result.data);

                return post_result;

            case "PATCH":

                const patch_result = await axios.patch(
                    baseURL + PATH,
                    PARAMETERS, 
                    { 
                        headers: { 
                            Authorization: `Bearer ${BEARER}`,
                            'Content-Type': 'application/json'
                        }, 
                    } 
                );

                if (patch_result.data.status == 'failure') console.error(patch_result.data);

                return patch_result;
        }

    } catch (error) {
        
        console.error('There was an error fetching the data:', error.response);

        // If the API call failed because of missing or expired bearer token
        if ((error.response.status === 401 || error.response.data.message === 'Unauthorized') && retry === false){
            
            // Reset token
            // console.log("Token invalid. Resetting store and trying again");

            localStorage.setItem("BEARER", null);

            // Try again with true retry falg to prevent loops
            return await WhichWeightAPI(SERVICE, METHOD, PATH, PARAMETERS, true);

        }

        // Otherwise return a failure message
        return "failure"

    }

}

const GetBearerTokenFromLocalStorage = async () => {

    try {

        // Get encrypted token from local storage
        var encryptedBearerToken = localStorage.getItem("BEARER");

        // console.log("Grabbing encrypyed bearer token from local storage:\n" + encryptedBearerToken);

        // If there is no token there, return null
        if (encryptedBearerToken === null || encryptedBearerToken === "null") return null;

        // console.log("Encrypted token is not null. Decrypting..")

        // Decrypt token
        var decryptedBearerToken = await decryptString(encryptedBearerToken, process.env.REACT_APP_BEARER_ENCRYPTION_KEY);

        // console.log("Decrypted token:\n" + decryptedBearerToken);

        // Return it
        return decryptedBearerToken;

    }
    catch (error){
        
        console.error('There was an error fetching local store bearer token:', error)
        return null;

    }

}

const SetBearerTokenInLocalStorage = async (BEARER) => {

    // Encrypt token
    var encryptedBearerToken = await encryptString(BEARER, process.env.REACT_APP_BEARER_ENCRYPTION_KEY);

    // Set it in store
    localStorage.setItem("BEARER", encryptedBearerToken);

}

// Encrypt function
const encryptString = async (text, key) => {

    const encoder = new TextEncoder();
    const encodedText = encoder.encode(text);

    const cryptoKey = await window.crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode(key),
        'AES-GCM',
        true,
        ['encrypt']
    );

    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encryptedData = await window.crypto.subtle.encrypt(
        { name: 'AES-GCM', iv: iv },
        cryptoKey,
        encodedText
    );

    // Concatenate the IV and the encrypted data
    const encryptedArray = new Uint8Array(iv.byteLength + encryptedData.byteLength);
    encryptedArray.set(iv, 0);
    encryptedArray.set(new Uint8Array(encryptedData), iv.byteLength);

    // Return in string format for localStorage
    return JSON.stringify(Array.from(encryptedArray));

};

// Decrypt function
const decryptString = async (encryptedData, key) => {

    const cryptoKey = await window.crypto.subtle.importKey(
        'raw',
        new TextEncoder().encode(key),
        'AES-GCM',
        true,
        ['decrypt']
    );

    // Deserialize encrypted data
    const deserializedEncryptedData = new Uint8Array(JSON.parse(encryptedData));

    // Extract the IV and encrypted data from the concatenated array
    const iv = deserializedEncryptedData.slice(0, 12);
    const data = deserializedEncryptedData.slice(12);
    
    const ivUint8Array = new Uint8Array(iv);
    const dataUint8Array = new Uint8Array(data);

    // Decrypt the data using the key and IV
    const decryptedData = await window.crypto.subtle.decrypt(
        { name: 'AES-GCM', iv: ivUint8Array },
        cryptoKey,
        dataUint8Array
    );

    // Convert the decrypted ArrayBuffer to a string
    const decryptedText = new TextDecoder().decode(decryptedData);

    return decryptedText;
    
};