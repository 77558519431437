import { useContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import LoadingAnimation from 'media/loading.svg'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { ConvertPoundsToNearestFiveKilograms } from 'functions/measurementSystemUtils'
import { useGetExercises } from 'redux/customHooks/useGetExercises'
import { GetActiveWorkoutSliceKey } from 'functions/sliceUtils'
import { GetPrimaryColorHex } from 'redux/slices/brand'

import Note from 'components/ActiveWorkout/Note'
import EquipmentDetails from 'components/ActiveWorkout/EquipmentDetails'
import LoadingButton from 'components/LoadingButton'

export default function ExerciseBody({ 
    phase,
    phaseIndex, 
    exerciseIndex, 
    exercise, 
    setIndex, 
    set, 
    ShowEDSModalForCurrentSet, 
    ExpandedModeNavigateToNextSet 
}) {

    const { ShowModal } = useContext(ModalContext)
    const allDatabaseExercises = useGetExercises()

    const activeWorkout = useSelector((state) => state.activeWorkout)
    const activeAssessment = useSelector((state) => state.activeAssessment)
    var workoutTypeKey = GetActiveWorkoutSliceKey(activeWorkout, activeAssessment)

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const measurementSystem = useSelector((state) => state.user.fitnessProfile?.measurementSystem)

    const [videoThumbnailUrl, setVideoThumbnailUrl] = useState('')
    const [videoKey, setVideoKey] = useState(1)

    const OnShowMoreExerciseInfoModal = () => {
        const modalData = {
            key: ModalKey.ExerciseInstructions,
            fullscreen: true,
            exerciseId: exercise.id,
        }

        ShowModal(modalData)
    }

    const OnShowWeightRecommendationInfoModal = () => {
        const modalData = {
            key: ModalKey.WorkoutSetInformation,
            fullscreen: true,
            phaseIndex: phaseIndex,
            exerciseIndex: exerciseIndex,
            exercise: exercise,
            setIndex: setIndex,
            set: set,
        }

        ShowModal(modalData)
    }

    // Updates video items every time we progress to a new exercise
    useEffect(() => {
        // Set thumbnail
        if (allDatabaseExercises.length > 0) {
            setVideoThumbnailUrl(allDatabaseExercises.find((item) => item.id === exercise.id).imageUrl)
        }

        // Update key which triggers rerender
        setVideoKey(videoKey + 1)
    }, [exercise])

    return (
        <>
            {/* Exercise Name */}
            <div className="flex text-white gap-x-2 text-center items-center justify-center px-4 text-3xl font-semibold">{exercise.name}</div>

            {/* Exercise Instructions */}
            <div className="p-4">
                
                {/* Video Instructions */}
                <video
                    key={videoKey} 
                    poster={videoThumbnailUrl} 
                    width="720" 
                    height="400" 
                    controls
                    playsInline 
                    loop
                    x-webkit-airplay="deny"
                    className="w-full h-[200px] object-contain rounded-t-2xl border-t-2 border-x-2 border-white bg-black" 
                    onError={(e) => {
                        const error = e?.nativeEvent?.error || e.target.error;
                        if (error?.name === 'AbortError') {
                            console.warn('Video loading was aborted. This is normal if the user navigated away.');
                        } else {
                            throw new Error(`Unhandled video playback error: ${error}`);
                        }
                    }}
                >
                    <source src={exercise.videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

                {/* More Info Button */}
                <div className="flex w-full h-8 text-black border-2 border-white rounded-b-2xl items-center justify-center bg-gray-300  shadow" onClick={() => OnShowMoreExerciseInfoModal()}>
                    More Info
                </div>

            </div>

            {/* Exercise Set Recommendation Section */}
            <div className="text-white text-center">
                <div className="flex p-4 -mt-4 gap-x-4">
                    {/* Set Unit Box */}
                    <div 
                        className="flex-1 rounded-xl h-32 py-2"
                        style={{
                            backgroundColor: GetPrimaryColorHex(styleConfig)
                        }}
                    >


                        {workoutTypeKey === 'active-assessment' && 
                            <>
                                {exercise.id === 267 &&
                                    <>
                                        <div className="text-xl">Reps</div>
                                        <div className="flex text-5xl font-semibold justify-center pt-2 pb-1">1</div>
                                        <div>on each side</div>
                                    </>
                                }

                                {exercise.id !== 267 &&
                                    <>
                                        {/* Reps Set Unit */}
                                        {exercise.setUnit === 'reps' && (
                                            <>
                                                <div className="text-xl">Reps</div>
                                                <div className="flex text-5xl font-semibold justify-center pt-2 pb-1">{set.reps}</div>
                                                {exercise.lateral === 'unilateral' && <div>on each side</div>}
                                            </>
                                        )}

                                        {/* Seconds Sets Unit */}
                                        {exercise.setUnit === 'seconds' && (
                                            <>
                                                <div className="text-xl">Seconds</div>
                                                <div className="flex text-5xl font-semibold justify-center pt-2 pb-1">{set.seconds}s</div>
                                                {exercise.lateral === 'unilateral' && <div>on each side</div>}
                                            </>
                                        )}
                                    </>
                                }
                            </>
                        }

                        {workoutTypeKey === 'active-workout' && 
                            <>

                                {/* Reps Set Unit */}
                                {exercise.setUnit === 'reps' && (
                                    <>
                                        <div className="text-xl">Reps</div>
                                        <div className="flex text-5xl font-semibold justify-center pt-2 pb-1">{set.reps}</div>
                                        {exercise.lateral === 'unilateral' && <div>on each side</div>}
                                    </>
                                )}

                                {/* Seconds Sets Unit */}
                                {exercise.setUnit === 'seconds' && (
                                    <>
                                        <div className="text-xl">Seconds</div>
                                        <div className="flex text-5xl font-semibold justify-center pt-2 pb-1">{set.seconds}s</div>
                                        {exercise.lateral === 'unilateral' && <div>on each side</div>}
                                    </>
                                )}

                            </>
                        }

                    </div>

                    {/* Recommendation Box */}
                    <div 
                        className="flex-1 rounded-xl h-32 py-2" 
                        style={{
                            backgroundColor: GetPrimaryColorHex(styleConfig)
                        }}
                        onClick={() => OnShowWeightRecommendationInfoModal()}
                    >
                        {/* Header with info Icon */}
                        <div className="flex items-center justify-center gap-x-2">
                            <div className="text-xl">Weight</div>
                            <InformationCircleIcon className="items-center h-4 text-white pointer-events-none" />
                        </div>

                        {/* If Recommendation is loading, show animation */}
                        {!set.recommendationData.weightRecommendationLoaded && (
                            <div className="flex justify-center mt-2">
                                <img src={LoadingAnimation} alt="Loading" className="h-16" />
                            </div>
                        )}

                        {/* If Recommendation is loaded (Imperial) */}
                        {set.recommendationData.weightRecommendationLoaded && measurementSystem === 'imperial' && (
                            <>
                                {/* Continuous Override */}
                                {exercise.continuousOverride && <div className="pt-4 text-4xl font-semibold">{exercise.continuousOverrideWeight} lbs</div>}

                                {/* Calculated Recommendation */}
                                {!exercise.continuousOverride && exercise.exerciseType === 'recommendation' && (
                                    <div className="pt-4 text-4xl font-semibold">{set.recommendationData.weightRecommendationRounded} lbs</div>
                                )}

                                {/* Bodyweight Recommendation */}
                                {!exercise.continuousOverride && exercise.exerciseType === 'bodyweight' && (
                                    <>
                                        <div className="pt-2 pb-1 text-5xl font-semibold">0 lbs</div>
                                        <div>Use body weight</div>
                                    </>
                                )}
                            </>
                        )}

                        {/* If Recommendation is loaded (Metric) */}
                        {set.recommendationData.weightRecommendationLoaded && measurementSystem === 'metric' && (
                            <>
                                {/* Continuous Override */}
                                {exercise.continuousOverride && <div className="pt-4 text-4xl font-semibold">{ConvertPoundsToNearestFiveKilograms(exercise.continuousOverrideWeight)} kgs</div>}

                                {/* Calculated Recommendation */}
                                {!exercise.continuousOverride && exercise.exerciseType === 'recommendation' && (
                                    <div className="pt-4 text-4xl font-semibold">{ConvertPoundsToNearestFiveKilograms(set.recommendationData.weightRecommendation)} kgs</div>
                                )}

                                {/* Bodyweight Recommendation */}
                                {!exercise.continuousOverride && exercise.exerciseType === 'bodyweight' && (
                                    <>
                                        <div className="pt-2 pb-1 text-5xl font-semibold">0 kgs</div>
                                        <div>Use body weight</div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>

                {/* Equipment Details (Imperial Only) */}
                {measurementSystem === 'imperial' && (
                    <EquipmentDetails equipmentDetails={set.recommendationData.equipmentDetails} roundedRecommendation={set.recommendationData.weightRecommendationRounded} style="expanded" />
                )}
            </div>

            {/* Exercise Note */}
            {exercise.note.length > 0 && (
                <div className="pb-4 px-4">
                    <Note text={exercise.note} />
                </div>
            )}

            {/* Footer Buttons */}
            <div className="mt-auto px-4 pb-8 space-y-4 w-full">

                {phase.completed && 
                    <button
                        type="button"
                        className="w-full text-center text-gray-600 rounded-full bg-white py-2 px-4 text-md font-semibold shadow-sm"
                        onClick={() => ExpandedModeNavigateToNextSet()}
                    >
                        Next Set
                    </button>
                }

                {!phase.completed && 
                    <>

                        {/* If set is not completed, show complete button */}
                        {!set.completedSetData.completed && (
                            <LoadingButton
                                buttonText="Complete Set"
                                isLoading={set.completedSetData.savingResult}
                                spinnerColor="white"
                                className="w-full flex justify-center items-center gap-x-4 bg-white rounded-full text-gray-600 font-semibold py-2"
                                backgroundColor={'#fafafa'}
                                action={() => ShowEDSModalForCurrentSet(phaseIndex, exerciseIndex, exercise, setIndex)}
                            />
                        )}

                        {/* If set is completed */}
                        {set.completedSetData.completed && (
                            <button
                                type="button"
                                className="w-full text-center text-gray-600 rounded-full bg-white py-2 px-4 text-md font-semibold shadow-sm"
                                onClick={() => ExpandedModeNavigateToNextSet()}
                            >
                                Next Set
                            </button>
                        )}

                    </>

                }
                
            </div>
        </>
    )
}
