import { useState } from 'react';

const useWakeLock = () => {

    const [wakeLock, setWakeLock] = useState(null);

    const requestWakeLock = async () => {
        if ('wakeLock' in navigator) {

            try {

                const lock = await navigator.wakeLock.request('screen');
                setWakeLock(lock);

                // lock.addEventListener('release', () => {
                //     console.log('Wake lock released');
                // });

                // console.log('Wake lock active');

            } catch (err) {
                console.error('Wake lock request failed:', err);
            }

        } else {
            console.warn('Wake Lock API is not supported in this browser.');
        }
    };

    const releaseWakeLock = () => {
        if (wakeLock) {
            wakeLock.release();
            setWakeLock(null);
            // console.log('Wake lock released');
        }
    };


    return { wakeLock, requestWakeLock, releaseWakeLock };
};

export default useWakeLock;
