import { useContext } from 'react'
import { ModalContext } from '../ModalContext'
import { XMarkIcon } from '@heroicons/react/24/outline'

export default function CloseButtonHeader() {
    const { HideModal } = useContext(ModalContext)

    return (
        <div className="flex justify-end">
            {/* Close Button */}
            <button className="rounded-md bg-white text-gray-400 hover:text-gray-500" onClick={() => HideModal()}>
                <span className="sr-only">Close</span>

                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
        </div>
    )
}
