import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'

export default function Status({ subscriptionData }) {
    switch (subscriptionData.status) {
        case 'active':
            return (
                <div className="flex border-b py-3">
                    <div className="pl-2 flex-grow text-gray-600">Subscription</div>

                    {!subscriptionData.cancel_at_period_end && (
                        <div className="flex gap-x-1 items-center text-green-500">
                            <CheckCircleIcon className="h-5" />
                            <div className="font-bold">Active</div>
                        </div>
                    )}

                    {subscriptionData.cancel_at_period_end && (
                        <div className="flex gap-x-1 items-center text-red-500">
                            <ExclamationCircleIcon className="h-5" />
                            <div className="font-bold">Inactive</div>
                        </div>
                    )}
                </div>
            )

        case 'trialing':
            return (
                <div className="flex border-b py-3">
                    <div className="pl-2 flex-grow text-gray-600">Subscription</div>
                    {!subscriptionData.cancel_at_period_end && (
                        <div className="flex gap-x-1 items-center text-green-500">
                            <CheckCircleIcon className="h-5" />
                            <div className="font-bold">Trialing</div>
                        </div>
                    )}

                    {subscriptionData.cancel_at_period_end && (
                        <div className="flex gap-x-1 items-center text-red-500">
                            <ExclamationCircleIcon className="h-5" />
                            <div className="font-bold">Inactive</div>
                        </div>
                    )}
                </div>
            )

        case 'past_due':
            return (
                <div className="border-b py-3">
                    <div className="flex">
                        <div className="pl-2 flex-grow text-gray-600">Subscription</div>
                        <div className="flex gap-x-1 items-center text-red-500">
                            <ExclamationCircleIcon className="h-5" />
                            <div className="font-bold">Past Due</div>
                        </div>
                    </div>
                    <div className="pt-1 text-xs text-red-500 italic text-center">Please retry payment or update your payment method below</div>
                </div>
            )

        case 'unpaid':
            return (
                <div className="flex border-b py-3">
                    <div className="pl-2 flex-grow text-gray-600">Subscription</div>
                    <div className="flex gap-x-1 items-center text-red-500">
                        <ExclamationCircleIcon className="h-5" />
                        <div className="font-bold">Unpaid</div>
                    </div>
                </div>
            )

        default:
            return (
                <div className="flex border-b py-3">
                    <div className="pl-2 flex-grow text-gray-600">Subscription</div>
                    <div className="flex gap-x-1 items-center text-gray-600">{subscriptionData.status}</div>
                </div>
            )
    }
}
