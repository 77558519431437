import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setActiveWorkoutExerciseSetWeightOverride } from 'redux/slices/activeWorkout'
import { ConvertPoundsToNearestFiveKilograms, ConvertKilogramsToPounds } from 'functions/measurementSystemUtils'

export default function WeightOverride({ phaseIndex, exerciseIndex, setIndex }) {
    const dispatch = useDispatch()
    const measurementSystem = useSelector((state) => state.user.measurementSystem)

    const currentSet =  useSelector((state) => state.activeWorkout.workoutTemplate.phases[phaseIndex].exercises[exerciseIndex].sets[setIndex])
    const weightRecommendation = parseFloat(currentSet.recommendationData.weightRecommendation)
    const weightRecommendationRounded = parseInt(currentSet.recommendationData.weightRecommendationRounded)
    const override = parseInt(currentSet.completedSetData.override)

    const [editedWeightRecommendation, setEditedWeightRecommendation] = useState(weightRecommendation)
    const [editedWeightRecommendationRounded, setEditedWeightRecommendationRounded] = useState(weightRecommendationRounded)
    const [editedOverride, setEditedOverride] = useState(override)

    // Function that subtract weight during override
    const OverrideSubtract = () => {

        if (measurementSystem === 'imperial') {

            if (5 < weightRecommendationRounded) {

                setEditedWeightRecommendation(editedWeightRecommendation - 5.0)
                setEditedWeightRecommendationRounded(editedWeightRecommendationRounded - 5)
                setEditedOverride(editedOverride - 1)

            }

        }

        if (measurementSystem === 'metric') {

            if (5 < weightRecommendationRounded) {

                setEditedWeightRecommendation(editedWeightRecommendation - ConvertKilogramsToPounds(5.0))
                setEditedWeightRecommendationRounded(Math.round(editedWeightRecommendationRounded - ConvertKilogramsToPounds(5)))
                setEditedOverride(editedOverride - 1)

            }

        }
    }

    // Function that adds weight during override
    const OverrideAdd = () => {

        if (measurementSystem === 'imperial') {

            setEditedWeightRecommendation(editedWeightRecommendation + 5.0)
            setEditedWeightRecommendationRounded(editedWeightRecommendationRounded + 5)
            setEditedOverride(editedOverride + 1)

        }

        if (measurementSystem === 'metric') {

            setEditedWeightRecommendation(editedWeightRecommendation + ConvertKilogramsToPounds(5.0))
            setEditedWeightRecommendationRounded(Math.round(editedWeightRecommendationRounded + ConvertKilogramsToPounds(5)))
            setEditedOverride(editedOverride + 1)

        }

    }

    const OnOverrideInputBoxChanged = (e) => {
        
        if (measurementSystem === 'metric') return

        var inputBoxValue = parseFloat(e.target.value)

        setEditedWeightRecommendation(inputBoxValue)
        setEditedWeightRecommendationRounded(inputBoxValue)
        setEditedOverride(override + Math.round((inputBoxValue - weightRecommendationRounded) / 5.0))

    }

    const OnSaveChanges = () => {

        if (isNaN(editedWeightRecommendation) || editedWeightRecommendation < 5) return
        
        const overrideInfo = {
            phaseIndex: phaseIndex,
            exerciseIndex: exerciseIndex,
            setIndex: setIndex,
            weightRecommendation: parseFloat(editedWeightRecommendation),
            weightRecommendationRounded: parseInt(editedWeightRecommendationRounded),
            override: parseInt(editedOverride),
        }

        // console.log(overrideInfo)

        dispatch(setActiveWorkoutExerciseSetWeightOverride(overrideInfo))

    }

    return (
        <div>
            <div className="flex items-center gap-x-2 py-2">
                <button type="button" className="h-10 rounded-lg bg-white px-5 border border-gray-300" onClick={() => OverrideSubtract()}>
                    -5 {measurementSystem === 'imperial' ? 'lbs' : 'kgs'}
                </button>
    
                <input 
                    type="tel"
                    pattern="[0-9]*"
                    className="h-10 w-20 rounded-lg flex-grow text-center border border-gray-300"
                    value={measurementSystem === 'imperial' ? editedWeightRecommendationRounded : ConvertPoundsToNearestFiveKilograms(editedWeightRecommendation)}
                    onChange={(e) => OnOverrideInputBoxChanged(e)}
                />

                <button type="button" className="h-10 rounded-lg bg-white px-5 border border-gray-300" onClick={() => OverrideAdd()}>
                    +5 {measurementSystem === 'imperial' ? 'lbs' : 'kgs'}
                </button>
            </div>

            {(isNaN(editedWeightRecommendation) || editedWeightRecommendation < 5) && (
                <div className="text-red-500 text-center text-xs pb-2">
                    Please enter a value greater than 5
                </div>
            )}

            {/* Save Changes Button */}
            {(
                editedWeightRecommendation !== weightRecommendation ||
                editedWeightRecommendationRounded !== weightRecommendationRounded ||
                editedOverride !== override
            ) && (
                <div 
                    className='bg-white w-full h-10 rounded-full flex items-center justify-center border border-gray-300'
                    onClick={() => OnSaveChanges()}
                >
                    Save
                </div>
            )}

        </div>
    )
}
