import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GetPrimaryColorHex } from 'redux/slices/brand'
import { BanknotesIcon, ClockIcon, MagnifyingGlassIcon, ChartBarIcon } from '@heroicons/react/24/outline'

import LogoHeader from 'components/Header/LogoHeader'
import BodyFrame from 'components/Frame/BodyFrame'

export default function OnboardingAssessmentPreview() {

    const navigate = useNavigate()

    const styleConfig = useSelector((state) => state.brand.styleConfig)

    const OnTakeAssessmentButton = async () => {
        
        const props = {
            state: {
                assessmentUIFlowKey: 'onboarding'
            }
        }

        navigate('/assessment/preview', props)

    }

    const cards = [
        {
            name: 'Completely FREE',
            description: 'No credit card or further information required to take the assessment',
            icon: BanknotesIcon,
        },
        {
            name: 'Quick & Easy',
            description: '30 minutes to complete with minimal equipment required',
            icon: ClockIcon,
        },
        {
            name: 'Identify Your Weaknesses',
            description: 'Carefully crafted to identify weak muscle groups',
            icon: MagnifyingGlassIcon,
        },
        {
            name: 'Asymmetry Reduction Training',
            description: 'Assessment results inform your program and weave in exercises to address key weaknesses',
            icon: ChartBarIcon,
        }
    ]

    return(
        <div 
            className="fixed w-full h-full flex flex-col"
            style={{
                background: `linear-gradient(to bottom, ${GetPrimaryColorHex(styleConfig)}, ${GetPrimaryColorHex(styleConfig, 2)})`
            }}
        >

            <LogoHeader title='Sign Up' />

            <BodyFrame>

                <div className='text-2xl font-bold text-center text-white italic'>
                    <div>Take Your Muscle</div>
                    <div>Weakness Assessment!</div>
                </div>

                <div className='flex flex-col items-center space-y-4'>
                    {cards.map((card) => (
                        <div key={card.name} className='relative p-2 py-4 rounded-2xl w-full bg-white shadow-lg'>
                            <dt className='flex items-center space-x-2 px-2'>
                                <card.icon className='text-zinc-600 h-8 w-8' aria-hidden='true' />
                                <div className='flex-1 flex-grow px-2'>
                                    <div className='text font-semibold text-gray-900'>{card.name}</div>
                                    <div className='text-gray-600 text-xs'>{card.description}</div>
                                </div>
                            </dt>
                        </div>
                    ))}
                </div>

                <div className='mt-auto space-y-4'>
                    
                    <div
                        className="w-full flex justify-center items-center gap-x-4 rounded-full py-2 text-gray-100 border-2 border-gray-100"
                        onClick={() => navigate('/program/assignment/intro')}
                    >
                        I'll Take My Assessment Later
                    </div>

                    <div
                        className="w-full flex justify-center items-center gap-x-4 rounded-full font-semibold py-2 bg-white text-gray-800 border-2 border-gray-100"
                        onClick={() => OnTakeAssessmentButton()}
                    >
                        Take Assessment Now!
                    </div>

                </div>

            </BodyFrame>

        </div>
    )

}