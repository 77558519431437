import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CheckCircleIcon } from '@heroicons/react/24/outline'

import Status from './SubscriptionDetailsItems/Status'
import RenewalDate from './SubscriptionDetailsItems/RenewalDate'
import Amount from './SubscriptionDetailsItems/Amount'
import BillingCycle from './SubscriptionDetailsItems/BillingCycle'
import PaymentMethod from './SubscriptionDetailsItems/PaymentMethod'

import ResumeSubscriptionButton from './SubscriptionDetailsItems/ResumeSubscriptionButton'
import RetryPaymentButton from './SubscriptionDetailsItems/RetryPaymentButton'
import UpdatePaymentMethod from './SubscriptionDetailsItems/UpdatePaymentMethodButton'
import CancelSubscriptionButton from './SubscriptionDetailsItems/CancelSubscriptionButton'

export default function SubscriptionDetails({ subscriptionData, defaultPaymentMethod, GetSubscriptionInfoFromBackend, triggerRefresh, setTriggerRefresh }) {
    const navigate = useNavigate()

    const email = useSelector((state) => state.user.email)

    const [isResumeSubscriptionLoading, setIsResumeSubscriptionLoading] = useState(false)
    const [retryingPayment, setRetryingPayment] = useState(false)

    const OnResumeSubscription = async () => {
        setIsResumeSubscriptionLoading(true)

        const jsonBody = {
            email: email,
        }

        // Resume subscription
        const result = await WhichWeightAPI('BILLING', 'POST', '/resume_subscription', jsonBody)

        setIsResumeSubscriptionLoading(false)

        GetSubscriptionInfoFromBackend()
    }

    const OnRetryPastDuePayment = async () => {
        setRetryingPayment(true)

        const jsonBody = {
            email: email,
        }

        const result = await WhichWeightAPI('BILLING', 'POST', '/subscription_payment_retry', jsonBody)

        // console.log(result)

        setRetryingPayment(false)
        setTriggerRefresh(!triggerRefresh)
    }

    const OnCancelSubscriptionButton = (periodEndDate) => {
        const navigationProps = {
            state: {
                status: subscriptionData.status,
                periodEndDate: periodEndDate,
            },
        }

        navigate('/subscription/cancel', navigationProps)
    }

    return (
        <>
            <div className="text-lg">
                <Status subscriptionData={subscriptionData} />

                <RenewalDate subscriptionData={subscriptionData} />

                <Amount subscriptionData={subscriptionData} />

                <BillingCycle subscriptionData={subscriptionData} />

                <PaymentMethod subscriptionData={subscriptionData} defaultPaymentMethod={defaultPaymentMethod} />
            </div>

            <ResumeSubscriptionButton subscriptionData={subscriptionData} OnResumeSubscription={OnResumeSubscription} isResumeSubscriptionLoading={isResumeSubscriptionLoading} />

            <RetryPaymentButton subscriptionData={subscriptionData} OnRetryPastDuePayment={OnRetryPastDuePayment} retryingPayment={retryingPayment} />

            <UpdatePaymentMethod subscriptionData={subscriptionData} />

            <CancelSubscriptionButton subscriptionData={subscriptionData} OnCancelSubscriptionButton={OnCancelSubscriptionButton} />
        </>
    )
}
