import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { ModalContext } from '../ModalContext'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { GetPrimaryColorHex } from 'redux/slices/brand'

import LoadingButton from 'components/LoadingButton'

export default function ActionWarningModal() {

    const { modalData, HideModal } = useContext(ModalContext)
    const styleConfig = useSelector((state) => state.brand.styleConfig)

    return (
        <>
            {/* Exclamation Icon */}
            <div 
                className="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full" 
                style={{
                    backgroundColor: GetPrimaryColorHex(styleConfig),
                }}
            >
                <ExclamationTriangleIcon className="h-8 w-8 text-white" aria-hidden="true" />
            </div>

            {/* Title */}
            <div className="text-center text-lg font-semibold leading-6 text-gray-900">{modalData.titleText}</div>

            {/* Text */}
            <div className="space-y-2">
                {modalData.bodyText.map((text, index) => (
                    <p key={index} className="text-sm text-gray-600">
                        {text}
                    </p>
                ))}
            </div>

            {/* Button */}
            <LoadingButton
                buttonText={modalData.buttonText}
                action={() => {
                    modalData.buttonAction()
                    HideModal()
                }}
            />
        </>
    )
}
