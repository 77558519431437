import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useGetEntireWeeklyCalendarJSON } from 'redux/customHooks/useGetEntireWeeklyCalendarJSON'
import { setWhichWeightCacheEntireWeeklyCalendarJSON } from 'redux/slices/whichWeightCache'

import FullscreenLoading from 'components/Loading/FullscreenLoading'
import ResumeWorkoutHeader from 'components/Home/ResumeWorkoutHeader'
import HomeHeader from 'components/Header/HomeHeader'
import RaceCountdown from 'components/Home/HighLevelStats/RaceCountdown'
import WeeklyStreak from 'components/Home/HighLevelStats/WeeklyStreak'
import TrainingStatus from 'components/Home/HighLevelStats/TrainingStatus'
import VolumeLiftedChart from 'components/Home/HighLevelStats/VolumeLiftedChart'
import WeeklyWorkouts from 'components/Home/WeeklyWorkouts'
import TraineeSidebar from 'components/Sidebar/TraineeSidebar'

export default function Home() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userOnboarded = useSelector((state) => state.user.onboarded)
    const onboardingStepKey = useSelector((state) => state.user.onboardingStepKey)
    const newFitnessProfileVariablesPopulated = useSelector((state) => state.user.newFitnessProfileVariablesPopulated)
    const cachedEntireWeeklyCalendarJSON = useSelector((state) => state.whichWeightCache.entireWeeklyCalendarJSON)
    const sidebarOpen = useSelector((state) => state.userInterface.sidebarOpen)

    // Weekly calendar variables
    const entireWeeklyCalendarJSON = useGetEntireWeeklyCalendarJSON()

    // Check if user is onboarded
    // If not send them to the onboarding screen
    useEffect(() => {
        if (!userOnboarded){ 
            if (onboardingStepKey === '' || onboardingStepKey === 'personal-info') navigate('/onboarding/welcome')
            if (onboardingStepKey === 'fitness-profile') navigate('/onboarding/sport')
        }

        if (userOnboarded && !newFitnessProfileVariablesPopulated) navigate('/onboarding/newFitnessProfileVariables')
    }, [])

    if (!userOnboarded) return <FullscreenLoading loadingText="Getting User Info..." />

    if (entireWeeklyCalendarJSON === null || cachedEntireWeeklyCalendarJSON === null) return <FullscreenLoading loadingText="Loading Programming..." />

    {/* Loading timeout UI */}
    if (entireWeeklyCalendarJSON === 'timeout'){

        return (
            <>
                
                <HomeHeader />

                <div className='text-center pt-20 px-8 space-y-8'>

                    <div className='text-4xl font-bold'>Oops..</div>

                    <div>Looks like WhichWeight failed to load. Please check your internet connection and try again</div>

                    <div>If issue persists, please reach out to <strong>support@whichweight.com</strong></div>

                    <div 
                        className='text-xl border border-gray-600 rounded-full py-2'
                        onClick={() => dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON(null))}
                    >
                        Reload App
                    </div>

                </div>

                {sidebarOpen && <TraineeSidebar />}

            </>
        )

    }

    return (
        <div className='relative'>

            <ResumeWorkoutHeader />

            <HomeHeader />

            <div className="mx-4 mt-2 mb-2 border border-gray-600 rounded-xl overflow-hidden">
                <RaceCountdown />

                <div className="flex border-b border-gray-600">
                    <WeeklyStreak />

                    <TrainingStatus />
                </div>

                <VolumeLiftedChart />
            </div>

            <WeeklyWorkouts />

            {sidebarOpen && <TraineeSidebar />}

        </div>
    )
}
