import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'

export default function CustomHeader({ text = 'Header Text', backButtonType = 'arrow', backButtonAction = () => {} }) {
    return (
        <div className="bg-gray-100 shadow flex items-center p-4 h-14">
            {/* Back Button */}
            <div className="flex-1" onClick={backButtonAction}>
                {backButtonType === 'arrow' && <ChevronLeftIcon className="h-5 w-5" />}
                {backButtonType === 'x' && <XMarkIcon className="h-5 w-5" />}
            </div>

            <div className="text-xl font-semibold -m-2">{text}</div>

            <div className="flex-1" />
        </div>
    )
}
