import { createSlice } from '@reduxjs/toolkit'

// Initial state
const initialState =  {
    events: [],
    programAssignmentPreview: null
}

// Program Assignment Slice
const programAssignmentSlice = createSlice({
  name: 'programAssignment',
  initialState,
  reducers: {
    addProgramAssignmentEvent: (state, action) => { state.events.push(action.payload); },
    removeProgramAssignmentEvent: (state, action) => { state.events = state.events.filter((item, index) => index !== action.payload); },
    setProgramAssignmentPreview: (state, action) => { state.programAssignmentPreview = action.payload },
    resetProgramAssignmentState: () => initialState
  },
});

export const {
    addProgramAssignmentEvent,
    removeProgramAssignmentEvent,
    setProgramAssignmentPreview,
    resetProgramAssignmentState
} = programAssignmentSlice.actions;

export default programAssignmentSlice.reducer;