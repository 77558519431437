import { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { ModalContext } from '../ModalContext'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { GetPrimaryColorHex } from 'redux/slices/brand'
import { GetActiveWorkoutSliceKey } from 'functions/sliceUtils'
import { GetMovementDifficultyScaleText, GetLoadDifficultyScaleText } from 'functions/textUtils'

import tooEasyIcon from 'media/icons/eds/too_easy.png'
import aLittleEasyIcon from 'media/icons/eds/a_little_easy.png'
import neutralIcon from 'media/icons/eds/neutral.png'
import aLittleHardIcon from 'media/icons/eds/a_little_hard.png'
import tooHardIcon from 'media/icons/eds/too_hard.png'

import LoadingButton from 'components/LoadingButton'

export default function WorkoutSetFeedbackModal() {

    const { modalData, HideModal } = useContext(ModalContext)
    const styleConfig = useSelector((state) => state.brand.styleConfig)

    const activeWorkout = useSelector((state) => state.activeWorkout)
    const activeAssessment = useSelector((state) => state.activeAssessment)
    var workoutTypeKey = GetActiveWorkoutSliceKey(activeWorkout, activeAssessment)

    const movementDifficultySliderOptions = [
        {
            value: 2,
            text: GetMovementDifficultyScaleText(2),
            icon: tooHardIcon
        },
        {
            value: 1,
            text: GetMovementDifficultyScaleText(1),
            icon: aLittleHardIcon
        },
        {
            value: 0,
            text: GetMovementDifficultyScaleText(0),
            icon: neutralIcon
        },
        {
            value: -1,
            text: GetMovementDifficultyScaleText(-1),
            icon: aLittleEasyIcon
        },
        {
            value: -2,
            text: GetMovementDifficultyScaleText(-2),
            icon: tooEasyIcon
        }
    ]
    
    const loadDifficultySliderOptions = [
        {
            value: 2,
            text: GetLoadDifficultyScaleText(2),
            icon: tooHardIcon
        },
        {
            value: 1,
            text: GetLoadDifficultyScaleText(1),
            icon: aLittleHardIcon
        },
        {
            value: 0,
            text: GetLoadDifficultyScaleText(0),
            icon: neutralIcon
        },
        {
            value: -1,
            text: GetLoadDifficultyScaleText(-1),
            icon: aLittleEasyIcon
        },
        {
            value: -2,
            text: GetLoadDifficultyScaleText(-2),
            icon: tooEasyIcon
        }
    ]

    // Movement Difficulty Scale (MDS) variables
    const [selectedMovementDifficultyScaleOption, setSelectedMovementDifficultyScaleOption] = useState(0)
    const [displayMDSInfo, setDisplayMDSInfo] = useState(false)

    // Load Difficulty Scale (LDS) variables
    const [selectedLoadDifficultyScaleOption, setSelectedLoadDifficultyScaleOption] = useState(0)
    const [displayLDSInfo, setDisplayLDSInfo] = useState(false)

    // Reps completed variables
    const repsAssigned = parseInt(modalData.currentExercise.sets[modalData.setIndex].reps)
    const [repsCompleted, setRepsCompleted] = useState(repsAssigned)
    const [displayRepititionTrackerInfo, setDisplayRepititionTrackerInfo] = useState(false)
    
    const OnDoneButton = () => {

        const payload = {
            repsCompleted: repsCompleted,
            selectedMovementDifficultyScaleOption: selectedMovementDifficultyScaleOption,
            selectedLoadDifficultyScaleOption: selectedLoadDifficultyScaleOption
        }

        modalData.buttonAction(payload)

        HideModal()
    }

    // Lock scrolling when modal is open
    useEffect(() => {

        // Save current body style
        const originalStyle = window.getComputedStyle(document.body).overflow
        
        // Prevent scrolling on mount
        document.body.style.overflow = 'hidden'
        document.body.style.position = 'fixed' // Needed for iOS Safari
        document.body.style.width = '100%'
        document.body.style.height = '100%'

        // Re-enable scrolling on cleanup
        return () => {
            document.body.style.overflow = originalStyle
            document.body.style.position = ''
            document.body.style.width = ''
            document.body.style.height = ''
        }

    }, []) // Empty dependency array means this runs once on mount

    return (
        <>
            <div className="space-y-4">

                {/* Title */}
                <div className='space-y-1'>
                    <div className="font-semibold">
                        {modalData.currentExercise.exerciseName} Set {modalData.setIndex + 1} Complete!
                    </div>

                    <div className='text-xs italic text-gray-600'>
                        Your feedback is vital to personalizing your training
                    </div>
                </div>

                {/* Movement Difficulty Scale (MDS) */}
                <div className="pt-4 px-4 pb-2 space-y-2 border border-gray-400 rounded-lg overflow-hidden">
                    
                    {/* Title */}
                    <div className='space-y-2'>

                        <div 
                            className='flex items-center justify-center space-x-1'
                            onClick={() => setDisplayMDSInfo(!displayMDSInfo)}
                        >
                            <div className='text-xs font-bold'>Movement Difficulty Scale (MDS)</div>
                            <InformationCircleIcon className='h-4 w-4' />
                        </div>

                        {displayMDSInfo && 
                            <div className='bg-gray-100 p-2 rounded-lg text-xs italic'>
                                Movement difficulty scale provides the feedback that helps us pick the right exercise to continue your progression
                            </div>
                        }

                        <div className='italic'>How difficult was the movement?</div>

                    </div>

                    {/* Movement Difficulty Options */}
                    <div className='grid grid-cols-5 -mx-2'>
                        {movementDifficultySliderOptions.map((option) => (
                            <div
                                key={option.value}
                                className={`
                                    py-2 space-y-1 flex flex-col items-center justify-center
                                    ${selectedMovementDifficultyScaleOption === option.value ? 'border-2 border-gray-400 rounded-lg' : ''}
                                `}
                                onClick={() => setSelectedMovementDifficultyScaleOption(parseInt(option.value))}
                            >
                                <img src={option.icon} className="h-8 w-8" alt="Difficulty Icon" />
                                <div className="text-[11px] text-gray-600">{option.text}</div>
                            </div>
                        ))}
                    </div>

                </div>

                {/* Load Difficulty Scale (LDS). Only for recommendation exercises */}
                {modalData.currentExercise.exerciseType === 'recommendation' &&
                    <div className="pt-4 px-4 pb-2 space-y-2 border border-gray-400 rounded-lg overflow-hidden">
                            
                        {/* Title */}
                        <div className='space-y-2'>
                            
                            <div 
                                className='flex items-center justify-center space-x-1'
                                onClick={() => setDisplayLDSInfo(!displayLDSInfo)}
                            >
                                <div className='text-xs font-bold'>Load Difficulty Scale (LDS)</div>
                                <InformationCircleIcon className='h-4 w-4' />
                            </div>

                            {displayLDSInfo && 
                                <div className='bg-gray-100 p-2 rounded-lg text-xs italic'>
                                    Load Difficulty scale provides the feedback that helps us pick the right weight for each exercise
                                </div>
                            }

                            <div className='italic'>How heavy was the weight?</div>

                        </div>

                        {/* Load Difficulty Options */}
                        <div className='grid grid-cols-5 -mx-2'>
                            {loadDifficultySliderOptions.map((option) => (
                                <div
                                    key={option.value}
                                    className={`
                                        py-2 space-y-1 flex flex-col items-center justify-center
                                        ${selectedLoadDifficultyScaleOption === option.value ? 'border-2 border-gray-400 rounded-lg' : ''}
                                    `}
                                    onClick={() => setSelectedLoadDifficultyScaleOption(parseInt(option.value))}
                                >
                                    <img src={option.icon} className="h-8 w-8" alt="Difficulty Icon" />
                                    <div className="text-[11px] text-gray-600">{option.text}</div>
                                </div>
                            ))}
                        </div>

                        {/* Limited week reminder */}
                        {modalData.taperData.useTaper && (
                            <div className="w-full flex items-center justify-center gap-x-2 text-xs">
                                <ExclamationTriangleIcon className="text-yellow-400 h-5 pt-0.5"/>
                                <div>Load adjustments limited due to taper</div>
                            </div>
                        )}

                    </div>
                }

                {/* Repitition Tracker */}
                {(
                    workoutTypeKey === 'active-workout'
                    || (
                        workoutTypeKey === 'active-assessment' 
                        && modalData.currentExercise.id !== 267
                    )
                ) &&                         
                    <div className="w-full p-4 space-y-2 border border-gray-400 rounded-lg">

                        <div 
                            className='flex items-center justify-center space-x-1'
                            onClick={() => setDisplayRepititionTrackerInfo(!displayRepititionTrackerInfo)}
                        >
                            <div className='text-xs font-bold'>Repitition Tracker</div>
                            <InformationCircleIcon className='h-4 w-4' />
                        </div>

                        {displayRepititionTrackerInfo && 
                            <div className='bg-gray-100 p-2 rounded-lg text-xs italic'>
                                Repitition tracker provides the feedback that helps us pick the right exercise to continue your progression
                            </div>
                        }

                        {modalData.currentExercise.setUnit === 'reps' && <div className='italic'>How many reps did you complete?</div>}
                        {modalData.currentExercise.setUnit === 'seconds' && <div className='italic'>How many seconds did you complete?</div>}

                        {/* Custom Styled Slider */}
                        <div className='relative h-6 flex items-center'>

                            {/* Background Bar */}
                            <div className='absolute w-full h-2 bg-gray-200 rounded-full' />

                            {/* Accent Bar */}
                            <div 
                                className='absolute h-2 bg-gray-200 rounded-full'
                                style={{
                                    width: `${(repsCompleted/modalData.currentExercise.sets[modalData.setIndex].reps)*100}%`,
                                    backgroundColor: GetPrimaryColorHex(styleConfig)
                                }}
                            />

                            {/* Input */}
                            <input
                                id="repsCompleted"
                                type="range"
                                min="0"
                                max={modalData.currentExercise.sets[modalData.setIndex].reps}
                                value={repsCompleted}
                                onChange={(event) => setRepsCompleted(parseInt(event.target.value))}
                                style={{ 
                                    accentColor: GetPrimaryColorHex(styleConfig),
                                    height: '24px',
                                    cursor: 'pointer',
                                    WebkitAppearance: 'none',
                                    background: 'transparent'
                                }}
                                className="absolute w-full touch-manipulation"
                            />

                        </div>

                        {modalData.currentExercise.setUnit === 'reps' && 
                            <div className="text-center">
                                {repsCompleted} / {repsAssigned} reps
                            </div>
                        }

                        {modalData.currentExercise.setUnit === 'seconds' &&
                            <div className="text-center">
                                {repsCompleted} / {repsAssigned} seconds
                            </div>
                        }

                    </div>
                }

            </div>

            {/* Done Button */}
            <div className="mt-auto pt-4">

                <LoadingButton 
                    buttonText="Done" 
                    action={() => OnDoneButton()} 
                />

            </div>

        </>
    )
}
