import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetUserState } from 'redux/slices/user'
import { resetProgramAssignmentState } from 'redux/slices/programAssignment'
import { setWhichWeightCacheEntireWeeklyCalendarJSON } from 'redux/slices/whichWeightCache'
import { SeasonPhaseNames, WeekNames } from 'functions/weeklyCalendarUtils'
import { GetProgramPhaseColorHex } from 'redux/slices/brand'
import moment from 'moment'

import ScreenFrame from 'components/Frame/ScreenFrame'
import LogoHeader from 'components/Header/LogoHeader'
import BodyFrame from 'components/Frame/BodyFrame'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import LoadingButton from 'components/LoadingButton'

export default function ProgramAssignmentOverview() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const userId = useSelector((state) => state.user.id)
    const programAssignmentPreview = useSelector((state) => state.programAssignment.programAssignmentPreview)
    const events = useSelector((state) => state.programAssignment.events)

    const [isStartProgramLoading, setIsStartProgramLoading] = useState(false)

    const OnStartProgram = async () => {

        setIsStartProgramLoading(true)

        for (let i = 0; i < events.length; i++) {
            const jsonBody = {
                traineeId: userId,
                name: events[i].name,
                eventTrainingStartDate: events[i].eventTrainingStartDate,
                eventType: events[i].eventType,
                eventTerrain: events[i].eventTerrain,
                eventTerrainTextInput: events[i].eventTerrainTextInput,
                eventDistance: events[i].eventDistance,
                eventDistanceTextInput: events[i].eventDistanceTextInput,
                eventDate: events[i].eventDate,
            }

            // console.log(jsonBody);

            // return

            const result = await WhichWeightAPI('CORE', 'POST', '/event', jsonBody)

            // console.log(result.data);
        }

        setIsStartProgramLoading(false)

        dispatch(resetProgramAssignmentState())
        dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON(null)) // Reset calendar data
        
        if (styleConfig.brandKey !== 'blue-collar' && styleConfig.brandKey !== 'which-weight'){ 
            console.error('Invalid brand key: ' + styleConfig.brandKey)
            navigate('/profile/membership')
        }

        if (styleConfig.brandKey === 'blue-collar') {
            dispatch(resetUserState())
            navigate('/home')
        }
        
        if (styleConfig.brandKey === 'which-weight') navigate('/profile/membership')

    }

    return (
        <ScreenFrame>
            <LogoHeader backButtonAction={() => navigate('/program/assignment/events')} />

            <BodyFrame>

                {/* Loading Animation */}
                {programAssignmentPreview === null && <MediumLoadingComponent />}

                {programAssignmentPreview !== null && (
                    <>
                        <div>
                            {/* Program Title */}
                            <div className="text-center text-xl font-semibold text-gray-900">{programAssignmentPreview.programName}</div>

                            {/* Starting Week */}
                            <div className="text-center text-gray-500">Training Begins On {moment(programAssignmentPreview.eventTrainingStartDate).format('MMMM D')}</div>
                        </div>

                        {/* Start Program Button */}
                        <LoadingButton
                            buttonText="Start Program"
                            className="w-full flex justify-center gap-x-4 items-center text-center text-white rounded-full py-2 px-4 text-lg font-semibold"
                            isLoading={isStartProgramLoading}
                            action={() => OnStartProgram()}
                        />

                        {/* Map over every week */}
                        {programAssignmentPreview.programWeeks.map((programWeek) => (
                            <div 
                                key={programWeek.programWeekIndex} 
                                className='px-4 py-1 flex items-center shadow rounded-lg border'
                                style={{
                                    borderColor: GetProgramPhaseColorHex(styleConfig, programWeek.volumeData.programPhaseKey)
                                }}
                            >
                                <p className="text w-20">Week {programWeek.programWeekIndex + 1}</p>

                                <p className="text-xs text-gray-400 flex-grow">
                                    {programWeek.volumeData.taperData.taperType === 'program' && WeekNames[programWeek.volumeData.programWeekKey]}
                                    {programWeek.volumeData.taperData.taperType === 'event' && `${programWeek.volumeData.taperData.eventName} Prep`}
                                </p>

                                <p 
                                    className="my-2 w-32 text-center text-gray-800 text-xs py-1 rounded-full" 
                                    style={{ backgroundColor: GetProgramPhaseColorHex(styleConfig, programWeek.volumeData.programPhaseKey) }}
                                >
                                    {SeasonPhaseNames[programWeek.volumeData.programPhaseKey]}
                                </p>
                                
                            </div>
                        ))}

                        {/* Future tag */}
                        <div className="flex items-center shadow border border-gray-400 rounded-lg px-4 py-2">
                            <p className="text-lg flex-grow">Future Weeks</p>
                            <p className="text-gray-400">To Be Calculated</p>
                        </div>

                        {/* Out of Bounds Events */}
                        {programAssignmentPreview.outOfBoundsEvents.length > 0 && (
                            <>
                                {programAssignmentPreview.outOfBoundsEvents.map((event, eventIndex) => (
                                    <div key={eventIndex} className="flex items-center shadow border border-orange-600 rounded-lg px-4 py-2">
                                        <div className="text-lg text-orange-600 flex-grow">Future Event</div>
                                        <div className="text-orange-400 font-semibold ">{event.name}</div>
                                    </div>
                                ))}
                            </>
                        )}
                    </>
                )}

            </BodyFrame>

        </ScreenFrame>
    )
}
