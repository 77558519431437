import { useDispatch } from 'react-redux'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useAuth0 } from '@auth0/auth0-react'
import { resetUserState } from 'redux/slices/user'
import { resetOnboardingState } from 'redux/slices/onboarding'

export default function IncorrectAccountTypeView() {
    const { logout } = useAuth0()

    const dispatch = useDispatch()

    const OnLogout = () => {
        // Reset active workout state
        // dispatch(resetActiveWorkoutState());

        // Reset user state
        dispatch(resetUserState())

        // Reset onboarding state
        dispatch(resetOnboardingState())

        logout({ logoutParams: { returnTo: window.location.origin + '/login' } })
    }

    return (
        <div className='p-4 mx-auto space-y-4'>
            <div className="p-2 space-y-2">
                <MagnifyingGlassIcon className="mt-20 mb-10 mx-auto w-40 text-gray-500" />

                <div className="text-center font-bold text-2xl">You appear to be lost</div>

                <div className="text-center text-lg">It looks like you are trying to login to the WhichWeight athlete app with a coach account</div>

                <div className="text-center text-lg">Please go to <a href='https://coach.whichweight.com' className='text-blue-500'>coach.whichweight.com</a> to login to your coach account</div>

                <div className="text-center text-lg">Or create a new WhichWeight athlete account using a new email</div>

                <div className="text-center text-lg">For more support reach out to <strong>support@whichweight.com</strong></div>

            </div>

            <button type="button" className="w-full text-center rounded-full border border-gray-600 py-2 text-md font-semibold text-gray-500 shadow-sm" onClick={() => OnLogout()}>
                Logout
            </button>
            
        </div>
    )
}
