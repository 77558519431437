import React, { createContext, useState } from 'react'

export const ModalKey = {
    ActionWarning: 'action-warning',
    ActivateMembership: 'activate-membership',
    AddToHomeScreen: 'add-to-home-screen',
    ExerciseAssessmentInfo: 'exercise-info-assessment',
    ExerciseInstructions: 'exercise-instructions',
    Feedback: 'feedback',
    ListSelection: 'list-selection',
    MoveWorkoutWeekDay: 'move-workout-week-day',
    MultiActionWarning: 'multi-action-warning',
    NetPromoterScore: 'net-promoter-score',
    SelectExercise: 'select-exercise',
    Text: 'text',
    TextInput: 'text-input',
    TrainingStatus: 'training-status',
    VideoPlayer: 'video-player',
    WorkoutSetFeedback: 'workout-set-feedback',
    WorkoutSetInformation: 'workout-set-information',
}

export const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
    // Hook that stores modal data
    // This is placed in the Modal.Context so that it can be accessed from all scripts
    const [modalData, setModalData] = useState(null)

    // Function that can be called by any component that sets modal data
    const ShowModal = (modalData) => {
        // TODO Verify modalInfo structure here

        setModalData(modalData)
    }

    // Function that can be called by any component that resets modal data
    const HideModal = () => setModalData(null)

    return <ModalContext.Provider value={{ modalData, ShowModal, HideModal }}>{children}</ModalContext.Provider>
}
