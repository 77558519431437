import { useSelector } from 'react-redux'
import { GetMoneyFormattedString } from 'functions/subscriptionUtils'

export default function Subtotal({ interval, amountDue }) {
    const usedFreeTrial = useSelector((state) => state.user.usedFreeTrial)
    const couponData = useSelector((state) => state.subscription.couponData)

    return (
        <div className="flex border-t border-gray-300 pt-4 px-2">
            {!usedFreeTrial && (
                <>
                    {interval === 4 && <div className="flex-grow">Subtotal (After 7 days)</div>}
                    {interval === 52 && <div className="flex-grow">Subtotal (After 14 days)</div>}
                </>
            )}

            {usedFreeTrial && <div className="flex-grow">Subtotal</div>}

            {couponData === null && <div>{GetMoneyFormattedString(amountDue)}</div>}

            {couponData !== null && couponData.amountOff !== null && <div>{GetMoneyFormattedString(amountDue - couponData.amountOff)}</div>}

            {couponData !== null && couponData.percentOff !== null && <div>{GetMoneyFormattedString(amountDue * ((100 - couponData.percentOff) / 100))}</div>}
        </div>
    )
}
