import { useSelector } from 'react-redux'
import { WeekOfTheYearKey } from 'classes/WeekOfTheYearKey'
import { GetPresentWeekOverview } from 'redux/selectors/PresentWeekOverviewSelector'
import { AreaChart, Area, LabelList, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts'
import { GetPrimaryColorHex } from 'redux/slices/brand'

export default function WeeklyRepsChart({ height = 100 }) {
    
    const styleConfig = useSelector((state) => state.brand.styleConfig)
    
    const presentWeek = GetPresentWeekOverview()
    const weeklyRepsCompletedChartData = presentWeek.weeklyRepsCompletedChartData.slice().reverse()

    return (
        <>
            {/* Header */}
            <div className="py-1 text-center text-xs text-gray-600">Total Weekly Reps</div>

            <div className="bg-white rounded-lg">
                {/* Chart */}
                <ResponsiveContainer width="100%" height={height}>
                    <AreaChart 
                        data={weeklyRepsCompletedChartData} 
                        margin={{ top: 4, right: 20, left: -20, bottom: 20 }}
                    >
                        <CartesianGrid strokeDasharray="1 2" stroke="#D3D3D3" horizontal={true} />

                        <XAxis
                            dataKey="weekOfTheYearKey"
                            style={{ fontSize: '0.5rem' }}
                            tickFormatter={(weekOfTheYearKey) => new WeekOfTheYearKey(weekOfTheYearKey).toDateRangeString('M/D')} 
                            padding={{ left: 10, right: 10 }}
                            angle={-30}
                            textAnchor="end"
                        />

                        <YAxis style={{ fontSize: '0.7rem' }} tickLine={false} padding={{ top: 10 }} />

                        <Area 
                            type="monotone" 
                            dataKey="weeklyTotalRepsCompleted" 
                            name="Reps Completed" 
                            stroke={GetPrimaryColorHex(styleConfig)}
                            fill={GetPrimaryColorHex(styleConfig, -2)}
                        >
                            <LabelList 
                                dataKey="weeklyTotalRepsCompleted" 
                                position="top" 
                                style={{ fontSize: '0.7rem' }} 
                            />
                        </Area>

                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </>
    )
}
