import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon, Bars3BottomLeftIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { ConvertPoundsToNearestFiveKilograms } from 'functions/measurementSystemUtils'
import { GetActiveWorkoutSliceKey } from 'functions/sliceUtils'
import { GetPrimaryColorHex } from 'redux/slices/brand'
import LoadingAnimation from 'media/loading.svg'
import SpinnerWhite from 'media/icons/spinner-white.png'

export default function CompactExerciseSetCard({ 
    allDatabaseExercises, 
    phaseIndex, 
    phase, 
    exerciseIndex, 
    exercise, 
    setIndex, 
    set, 
    ShowEDSModalForCurrentSet 
}) {

    const { ShowModal } = useContext(ModalContext)

    const activeWorkout = useSelector((state) => state.activeWorkout)
    const activeAssessment = useSelector((state) => state.activeAssessment)
    var workoutTypeKey = GetActiveWorkoutSliceKey(activeWorkout, activeAssessment)

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const measurementSystem = useSelector((state) => state.user.fitnessProfile?.measurementSystem)

    const GetTextStyle = (text) => {
        var style = 'w-28 text-left '

        if (text.length <= 16) style += 'text-sm'
        else if (16 < text.length && text.length <= 24) style += 'text-xs'
        else style += 'text-[10px]'

        return style
    }

    const OnShowExerciseInstructionModal = (exerciseId) => {
        const modalData = {
            key: ModalKey.ExerciseInstructions,
            fullscreen: true,
            exerciseId: exerciseId,
        }

        ShowModal(modalData)
    }

    const OnShowWeightRecommendationInfoModal = (exerciseIndex, exercise, setIndex, set) => {
        const modalData = {
            key: ModalKey.WorkoutSetInformation,
            fullscreen: true,
            phaseIndex: phaseIndex,
            exerciseIndex: exerciseIndex,
            exercise: exercise,
            setIndex: setIndex,
            set: set,
        }

        ShowModal(modalData)
    }

    return (
        <div className="bg-white rounded-lg shadow">
            <div className="flex items-center h-10 pl-1 pr-2 gap-x-2">
                {/* Left hand side exercise details */}
                <div className="flex flex-grow items-center gap-x-2" onClick={() => OnShowExerciseInstructionModal(exercise.id)}>
                    {/* Thumbnail */}
                    {allDatabaseExercises.length > 0 && (
                        <img src={allDatabaseExercises.find((item) => item.id === exercise.id).imageUrl} alt="Exercise Thumbnail" className="w-8 h-8 object-cover rounded-md" />
                    )}

                    {/* Exercise Name */}
                    <div className={GetTextStyle(exercise.name)}>{exercise.name}</div>
                </div>

                {/* Reps */}
                <div className="flex items-center gap-x-1 text-sm">

                    {workoutTypeKey === 'active-assessment' && 
                        <>
                            {exercise.id === 267 &&
                                <div>1 / side</div>
                            }

                            {exercise.id !== 267 &&
                                <>
                                    {exercise.setUnit === 'reps' && (
                                        <>
                                            {exercise.lateral !== 'unilateral' && <div>{set.reps} reps</div>}
                                            {exercise.lateral === 'unilateral' && <div>{set.reps} / side</div>}
                                        </>
                                    )}

                                    {/* Seconds Sets Unit */}
                                    {exercise.setUnit === 'seconds' && (
                                        <>
                                            {exercise.lateral !== 'unilateral' && <div>{set.seconds}s</div>}
                                            {exercise.lateral === 'unilateral' && <div>{set.seconds}s / side</div>}
                                        </>
                                    )}
                                </>
                            }


                        </>
                    }

                    {workoutTypeKey === 'active-workout' && 
                        <>

                            {exercise.setUnit === 'reps' && (
                                <>
                                    {exercise.lateral !== 'unilateral' && <div>{set.reps} reps</div>}
                                    {exercise.lateral === 'unilateral' && <div>{set.reps} / side</div>}
                                </>
                            )}

                            {/* Seconds Sets Unit */}
                            {exercise.setUnit === 'seconds' && (
                                <>
                                    {exercise.lateral !== 'unilateral' && <div>{set.seconds}s</div>}
                                    {exercise.lateral === 'unilateral' && <div>{set.seconds}s / side</div>}
                                </>
                            )}

                        </>
                    }

                </div>

                {/* WhichWeight Weight Recommendation */}
                <div
                    className="flex items-center px-2 py-0.5 gap-x-1 rounded-lg border border-gray-400 bg-white text-sm"
                    onClick={() => OnShowWeightRecommendationInfoModal(exerciseIndex, exercise, setIndex, set)}
                >
                    {/* If the recommendation is loading, show animation */}
                    {!set.recommendationData.weightRecommendationLoaded && (
                        <div className="py-0.5 px-4">
                            <img src={SpinnerWhite} alt="Spinner" className="animate-spin h-4 w-4" />
                        </div>
                    )}

                    {/* If the recommendation is loaded (imperial) */}
                    {set.recommendationData.weightRecommendationLoaded && measurementSystem === 'imperial' && (
                        <>
                            {/* Continuous Override */}
                            {exercise.continuousOverride && <div>{exercise.continuousOverrideWeight} lbs</div>}

                            {/* Calculated Recommendation */}
                            {!exercise.continuousOverride && exercise.exerciseType === 'recommendation' && <div>{set.recommendationData.weightRecommendationRounded} lbs</div>}

                            {/* Bodyweight Recommendation */}
                            {!exercise.continuousOverride && exercise.exerciseType === 'bodyweight' && <div>Body</div>}

                            {/* Info Circle Icon */}
                            <InformationCircleIcon className="h-4" />
                        </>
                    )}

                    {/* If the recommendation is loaded (metric) */}
                    {set.recommendationData.weightRecommendationLoaded && measurementSystem === 'metric' && (
                        <>
                            {/* Continuous Override */}
                            {exercise.continuousOverride && <div>{Math.round(ConvertPoundsToNearestFiveKilograms(exercise.continuousOverrideWeight))} kgs</div>}

                            {/* Calculated Recommendation */}
                            {!exercise.continuousOverride && exercise.exerciseType === 'recommendation' && (
                                <div>{Math.round(ConvertPoundsToNearestFiveKilograms(set.recommendationData.weightRecommendation))} kgs</div>
                            )}

                            {/* Bodyweight Recommendation */}
                            {!exercise.continuousOverride && exercise.exerciseType === 'bodyweight' && <div>Body</div>}

                            {/* Info Circle Icon */}
                            <InformationCircleIcon className="h-4" />
                        </>
                    )}
                </div>

                {/* If result is currently being saved */}
                {set.completedSetData.savingResult && (
                    <div className="h-6 w-6 p-0.5 rounded-full bg-gray-200">
                        <img src={LoadingAnimation} className="h-5" alt="Loading animation" />
                    </div>
                )}

                {/* If result is not currently being saved */}
                {!set.completedSetData.savingResult && (
                    <>
                        {/* Discarded Set Icon */}
                        {phase.completed && !set.completedSetData.completed && (
                            <div className="h-6 w-6 p-0.5 rounded-full bg-gray-200">
                                <XCircleIcon className="h-5 text-gray-400" />
                            </div>
                        )}

                        {/* Incomplete Set Icon */}
                        {!phase.completed && !set.completedSetData.completed && (
                            <div className="h-6 w-6 rounded-full bg-gray-200" onClick={() => ShowEDSModalForCurrentSet(phaseIndex, exerciseIndex, exercise, setIndex)} />
                        )}

                        {/* Completed Set Icon */}
                        {set.completedSetData.completed && (
                            <div 
                                className="h-6 w-6 p-0.5 rounded-full"
                                style={{
                                    backgroundColor: GetPrimaryColorHex(styleConfig)
                                }}
                            >
                                <CheckCircleIcon 
                                    className="h-5"
                                    style={{
                                        color: GetPrimaryColorHex(styleConfig, -3)
                                    }} 
                                />
                            </div>
                        )}
                    </>
                )}
            </div>

            {/* Exercise note */}
            {exercise.note.length > 0 && (
                <div className="flex items-center gap-x-2 mx-1 mb-1 px-2 py-1 border border-gray-400 text-gray-600 text-xs rounded-lg">
                    <Bars3BottomLeftIcon className="h-3" />
                    <div>{exercise.note}</div>
                </div>
            )}
        </div>
    )
}
