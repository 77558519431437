import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'

import LoadingButton from 'components/LoadingButton'

export default function Checkout() {
    const navigate = useNavigate()
    const stripe = useStripe()
    const elements = useElements()

    const email = useSelector((state) => state.user.email)

    const [isApplyBillingLoading, setIsApplyBillingLoading] = useState(false)
    const [billingErrorMessage, setBillingErrorMessage] = useState('')

    const HandleCheckoutSubmit = async (event) => {
        event.preventDefault()

        if (!stripe || !elements) return

        setBillingErrorMessage('')
        setIsApplyBillingLoading(true)

        const confirmPaymentResult = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
        })

        // Catch error
        if (confirmPaymentResult.error) {
            console.error(confirmPaymentResult)
            setIsApplyBillingLoading(false)
            setBillingErrorMessage('Error applying billing info. ' + confirmPaymentResult.error.message + ' Please try again or contact support')
            return
        }

        // Catch payment status not succeeding
        if (confirmPaymentResult.paymentIntent.status !== 'succeeded') {
            console.error(confirmPaymentResult)
            setIsApplyBillingLoading(false)
            setBillingErrorMessage('Error: payment was unsuccessfull. Please try again or contact support')
            return
        }

        const jsonBody = {
            email: email,
            stripePaymentMethodId: confirmPaymentResult.paymentIntent.payment_method,
        }

        // console.log(jsonBody)

        const attachPaymentMethodResult = await WhichWeightAPI('BILLING', 'POST', '/attach_payment_method_to_customer', jsonBody)

        if (attachPaymentMethodResult.data.status === 'failure') {
            console.error(attachPaymentMethodResult)
            setIsApplyBillingLoading(false)
            setBillingErrorMessage('Error setting default payment method. Please try again or contact support')
            return
        }

        navigate('/subscription/success')
    }

    return (
        <div className="p-4 space-y-4">
            {/* Stripe Payment Element */}
            <PaymentElement />

            {/* Error Message */}
            {billingErrorMessage.length > 0 && <div className="text font-semibold text-red-400">Error: {billingErrorMessage}</div>}

            {/* Purchase Button */}
            {stripe && <LoadingButton buttonText="Pay" isLoading={isApplyBillingLoading} action={(event) => HandleCheckoutSubmit(event)} />}
        </div>
    )
}
