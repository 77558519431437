import { WhichWeightAPI } from 'WhichWeightAPI'
import { useContext } from 'react'
import { useGetExercises } from 'redux/customHooks/useGetExercises'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { ArrowPathIcon } from '@heroicons/react/24/solid'

export default function WorkoutPreview({ workoutTemplate, setWorkoutTemplate }) {
    // Modal variables
    const { ShowModal, HideModal } = useContext(ModalContext)

    const exercises = useGetExercises()

    const OnExercisePressed = (exerciseId) => {
        const modalData = {
            key: ModalKey.ExerciseInstructions,
            fullscreen: true,
            exerciseId: exerciseId,
        }

        ShowModal(modalData)
    }

    const ShowBodyLocationOptions = (event, exerciseIndex) => {
        const modalData = {
            key: ModalKey.ListSelection,
            fullscreen: false,
            headerText: 'What area of the body would you like to work on?',
            noListItemsText: 'No body locations are available at this time',
            getListItems: async () => {
                return [
                    { name: 'Adductors', key: 'adductors' },
                    { name: 'Ankles', key: 'ankles' },
                    { name: 'Glutes', key: 'glute-max' },
                    { name: 'Hamstrings', key: 'hamstrings' },
                    { name: 'Hip Flexors', key: 'hip-flexors' },
                    { name: 'ITB / TFL', key: 'itb' },
                    { name: 'Quads', key: 'quads' },
                    { name: 'Shoulders / Chest', key: 'shoulders' },
                    { name: 'Thoracic Spine', key: 'thoracic-spine' },
                ]
            },
            selectAction: async (bodyLocation) => ShowExerciseSwapOptionsFromBodyLocation(bodyLocation, exerciseIndex),
        }

        ShowModal(modalData)

        event.stopPropagation()
    }

    const ShowExerciseSwapOptionsFromBodyLocation = (bodyLocation, exerciseIndex) => {
        const modalData = {
            key: ModalKey.ListSelection,
            fullscreen: false,
            headerText: 'Select an alternate exercise',
            noListItemsText: 'No mobility swaps are available at this time',
            getListItems: async () => {
                const jsonBody = {
                    swapType: 'mobility',
                    bodyLocation: bodyLocation.key,
                }

                // console.log(jsonBody)

                const result = await WhichWeightAPI('CORE', 'GET', '/exercise_swap_options', jsonBody)

                // console.log(result);

                return result.data.data
            },
            selectAction: async (swapExercise) => {
                // console.log(swapExercise)
                // console.log(exerciseIndex)

                var temp_workoutTemplate = JSON.parse(JSON.stringify(workoutTemplate))

                temp_workoutTemplate.phases[0].exercises[exerciseIndex].id = swapExercise.id
                temp_workoutTemplate.phases[0].exercises[exerciseIndex].name = swapExercise.name
                temp_workoutTemplate.phases[0].exercises[exerciseIndex].exerciseType = swapExercise.exerciseType
                temp_workoutTemplate.phases[0].exercises[exerciseIndex].lateral = swapExercise.lateral
                temp_workoutTemplate.phases[0].exercises[exerciseIndex].setUnit = swapExercise.setUnit
                temp_workoutTemplate.phases[0].exercises[exerciseIndex].videoUrl = swapExercise.videoUrl
                temp_workoutTemplate.phases[0].exercises[exerciseIndex].generationVariables = { fetchType: 'mobility-swap' }

                // console.log(temp_workoutTemplate)

                setWorkoutTemplate(temp_workoutTemplate)

                HideModal()
            },
        }

        ShowModal(modalData)
    }

    /*
    const ShowExerciseSwapOptionsFromExerciseId = (event, exerciseId, exerciseIndex) => {
        const modalData = {
            key: ModalKey.ListSelection,
            fullscreen: false,
            headerText: 'Select an alternate exercise',
            getListItems: async () => {
                const jsonBody = {
                    swapType: 'home',
                    exerciseId: exerciseId,
                }

                // console.log(jsonBody)

                const result = await WhichWeightAPI('CORE', 'GET', '/exercise_swap_options', jsonBody)

                // console.log(result);

                return result.data.data
            },
            selectAction: async (swapExercise) => {
                // console.log(swapExercise)
                // console.log(exerciseIndex)

                var temp_workoutTemplate = JSON.parse(JSON.stringify(workoutTemplate))

                temp_workoutTemplate.phases[0].exercises[exerciseIndex].id = swapExercise.id
                temp_workoutTemplate.phases[0].exercises[exerciseIndex].name = swapExercise.name
                temp_workoutTemplate.phases[0].exercises[exerciseIndex].exerciseType = swapExercise.exerciseType
                temp_workoutTemplate.phases[0].exercises[exerciseIndex].lateral = swapExercise.lateral
                temp_workoutTemplate.phases[0].exercises[exerciseIndex].setUnit = swapExercise.setUnit
                temp_workoutTemplate.phases[0].exercises[exerciseIndex].videoUrl = swapExercise.videoUrl
                temp_workoutTemplate.phases[0].exercises[exerciseIndex].generationVariables = { fetchType: 'mobility-swap' }

                // console.log(temp_workoutTemplate)

                setWorkoutTemplate(temp_workoutTemplate)

                HideModal()
            },
        }

        ShowModal(modalData)

        event.stopPropagation()
    }
    */

    return (
        <>
            {/* Map over phases */}
            {workoutTemplate.phases.map((phase, phaseIndex) => (
                <div key={phaseIndex} className="shadow border border-stone-200 rounded-lg px-4 py-2 items-center">
                    {/* Phase Name */}
                    <div className="text-lg">{phase.name}</div>

                    {/* Circuit rounds (if the phase is of type circuit) */}
                    {phase.type === 'circuit' && <div className="text-sm text-gray-500">{phase.circuitRounds} rounds</div>}

                    {/* Phase note (if the phase has a note) */}
                    {phase.note !== '' && <div className="text-sm text-gray-500">{phase.note}</div>}

                    {/* Exercises */}
                    <div className="mt-2">
                        {phase.exercises.map((exercise, exerciseIndex) => (
                            <div key={exerciseIndex} className="flex items-center gap-x-2 py-2" onClick={() => OnExercisePressed(exercise.id)}>
                                {/* Thumbnail */}
                                {exercises.length > 0 && (
                                    <img src={exercises.find((item) => item.id === exercise.id).imageUrl} alt="Exercise Thumbnail" className="w-8 h-8 object-cover rounded-lg" />
                                )}

                                {/* Exercise Name */}
                                <div className="flex-grow">{exercise.name}</div>

                                {/* Warm Up Swap button. Based on bodyLocation */}
                                {(phase.phaseKey === 'warm-up' || phase.phaseKey === 'mobility-circuit') && (
                                    <div className="px-4 py-1 border border-gray-400 rounded-xl" onClick={(event) => ShowBodyLocationOptions(event, exerciseIndex)}>
                                        <ArrowPathIcon className="h-5 text-gray-600" />
                                    </div>
                                )}

                                {/* Swap button. Based on exerciseId 
                                {phase.phaseKey !== 'warm-up' && 
                                    <div 
                                        className="px-4 py-1 border border-gray-400 rounded-xl"
                                        onClick={(event) => ShowExerciseSwapOptionsFromExerciseId(event, exercise.id, exerciseIndex)}
                                    >
                                        <ArrowPathIcon className="h-5 text-gray-600" />
                                    </div>
                                }
                                */}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </>
    )
}
