import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ChevronUpIcon, ChevronDownIcon, CircleStackIcon, ChartBarIcon } from '@heroicons/react/24/solid'
import { ConvertPoundsToNearestFiveKilograms } from 'functions/measurementSystemUtils'
import { GetPrimaryColorHex } from 'redux/slices/brand'

import CustomHeader from 'components/Header/CustomHeader'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import LinearDial from 'components/Dials/LinearDial'
import WorkoutSummaryExerciseSetListItem from 'components/WorkoutSummary/WorkoutSummaryExerciseSetListItem'
import LoadingButton from 'components/LoadingButton'

export default function CompletedWorkoutSummary() {
    const navigate = useNavigate()
    const location = useLocation()
    const { weekOfTheYearKey, workoutTemplateKey, doneNavigation } = location.state

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const userId = useSelector((state) => state.user.id)
    const measurementSystem = useSelector((state) => state.user.measurementSystem)

    const [completedWorkoutData, setCompletedWorkoutData] = useState(null)
    const [totalSetsCompleted, setTotalSetsCompleted] = useState(0)
    const [percentageOfSetsCompleted, setPercentageOfSetsCompleted] = useState(0)
    const [totalVolumeLifted, setTotalVolumeLifted] = useState(0)
    const [totalRepsCompleted, setTotalRepsCompleted] = useState(0)

    // UI Hooks
    const [expandPhaseJSON, setExpandPhaseJSON] = useState(null)

    // Fetches exercise data from the backend
    const GetCompletedWorkoutDataFromBackend = async () => {
        const jsonBody = {
            userId: userId,
            weekOfTheYearKey: weekOfTheYearKey,
            workoutTemplateKey: workoutTemplateKey,
        }

        // console.log(jsonBody)

        const result = await WhichWeightAPI('PROGRAM', 'GET', '/completed_workout_data', jsonBody)

        if (result.data.status === 'failure') {
            console.error(result)
            return
        }

        // console.log(result.data.data);

        // For version 2.x calculate additional stats
        if (result.data.data.version === '2.0' || result.data.data.version === '2.1') {
            // Calculate Stats
            setTotalSetsCompleted(CalculateTotalSetsCompleted(result.data.data.workoutData.phases))
            setPercentageOfSetsCompleted(CalculatePercentageOfSetsCompleted(result.data.data.workoutData.phases))

            // Set total volume completed
            var totalVolumeCompleted = result.data.data.totalVolumeCompleted
            if (measurementSystem === 'metric') totalVolumeCompleted = ConvertPoundsToNearestFiveKilograms(totalVolumeCompleted)
            setTotalVolumeLifted(totalVolumeCompleted)

            // Set total reps completed
            setTotalRepsCompleted(result.data.data.totalRepsCompleted)

            var temp_expandPhaseJSON = {}

            // Create JSON object for controlling the expand/collapse of each workout phase
            result.data.data.workoutData.phases.map((phase) => {
                temp_expandPhaseJSON[phase.phaseKey] = false
            })

            // console.log(temp_expandPhaseJSON)

            setExpandPhaseJSON(temp_expandPhaseJSON)
        }

        setCompletedWorkoutData(result.data.data)
    }

    const OnBackButtonPressed = () => {
        if (doneNavigation === 'home') navigate('/home')
        else if (doneNavigation === 'back') navigate(-1)
        else navigate(-1)
    }

    const CalculateTotalSetsCompleted = (phases) => {
        var totalSetsCompleted = 0

        phases.map((phase) => {
            phase.exercises.map((exercise) => {
                exercise.sets.map((set) => {
                    if (set.completedSetData.completed) totalSetsCompleted = totalSetsCompleted + 1
                })
            })
        })

        return totalSetsCompleted
    }

    const CalculatePercentageOfSetsCompleted = (phases) => {
        var totalSets = 0
        var totalSetsCompleted = 0

        phases.map((phase) => {
            phase.exercises.map((exercise) => {
                exercise.sets.map((set) => {
                    totalSets = totalSets + 1

                    if (set.completedSetData.completed) totalSetsCompleted = totalSetsCompleted + 1
                })
            })
        })

        return Math.round((totalSetsCompleted / totalSets) * 100)
    }

    const OnTogglePhaseExpand = (phaseKey) => {
        var temp_expandPhaseJSON = JSON.parse(JSON.stringify(expandPhaseJSON))

        temp_expandPhaseJSON[phaseKey] = !temp_expandPhaseJSON[phaseKey]

        setExpandPhaseJSON(temp_expandPhaseJSON)
    }

    useEffect(() => {
        GetCompletedWorkoutDataFromBackend()
    }, [])

    return (
        <>
            {completedWorkoutData === null && <MediumLoadingComponent />}

            {completedWorkoutData !== null && (
                <>
                    {/* For version 1.0 files */}
                    {completedWorkoutData.version === '1.0' && (
                        <>
                            {/* Header */}
                            <CustomHeader text="Workout Summary" backButtonAction={() => OnBackButtonPressed()} />

                            {completedWorkoutData.workoutData.length === 0 && <div className="p-4 text-center">No Sets Completed For this Workout</div>}

                            {completedWorkoutData.workoutData.length > 0 && (
                                <div className="p-2">
                                    {completedWorkoutData.workoutData.map((exerciseSetRecord, exerciseSetRecordIndex) => (
                                        <WorkoutSummaryExerciseSetListItem
                                            key={exerciseSetRecordIndex}
                                            completed={true}
                                            exerciseId={exerciseSetRecord.exerciseId}
                                            exerciseName={exerciseSetRecord.exerciseName}
                                            exerciseType={exerciseSetRecord.exerciseType}
                                            setIndex={exerciseSetRecord.currentSet - 1}
                                            setsAssigned={exerciseSetRecord.setsAssigned}
                                            setUnit={exerciseSetRecord.setUnit}
                                            repsAssigned={exerciseSetRecord.setUnitsAssigned}
                                            setUnitsCompleted={exerciseSetRecord.setUnitsCompleted}
                                            weightRecommendation={exerciseSetRecord.weightRecommendation}
                                            weightRecommendationRounded={exerciseSetRecord.weightRecommendationRounded}
                                            movementDifficulty={exerciseSetRecord.movementDifficulty || 0}
                                            difficulty={exerciseSetRecord.difficulty}
                                        />
                                    ))}
                                </div>
                            )}
                        </>
                    )}

                    {/* For version 2.x files */}
                    {(completedWorkoutData.version === '2.0' || completedWorkoutData.version === '2.1') && (
                        <>
                            {/* Header */}
                            <CustomHeader text={completedWorkoutData.workoutData.name} backButtonAction={() => OnBackButtonPressed()} />

                            <div className="p-4 space-y-4">
                                {/* Sets Data */}
                                <div>
                                    <div className="pl-2 pb-2 text-center text-lg">Total Sets Completed: {totalSetsCompleted}</div>

                                    <LinearDial
                                        fillPercentage={percentageOfSetsCompleted}
                                        fillLabel={percentageOfSetsCompleted + '% Completed'}
                                        emptyLabel={100 - percentageOfSetsCompleted + '% Discarded'}
                                    />
                                </div>

                                {/* Stats Horizontal Scroll */}
                                <div className="overflow-x-scroll whitespace-nowrap pb-2">
                                    {/* Total Volume Lifted */}
                                    <div className="inline-block p-4 border border-gray-400 rounded-lg mr-4">
                                        <div className="flex items-center gap-x-4">
                                            <div 
                                                className="p-2 w-10 h-10 rounded-lg"
                                                style={{ backgroundColor: GetPrimaryColorHex(styleConfig) }}
                                            >
                                                <CircleStackIcon className="h-6 w-6 text-white" />
                                            </div>

                                            <div className="w-40">
                                                <div className="font-semibold">
                                                    {totalVolumeLifted} {measurementSystem === 'imperial' ? 'lbs' : 'kgs'}
                                                </div>
                                                <div className="text-xs text-gray-600">Total Volume Lifted</div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Total Reps Completed */}
                                    <div className="inline-block p-4 border border-gray-400 rounded-lg">
                                        <div className="flex items-center gap-x-4">
                                            <div 
                                                className="p-2 w-10 h-10 rounded-lg"
                                                style={{ backgroundColor: GetPrimaryColorHex(styleConfig) }}
                                            >
                                                <ChartBarIcon className="h-6 w-6 text-white" />
                                            </div>

                                            <div className="w-40">
                                                <div className="font-semibold">{totalRepsCompleted}</div>
                                                <div className="text-xs text-gray-600">Total Reps Completed</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Loop over each phase */}
                                {completedWorkoutData.workoutData.phases.map((phase) => (
                                    <div key={phase.phaseKey} className="border border-gray-400 rounded-lg overflow-hidden">
                                        {/* Phase Header */}
                                        <div className="py-2 px-3 flex items-center bg-gray-100" onClick={() => OnTogglePhaseExpand(phase.phaseKey)}>
                                            <div className="text-lg font-semibold flex-grow">{phase.name}</div>

                                            {!expandPhaseJSON[phase.phaseKey] && <ChevronDownIcon className="h-5" />}
                                            {expandPhaseJSON[phase.phaseKey] && <ChevronUpIcon className="h-5" />}
                                        </div>

                                        {/* CIRCUIT Phase Type */}
                                        {expandPhaseJSON[phase.phaseKey] && phase.type === 'circuit' && (
                                            <>
                                                {Array.from({ length: phase.circuitRounds }).map((_, setIndex) => (
                                                    <div key={setIndex} className={`${setIndex < phase.circuitRounds - 1 ? 'border-b border-gray-400' : ''}`}>
                                                        {phase.exercises.map((exercise, exerciseIndex) => (
                                                            <div key={exerciseIndex} className={`${exerciseIndex < phase.exercises.length - 1 ? 'border-b border-gray-400' : ''}`}>

                                                                {/* <pre>{JSON.stringify(exercise.sets[setIndex].completedSetData, null, 2)}</pre> */}
                                                                
                                                                <WorkoutSummaryExerciseSetListItem
                                                                    completed={exercise.sets[setIndex].completedSetData.completed}
                                                                    exerciseId={exercise.id}
                                                                    exerciseName={exercise.name}
                                                                    exerciseType={exercise.exerciseType}
                                                                    setIndex={setIndex}
                                                                    setsAssigned={phase.circuitRounds}
                                                                    setUnit={exercise.setUnit}
                                                                    repsAssigned={exercise.sets[setIndex].reps}
                                                                    setUnitsCompleted={exercise.sets[setIndex].completedSetData.setUnitsCompleted}
                                                                    weightRecommendation={exercise.sets[setIndex].recommendationData.weightRecommendation}
                                                                    weightRecommendationRounded={exercise.sets[setIndex].recommendationData.weightRecommendationRounded}
                                                                    movementDifficulty={exercise.sets[setIndex].completedSetData.movementDifficulty || 0}
                                                                    difficulty={exercise.sets[setIndex].completedSetData.difficulty}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </>
                                        )}

                                        {/* STRAIGHT Phase Type */}
                                        {expandPhaseJSON[phase.phaseKey] && phase.type === 'straight' && (
                                            <div>
                                                {/* Exercises */}
                                                {phase.exercises.map((exercise, exerciseIndex) => (
                                                    <div key={exerciseIndex} className={`${exerciseIndex < phase.exercises.length - 1 ? 'border-b border-gray-400' : ''}`}>
                                                        {exercise.sets.map((set, setIndex) => {
                                                            <WorkoutSummaryExerciseSetListItem
                                                                completed={set.completedSetData.completed}
                                                                exerciseId={exercise.id}
                                                                exerciseName={exercise.name}
                                                                exerciseType={exercise.exerciseType}
                                                                setIndex={setIndex}
                                                                setsAssigned={exercise.sets.length}
                                                                setUnit={exercise.setUnit}
                                                                repsAssigned={set.reps}
                                                                setUnitsCompleted={set.completedSetData.setUnitsCompleted}
                                                                weightRecommendation={set.recommendationData.weightRecommendation}
                                                                weightRecommendationRounded={set.recommendationData.weightRecommendationRounded}
                                                                movementDifficulty={set.completedSetData.movementDifficulty || 0}
                                                                difficulty={set.completedSetData.difficulty}
                                                            />
                                                        })}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}

                    {/* Done Button */}
                    <div className="px-4 pb-8">
                        <LoadingButton
                            buttonText="Done"
                            className="w-full flex justify-center items-center gap-x-4 bg-white rounded-full font-semibold py-2 border border-gray-400"
                            backgroundColor={'#fafafa'}
                            action={() => navigate('/')}
                        />
                    </div>
                </>
            )}
        </>
    )
}
