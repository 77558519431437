import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { GetPrimaryColorHex } from 'redux/slices/brand'
import moment from 'moment'

export default function ExerciseWeightChart({ completedExericseRecords, exerciseType }) {
    
    const styleConfig = useSelector((state) => state.brand.styleConfig)

    const [chartData, setChartData] = useState([])

    const ReverseOrderOfTimestamps = (data) => {
        return data.sort((a, b) => moment(a.completeDate) - moment(b.completeDate))
    }

    const InitializeChartData = () => {
        var eightRecords = []

        // If there are 8 or more records to display, just grab the 8 most recent from the records to display
        if (8 <= completedExericseRecords.length) eightRecords = completedExericseRecords.slice(0, 8)

        // If there are less than 8 values, fill in empty slots with dummy data
        if (completedExericseRecords.length < 8) {
            // First set what values are in there
            eightRecords = completedExericseRecords

            // While we still don't have eight records
            while (eightRecords.length < 8) {
                // Copy last value in array and make is a dummy record
                var dummyRecord = JSON.parse(JSON.stringify(eightRecords[eightRecords.length - 1]))

                dummyRecord.exerciseId = 0
                dummyRecord.exerciseName = 'Dummy Record'
                dummyRecord.weightRecommendationRounded = 0
                dummyRecord.weightRecommendation = 0
                dummyRecord.setUnitsCompleted = 0
                dummyRecord.setUnitsAssigned = 0
                dummyRecord.completeDate = '2024-01-01'

                // Add to end of array
                eightRecords.push(dummyRecord)
            }
        }

        // console.log(ReverseOrderOfTimestamps(eightRecords))

        // Sort data before setting state so graph goes up and to the right
        setChartData(ReverseOrderOfTimestamps(eightRecords))
    }

    useEffect(() => {
        InitializeChartData()
    }, [])

    return (
        <ResponsiveContainer width="100%" height={400}>
            <AreaChart data={chartData} margin={{ top: 0, right: 4, left: -20, bottom: 4 }}>
                <CartesianGrid strokeDasharray="3 3" />

                {/* Axes */}
                <XAxis 
                    dataKey="completeDate" 
                    angle={-45} 
                    textAnchor="end" 
                    tickFormatter={(value) => moment(value).format('MMM DD')} 
                />

                <YAxis>{/* <Label value={xlabel} angle={-90} position="insideLeft" /> */}</YAxis>

                <Tooltip labelFormatter={(value) => moment(value).format('MMM DD, YYYY h:mm A')} />

                <Legend wrapperStyle={{ paddingTop: '30px' }} />

                {/* Weight Plot */}
                {exerciseType === 'recommendation' && 
                    <Area
                        type="monotone" 
                        dataKey="weightRecommendationRounded" 
                        stroke={GetPrimaryColorHex(styleConfig)} 
                        fill={GetPrimaryColorHex(styleConfig,-2)}
                        name={'Weight'} 
                    />
                }

                {/* Reps Completed Plot */}
                <Area 
                    type="monotone" 
                    dataKey="setUnitsCompleted" 
                    stroke="#f84471"
                    fill="#ffc8d8"
                    name={'Reps Completed'} 
                />

                {/* Reps Assigned Plot 
                <Line 
                    type="monotone" 
                    dataKey="setUnitsAssigned" 
                    stroke="#FFA500" 
                    name={"Reps Assigned"}  
                />
                */}
            </AreaChart>
        </ResponsiveContainer>
    )
}
