import { WhichWeightAPI } from "WhichWeightAPI";

export const IsLoadDifficultyFeedbackEnabledForExercise = (exercise) => {

    return (
        exercise.setUnit === "reps" &&
        exercise.exerciseType === "recommendation" && 
        !exercise.continuousOverride
    )

}

export const GetSetWeightRecommendation = async (
    dispatch, 
    userId, 
    activeWorkout, 
    phaseIndex, 
    exerciseIndex, 
    setIndex, 
    dispatchFunction
) => {
    
    const exercise = activeWorkout.workoutTemplate.phases[phaseIndex].exercises[exerciseIndex]
    const set = exercise.sets[setIndex]

    // Initial set data
    // Defaults to bodyweight object
    const incompleteSetInitialData = {
        phaseIndex: phaseIndex, 
        exerciseIndex: exerciseIndex, 
        setIndex: setIndex,
        weightRecommendation: 0,
        weightRecommendationRounded: 0,
        formulaTag: "bodyweight",
        equipmentDetails: null
    }

    // Initial data for continuous override exercise
    if (exercise.continuousOverride){
        incompleteSetInitialData.weightRecommendation = exercise.continuousOverrideWeight
        incompleteSetInitialData.weightRecommendationRounded = exercise.continuousOverrideWeight
        incompleteSetInitialData.formulaTag = "continuous-override"
    }

    // Initial data for recommendation exercises
    if (!exercise.continuousOverride && exercise.exerciseType === "recommendation"){

        const jsonBody = { 
            traineeId: userId,
            exerciseId: exercise.id,
            reps: set.reps,
            taper: activeWorkout.taperData.useTaper ? 1 : 0,
            taperDampener: activeWorkout.taperData.taperDampener
        }

        // console.log(jsonBody);

        const result = await WhichWeightAPI("RECOMMENDATION", "GET", "/weight_recommendation", jsonBody);

        // console.log(result.data.data);
        
        // TODO ERROR HANDLING

        // Set values from the backend
        incompleteSetInitialData.weightRecommendation = result.data.data.weightRecommendation
        incompleteSetInitialData.weightRecommendationRounded = result.data.data.weightRecommendationRounded
        incompleteSetInitialData.formulaTag = result.data.data.formulaTag
        incompleteSetInitialData.equipmentDetails = result.data.data.denominationDetails

    }

    // Send incomplete set initial data to store
    dispatch(dispatchFunction(incompleteSetInitialData));

}

export const InitAssessmentSetWeightRecommendation = async (
    dispatch, 
    activeAssessment, 
    phaseIndex, 
    exerciseIndex, 
    setIndex, 
    dispatchFunction
) => {
    
    const exercise = activeAssessment.workoutTemplate.phases[phaseIndex].exercises[exerciseIndex]

    // Initial set data
    // Defaults to bodyweight object
    const incompleteSetInitialData = {
        phaseIndex: phaseIndex, 
        exerciseIndex: exerciseIndex, 
        setIndex: setIndex,
        weightRecommendation: 0,
        weightRecommendationRounded: 0,
        formulaTag: "bodyweight",
        equipmentDetails: null
    }

    // Initial data for continuous override exercise
    if (exercise.continuousOverride){
        incompleteSetInitialData.weightRecommendation = exercise.continuousOverrideWeight
        incompleteSetInitialData.weightRecommendationRounded = exercise.continuousOverrideWeight
        incompleteSetInitialData.formulaTag = "continuous-override"
    }

    // Send incomplete set initial data to store
    dispatch(dispatchFunction(incompleteSetInitialData));

}

export const AutoOverrideRemainingSets = (
    dispatch, 
    phaseIndex, 
    exerciseIndex, 
    exercise, 
    override, 
    currentSetIndex,
    dispatchFunction
) => {

    // Loop over all sets
    exercise.sets.map((set, setIndex) => {

        // For all incomplete sets
        if (!set.completedSetData.completed && parseInt(setIndex) !== parseInt(currentSetIndex)) {

            // Auto-override the next sets for that exercise
            const overrideInfo = {
                phaseIndex: parseInt(phaseIndex),
                exerciseIndex: parseInt(exerciseIndex),
                setIndex: setIndex,
                weightUpdate: override * 5.0,
                weightUpdateRounded: override * 5,
                override: override
            }
            
            // updateActiveWorkoutExerciseSetWeightOverride
            dispatch(dispatchFunction(overrideInfo))

        }
    })

}