import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { addProgramAssignmentEvent } from 'redux/slices/programAssignment'
import moment from 'moment'

import ScreenFrame from 'components/Frame/ScreenFrame'
import LogoHeader from 'components/Header/LogoHeader'
import BodyFrame from 'components/Frame/BodyFrame'
import NextButton from 'components/Onboarding/NextButton'

export default function ProgramAssignmentLengthSelection() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Event training start week
    const [eventTrainingStartWeekOffset, setEventTrainingStartWeekOffset] = useState(0)
    const handleWeekOffsetChange = (event) => {
        setEventTrainingStartWeekOffset(parseInt(event.target.value))
    }

    // Event training start week
    const [programLength, setProgramLength] = useState(8)
    const handleProgramLengthChange = (event) => {
        setProgramLength(parseInt(event.target.value))
    }

    const OnSaveEventButton = () => {
        const newEvent = {
            id: 1,
            name: 'Default Event',
            eventType: 'a-race',
            eventTerrain: 'other',
            eventTerrainTextInput: 'default-event',
            eventDistance: 'other',
            eventDistanceTextInput: 'default-event',
            eventTrainingStartDate: moment.utc().local().add(eventTrainingStartWeekOffset, 'isoWeeks').startOf('isoWeek').format('YYYY-MM-DD'),
            // Adding minus one here because we do not need training on the week of event
            eventDate: moment
                .utc()
                .local()
                .add(eventTrainingStartWeekOffset + programLength - 1, 'isoWeeks')
                .endOf('isoWeek')
                .format('YYYY-MM-DD'),
        }

        // console.log(newEvent); return;

        dispatch(addProgramAssignmentEvent(newEvent))

        navigate('/program/assignment/loading')
    }

    return (
        <ScreenFrame>

            <LogoHeader backButtonAction={() => navigate('/program/assignment/events')} />

            <BodyFrame>

                <div className='text-center py-2'>If you do not have any upcoming events,<br/> you can select a program length below</div>

                {/* Event Start Week */}
                <div className="space-y-1">
                    <div className="pl-2 text-sm text-gray-600">Event Training Start Week</div>

                    <div className="pb-2 pl-2 text-xs text-gray-400">Which week would you like to begin your strength programming?</div>

                    <select
                        id="startWeek"
                        value={eventTrainingStartWeekOffset}
                        onChange={handleWeekOffsetChange}
                        className="w-full px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                    >
                        {Array.from({ length: 7 }, (_, i) => i).map((i) => (
                            <option key={i} value={i}>
                                {moment.utc().local().add(i, 'isoWeeks').startOf('isoWeek').format('MMMM D')} - {moment.utc().local().add(i, 'isoWeeks').endOf('isoWeek').format('MMMM D')}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Program Length */}
                <div className="space-y-2">
                    <div className="pl-2 text-sm text-gray-600">Program Length</div>

                    <select
                        id="programLength"
                        value={programLength}
                        onChange={handleProgramLengthChange}
                        className="w-full px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                    >
                        <option value={8}>8 weeks</option>
                        <option value={12}>12 weeks</option>
                        <option value={16}>16 weeks</option>
                        <option value={24}>24 weeks</option>
                        <option value={32}>32 weeks</option>
                    </select>
                </div>
                
                <div className='mt-auto'>
                    <NextButton text="Next" action={() => OnSaveEventButton()} />
                </div>

            </BodyFrame>
        </ScreenFrame>
    )
}
