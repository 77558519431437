import { createSlice } from '@reduxjs/toolkit'

// Initial state
const initialState =  {
    id: null
}

// View Workout Slice
const viewWorkoutSlice = createSlice({
  name: 'viewWorkout',
  initialState,
  reducers: {
    setViewWorkoutId: (state, action) => {
      state.id = action.payload;
    }
  },
});

export const { setViewWorkoutId } = viewWorkoutSlice.actions;

export default viewWorkoutSlice.reducer;