import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'

import LoadingButton from 'components/LoadingButton'

export default function PaymentMethod({ titleText = '', subTitleText = '', buttonText = '', PostCreationAction, buttonLoading, setButtonLoading, errorMessage, setErrorMessage }) {
    const stripe = useStripe()
    const elements = useElements()

    const HandlePaymentFormSubmit = async (event) => {
        event.preventDefault()

        if (!stripe || !elements) return

        setButtonLoading(true)
        setErrorMessage('')

        // Create the payment method in stripe

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        })

        if (error) {
            console.error(error)
            setButtonLoading(false)
            setErrorMessage('Could not save billing method. ' + error.message + ' Please try again. If issue persists please reach out to support')
            return
        }

        PostCreationAction(paymentMethod)
    }

    return (
        <div className="p-4 space-y-4">
            {/* Description Texts */}
            <div className="space-y-2 text-center">
                {titleText.length > 0 && <div className="text-center text-xl font-semibold">{titleText}</div>}
                {subTitleText.length > 0 && <div className="text-gray-600">{subTitleText}</div>}
            </div>

            {/* Card Element */}
            <div className="border p-4 rounded-lg">
                <CardElement />
            </div>

            {/* Error Message */}
            {errorMessage.length > 0 && <div className="text font-semibold text-red-400">Error: {errorMessage}</div>}

            {/* Purchase Button */}
            {stripe && (
                <LoadingButton
                    buttonText={buttonText}
                    className="w-full flex justify-center gap-x-4 items-center text-center text-white rounded-full py-2 px-4 text-lg font-semibold bg-gradient-to-r from-orange-600 to-orange-400"
                    isLoading={buttonLoading}
                    action={(event) => HandlePaymentFormSubmit(event)}
                />
            )}
        </div>
    )
}
