import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

// Helper function for getting initial selectedWeekDayIndex
const GetInitialISOWeekDayIndex = () => {

    var normalDayIndex = parseInt(moment.utc().local().format('d'))

    if (normalDayIndex === 0) normalDayIndex = 6
    else normalDayIndex = normalDayIndex - 1

    return normalDayIndex

}

// Initial state
const initialState = {
    sidebarOpen: false,
    weeklyCalendarWeekOffset: 0,
    selectedWeekDayIndex: GetInitialISOWeekDayIndex()
}

// User Interface Slice
const userInterfaceSlice = createSlice({
  name: 'userInferface',
  initialState,
  reducers: {
    setSidebarOpen: (state, action) => { state.sidebarOpen = action.payload; },
    setWeeklyCalendarWeekOffset: (state, action) => { state.weeklyCalendarWeekOffset = action.payload; },
    setSelectedWeekDayIndex: (state, action) => { state.selectedWeekDayIndex = action.payload; },
    resetUserInterfaceState: () => initialState
  },
});

export const { 
  setSidebarOpen,
  setWeeklyCalendarWeekOffset,
  setSelectedWeekDayIndex,
  resetUserInterfaceState 
} = userInterfaceSlice.actions;

export default userInterfaceSlice.reducer;