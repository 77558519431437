export default function Amount({ subscriptionData }) {
    if (subscriptionData.status === 'unpaid') return <></>

    if (!subscriptionData.cancel_at_period_end) {
        return (
            <div className="flex border-b py-3">
                <div className="pl-2 flex-grow text-gray-600">Amount</div>

                {subscriptionData.discounts.length === 0 && <>${subscriptionData.plan.amount / 100}</>}

                {/* TODO Add support for percentage coupons */}
                {subscriptionData.discounts.length === 1 && <>${(subscriptionData.plan.amount - subscriptionData.discount.coupon.amount_off) / 100}</>}
            </div>
        )
    }
}
