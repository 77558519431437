import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedWeekDayIndex } from 'redux/slices/userInterface'
import { IsActiveWorkoutSliceInInitialState } from 'redux/slices/activeWorkout'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { WorkoutTemplateNames, GetWeekDayWorkoutIcon } from 'functions/weeklyCalendarUtils'
import { GetPresentWeekOverview } from 'redux/selectors/PresentWeekOverviewSelector'
import { WeekOfTheYearKey } from 'classes/WeekOfTheYearKey'
import { GetPrimaryColorHex } from 'redux/slices/brand'
import moment from 'moment'

import WeeklyProgress from './WeeklyProgress'

export default function WeeklyWorkouts() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { ShowModal } = useContext(ModalContext)

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const presentWeekOfTheYearKey = useSelector((state) => state.user.presentWeekOfTheYearKey)
    const presentWeek = GetPresentWeekOverview()

    const selectedWeekDayIndex = useSelector((state) => state.userInterface.selectedWeekDayIndex)
    const membershipActive = useSelector((state) => state.user.membershipActive)
    const activeWorkout = useSelector((state) => state.activeWorkout)

    const races = presentWeek ? presentWeek.races : []
    const aRace = races.find((race) => race.eventType === 'a-race')
    const daysTilARaceProgramStarts = aRace ? moment(aRace.eventTrainingStartDate).diff(moment(), 'days') : null

    const OnMoveWorkoutButton = () => {
        const modalData = {
            key: ModalKey.MoveWorkoutWeekDay,
            fullscreen: false,
        }

        ShowModal(modalData)
    }

    const OnStartWorkoutButton = () => {
        // Membership check here
        if (!membershipActive) {
            const modalData = {
                key: ModalKey.ActivateMembership,
                fullscreen: false,
            }

            ShowModal(modalData)

            return
        }

        // Check to make sure no other workouts are active
        if (!IsActiveWorkoutSliceInInitialState(activeWorkout)) {
            const modalData = {
                key: ModalKey.Text,
                fullscreen: false,
                titleText: 'Unable to Start New Workout',
                bodyText: [
                    'Please complete any active workouts before starting a new one.',
                    'You should have to option to either resume or end your current active workout from the home screen',
                    'Please contact support@whichweight.com with issues',
                ],
            }

            ShowModal(modalData)

            return
        }

        const props = {
            state: {
                weekOfTheYearKey: presentWeek.weekOfTheYearKey,
                taperData: presentWeek.taperData,
                workoutTemplateKey: presentWeek.weekDaySchedule[selectedWeekDayIndex],
            },
        }

        navigate('/active/workout/preview', props)
    }

    const OnViewWorkoutButton = (workoutTemplateKey) => {
        const props = {
            state: {
                weekOfTheYearKey: presentWeek.weekOfTheYearKey,
                workoutTemplateKey: workoutTemplateKey,
                doneNavigation: 'home',
            },
        }

        navigate('/workout/summary', props)
    }

    if (presentWeek === null) return <></>

    return (
        <div className="px-4 pb-8 space-y-2">
            
            <div className="pt-2 text-center text-lg font-semibold">
                {new WeekOfTheYearKey(presentWeekOfTheYearKey).toDateRangeString('MMMM Do')}
            </div>

            {presentWeek.programWeekKey === 'error' && (
                <div className="py-6 px-4 space-y-2 text-center border border-gray-600 rounded-xl">
                    <ExclamationTriangleIcon className="mx-auto h-16 text-orange-400" />

                    <div className="text-xl font-semibold text-gray-600">Program Error</div>

                    <div className="text-gray-600">
                        There was an error generating this weeks programming. Please try restarting the app and logging back in. If issues persist please contact our support team at
                        support@whichweight.com
                    </div>
                </div>
            )}

            {presentWeek.programWeekKey !== 'error' && (
                <>
                    {/* Show workout selector if week has workout templates */}
                    {presentWeek.calendarDataKey !== 'empty' && (
                        <>
                            <WeeklyProgress />

                            <div className="grid grid-cols-7 text-center">
                                {Array.from({ length: 7 }, (_, i) => i).map((dayOfTheWeekIndex) => (
                                    <Fragment key={dayOfTheWeekIndex}>
                                        {/* Unselected day of the week square */}
                                        {dayOfTheWeekIndex !== selectedWeekDayIndex && (
                                            <div className="pt-2" onClick={() => dispatch(setSelectedWeekDayIndex(dayOfTheWeekIndex))}>
                                                <div className="text-xs font-semibold text-gray-900">{moment.utc().local().startOf('isoWeek').add(dayOfTheWeekIndex, 'days').format('ddd')}</div>
                                                <div className="text-xs text-gray-600 mb-2">{moment.utc().local().startOf('isoWeek').add(dayOfTheWeekIndex, 'days').format('DD')}</div>
                                                <div className={`py-2 bg-zinc-100 ${dayOfTheWeekIndex === 0 ? 'rounded-l-lg' : dayOfTheWeekIndex === 6 ? 'rounded-r-lg' : ''}`}>
                                                    <img src={GetWeekDayWorkoutIcon(presentWeek, dayOfTheWeekIndex)} className="mt-1 h-6 mx-auto text-gray-400" />
                                                </div>
                                            </div>
                                        )}

                                        {/* Selected day of the week square */}
                                        {dayOfTheWeekIndex === selectedWeekDayIndex && (
                                            <div 
                                                className='mx-0.5 rounded-t-lg pt-2'
                                                style={{
                                                    backgroundColor: GetPrimaryColorHex(styleConfig)
                                                }}
                                            >
                                                <div className="text-xs text-white">{moment.utc().local().startOf('isoWeek').add(dayOfTheWeekIndex, 'days').format('ddd')}</div>
                                                <div className="text-white font-bold text-xs mb-2">{moment.utc().local().startOf('isoWeek').add(dayOfTheWeekIndex, 'days').format('DD')}</div>
                                                <div className="py-2 rounded-lg">
                                                    <img src={GetWeekDayWorkoutIcon(presentWeek, dayOfTheWeekIndex)} className="mt-1 h-6 mx-auto filter invert" />
                                                </div>
                                            </div>
                                        )}
                                    </Fragment>
                                ))}
                            </div>

                            {/* Workout Card */}
                            <div className="p-4 h-[136px] border border-zinc-600 rounded-xl">
                                {/* Header */}
                                <div className="flex items-center justify-center gap-x-2">
                                    {/* Workout Icon */}
                                    <img src={GetWeekDayWorkoutIcon(presentWeek, selectedWeekDayIndex)} className="h-8 mr-2" />

                                    {/* Workout Title */}
                                    <div className="text-center text-2xl font-semibold">{WorkoutTemplateNames[presentWeek.weekDaySchedule[selectedWeekDayIndex]]}</div>
                                </div>

                                {/* Action Button */}
                                {(presentWeek.weekDaySchedule[selectedWeekDayIndex].includes('strength') || presentWeek.weekDaySchedule[selectedWeekDayIndex].includes('mobility')) && (
                                    <div className="mt-5">
                                        {/* View Button */}
                                        {presentWeek.completionData[presentWeek.weekDaySchedule[selectedWeekDayIndex]].completed && (
                                            <div className="py-2 px-8 text-center rounded-full bg-zinc-100" onClick={() => OnViewWorkoutButton(presentWeek.weekDaySchedule[selectedWeekDayIndex])}>
                                                View Workout
                                            </div>
                                        )}

                                        {/* Start Button */}
                                        {!presentWeek.completionData[presentWeek.weekDaySchedule[selectedWeekDayIndex]].completed && (
                                            <div className="flex flex-col space-y-4">
                                                <div className="flex space-x-2">
                                                    {/* Quick Start */}
                                                    <button
                                                        type="button"
                                                        className='w-full text-center text-white rounded-full py-2 px-4 text-lg font-semibold'
                                                        style={{
                                                            backgroundImage: `linear-gradient(to right, ${GetPrimaryColorHex(styleConfig, 2)}, ${GetPrimaryColorHex(styleConfig)})`
                                                        }}
                                                        onClick={() => OnStartWorkoutButton()}
                                                    >
                                                        Start Workout
                                                    </button>

                                                    {/* Workout Preferences button 
                                                        <button
                                                            type="button"
                                                            className="w-1/6 text-center text-white rounded-full py-2 px-4 text-lg font-semibold bg-zinc-100 flex items-center justify-center"
                                                            onClick={() => OnStartWorkoutButton()}
                                                        >
                                                            <AdjustmentsHorizontalIcon className="w-6 h-6 text-zinc-500" />
                                                        </button>
                                                    */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {/* Rest with option to move workout */}
                                {presentWeek.weekDaySchedule[selectedWeekDayIndex].includes('rest') && (
                                    <div className="text-center space-y-1 mt-1">
                                        <div className="text-sm text-gray-600">Want to do a workout on this day?</div>

                                        <div className="py-2 px-8 text-center rounded-full bg-zinc-100" onClick={() => OnMoveWorkoutButton()}>
                                            Move a Workout Here
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {/* Show message if week does not have workout templates */}
                    {presentWeek.calendarDataKey === 'empty' && 
                        <div 
                            className="border border-gray-400 text-gray-600 text-center px-4 py-4 space-y-4 rounded-xl"
                            onClick={() => navigate('/events')}
                        >
                            <div className="font-semibold">No workouts this week</div>
                            <div className="text-lg px-2">Your program for {aRace.name} is set to begin in {daysTilARaceProgramStarts} days</div>
                            <div className="border border-gray-400 py-2 rounded-full text-center">
                                Add A New Event
                            </div>
                        </div>
                    }
                </>
            )}
        </div>
    )
}
