import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { ModalProvider } from 'components/Modals/ModalContext'
import ModalOverlay from 'components/Modals/ModalOverlay'

import Login from 'views/PublicViews/Login'

import WelcomeScreen from 'views/Onboarding/0_WelcomeScreen'
import PersonalInfoInputView from 'views/Onboarding/1_PersonalInfoInputView'
import SportInputView from 'views/Onboarding/2_SportInputView'
import DaysOfTrainingInputView from 'views/Onboarding/3_DaysOfTrainingInputView'
import MonthsOfTrainingInputView from 'views/Onboarding/4_MonthsOfTrainingInputView'
import YearsOfTrainingInputView from 'views/Onboarding/5_YearsOfTrainingInputView'
import WeekDayScheduleInputView from 'views/Onboarding/6_WeekDayScheduleInputView'
import FitnessProfileReview from 'views/Onboarding/7_FitnessProfileReview'
import OnboardingAssessmentPreview from 'views/Onboarding/8_OnboardingAssessmentPreview'

import ProgramAssignmentIntro from 'views/ProgramAssignment/1_ProgramAssignmentIntro'
import ProgramAssignmentEvents from 'views/ProgramAssignment/2a_ProgramAssignmentEvents'
import ProgramAssignmentAddEvent from 'views/ProgramAssignment/2b_ProgramAssignmentAddEvent'
import ProgramAssignmentLengthSelection from 'views/ProgramAssignment/2c_ProgramAssignmentLengthSelection'
import ProgramAssignmentLoading from 'views/ProgramAssignment/3_ProgramAssignmentLoading'
import ProgramAssignmentOverview from 'views/ProgramAssignment/4_ProgramAssignmentOverview'

import NewFitnessProfileVariablesInput from './Onboarding/NewFitnessProfileVariablesInput'

import Home from 'views/Home'

import AssessmentList from 'views/AthleteAssessment/AssessmentList'
import AssessmentPreview from 'views/AthleteAssessment/AssessmentPreview'
import AssessmentWorkout from 'views/AthleteAssessment/AssessmentWorkout'
import CompletedAssessmentSummary from 'views/CompletedWorkouts/CompletedAssessmentSummary'

import ProgramOverview from 'views/Program/ProgramOverview'

import ActiveWorkoutPreview from 'views/ActiveWorkouts/ActiveWorkoutPreview'
import ActiveWorkout from 'views/ActiveWorkouts/ActiveWorkout'

import CompletedWorkoutSummary from 'views/CompletedWorkouts/CompletedWorkoutSummary'
import Reports from 'views/CompletedWorkouts/Reports'
import ExerciseWeightHistory from 'views/CompletedWorkouts/ExerciseWeightHistory'

import JoinOrganization from 'views/UserProfile/JoinOrganization'

import Membership from 'views/UserProfile/SubscriptionManagement/Membership'

import SubscriptionOptions from 'views/UserProfile/SubscriptionManagement/SubscriptionOptions'
import PaymentMethodWrapper from 'views/UserProfile/SubscriptionManagement/PaymentMethodWrapper'
import CheckoutWrapper from 'views/UserProfile/SubscriptionManagement/CheckoutWrapper'
import SubscriptionPurchaseSuccess from 'views/UserProfile/SubscriptionManagement/SubscriptionPurchaseSuccess'

import UpdatePaymentMethod from 'views/UserProfile/SubscriptionManagement/UpdatePaymentMethod'
import SubscriptionCancel from 'views/UserProfile/SubscriptionManagement/SubscriptionCancel'

import Events from 'views/Events/Events'
import AddEvent from 'views/Events/AddEvent'

import EditWeeklySchedule from 'views/WeeklySchedule/EditWeeklySchedule'

import ViewFitnessProfile from 'views/UserProfile/ViewFitnessProfile'
import EditFitnessProfile from 'views/UserProfile/EditFitnessProfile'

import EquipmentView from 'views/Equipment/EquipmentView'

import ExerciseLibrary from 'views/Exercises/ExerciseLibrary'

import EmailPreferences from 'views/UserProfile/EmailPreferences'

import HelpCenter from 'views/UserProfile/HelpCenter'
import FrequentlyAskedQuestions from 'views/HelpCenter/FrequentlyAskedQuestions'
import AddToHomeScreen from 'views/HelpCenter/AddToHomeScreen'
import ContactSupport from 'views/HelpCenter/ContactSupport'

// import TestModal from 'views/Testing/TestModal'

import RouteNotFoundView from 'views/RouteNotFoundView'

function TraineeApp({
    viewGroup="authenticated-user"
}) {
    return (
        <BrowserRouter>
            <ModalProvider>
                <Routes>

                    {viewGroup === "public-user" && (
                        <>

                            <Route path="/" element={<Login />} />
                            <Route path="/home" element={<Login />} />
                            <Route path="/login" element={<Login />} />

                            <Route path="*" element={<Login />} />

                        </>
                    )}

                    {viewGroup === "authenticated-user" && (
                        <>
                            <Route path="/onboarding/welcome" element={<WelcomeScreen />} />
                            <Route path="/onboarding/personalInfo" element={<PersonalInfoInputView />} />

                            <Route path="/onboarding/sport" element={<SportInputView />} />
                            <Route path="/onboarding/daysOfTraining" element={<DaysOfTrainingInputView />} />
                            <Route path="/onboarding/monthsOfTraining" element={<MonthsOfTrainingInputView />} />
                            <Route path="/onboarding/yearsOfTraining" element={<YearsOfTrainingInputView />} />
                            <Route path="/onboarding/weekDaySchedule" element={<WeekDayScheduleInputView />} />
                            <Route path="/onboarding/fitnessProfileReview" element={<FitnessProfileReview />} />
                            <Route path="/onboarding/assessmentPreview" element={<OnboardingAssessmentPreview />} />

                            <Route path="/program/assignment/intro" element={<ProgramAssignmentIntro />} />
                            <Route path="/program/assignment/events" element={<ProgramAssignmentEvents />} />
                            <Route path="/program/assignment/events/add" element={<ProgramAssignmentAddEvent />} />
                            <Route path="/program/assignment/lengthSelection" element={<ProgramAssignmentLengthSelection />} />
                            <Route path="/program/assignment/loading" element={<ProgramAssignmentLoading />} />
                            <Route path="/program/assignment/overview" element={<ProgramAssignmentOverview />} />

                            <Route path="/profile/membership" element={<Membership />} />

                            <Route path="/profile/membership/organization/join" element={<JoinOrganization />} />

                            <Route path="/subscription/options" element={<SubscriptionOptions />} />
                            <Route path="/subscription/paymentMethod" element={<PaymentMethodWrapper />} />
                            <Route path="/subscription/checkout" element={<CheckoutWrapper />} />
                            <Route path="/subscription/success" element={<SubscriptionPurchaseSuccess />} />
                            <Route path="/subscription/paymentMethod/update" element={<UpdatePaymentMethod />} />
                            <Route path="/subscription/cancel" element={<SubscriptionCancel />} />

                            <Route path="/onboarding/newFitnessProfileVariables" element={<NewFitnessProfileVariablesInput />} />

                            <Route path="/" element={<Home />} />
                            <Route path="/home" element={<Home />} />

                            <Route path="/program/overview" element={<ProgramOverview />} />

                            <Route path="/active/workout/preview" element={<ActiveWorkoutPreview />} />
                            <Route path="/active/workout" element={<ActiveWorkout />} />

                            <Route path="/workout/summary" element={<CompletedWorkoutSummary />} />

                            <Route path="/assessment/list" element={<AssessmentList />}/>
                            <Route path="/assessment/preview" element={<AssessmentPreview /> } />
                            <Route path="/assessment/active" element={<AssessmentWorkout />} />
                            <Route path="/assessment/summary" element={<CompletedAssessmentSummary />} />

                            <Route path="/events" element={<Events />} />
                            <Route path="/events/add" element={<AddEvent />} />

                            <Route path="/weeklySchedule/edit" element={<EditWeeklySchedule />} />

                            <Route path="/fitnessProfile" element={<ViewFitnessProfile />} />
                            <Route path="/fitnessProfile/edit" element={<EditFitnessProfile />} />

                            <Route path="/equipment" element={<EquipmentView />} />

                            <Route path="/reports/list" element={<Reports />} />
                            <Route path="/reports/exerciseWeightHistory" element={<ExerciseWeightHistory />} />

                            <Route path="/exercise/library" element={<ExerciseLibrary />} />

                            <Route path="/emailPreferences" element={<EmailPreferences />} />
                            
                            <Route path="/profile/helpCenter" element={<HelpCenter />} />
                            <Route path="/profile/helpCenter/frequentlyAskedQuestions" element={<FrequentlyAskedQuestions />} />
                            <Route path="/profile/helpCenter/addToHomeScreen" element={<AddToHomeScreen />} />
                            <Route path="/profile/helpCenter/contact" element={<ContactSupport />} />

                            {/* <Route path="/test/modal" element={<TestModal />} /> */}

                            <Route path="*" element={<RouteNotFoundView />} />

                        </>
                    )}

                </Routes>

                <ModalOverlay />

            </ModalProvider>
        </BrowserRouter>
    )
}

export default TraineeApp
