export function CapitalizeFirstLetter(string) {

    return string.charAt(0).toUpperCase() + string.slice(1);
    
}

export function GetWorkoutTemplateNameFromKey(string) {

    switch(string){
        case "strength-session-a": return "Strength Session A";
        case "strength-session-b": return "Strength Session B";
        case "mobility-a": return "Mobility A";
        case "mobility-b": return "Mobility B";
        default: return string 
    }

}

export function GetProgressionLevelName(progressionLevel) {

    const progressionLevelNames = {
        '0': 'Beginner',
        '1': 'Foundational',
        '2': 'Intermediate',
        '3': 'Progressed',
        '4': 'Advanced'
    }

    return progressionLevelNames[progressionLevel]

}

export function GetMovementDifficultyScaleText(difficulty) {

    switch (difficulty){

        case -2: return "Too Easy"
        case -1: return "Easy"
        case 0: return "Neutral"
        case 1: return "Hard"
        case 2: return "Too Hard"
        default:
            console.warn(difficulty + " is not a valid difficulty value!! Defaulting to neutral icon")
            return "Neutral"

    }

}

export function GetLoadDifficultyScaleText(difficulty) {

    switch (difficulty){

        case -2: return "Too Light"
        case -1: return "Light"
        case 0: return "Neutral"
        case 1: return "Heavy"
        case 2: return "Too Heavy"
        default:
            console.warn(difficulty + " is not a valid difficulty value!! Defaulting to neutral icon")
            return "Neutral"

    }

}