import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'
import { updateAllowMonthlyReminderPreference } from 'redux/slices/user'
import { GetPrimaryColorHex } from 'redux/slices/brand'

import CustomHeader from 'components/Header/CustomHeader'

export default function EmailPreferences() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const userId = useSelector((state) => state.user.id)
    const reduxAllowMonthlyReminders = useSelector((state) => state.user.allowMonthlyReminders)

    const [allowMonthlyReminders, setAllowMonthlyReminders] = useState(reduxAllowMonthlyReminders)

    const HandleToggle = async () => {
        var originalValue = reduxAllowMonthlyReminders
        var newValue = allowMonthlyReminders === 1 ? 0 : 1

        // TODO FIX THIS!! A little sloppy

        setAllowMonthlyReminders(newValue)
        dispatch(updateAllowMonthlyReminderPreference(newValue))

        const jsonBody = {
            userId: userId,
            allowMonthlyReminders: newValue,
        }

        // console.log(jsonBody)

        const result = await WhichWeightAPI('CORE', 'POST', '/update_monthly_email_reminders_preference', jsonBody)

        // console.log(result)

        // If failure switch back
        if (result.data.status === 'failure') {
            console.error(result)
            setAllowMonthlyReminders(originalValue)
            dispatch(updateAllowMonthlyReminderPreference(originalValue))
        }
    }

    return (
        <div className="w-full h-screen flex flex-col">
            <CustomHeader text="Email Preferences" backButtonAction={() => navigate('/home')} />

            <div className="p-4 space-y-2 border-b border-gray-400">
                <div className="flex items-center justify-between text-sm text-gray-800">
                    <span className="text-m font-medium">Send Training Reminders Every Month</span>

                    <Switch
                        checked={allowMonthlyReminders}
                        onChange={HandleToggle}
                        className={`
                            relative inline-flex h-4 w-8 cursor-pointer rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none
                        `}
                        style={{
                            backgroundColor: allowMonthlyReminders ? GetPrimaryColorHex(styleConfig) : '#e5e7eb',
                        }}
                    >
                        <span
                            aria-hidden="true"
                            className={`${allowMonthlyReminders ? 'translate-x-4' : 'translate-x-0'} inline-block h-3 w-3 transform bg-white rounded-full transition duration-200`}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    )
}
