import { useSelector } from 'react-redux'
import SafariLogo from 'media/misc/SafariLogo.png'
import SafariShareIconLocation from 'media/misc/SafariShareIconLocation.png'
import SafariAddToHomeScreenLocation from 'media/misc/SafariAddToHomeScreenLocation.png'

export default function IOSAddToHomeScreenInstructions() {

    const styleConfig = useSelector((state) => state.brand.styleConfig)

    return (
        <>
            <div className="space-y-2 p-4 rounded-xl border border-gray-400">
                <div className="flex items-center justify-center gap-x-2 text-xl">
                    <div>Open</div>
                    <div className="flex items-center gap-x-2 bg-white px-1 rounded-full">
                        <img src={SafariLogo} className="h-5" />
                        <div>Safari</div>
                    </div>
                </div>
            </div>

            <div className="space-y-2 p-4 rounded-xl border border-gray-400">
                <div className="flex items-center justify-center gap-x-1 text-xl">
                    <div>Go to </div>
                    <a href={styleConfig.text.appURL} className="text-blue-600 hover:text-blue-800 underline">
                        {styleConfig.text.appURL}
                    </a>
                </div>
            </div>

            <div className="space-y-2 p-4 rounded-xl border border-gray-400">
                <div className="text-xl text-center">Press the Share button located at the bottom of the screen</div>
                <img src={SafariShareIconLocation} className="rounded-lg" />
            </div>

            <div className="space-y-2 p-4 rounded-xl border border-gray-400">
                <div className="text-xl text-center">Select Add to Home Screen</div>
                <img src={SafariAddToHomeScreenLocation} className="rounded-lg" />
            </div>
        </>
    )
}