import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resetActiveAssessmentState, initializeActiveAssessment } from 'redux/slices/activeAssessment'
import { useGetExercises } from 'redux/customHooks/useGetExercises'
import { useGetFitnessProfile } from 'redux/customHooks/useGetFitnessProfile'
import { equipmentMedia } from 'functions/equipmentUtils'

import CustomHeader from 'components/Header/CustomHeader'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import WorkoutPreview from 'components/ActiveWorkout/WorkoutPreview'
import LoadingButton from 'components/LoadingButton'

import runAssessmentTemplate from 'json/runAssessmentTemplate'
import cycleAssessmentTemplate from 'json/cycleAssessmentTemplate'
import triAssessmentFemaleTemplate from 'json/triAssessmentFemaleTemplate'
import triAssessmentMaleTemplate from 'json/triAssessmentMaleTemplate'


export default function AssessmentPreview() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const exercises = useGetExercises()
    const fitnessProfile = useGetFitnessProfile()

    const { assessmentUIFlowKey } = location.state

    const [assessmentWorkoutTemplate, setAssessmentWorkoutTemplate] = useState(null)
    const [isStartWorkoutLoading, setIsStartWorkoutLoading] = useState(false)

    const SelectAssessmentTemplate = () => {

        // TRIATHLON
        if (fitnessProfile.sportId == 12) {

            if (fitnessProfile.gender == 'female') setAssessmentWorkoutTemplate(triAssessmentFemaleTemplate)
            else setAssessmentWorkoutTemplate(triAssessmentMaleTemplate)
        }
        // CYCLING
        else if (fitnessProfile.sportId == 10) setAssessmentWorkoutTemplate(cycleAssessmentTemplate)

        // RUNNING (ALL OTHER SPORTS)
        else setAssessmentWorkoutTemplate(runAssessmentTemplate)

    }

    const GetRequiredEquipment = (reduxWorkoutTemplate) => {
        var requiredEquipment = []

        // For each phase
        reduxWorkoutTemplate.phases.map((phase) => {
            // For each exercise
            phase.exercises.map((exercise) => {
                // console.log(exercise.equipment)

                // Edge case for band and machine equipment. Only push band as required
                if (exercise.equipment === 'band,machine') {
                    if (!requiredEquipment.includes('band')) requiredEquipment.push('band')
                }
                // Edge case for dumbbell and kettlebell equipment. Only push dumbbell as required
                else if (exercise.equipment === 'dumbbell,kettlebell') {
                    if (!requiredEquipment.includes('dumbbell')) requiredEquipment.push('dumbbell')
                }
                // Edge case for band, dumbbell, and kettlebell equipment. Only push dumbbell as required
                else if (exercise.equipment === 'band,dumbbell,kettlebell') {
                    if (!requiredEquipment.includes('band')) requiredEquipment.push('band')
                    if (!requiredEquipment.includes('dumbbell')) requiredEquipment.push('dumbbell')
                } else {
                    var currentExerciseEquipment = exercise.equipment.split(',')

                    currentExerciseEquipment.map((equipment) => {
                        if (equipment.length > 0) {
                            if (!requiredEquipment.includes(equipment)) requiredEquipment.push(equipment)
                        }
                    })
                }
            })
        })

        // console.log(requiredEquipment)

        return requiredEquipment
    }

    // Post workout to the backend
    const StartWorkout = async () => {

        setIsStartWorkoutLoading(true)

        var newAssessmentWorkoutTemplate = JSON.parse(JSON.stringify(assessmentWorkoutTemplate))

        // For each phase
        newAssessmentWorkoutTemplate.phases.map((phase) => {
            // Add a completed boolean
            phase.completed = false

            // For each exercise
            phase.exercises.map((exercise) => {
                // Cache the exercise video URL
                exercise.videoUrl = exercises.find((item) => item.id === exercise.id).videoUrl

                // For each set
                exercise.sets.map((set) => {
                    // Add default values for recommendation data
                    set.recommendationData = {
                        weightRecommendationLoaded: false,
                        weightRecommendation: 0,
                        weightRecommendationRounded: 0,
                        formulaTag: '',
                        equipmentDetails: null,
                    }

                    // Add default values for completed set data
                    set.completedSetData = {
                        savingResult: false,
                        completed: false,
                        setUnitsCompleted: 0,
                        movementDifficulty: 0,
                        difficulty: 0,
                        override: 0,
                        databaseId: 0,
                    }
                })
            })
        })

        setIsStartWorkoutLoading(false)

        const activeAssessmentPayload = {
            assessmentUIFlowKey: assessmentUIFlowKey,
            workoutTemplate: newAssessmentWorkoutTemplate,
        }

        // console.log(activeAssessmentPayload)

        dispatch(initializeActiveAssessment(activeAssessmentPayload))

        navigate('/assessment/active')

    }

    useEffect(() => { 
        dispatch(resetActiveAssessmentState())

        if (fitnessProfile !== null && exercises.length > 0) SelectAssessmentTemplate()

    }, [fitnessProfile, exercises])

    if (fitnessProfile === null) return <MediumLoadingComponent />

    if (exercises.length === 0) return <MediumLoadingComponent />

    if (assessmentWorkoutTemplate === null) return <MediumLoadingComponent />

    return (
        <>

            <CustomHeader text={'Workout Preview'} backButtonAction={() => navigate(-1)} />

            <div className="p-4 space-y-4">

                {GetRequiredEquipment(assessmentWorkoutTemplate).length > 0 && (
                    <div className="shadow border border-gray-400 rounded-lg px-4 pt-4 pb-2 space-y-4 items-center">
                        <div className='flex'>
                            <div className='flex-grow'>Equipment For This Workout</div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-2">
                            {GetRequiredEquipment(assessmentWorkoutTemplate).map((equipment, index) => (
                                <div key={index} className="flex items-center gap-x-2 bg-gray-100 py-1 px-2 rounded-xl mb-2">
                                    <img src={equipmentMedia[equipment].imageURL} alt="Equipment Icon" className="h-6" />
                                    <div className="text-xs text-gray-600">{equipmentMedia[equipment].name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <LoadingButton 
                    buttonText="Start Assessment" 
                    isLoading={isStartWorkoutLoading} 
                    action={() => StartWorkout()} 
                />

                <WorkoutPreview 
                    workoutTemplate={assessmentWorkoutTemplate} 
                    setWorkoutTemplate={setAssessmentWorkoutTemplate} 
                />

            </div>

        </>
    )
}
