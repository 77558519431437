import { WhichWeightAPI } from 'WhichWeightAPI'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState, useEffect, useContext } from 'react'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { MagnifyingGlassIcon, ChartBarIcon } from '@heroicons/react/24/outline'
import { CalculateAssessmentScore } from 'functions/assessmentSummaryUtils'
import { IsActiveAssessmentSliceInInitialState } from 'redux/slices/activeAssessment'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import moment from 'moment'

import CustomHeader from 'components/Header/CustomHeader'

export default function AssessmentList() {

    const navigate = useNavigate()
    const { ShowModal } = useContext(ModalContext)

    const email = useSelector((state) => state.user.email)
    const activeAssessment = useSelector((state) => state.activeAssessment)

    const [assessments, setAssessments] = useState(null);
    // const [daysSinceLatestAssessment, setDaysSinceLatestAssessment] = useState(0);

    const cards = [
        {
            name: 'Identify Your Weaknesses',
            description: 'Carefully crafted to identify weak muscle groups',
            icon: MagnifyingGlassIcon,
        },
        {
            name: 'Asymmetry Reduction Training',
            description: 'Assessment results inform your program and weave in exercises to address key weaknesses',
            icon: ChartBarIcon,
        }
    ]
    
    const FetchAssessmentsFromBackend = async () => {
        
        const jsonBody = {
            email: email
        }

        // console.log(jsonBody);

        const result = await WhichWeightAPI('CORE', 'GET', '/assessment_list', jsonBody)

        // console.log(result.data.data);

        setAssessments(result.data.data)

        // If we have no assessments, make sure we are not cooldown blocked

        // if (result.data.data.length === 0){
        //     setDaysSinceLatestAssessment(100)
        //     return
        // }

        // Otherwise calculate the cooldown time

        // const latestAssessment = result.data.data.reduce((latest, current) => {
        //     return moment(current.createdTimestamp).isAfter(moment(latest.createdTimestamp)) ? current : latest;
        // });
        
        // const daysSince = moment().diff(moment(latestAssessment.createdTimestamp), 'days');
        // setDaysSinceLatestAssessment(daysSince)

    }

    const ShowAssessmentWarningModal = () => {

        const modalData = {
            key: ModalKey.ActionWarning,
            fullscreen: false,
            titleText: 'Continue to Assessment?',
            bodyText: [
                'If you are at the beginning of your program, the assessment is a great way to target weakness areas!',
                'If you are already multiple weeks into your program, taking an assessment may reset some of your progress.'
            ],
            buttonText: 'Continue',
            buttonAction: () => OnStartAssessment()
        }

        ShowModal(modalData)

    }

    const OnStartAssessment = async () => {

        const props = {
            state: {
                assessmentUIFlowKey: 'list'
            }
        }

        navigate('/assessment/preview', props)

    }

    const OnResumeAssessmentButton = () => {

        navigate('/assessment/active')

    }

    const OnViewAssessmentButton = (record) => {

        const props = {
            state: {
                assessmentUIFlowKey: 'list',
                assessmentWorkoutTemplate : record.assessmentTemplateData
            }
        }

        navigate('/assessment/summary', props)

    }

    useEffect(() => {
        FetchAssessmentsFromBackend()
    }, [])

    return (
        <>

            <CustomHeader text="Assessments" backButtonAction={() => navigate('/home')} />

            {assessments === null && <MediumLoadingComponent />}

            {assessments !== null && (
                <div className="space-y-4 p-4">

                    {/* Info Section */}
                    <div className='space-y-4'>
                        {cards.map((card) => (
                            <div 
                                key={card.name} 
                                className='px-4 py-2 rounded-xl bg-gray-100 shadow relative w-full'
                            >
                                <dt className='flex items-center space-x-2'>
                                    <card.icon className='text-zinc-600 h-6 w-6' aria-hidden='true' />
                                    <div className='flex-1 flex-grow px-2'>
                                        <div className='text-sm font-semibold text-gray-900'>{card.name}</div>
                                        <div className='text-gray-600 text-xs'>{card.description}</div>
                                    </div>
                                </dt>
                            </div>
                        ))}
                    </div>

                    {/* Cooldown Warning */}
                    {/* If a user has taken an assessment in the past 12 weeks */}
                    {/* {daysSinceLatestAssessment < 84 && (
                        <>

                            <div className="rounded-xl py-2 px-4 flex gap-x-4 items-center bg-gray-100 opacity-80">
                                <ClockIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                                <div className='flex-1 text-xs text-gray-500'>
                                    <div>{84 - daysSinceLatestAssessment <= 10 && <p>You have recently taken an assessment. We recommend waiting at least {84 - daysSinceLatestAssessment} more days before taking another assessment</p>}</div>
                                    <div>{84 - daysSinceLatestAssessment > 10 && <p>You have recently taken an assessment. We recommend waiting at least {Math.floor((84 - daysSinceLatestAssessment) / 7)} more weeks before taking another assessment</p>}</div>
                                </div>
                            </div>

                            <div className='text-xs text-gray-500 text-center italic'>
                                Recommended Reassessment Date: {moment().add(84 - daysSinceLatestAssessment, 'days').format('MMMM DD, YYYY')}
                            </div>

                        </>
                    )} */}

                    {/* Start Assessment Button */}
                    {IsActiveAssessmentSliceInInitialState(activeAssessment) && 
                        <button
                            type="button"
                            className="w-full flex justify-center items-center rounded-full text-center text-white rounded-full py-2 px-4 text-lg font-semibold bg-gradient-to-r from-orange-600 to-orange-400"
                            onClick={() => ShowAssessmentWarningModal()}
                        >
                            Take Assessment
                        </button>
                    }

                    {/* Resume Assessment Button */}
                    {!IsActiveAssessmentSliceInInitialState(activeAssessment) && 
                        <button
                            type="button"
                            className="w-full flex justify-center items-center rounded-full text-center text-white rounded-full py-2 px-4 text-lg font-semibold bg-gradient-to-r from-orange-600 to-orange-400"
                            onClick={() => OnResumeAssessmentButton()}
                        >
                            Resume Assessment
                        </button>
                    }

                    <div>
                        <table className="w-full">
                            <thead>
                                <tr className="border-b-2 border-zinc-500 text-left font-medium p-10 text-sm">
                                    <th className="pl-4 py-2">Name</th>
                                    <th className="pl-4 py-2">Date</th>
                                    <th className="py-2">Score</th>
                                    <th className="py-2 text-center"></th>
                                </tr>
                            </thead>

                            <tbody>

                                {assessments.length === 0 && (
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            <div className="bg-gray-100 p-20">
                                                <p className="text-xs italic">No assessment history</p>
                                            </div>
                                        </td>
                                    </tr>
                                )}

                                {assessments.length > 0 && assessments.map((record, index) => (
                                    <tr 
                                        key={index} 
                                        className={`text-sm border-b border-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                                        onClick={() => OnViewAssessmentButton(record)}
                                    >

                                        <td className="py-4 pl-4 font-medium">Assessment {assessments.length - index}</td>

                                        <td className="py-4 pl-4 text-gray-600 text-xs">{moment.utc(record.createdTimestamp).local().format('MMMM DD hh:mm a')}</td>

                                        <td className="py-4">{CalculateAssessmentScore(record.assessmentTemplateData)} %</td>

                                        <td><ChevronRightIcon className='w-5 h-5' /></td>

                                    </tr>
                                ))} 
                        
                            </tbody>
                        </table>
                    </div>

                </div>

            )}

        </>
    )
}
