import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { ModalContext } from '../ModalContext'
import { StarIcon } from '@heroicons/react/24/solid'
import { GetPrimaryColorHex } from 'redux/slices/brand'

import LoadingButton from 'components/LoadingButton'

export default function NetPromoterScoreModal() {
    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const userId = useSelector((state) => state.user.id)
    const { HideModal } = useContext(ModalContext)

    const ratingTexts = {
        1: 'It has been a bad experience',
        2: 'It could be a bit better',
        3: 'The app is average',
        4: 'It is pretty good',
        5: 'Best workout app ever!',
    }

    const [selectedRating, setSelectedRating] = useState(null)
    const [additionalNote, setAdditionalNote] = useState('')
    const [errorText, setErrorText] = useState('')
    const [isNPSScoreLoading, setIsNPSScoreLoading] = useState(false)

    const HandleRatingSelection = (newRating) => {
        setErrorText('')
        setSelectedRating(newRating)
    }

    const HandleAdditionalNotesChange = (event) => {
        setErrorText('')
        if (event.target.value.length < 150) setAdditionalNote(event.target.value)
    }

    const OnSubmit = async () => {
        if (selectedRating === null) {
            setErrorText('Please select a rating option')
            return
        }

        setIsNPSScoreLoading(true)

        const jsonBody = {
            userId: userId,
            source: 'net-promoter-score',
            detailsJSON: {
                rating: selectedRating,
            },
            additionalNote: additionalNote,
        }

        // console.log(jsonBody)

        const result = await WhichWeightAPI('CORE', 'POST', '/user_feedback_item', jsonBody)

        // console.log(result)

        setIsNPSScoreLoading(false)

        if (result.data.status === 'failure') {
            console.error(result)
            return
        }

        HideModal()
    }

    return (
        <>
            <div className="text-center text-lg font-semibold leading-6 text-gray-900">How has your experience been with WhichWeight so far?</div>

            <div className="py-4 border border-gray-400 rounded-xl space-y-4">
                <div className="grid grid-cols-5 px-6">
                    {Array.from({ length: 5 }, (_, currentRating) => (
                        <div
                            key={currentRating + 1}
                            className="flex items-center justify-center"
                            onClick={() => {
                                HandleRatingSelection(currentRating + 1)
                            }}
                        >
                            <StarIcon 
                                className="h-10" 
                                style={{
                                    color: currentRating + 1 <= selectedRating ? GetPrimaryColorHex(styleConfig) : '#d1d5db',
                                }}
                            />
                        </div>
                    ))}
                </div>

                {selectedRating === null && <div className="italic text-gray-400">Select An Option</div>}
                {selectedRating !== null && <div className="text-gray-600">{ratingTexts[selectedRating]}</div>}
            </div>

            {/* Additional Notes Box */}
            <textarea
                type="text"
                min="0"
                name="additionalNotes"
                id="additionalNotes"
                className="h-20 w-full py-2 px-4 text-sm rounded-lg border border-gray-400 placeholder:text-gray-400"
                placeholder={'Please provide us with any additional details.. '}
                value={additionalNote}
                onChange={(event) => HandleAdditionalNotesChange(event)}
            />

            {errorText !== '' && <div className="text-sm text-red-400">{errorText}</div>}

            <LoadingButton buttonText="Submit" isLoading={isNPSScoreLoading} action={() => OnSubmit()} />
        </>
    )
}
