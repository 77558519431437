import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { ModalKey, ModalContext } from 'components/Modals/ModalContext'
import { GetPrimaryColorHex } from 'redux/slices/brand'

import AbbyHall from 'media/athletes/abby_hall.png'
import AdamGoucher from 'media/athletes/adam_goucher.png'
import DrewHunter from 'media/athletes/drew_hunter.png'
import EliHemming from 'media/athletes/eli_hemming.png'
import KaraGoucher from 'media/athletes/kara_goucher.png'
import LauraThweatt from 'media/athletes/laura_thweatt.png'
import MorganPearson from 'media/athletes/morgan_pearson.png'
import ReedFischer from 'media/athletes/reed_fischer.png'
import RudyVonBerg from 'media/athletes/rudy_von_berg.png'
import RuthWinder from 'media/athletes/ruth_winder.png'
import SamAppleton from 'media/athletes/sam_appleton.png'
import SaraVaughn from 'media/athletes/sara_vaughn.png'
import TaylorSpivey from 'media/athletes/taylor_spivey.png'
import VittoriaLopes from 'media/athletes/vittoria_lopes.png'

// Blue Collar Media
import WelcomeImage1 from 'media/blue_collar/welcome_image_1.png'
import WelcomeImage2 from 'media/blue_collar/welcome_image_2.png'
import WelcomeImage3 from 'media/blue_collar/welcome_image_3.png'
import WelcomeImage4 from 'media/blue_collar/welcome_image_4.png'
import WelcomeImage5 from 'media/blue_collar/welcome_image_5.png'


import ScreenFrame from 'components/Frame/ScreenFrame'
import LogoHeader from 'components/Header/LogoHeader'
import BodyFrame from 'components/Frame/BodyFrame'
import NextButton from 'components/Onboarding/NextButton'

export default function WelcomeScreen() {
    
    const navigate = useNavigate()
    const { ShowModal } = useContext(ModalContext)
    const { logout } = useAuth0()

    const styleConfig = useSelector((state) => state.brand.styleConfig)

    const athleteImages = [
        // Left side - larger images
        { top: '110px', left: '0px', size: '50px', animationDelay: '0s', src: AbbyHall },
        { top: '160px', left: '-40px', size: '60px', animationDelay: '0.5s', src: AdamGoucher },
        { top: '260px', left: '-38px', size: '75px', animationDelay: '1s', src: DrewHunter },
        { top: '360px', left: '-42px', size: '67.5px', animationDelay: '1.5s', src: EliHemming },
        { top: '460px', left: '-36px', size: '82.5px', animationDelay: '2s', src: KaraGoucher },
        { top: '560px', left: '-39px', size: '60px', animationDelay: '2.5s', src: LauraThweatt },
        // { top: '660px', left: '-41px', size: '75px', animationDelay: '3s', src: MorganPearson },

        // Right side - larger images
        // { top: '100px', right: '-45px', size: '55px', animationDelay: '3.5s', src: ReedFischer },
        { top: '180px', right: '-40px', size: '75px', animationDelay: '4s', src: RudyVonBerg },
        { top: '280px', right: '-37px', size: '67.5px', animationDelay: '4.5s', src: RuthWinder },
        { top: '380px', right: '-42px', size: '60px', animationDelay: '5s', src: SamAppleton },
        { top: '480px', right: '-36px', size: '82.5px', animationDelay: '5.5s', src: VittoriaLopes },
        { top: '580px', right: '-39px', size: '52.5px', animationDelay: '6s', src: ReedFischer },
        // { top: '680px', right: '-41px', size: '75px', animationDelay: '6.5s', src: SaraVaughn },

        // Left side - smaller images
        { top: '150px', left: '25px', size: '30px', animationDelay: '7s', src: SaraVaughn },
        { top: '215px', left: '10px', size: '40px', animationDelay: '7.5s', src: TaylorSpivey },
        { top: '330px', left: '12px', size: '30px', animationDelay: '8s', src: VittoriaLopes },
        { top: '420px', left: '8px', size: '32px', animationDelay: '8.5s', src: SamAppleton },
        { top: '540px', left: '14px', size: '33px', animationDelay: '9s', src: RuthWinder },
        // { top: '620px', left: '0px', size: '24px', animationDelay: '9.5s', src: RudyVonBerg },

        // Right side - smaller images
        { top: '120px', right: '12px', size: '50px', animationDelay: '10.5s', src: MorganPearson },
        { top: '252px', right: '10px', size: '30px', animationDelay: '11s', src: LauraThweatt },
        { top: '345px', right: '13px', size: '40px', animationDelay: '11.5s', src: KaraGoucher },
        { top: '440px', right: '8px', size: '24px', animationDelay: '12s', src: EliHemming },
        { top: '560px', right: '14px', size: '33px', animationDelay: '12.5s', src: DrewHunter },
        // { top: '640px', right: '11px', size: '21px', animationDelay: '13s', src: AdamGoucher },
        // { top: '740px', right: '9px', size: '30px', animationDelay: '13.5s', src: AbbyHall },
    ];

    const blueCollarWelcomeImages = [
        WelcomeImage1,
        WelcomeImage2,
        WelcomeImage3,
        WelcomeImage4
    ]

    const OnNextButton = () => {
        navigate('/onboarding/personalInfo')
    }

    const OnFinalBackButton = () => {
        const modalData = {
            key: ModalKey.ActionWarning,
            fullscreen: false,
            titleText: 'Cancel Onboarding and Logout?',
            bodyText: ['Any progress you have made during onboarding will be lost and you will be logged out.', 'You can always log back in at a later time to complete onboarding'],
            buttonText: 'Logout',
            buttonAction: () => {
                OnLogout()
            },
        }

        ShowModal(modalData)
    }

    const OnLogout = () => {
        const params = {
            logoutParams: {
                returnTo: window.location.origin + '/login',
            },
        }

        logout(params)
    }

    return (
        <ScreenFrame>

            <LogoHeader backButtonAction={() => OnFinalBackButton()} />

            {styleConfig.brandKey === "which-weight" && 
                <>

                    <BodyFrame>

                        <div 
                            className="relative z-10 mt-2 mb-2 text-center text-2xl text-transparent bg-clip-text font-extrabold"
                            style={{ 
                                backgroundImage: `linear-gradient(to bottom, ${GetPrimaryColorHex(styleConfig, 1)}, ${GetPrimaryColorHex(styleConfig)})` 
                            }}
                        >
                            Welcome to our Team!
                        </div>

                        <div className='relative z-10 text-xs text-center space-y-4 mx-2 font-medium'>

                            <div className='bg-white p-2 rounded-lg bg-opacity-100 border border-gray-500 shadow-md'>When you start training with WhichWeight, you’re embarking on a journey to elevate your athletic performance. Our Olympian Proven Method will guide you through a structured, science-based training program designed to optimize your strength and endurance. You’ll begin with our Olympic Building Block Exercises, which will establish a solid foundation by correcting imbalances and strengthening key areas of your body. Many of these exercises might appear easy or rudimentary—DON'T SLEEP ON THESE. Slow down and focus on doing them REALLY well, as they are critical to your development.</div>

                            <div className='bg-white p-2 rounded-lg bg-opacity-100 border border-gray-500 shadow-md'>As you progress, your training will be carefully adjusted to match the demands of your sport and the season. Whether you’re in the offseason building strength or preparing for a championship event, our program will ensure that you’re training at the right intensity, avoiding overtraining, and staying injury-free.</div>

                            <div className='bg-white p-2 rounded-lg bg-opacity-100 border border-gray-500 shadow-md'>You’ll notice that you’re not just getting stronger—you’re performing better in your sport. You’ll feel more balanced, powerful, and resilient, with a noticeable reduction in injury risk. This is the method that has transformed countless athletes, and it’s the one that will help you reach your full potential. Remember, true mastery comes from doing even the simplest exercises with precision and focus. Get ready to achieve greatness with WhichWeight! 🏅</div>

                        </div>

                        <div className='mt-auto z-10'>
                            <NextButton 
                                text='Start My Journey'
                                action={() => OnNextButton()}
                            />
                        </div>

                    </BodyFrame>

                    <div className="fixed inset-0 overflow-hidden pointer-events-none z-0">
                        {athleteImages.map((athlete, index) => (
                            <img
                                key={index}
                                src={athlete.src}
                                alt=""
                                className="absolute rounded-full"
                                style={{
                                    top: athlete.top,
                                    left: athlete.left,
                                    right: athlete.right,
                                    bottom: athlete.bottom,
                                    width: athlete.size,
                                    height: athlete.size,
                                    animationDuration: '5s',
                                    animationDelay: athlete.animationDelay,
                                }}
                            />
                        ))}
                    </div>

                </>
            }

            {styleConfig.brandKey === "blue-collar" && 
                <>

                    <BodyFrame>

                        <div 
                            className="relative z-10 mt-2 text-center text-xl text-transparent bg-clip-text font-extrabold"
                            style={{ 
                                backgroundImage: `linear-gradient(to bottom, ${GetPrimaryColorHex(styleConfig)}, ${GetPrimaryColorHex(styleConfig)})` 
                            }}
                        >
                            Welcome to Blue Collar Strength!
                        </div>

                        <div 
                            className='space-y-1 p-1 rounded-lg'
                            style={{ 
                                backgroundImage: `linear-gradient(to bottom, ${GetPrimaryColorHex(styleConfig, -2)}, ${GetPrimaryColorHex(styleConfig)})` 
                            }}
                        >

                            <div className='grid grid-cols-4 gap-x-1'>
                                {blueCollarWelcomeImages.map((image, index) => (
                                    <img 
                                        key={index} 
                                        src={image} 
                                        alt={`Welcome Image ${index + 1}`} 
                                        className="w-full h-28 object-cover rounded" 
                                    />
                                ))}
                            </div>

                            <img 
                                src={WelcomeImage5} 
                                alt='Welcome Image 5' 
                                className="w-full h-32 object-cover rounded-lg" 
                            />

                        </div>

                        <div className='relative z-10 text-xs text-center space-y-2 font-medium'>

                            <div className='bg-white p-2 rounded-lg bg-opacity-100 border border-gray-500 shadow-md'>Blue Collar Strength, powered by WhichWeight, is your custom guide on your journey to elevate your athletic performance. As you progress, your training will be carefully adjusted to match the demands of your sport and the season. Whether you’re in the offseason building strength or preparing for a championship event, our program will ensure that you’re training at the right intensity, avoiding overtraining, and staying injury-free.</div>

                            <div className='bg-white p-2 rounded-lg bg-opacity-100 border border-gray-500 shadow-md'>You’ll notice that you’re not just getting stronger—you’re performing better in your sport. You’ll feel more balanced, powerful, and resilient, with a noticeable reduction in injury risk. This is the method that has transformed countless athletes, and it’s the one that will help you reach your full potential. Remember, true mastery comes from doing even the simplest exercises with precision and focus. Get ready to achieve greatness with Blue Collar Strength, powered by WhichWeight! 🏅</div>

                        </div>

                        <div className='mt-auto z-10'>
                            <NextButton 
                                text='Start My Journey'
                                action={() => OnNextButton()}
                            />
                        </div>

                    </BodyFrame>

                </>
            }

        </ScreenFrame>
    )

}