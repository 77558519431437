import { createSlice } from '@reduxjs/toolkit'

const initialState =  {
    id: null,
    weekOfTheYearKey: null,
    workoutTemplateKey: null,
    uiMode: 'compact',
    taperData: null,
    workoutTemplate: null,
    phaseIndex: 0,
    exerciseIndex: 0,
    setIndex: 0,
    originalExerciseStorage: null
}

const activeWorkoutSlice = createSlice({
  name: 'activeWorkout',
  initialState,
  reducers: {
    initializeActiveWorkout: (state, action) => { 

        // Init variables
        state.id = action.payload.id
        state.weekOfTheYearKey = action.payload.weekOfTheYearKey
        state.workoutTemplateKey = action.payload.workoutTemplateKey
        state.taperData = action.payload.taperData
        state.workoutTemplate = action.payload.workoutTemplate
        state.phaseIndex = 0
        state.exerciseIndex = 0
        state.setIndex = 0

        // Initialize orgrinalExerciseStorange
        state.originalExerciseStorage = []

        // Loop over each phase
        action.payload.workoutTemplate.phases.map((phase) => {

            var storageExercises = []

            // Add a null object for each exercise
            phase.exercises.map(() => { storageExercises.push(null) })

            state.originalExerciseStorage.push(storageExercises)
            
        })

    },
    setActiveWorkoutPhaseIndex: (state, action) => { state.phaseIndex = parseInt(action.payload) },
    setActiveWorkoutExerciseIndex: (state, action) => { state.exerciseIndex = parseInt(action.payload) },
    setActiveWorkoutSetIndex: (state, action) => { state.setIndex = parseInt(action.payload) },
    setActiveWorkoutIncompleteSetInfo: (state, action) => {

        const currentSet = state.workoutTemplate.phases[action.payload.phaseIndex].exercises[action.payload.exerciseIndex].sets[action.payload.setIndex];

        currentSet.recommendationData.weightRecommendationLoaded = true;
        currentSet.recommendationData.weightRecommendation = action.payload.weightRecommendation;
        currentSet.recommendationData.weightRecommendationRounded = action.payload.weightRecommendationRounded;
        currentSet.recommendationData.formulaTag = action.payload.formulaTag;
        currentSet.recommendationData.equipmentDetails = action.payload.equipmentDetails;

    },
    resetActiveWorkoutIncompleteSetInfo: (state, action) => {
        
        const currentSet = state.workoutTemplate.phases[action.payload.phaseIndex].exercises[action.payload.exerciseIndex].sets[action.payload.setIndex];

        currentSet.recommendationData.weightRecommendationLoaded = false;
        currentSet.recommendationData.weightRecommendation = 0;
        currentSet.recommendationData.weightRecommendationRounded = 0;
        currentSet.recommendationData.formulaTag = "";
        currentSet.recommendationData.equipmentDetails = null;

    },
    setActiveWorkoutExerciseSetWeightOverride: (state, action) => {

        const currentSet = state.workoutTemplate.phases[action.payload.phaseIndex].exercises[action.payload.exerciseIndex].sets[action.payload.setIndex];

        currentSet.recommendationData.weightRecommendation = action.payload.weightRecommendation;
        currentSet.recommendationData.weightRecommendationRounded = action.payload.weightRecommendationRounded;

        currentSet.completedSetData.override = action.payload.override;

    },
    updateActiveWorkoutExerciseSetWeightOverride: (state, action) => {

        const currentSet = state.workoutTemplate.phases[action.payload.phaseIndex].exercises[action.payload.exerciseIndex].sets[action.payload.setIndex];

        currentSet.recommendationData.weightRecommendation += action.payload.weightUpdate;
        currentSet.recommendationData.weightRecommendationRounded += action.payload.weightUpdateRounded;

        // Make sure values cannot go below 10
        currentSet.recommendationData.weightRecommendation = Math.max(10, currentSet.recommendationData.weightRecommendation)
        currentSet.recommendationData.weightRecommendationRounded = Math.max(10, currentSet.recommendationData.weightRecommendationRounded)

        currentSet.completedSetData.override += action.payload.override;

    },
    setActiveWorkoutExerciseSetSavingResultBoolean: (state, action) => {

        const currentSet = state.workoutTemplate.phases[action.payload.phaseIndex].exercises[action.payload.exerciseIndex].sets[action.payload.setIndex];

        currentSet.completedSetData.savingResult = action.payload.savingResult;

    },
    setActiveWorkoutCompleteSetInfo: (state, action) => {
        
        const currentSet = state.workoutTemplate.phases[action.payload.phaseIndex].exercises[action.payload.exerciseIndex].sets[action.payload.setIndex];

        currentSet.completedSetData.completed = true;
        currentSet.completedSetData.databaseId = action.payload.databaseId;
        currentSet.completedSetData.setUnitsCompleted = action.payload.setUnitsCompleted;
        currentSet.completedSetData.movementDifficulty = action.payload.movementDifficulty;
        currentSet.completedSetData.difficulty = action.payload.difficulty;

    },
    setActiveWorkoutPhaseCompletionBoolean: (state, action) => {

        const currentPhase = state.workoutTemplate.phases[action.payload.phaseIndex]

        currentPhase.completed = action.payload.completed;

    },
    swapActiveWorkoutExercise: (state, action) => {

        const currentExercise = state.workoutTemplate.phases[action.payload.phaseIndex].exercises[action.payload.exerciseIndex];

        // Store current exercise for undo logic

        const storageExercise = {
            id: currentExercise.id,
            name: currentExercise.name,
            exerciseType: currentExercise.exerciseType,
            lateral: currentExercise.lateral,
            setUnit: currentExercise.setUnit,
            videoUrl: currentExercise.videoUrl,
            generationVariables: currentExercise.generationVariables
        }

        state.originalExerciseStorage[action.payload.phaseIndex][action.payload.exerciseIndex] = storageExercise

        // Update exercise

        currentExercise.id = action.payload.swapExercise.id;
        currentExercise.name = action.payload.swapExercise.name;
        currentExercise.exerciseType = action.payload.swapExercise.exerciseType;
        currentExercise.lateral = action.payload.swapExercise.lateral;
        currentExercise.setUnit = action.payload.swapExercise.setUnit;
        currentExercise.videoUrl = action.payload.swapExercise.videoUrl;   
        currentExercise.generationVariables = { fetchType: action.payload.swapType }

    },
    undoExerciseSwap: (state, action) => {

        const storedExercise = state.originalExerciseStorage[action.payload.phaseIndex][action.payload.exerciseIndex]
        const currentExercise = state.workoutTemplate.phases[action.payload.phaseIndex].exercises[action.payload.exerciseIndex];

        // Update exercise

        currentExercise.id = storedExercise.id;
        currentExercise.name = storedExercise.name;
        currentExercise.exerciseType = storedExercise.exerciseType;
        currentExercise.lateral = storedExercise.lateral;
        currentExercise.setUnit = storedExercise.setUnit;
        currentExercise.videoUrl = storedExercise.videoUrl;   
        currentExercise.generationVariables = storedExercise.generationVariables

        // Clear stored exercise

        state.originalExerciseStorage[action.payload.phaseIndex][action.payload.exerciseIndex] = null

    },
    toggleUIMode: (state) => {

        switch (state.uiMode){

            case "compact":

                state.uiMode = "expanded"

                // Toggle onto the next incomplete set

                const currentPhase = state.workoutTemplate.phases[state.phaseIndex]

                // Default new indices to end of a phase
                var newExerciseIndex = currentPhase.exercises.length - 1
                var newSetIndex = currentPhase.exercises[0].sets.length - 1
    
                // For straight phase type
                if (currentPhase.type === 'straight') {

                    // Loop over all exercises
                    outerLoop: for (let exerciseIndex = 0; exerciseIndex < currentPhase.exercises.length; exerciseIndex++) {

                        // For all sets
                        for (let setIndex = 0; setIndex < currentPhase.exercises[exerciseIndex].sets.length; setIndex++) {
                            
                            // On the first incomplete set
                            if (!currentPhase.exercises[exerciseIndex].sets[setIndex].completedSetData.completed) {
                                
                                newExerciseIndex = exerciseIndex
                                newSetIndex = setIndex
    
                                break outerLoop

                            }
                            
                        }

                    }
                }
    
                // For circuit phase type
                if (currentPhase.type === 'circuit') {

                    // Loop over each circuit round
                    outerLoop: for (let setIndex = 0; setIndex < currentPhase.circuitRounds; setIndex++) {

                        // Loop over all exercises
                        for (let exerciseIndex = 0; exerciseIndex < currentPhase.exercises.length; exerciseIndex++) {

                            // On the first incomplete set
                            if (!currentPhase.exercises[exerciseIndex].sets[setIndex].completedSetData.completed) {

                                newExerciseIndex = exerciseIndex
                                newSetIndex = setIndex
    
                                break outerLoop
                            }
                        }
                    }
                }

                state.exerciseIndex = newExerciseIndex
                state.setIndex = newSetIndex

                break

            case "expanded": 

                state.uiMode = "compact"

                break

            default:
                console.warn(state.uiMode + " is not a valid uiMode!! Defaulting to compact")
                state.uiMode = "compact"
                break

        }

    },
    resetActiveWorkoutState: () => initialState
  },
});

export const { 
    initializeActiveWorkout,
    setActiveWorkoutPhaseIndex,
    setActiveWorkoutExerciseIndex,
    setActiveWorkoutSetIndex,
    setActiveWorkoutIncompleteSetInfo,
    resetActiveWorkoutIncompleteSetInfo,
    setActiveWorkoutExerciseSetWeightOverride,
    updateActiveWorkoutExerciseSetWeightOverride,
    setActiveWorkoutExerciseSetSavingResultBoolean,
    setActiveWorkoutCompleteSetInfo,
    setActiveWorkoutPhaseCompletionBoolean,
    swapActiveWorkoutExercise,
    undoExerciseSwap,
    toggleUIMode,
    resetActiveWorkoutState 
} = activeWorkoutSlice.actions;

export const IsActiveWorkoutSliceInCurrentFormat = (activeWorkout) => {

    return (
        activeWorkout.hasOwnProperty("id") &&
        activeWorkout.hasOwnProperty("weekOfTheYearKey") &&
        activeWorkout.hasOwnProperty("workoutTemplateKey") &&
        activeWorkout.hasOwnProperty("uiMode") &&
        activeWorkout.hasOwnProperty("taperData") &&
        activeWorkout.hasOwnProperty("workoutTemplate") &&
        activeWorkout.hasOwnProperty("phaseIndex") &&
        activeWorkout.hasOwnProperty("exerciseIndex") &&
        activeWorkout.hasOwnProperty("setIndex") &&
        activeWorkout.hasOwnProperty("originalExerciseStorage")
    )

}

export const IsActiveWorkoutSliceInInitialState = (activeWorkout) => {
    return (
        activeWorkout.id === initialState.id &&
        activeWorkout.weekOfTheYearKey === initialState.weekOfTheYearKey && 
        activeWorkout.workoutTemplateKey === initialState.workoutTemplateKey && 
        activeWorkout.uiMode === initialState.uiMode && 
        activeWorkout.taperData === initialState.taperData && 
        activeWorkout.workoutTemplate === initialState.workoutTemplate && 
        activeWorkout.phaseIndex === initialState.phaseIndex && 
        activeWorkout.exerciseIndex === initialState.exerciseIndex && 
        activeWorkout.setIndex === initialState.setIndex && 
        activeWorkout.originalExerciseStorage === initialState.originalExerciseStorage
    );
};

export default activeWorkoutSlice.reducer;