import { useState, useEffect, useContext, useRef } from 'react'
import { ModalContext } from '../ModalContext'

import { useGetExercises } from 'redux/customHooks/useGetExercises'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export default function SelectExerciseModal() {
    const { modalData, HideModal } = useContext(ModalContext)

    // Search Variables
    const searchInputRef = useRef()
    const [searchTerm, setSearchTerm] = useState('')
    const exercises = useGetExercises()
    const [filteredExerciseOptions, setFilteredExerciseOptions] = useState([])

    // Filters exercises through searchTerm and returns results in filteredExercises
    const filterExercises = () => {
        if (exercises.length > 0) {
            if (searchTerm === '' || searchTerm === '' || searchTerm === null || searchTerm === undefined) {
                setFilteredExerciseOptions(exercises)
                return
            }

            const results = exercises.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))

            setFilteredExerciseOptions(results)
        }
    }

    // Sets the search term useState when the input box is changed
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value)
    }

    // Focuses input box on mounting
    useEffect(() => {
        searchInputRef.current.focus()
    }, [])

    // Refilters exercises every time the search term changes or on the initialization of the exercises data
    useEffect(() => {
        filterExercises()
    }, [exercises, searchTerm])

    return (
        <>
            {/* Header */}
            <div className="mb-2 text-lg font-semibold leading-6 text-gray-900">Select An Exercise</div>

            {/* Search bar */}
            <div className="flex rounded-md shadow-sm mb-4">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>

                    <input
                        ref={searchInputRef}
                        type="text"
                        name="exerciseName"
                        id="exerciseName"
                        className="block w-full rounded-none rounded-l-md rounded-r-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Exercise Name"
                        onChange={handleSearchChange}
                    />
                </div>
            </div>

            {/* Exercise List */}
            <div className="border rounded-lg overflow-hidden">
                <div className="w-full h-96 overflow-auto">
                    {filteredExerciseOptions.map((exercise) => (
                        <a
                            key={exercise.id}
                            onClick={() => {
                                modalData.buttonAction(exercise)
                                HideModal()
                            }}
                        >
                            <li className="flex border-b-2 border-stone-100 justify-between p-4 items-center cursor-pointer hover:bg-stone-100">
                                <p className="text-gray-900">{exercise.name}</p>
                                <p className="text-sm text-gray-400">{capitalizeFirstLetter(exercise.category)}</p>
                            </li>
                        </a>
                    ))}
                </div>
            </div>
        </>
    )
}
