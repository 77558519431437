import { createSlice } from '@reduxjs/toolkit'

// Initial state
const initialState = {
    selectedPricingOption: null,
    promotionId: null,
    couponData: null,
    stripeCustomerId: null,
    stripePaymentMethodId: null,
    stripeSubscriptionId: null,
    stripeClientSecret: null
}

// Subscription Slice
const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setReduxSelectedPricingOption: (state, action) => { state.selectedPricingOption = action.payload },
    setReduxPromotionId: (state, action) => { state.promotionId = action.payload },
    setReduxCouponData: (state, action) => { state.couponData = action.payload; },
    setReduxStripeCustomerId: (state, action) => { state.stripeCustomerId = action.payload },
    setReduxStripePaymentMethodId: (state, action) => { state.stripePaymentMethodId = action.payload },
    setReduxStripeSubscriptionId: (state, action) => { state.stripeSubscriptionId = action.payload },
    setReduxStripeClientSecret: (state, action) => { state.stripeClientSecret = action.payload },
    resetSubscriptionState: () => initialState
  },
});

export const {
  setReduxSelectedPricingOption,
  setReduxPromotionId, 
  setReduxCouponData,
  setReduxStripeCustomerId,
  setReduxStripePaymentMethodId,
  setReduxStripeSubscriptionId,
  setReduxStripeClientSecret,
  resetSubscriptionState
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;