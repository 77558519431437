import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setWeeklyCalendarWeekOffset } from 'redux/slices/userInterface'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon, XCircleIcon, ExclamationTriangleIcon, ChartBarSquareIcon, SparklesIcon } from '@heroicons/react/24/outline'
import { useGetEntireWeeklyCalendarJSON } from 'redux/customHooks/useGetEntireWeeklyCalendarJSON'
import { WeekNames, NoSeasonPhaseJSON, SeasonPhaseNames, SeasonPhaseDescriptions, WorkoutTemplateNames } from 'functions/weeklyCalendarUtils'
import { GetProgramPhaseColorHex, GetProgramPhaseBarGradientHexCodes } from 'redux/slices/brand'
import { WeekOfTheYearKey } from 'classes/WeekOfTheYearKey'
import restIcon from 'media/icons/rest.png'

import CustomHeader from 'components/Header/CustomHeader'

export default function ProgramOverview() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [weeklyCalendarVisibleSegment, setWeeklyCalendarVisibleSegment] = useState([])

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const presentWeekOfTheYearKey = useSelector((state) => state.user.presentWeekOfTheYearKey)
    const weeklyCalendarWeekOffset = useSelector((state) => state.userInterface.weeklyCalendarWeekOffset)
    const entireWeeklyCalendarJSON = useGetEntireWeeklyCalendarJSON()

    const GetProgramWeekBarStyle = (programSliceWeekIndex, programSliceWeek, presentWeekOfTheYearKey) => {

        var style = "w-full rounded-t-md "
    
        // Make all future weeks dimmed
        if (new WeekOfTheYearKey(presentWeekOfTheYearKey) < new WeekOfTheYearKey(programSliceWeek.weekOfTheYearKey)) style += "opacity-60 "
        
        // Always highlight the middle bar in the component with a white ring
        if (programSliceWeekIndex === 4) {
            if (styleConfig.trainingChartTheme === "dark") style += "border-x border-t border-white "
            if (styleConfig.trainingChartTheme === "light") style += "border-x border-t border-zinc-800 "
        }
    
        // For No Season style use little white bar
        if (programSliceWeek.programPhaseKey === "no-season"){ style += "h-2"; return style; }
    
        // Set bar height based on taper level
        if (programSliceWeek.taperData.taperLevel === 'taper-none') style += "h-16 "
        if (programSliceWeek.taperData.taperLevel === 'taper-one') style += "h-12 "
        if (programSliceWeek.taperData.taperLevel === 'taper-two') style += "h-8 "
        if (programSliceWeek.taperData.taperLevel === 'taper-three') style += "h-4 "
    
        return style;
    
    }

    const HandleCalendarWeekOffsetChange = () => {
        // Generate array of all weekOfTheYearKeys we need
        var segmentWeekOfTheYearKeys = []

        segmentWeekOfTheYearKeys.push(WeekOfTheYearKey.constructWithWeekOffset(weeklyCalendarWeekOffset - 4))
        segmentWeekOfTheYearKeys.push(WeekOfTheYearKey.constructWithWeekOffset(weeklyCalendarWeekOffset - 3))
        segmentWeekOfTheYearKeys.push(WeekOfTheYearKey.constructWithWeekOffset(weeklyCalendarWeekOffset - 2))
        segmentWeekOfTheYearKeys.push(WeekOfTheYearKey.constructWithWeekOffset(weeklyCalendarWeekOffset - 1))
        segmentWeekOfTheYearKeys.push(WeekOfTheYearKey.constructWithWeekOffset(weeklyCalendarWeekOffset))
        segmentWeekOfTheYearKeys.push(WeekOfTheYearKey.constructWithWeekOffset(weeklyCalendarWeekOffset + 1))
        segmentWeekOfTheYearKeys.push(WeekOfTheYearKey.constructWithWeekOffset(weeklyCalendarWeekOffset + 2))
        segmentWeekOfTheYearKeys.push(WeekOfTheYearKey.constructWithWeekOffset(weeklyCalendarWeekOffset + 3))
        segmentWeekOfTheYearKeys.push(WeekOfTheYearKey.constructWithWeekOffset(weeklyCalendarWeekOffset + 4))

        // console.log(segmentWeekOfTheYearKeys)

        var newSegment = []

        // Loop over all weekOfTheYearKeys we just cached
        segmentWeekOfTheYearKeys.forEach((cachedWeekOfTheYearKey) => {
            // Search backend data for the weekOfTheYearKey
            var seasonPhaseDataIndex = entireWeeklyCalendarJSON.findIndex((seasonPhase) => seasonPhase.weekOfTheYearKey === cachedWeekOfTheYearKey.toString())

            // If it does not exist, populate empty JSON
            if (seasonPhaseDataIndex === -1) {
                var newNoSeasonData = { ...NoSeasonPhaseJSON }

                newNoSeasonData.weekOfTheYearKey = cachedWeekOfTheYearKey.toString()

                if (new WeekOfTheYearKey(newNoSeasonData.weekOfTheYearKey).lessThan(new WeekOfTheYearKey(presentWeekOfTheYearKey))) {
                    newNoSeasonData.calendarDataKey = 'past'
                } else {
                    newNoSeasonData.calendarDataKey = 'future'
                }

                newSegment.push(newNoSeasonData)
            }

            // Otherwise use backend data
            else {
                newSegment.push({ ...entireWeeklyCalendarJSON[seasonPhaseDataIndex] })
            }
        })

        // console.log(newSegment);

        setWeeklyCalendarVisibleSegment(newSegment)
    }

    const OnViewWorkoutButton = (workoutTemplateKey) => {
        const props = {
            state: {
                weekOfTheYearKey: weeklyCalendarVisibleSegment[4].weekOfTheYearKey,
                workoutTemplateKey: workoutTemplateKey,
                doneNavigation: 'back',
            },
        }

        navigate('/workout/summary', props)
    }

    useEffect(() => {
        HandleCalendarWeekOffsetChange()
    }, [weeklyCalendarWeekOffset])

    if (weeklyCalendarVisibleSegment.length === 0) return <></>

    // console.log(weeklyCalendarVisibleSegment[4])

    return (
        <>
            <CustomHeader text="Program Overview" backButtonAction={() => navigate('/home')} />

            {/* Date Selection */}
            <div 
                className={`
                    flex items-center justify-center pt-6
                    ${styleConfig.trainingChartTheme === 'dark' ? 'bg-zinc-800' : ''}
                    ${styleConfig.trainingChartTheme === 'light' ? 'bg-gray-300' : ''}
                `}
            >
                {/* Previous Week Button */}
                <div className="h-full flex-grow flex items-center justify-start" onClick={() => dispatch(setWeeklyCalendarWeekOffset(weeklyCalendarWeekOffset - 1))}>
                    <ChevronLeftIcon 
                        className={`
                            h-6 px-4 pointer-events-none
                            ${styleConfig.trainingChartTheme === 'dark' ? 'text-white' : ''}
                            ${styleConfig.trainingChartTheme === 'light' ? 'text-gray-800' : ''}
                        `}
                    />
                </div>

                <div 
                    className={`
                        flex flex-col items-center text-lg text-center py-2
                        ${styleConfig.trainingChartTheme === 'dark' ? 'text-white' : ''}
                        ${styleConfig.trainingChartTheme === 'light' ? 'text-gray-800' : ''}
                    `}
                >
                    <div className='font-bold text-sm'>
                        {new WeekOfTheYearKey(weeklyCalendarVisibleSegment[4].weekOfTheYearKey).toDateRangeString('MMMM Do')}
                    </div>
                </div>

                {/* Next Week Button */}
                <div className="h-full flex-grow flex items-center justify-end" onClick={() => dispatch(setWeeklyCalendarWeekOffset(weeklyCalendarWeekOffset + 1))}>
                    <ChevronRightIcon 
                        className={`
                            h-6 px-4 pointer-events-none
                            ${styleConfig.trainingChartTheme === 'dark' ? 'text-white' : ''}
                            ${styleConfig.trainingChartTheme === 'light' ? 'text-gray-800' : ''}
                        `}
                    />
                </div>
            </div>

            {/* Season Phase Visualization */}
            <div 
                className={`
                    w-full grid grid-cols-9 px-4
                    ${styleConfig.trainingChartTheme === 'dark' ? 'bg-gradient-to-b from-zinc-800 to-zinc-600' : ''}
                    ${styleConfig.trainingChartTheme === 'light' ? 'bg-gradient-to-b from-gray-300 to-gray-100 border-b border-gray-600' : ''}
                `}
            >

                {weeklyCalendarVisibleSegment.map((segmentWeek, segmentWeekIndex) => (
                    <div key={segmentWeekIndex} className="flex flex-col items-center justify-end h-28 px-1">
                        {/* Only show icons for program weeks */}
                        {segmentWeek.programPhaseKey !== 'no-season' && (
                            <>
                                {/* Past Weeks */}
                                {segmentWeek.calendarDataKey === 'past' && (
                                    <>
                                        {segmentWeek.weekCompletionStatusKey === 'incomplete' && <XCircleIcon className="h-5 w-5 rounded-full text-red-400 mt-4 mb-auto" />}

                                        {segmentWeek.weekCompletionStatusKey === 'half-complete' && <CheckCircleIcon className="h-5 w-5 rounded-full text-yellow-400 mt-4 mb-auto" />}

                                        {segmentWeek.weekCompletionStatusKey === 'complete' && <CheckCircleIcon className="h-5 w-5 rounded-full text-green-400 mt-4 mb-auto" />}
                                    </>
                                )}

                                {/* Current Week */}
                                {segmentWeek.calendarDataKey === 'active' && <div className="h-4 w-4 rounded-full ring-1 ring-gray-400 ring-inset mt-[18px] mb-auto" />}
                            </>
                        )}

                        {/* Bar Styling */}
                        <div
                            className={GetProgramWeekBarStyle(segmentWeekIndex, segmentWeek, presentWeekOfTheYearKey)}
                            style={{
                                backgroundImage: `linear-gradient(to top, ${GetProgramPhaseBarGradientHexCodes(styleConfig, segmentWeek.programPhaseKey)[0]}, ${GetProgramPhaseBarGradientHexCodes(styleConfig, segmentWeek.programPhaseKey)[1]})`
                            }}
                            onClick={() => dispatch(setWeeklyCalendarWeekOffset(weeklyCalendarWeekOffset + (segmentWeekIndex - 4)))}
                        />
                    </div>
                ))}
            </div>

            {/* Season Phase Tag */}
            <div className="px-4 py-2 w-full space-y-2 overflow-hidden relative">
                <div 
                    className='mx-auto text-center text-sm font-semibold py-0.5 rounded-full'
                    style={{ backgroundColor: GetProgramPhaseColorHex(styleConfig,weeklyCalendarVisibleSegment[4].programPhaseKey) }}
                >
                    {SeasonPhaseNames[weeklyCalendarVisibleSegment[4].programPhaseKey]} Phase
                </div>
            </div>

            {/* Season Phase Info */}
            {weeklyCalendarVisibleSegment[4].programPhaseKey in SeasonPhaseDescriptions && (
                <div className="px-4 pb-4 bg-white w-full space-y-2 overflow-hidden relative" style={{ height: 'auto' }}>
                    <div className="border border-gray-800 rounded-lg p-4 space-y-4">
                        {/* What to expect */}
                        <div className="space-y-2">
                            <div className="text-center text-gray-800 font-bold text-sm">What To Expect</div>
                            <div
                                className="text-gray-800 text-xs whitespace-pre-line"
                                dangerouslySetInnerHTML={{ __html: SeasonPhaseDescriptions[weeklyCalendarVisibleSegment[4].programPhaseKey].expectation }}
                            />
                        </div>

                        {/* Key Focus Areas */}
                        <div className="space-y-2">
                            <div className="text-center text-gray-800 font-bold text-sm">Key Focus Areas</div>
                            <div
                                className="text-gray-800 text-xs whitespace-pre-line"
                                dangerouslySetInnerHTML={{ __html: SeasonPhaseDescriptions[weeklyCalendarVisibleSegment[4].programPhaseKey].focus }}
                            />
                        </div>

                        {/* Training Highlights */}
                        <div className="space-y-2">
                            <div className="text-center text-gray-800 font-bold text-sm">Training Highlights</div>
                            <div
                                className="text-gray-800 text-xs whitespace-pre-line"
                                dangerouslySetInnerHTML={{ __html: SeasonPhaseDescriptions[weeklyCalendarVisibleSegment[4].programPhaseKey].highlights }}
                            />
                        </div>

                    </div>
                </div>
            )}

            {weeklyCalendarVisibleSegment[4].programPhaseKey !== 'no-season' && (
                <div className="p-4 space-y-4">
                    {/* Week Overview */}
                    <div className="text-center space-y-2 rounded-xl">
                        <div className="text-lg font-semibold">
                            {weeklyCalendarVisibleSegment[4].taperData.taperType === 'program' && <>{WeekNames[weeklyCalendarVisibleSegment[4].programWeekKey]}</>}
                            {weeklyCalendarVisibleSegment[4].taperData.taperType === 'event' && <>{weeklyCalendarVisibleSegment[4].taperData.eventName} Prep</>}
                            {weeklyCalendarVisibleSegment[4].taperData.taperType === 'workout-history' && <>Return To Training</>}
                        </div>

                        {/* Active EDS Status */}
                        {!weeklyCalendarVisibleSegment[4].taperData.useTaper && (
                            <div className="flex items-center justify-center mx-auto gap-x-1.5 py-1 rounded-full bg-green-400">
                                <CheckCircleIcon className="h-3" />
                                <div>Feedback Active</div>
                            </div>
                        )}

                        {/* Limited EDS Status */}
                        {weeklyCalendarVisibleSegment[4].taperData.useTaper && (
                            <div className="flex items-center justify-center mx-auto gap-x-1.5 py-1 rounded-full bg-yellow-300">
                                <ExclamationTriangleIcon className="h-3" />
                                <div>Feedback Limited</div>
                            </div>
                        )}
                    </div>

                    {/* Completed Workouts */}
                    {weeklyCalendarVisibleSegment[4].calendarDataKey === 'past' && (
                        <div className="space-y-2">
                            {Object.keys(weeklyCalendarVisibleSegment[4].completionData).map((key, index) => (
                                <div key={index} className="h-10 pl-2 pr-1 py-1 flex items-center justify-center">
                                    {/* Completed Icon */}
                                    {weeklyCalendarVisibleSegment[4].completionData[key].completed && <CheckCircleIcon className="h-5 text-green-400 mr-2" />}

                                    {/* Incomplete Icon */}
                                    {!weeklyCalendarVisibleSegment[4].completionData[key].completed && <XCircleIcon className="h-5 text-red-400 mr-2" />}

                                    {/* Workout Template Name */}
                                    <div className="text-lg flex-grow">{WorkoutTemplateNames[key]}</div>

                                    {/* View Button */}
                                    {weeklyCalendarVisibleSegment[4].completionData[key].completed && (
                                        <div className="py-1 px-8 rounded-xl border border-gray-300" onClick={() => OnViewWorkoutButton(key)}>
                                            View
                                        </div>
                                    )}

                                    {/* Faded View Button */}
                                    {!weeklyCalendarVisibleSegment[4].completionData[key].completed && (
                                        <div className="py-1 px-8 rounded-xl border border-gray-300 bg-gray-200 text-gray-500">View</div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}

                    {weeklyCalendarVisibleSegment[4].calendarDataKey === 'active' && (
                        <div className="space-y-2">
                            {Object.keys(weeklyCalendarVisibleSegment[4].completionData).map((key, index) => (
                                <div key={index} className="h-10 pl-2 pr-1 py-1 flex items-center justify-center">
                                    {/* Completed Icon */}
                                    {weeklyCalendarVisibleSegment[4].completionData[key].completed && <CheckCircleIcon className="h-5 text-green-400 mr-2" />}

                                    {/* Incomplete Icon */}
                                    {!weeklyCalendarVisibleSegment[4].completionData[key].completed && <div className="h-4 w-4 rounded-full border border-gray-600 mr-3" />}

                                    {/* Workout Template Name */}
                                    <div className="text-lg flex-grow">{WorkoutTemplateNames[key]}</div>

                                    {/* View Button */}
                                    {weeklyCalendarVisibleSegment[4].completionData[key].completed && (
                                        <div className="py-1 px-8 rounded-xl border border-gray-300" onClick={() => OnViewWorkoutButton(key)}>
                                            View
                                        </div>
                                    )}

                                    {!weeklyCalendarVisibleSegment[4].completionData[key].completed && <div className="py-1 px-4 rounded-xl text-gray-500">Incomplete</div>}
                                </div>
                            ))}
                        </div>
                    )}

                    {weeklyCalendarVisibleSegment[4].calendarDataKey === 'future' && (
                        <div className="flex flex-col items-center text-center text-gray-400 py-4 space-y-4">
                            <SparklesIcon className="h-20 w-20 text-gray-300" />
                            <div>WhichWeight programs generate Monday morning of every week</div>
                            <div>Check back soon for more workouts</div>
                        </div>
                    )}
                </div>
            )}

            {weeklyCalendarVisibleSegment[4].programPhaseKey === 'no-season' && (
                <>
                    {weeklyCalendarVisibleSegment[4].calendarDataKey === 'past' && (
                        <div className="p-16 space-y-4 text-center">
                            <img src={restIcon} alt="Rest Icon" className="mx-auto h-16 text-gray-400 opacity-30" />
                            <div className="text-xl font-semibold text-gray-600">Rest Week</div>
                            <div className="text-gray-600">No WhichWeight programming here</div>
                        </div>
                    )}

                    {weeklyCalendarVisibleSegment[4].calendarDataKey === 'future' && (
                        <div className="p-6 space-y-4 text-center">
                            <ChartBarSquareIcon className="mx-auto h-16 text-gray-400" />

                            <div className="text-xl font-semibold text-gray-600">No Programming Scheduled</div>

                            <div className="py-2 px-8 text-center rounded-xl border border-gray-300" onClick={() => navigate('/events')}>
                                Add An Event
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    )
}
