import { useNavigate } from 'react-router-dom'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import CustomHeader from 'components/Header/CustomHeader'

export default function FrequentlyAskedQuestions() {
    
    const navigate = useNavigate()
    const [expandedIndex, setExpandedIndex] = useState(null)

    const faqs = [
        {
            question: "What is the Whichweight approach to strength training?",
            answer: "Our approach, known as the Olympian Proven Method, is a science-backed strategy that integrates strength and endurance training to enhance overall athletic performance. We focus on building a solid foundation in the offseason, maintaining gains during the season, and peaking at the right time for competitions.",
        },
        {
            question: "What are Olympic Building Block Exercises?",
            answer: "These are targeted movements designed to correct imbalances, enhance core stability, and strengthen key areas like the lumbopelvic complex. While some of these exercises might seem easy or rudimentary, DON'T SLEEP ON THESE. Slow down and focus on doing them REALLY well—they’re critical to your long-term success.",
        },
        {
            question: "How does Whichweight differ from traditional strength training programs?",
            answer: "Unlike traditional programs that may focus solely on muscle building, our method prioritizes the balance between strength and endurance, injury prevention, and optimal physiological response. We tailor your training to your sport’s specific demands, ensuring maximum performance without overtraining. Remember, even if some exercises seem simple, their effectiveness lies in the precision of execution.",
        },
        {
            question: "Will I experience muscle soreness after workouts?",
            answer: "In the early phases, you might experience moderate soreness as your body adapts to new movements. However, our goal is to progress to a point where soreness is minimal, allowing you to train consistently without excessive fatigue.",
        },
        {
            question: "How does Whichweight help prevent injuries?",
            answer: "Our method includes corrective exercises and periodized training to address key weaknesses and imbalances. By building a strong foundation and focusing on injury-prone areas, we reduce the risk of overuse injuries, keeping you healthy and performing at your best. Don’t underestimate the power of the foundational exercises—they are essential to injury prevention.",
        },
        {
            question: "What kind of results can I expect from using Whichweight?",
            answer: "You can expect improved strength, enhanced endurance, better balance, and reduced injury risk. Our method has consistently helped athletes reach new personal bests and achieve their competitive goals. Success lies in mastering even the simplest movements with precision.",
        }
    ]

    const toggleFAQ = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index)
    }
    
    return (
        <>
            {/* Header */}
            <CustomHeader text="FAQs" backButtonAction={() => navigate('/profile/helpCenter')} />

            <div className="bg-white pb-12">
                <dl className="space-y-6 divide-y divide-gray-900/10">
                    {faqs.map((faq, index) => (
                        <div key={index} className="pt-4 px-4">
                            <dt>
                            <button 
                                onClick={() => toggleFAQ(index)}
                                className="flex w-full items-start justify-between text-left text-gray-900"
                            >
                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                <span className="ml-6 flex h-7 items-center">
                                    {expandedIndex === index ? (
                                        <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                    )}
                                </span>
                            </button>
                            </dt>
                            {expandedIndex === index && (
                                <dd className="mt-2 pr-12">
                                    <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                                </dd>
                            )}
                        </div>
                    ))}
                </dl>
            </div>
        </>
    )

}
