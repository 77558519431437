export default function BillingCycle({ subscriptionData }) {
    if (subscriptionData.status === 'active' || subscriptionData.status === 'trialing') {
        if (!subscriptionData.cancel_at_period_end) {
            return (
                <div className="flex border-b py-3">
                    <div className="pl-2 flex-grow text-gray-600">Billing Cycle</div>
                    <div className="pr-2 text-right">
                        Every {subscriptionData.plan.interval_count} {subscriptionData.plan.interval}s
                    </div>
                </div>
            )
        }
    }
}
