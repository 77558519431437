import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ConvertPoundsToNearestFiveKilograms } from 'functions/measurementSystemUtils'
import { useNavigate } from 'react-router-dom'
import { WeekOfTheYearKey } from 'classes/WeekOfTheYearKey'
import { GetPresentWeekOverview } from 'redux/selectors/PresentWeekOverviewSelector'
import { AreaChart, Area, LabelList, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts'
import { GetPrimaryColorHex } from 'redux/slices/brand'

export default function VolumeLiftedChart({ height = 130 }) {
    const navigate = useNavigate()

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const measurementSystem = useSelector((state) => state.user.measurementSystem)
    const presentWeek = GetPresentWeekOverview()

    const [volumeLiftedCompletedChartData, setVolumeLiftedCompletedChartData] = useState([])

    // console.log(volumeLiftedCompletedChartData)

    // TODO Fix this?
    useEffect(() => {
        var temp_volumeLiftedCompletedChartData = JSON.parse(JSON.stringify(presentWeek.weeklyVolumeLiftedCompletedChartData.slice().reverse()))

        // Convert to kilograms if metric
        if (measurementSystem === 'metric') {
            temp_volumeLiftedCompletedChartData.map((dataPoint) => (dataPoint['weeklyVolumeLifted'] = ConvertPoundsToNearestFiveKilograms(dataPoint['weeklyVolumeLifted'])))
        }

        setVolumeLiftedCompletedChartData(temp_volumeLiftedCompletedChartData)
    }, [])

    return (
        <div onClick={() => navigate('/reports/list')}>
            {/* Header */}
            <div className="py-1 text-center text-xs text-gray-600">Weekly Volume Lifted {measurementSystem === 'metric' ? '(kg)' : '(lbs)'}</div>

            <div className="bg-white rounded-lg">
                {/* Chart */}
                <ResponsiveContainer 
                    width="100%" 
                    height={height}
                >
                    <AreaChart 
                        data={volumeLiftedCompletedChartData} 
                        margin={{ top: 4, right: 20, left: -20, bottom: 20 }}
                    >

                        <CartesianGrid strokeDasharray="1 2" stroke="#D3D3D3" horizontal={true} />

                        <XAxis
                            dataKey="weekOfTheYearKey"
                            style={{ fontSize: '0.5rem' }}
                            tickFormatter={(weekOfTheYearKey) => new WeekOfTheYearKey(weekOfTheYearKey).toDateRangeString('M/D')} 
                            padding={{ left: 10, right: 10 }}
                            angle={-30}
                            textAnchor="end"
                        />

                        <YAxis style={{ fontSize: '0.7rem' }} tickLine={false} padding={{ top: 10 }} />

                        <Area 
                            type="monotone" 
                            dataKey="weeklyTotalVolumeCompleted" 
                            name="Volume Lifted" 
                            stroke={GetPrimaryColorHex(styleConfig)}
                            fill={GetPrimaryColorHex(styleConfig,-2)}
                        >
                            <LabelList 
                                dataKey="weeklyTotalVolumeCompleted" 
                                position="top" 
                                style={{ fontSize: '0.7rem' }} 
                            />
                        </Area>
                        
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}
