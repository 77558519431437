import { useNavigate } from 'react-router-dom'
import { PencilSquareIcon } from '@heroicons/react/20/solid'
import { ConvertInchesToCentimeters, ConvertPoundsToKilograms } from 'functions/measurementSystemUtils'
import { useGetFitnessProfile } from 'redux/customHooks/useGetFitnessProfile'
import moment from 'moment'

import CustomHeader from 'components/Header/CustomHeader'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const ProgressionLevelNames = {
    0: 'Beginner',
    1: 'Foundational',
    2: 'Intermediate',
    3: 'Progressed',
    4: 'Advanced',
}

export default function ViewFitnessProfile() {
    const navigate = useNavigate()
    const fitnessProfile = useGetFitnessProfile()

    const OnEditButton = () => {
        const props = {
            state: {
                passedFitnessProfile: fitnessProfile,
            },
        }

        navigate('/fitnessProfile/edit', props)
    }

    return (
        <>
            {/* Header */}
            <CustomHeader text="Fitness Profile" backButtonAction={() => navigate('/home')} />

            {fitnessProfile === null && <MediumLoadingComponent />}

            {fitnessProfile !== null && (
                <div className="space-y-4 p-4">
                    {/* Fitness Profile */}
                    <div className="bg-gray-100 shadow px-4 py-2 space-y-1">
                        {/* Date of Birth */}
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Date of Birth</div>
                            <div>{moment(fitnessProfile.birthday).format('MMMM D, YYYY')}</div>
                        </div>

                        {/* Biological Gender */}
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Biological Gender</div>
                            <div>{capitalizeFirstLetter(fitnessProfile.gender)}</div>
                        </div>

                        {/* Measurement System */}
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Measurement System</div>
                            <div>{capitalizeFirstLetter(fitnessProfile.measurementSystem)}</div>
                        </div>

                        {/* Height */}
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Height</div>

                            {fitnessProfile.measurementSystem === 'imperial' && (
                                <div>
                                    {Math.floor(fitnessProfile.height / 12)}' {fitnessProfile.height % 12}"
                                </div>
                            )}

                            {fitnessProfile.measurementSystem === 'metric' && <div>{Math.round(ConvertInchesToCentimeters(fitnessProfile.height))} cm</div>}
                        </div>

                        {/* Weight */}
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Weight</div>
                            {fitnessProfile.measurementSystem === 'imperial' && <div>{fitnessProfile.weight} lbs</div>}
                            {fitnessProfile.measurementSystem === 'metric' && <div>{Math.round(ConvertPoundsToKilograms(fitnessProfile.weight))} kgs</div>}
                        </div>

                        {/* Sport */}
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Sport</div>
                            <div>{fitnessProfile.sportName}</div>
                        </div>

                        {/* Days Of Training */}
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Avg. Training Days Per Week</div>
                            <div>
                                {fitnessProfile.daysOfTraining} day{fitnessProfile.daysOfTraining !== 1 ? 's' : ''}
                            </div>
                        </div>

                        {/* Months Of Training */}
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Months Trained (Past Year)</div>
                            <div>
                                {fitnessProfile.monthsOfTraining} month{fitnessProfile.monthsOfTraining !== 1 ? 's' : ''}
                            </div>
                        </div>

                        {/* Years Of Training */}
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Years of Strength Training</div>
                            <div>
                                {fitnessProfile.yearsOfTraining} year{fitnessProfile.yearsOfTraining !== 1 ? 's' : ''}
                            </div>
                        </div>

                        {/* Years Of Training */}
                        <div className="flex text-gray-500">
                            <div className="flex-grow">Progression Level</div>
                            <div>{ProgressionLevelNames[fitnessProfile.progressionLevel]}</div>
                        </div>
                    </div>

                    {/* Edit Fitness Profile Button */}
                    <button
                        type="button"
                        className="w-full flex justify-center items-center rounded-full bg-white my-2 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => OnEditButton()}
                    >
                        <p>Edit</p>
                        <PencilSquareIcon className="ml-1 text-stone-500" style={{ width: 15 }} />
                    </button>
                </div>
            )}
        </>
    )
}
