import { useContext } from 'react'
import { ModalContext } from '../ModalContext'

export default function VideoPlayerModal() {
    const { modalData } = useContext(ModalContext)

    return (
        <>
            {/* Header */}
            <div className="mb-4 flex-grow text-lg font-semibold leading-6 text-gray-900">
                <p>{modalData.title}</p>
            </div>

            {/* Body */}
            <div className="h-full overflow-auto space-y-4 pb-20">
                
                {/* Video Instructions */}
                <div className='overflow-hidden rounded-lg'>
                    <video 
                        width="400" 
                        height="720" 
                        muted
                        controls
                        playsInline 
                        loop
                        x-webkit-airplay="deny"
                        onError={(e) => {
                            console.error('Video playback error:', e);
                        }}
                    >
                        <source src={modalData.videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                {modalData.description.length > 0 && <div className="text-left">{modalData.description}</div>}

            </div>
        </>
    )
}
