import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { setReduxYearsOfTraining } from 'redux/slices/onboarding'

import ScreenFrame from 'components/Frame/ScreenFrame'
import StepHeader from 'components/Header/StepHeader'
import BodyFrame from 'components/Frame/BodyFrame'
import TitleText from 'components/Onboarding/TitleText'

export default function YearsOfTrainingInputView() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const options = [
        { value: 0, text: "I don't do strength training" },
        { value: 0, text: 'I have not been consistent' },
        { value: 0, text: 'Less than a year' },
        { value: 1, text: '1 year' },
        { value: 2, text: '2 years' },
        { value: 3, text: '3 years' },
        { value: 4, text: '4 years' },
        { value: 5, text: '5+ years' },
    ]

    const OnNextButton = (value) => {
        dispatch(setReduxYearsOfTraining(value))

        navigate('/onboarding/weekDaySchedule')
    }

    return (
        <ScreenFrame>
            <StepHeader title='Fitness Profile' startPercentage={40} endPercentage={60} />

            <div className="flex-1 flex flex-col pt-4 pb-12 gap-y-4 overflow-y-auto bg-white">

                <div className='px-4 space-y-2'>
                    <TitleText text={'How many years have you been consistently strength training for?'} />
                    <div className="text-lg text-gray-600">WhichWeight defines consistency as training at least twice a week for at least 10 months out of the year</div>
                </div>

                <div className="mt-2 -mx-4">
                    {options.map((option, index) => (
                        <div key={index} className="flex border-b py-4 px-8" onClick={() => OnNextButton(option.value)}>
                            <div className="flex-grow text-xl">{option.text}</div>
                            <ChevronRightIcon className="h-6 text-gray-500" />
                        </div>
                    ))}
                </div>
            </div>
        </ScreenFrame>
    )
}
