import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GetPresentWeekOverview } from 'redux/selectors/PresentWeekOverviewSelector'
import { setSidebarOpen } from 'redux/slices/userInterface'
import { Bars3Icon } from '@heroicons/react/20/solid'
import { ClipboardDocumentListIcon, QuestionMarkCircleIcon, ExclamationTriangleIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { GetPrimaryColorHex } from 'redux/slices/brand'

export default function HomeHeader() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const presentWeek = GetPresentWeekOverview()
    const membershipActive = useSelector((state) => state.user.membershipActive)
    const subscriptionStatus = useSelector((state) => state.user.subscriptionStatus)
    const usedFreeTrial = useSelector((state) => state.user.usedFreeTrial)

    return (
        <>
            {/* Header Bar */}
            <div className="h-16 pt-2 flex justify-between items-center">
                {/* Sidebar Menu */}
                <div className="pl-4 flex-grow" onClick={() => dispatch(setSidebarOpen(true))}>
                    <Bars3Icon className="h-6" />
                </div>

                {/* WhichWeight Logo */}
                <img src={styleConfig.logo} alt="WhichWeight Icon Logo" className="h-12 -my-1 pointer-events-none" />

                {/* Help Icon */}
                <div className="pr-4 flex-grow flex justify-end" onClick={() => navigate('/profile/helpCenter')}>
                    <QuestionMarkCircleIcon className="h-6" />
                </div>
            </div>

            {/* Action Cards */}
            <div className="px-4 space-y-2">

                {/* Take Assessment Card */}
                {presentWeek && presentWeek.promptForAssessment && 
                    <div 
                        className="text-xs p-4 flex items-center gap-x-2 rounded-lg border-l-4"
                        style={{
                            backgroundColor: GetPrimaryColorHex(styleConfig, -8),
                            borderColor: GetPrimaryColorHex(styleConfig)
                        }} 
                        onClick={() => navigate('/assessment/list')}
                    >
                        <ClipboardDocumentListIcon 
                            className="h-4 w-4" 
                            style={{ color: GetPrimaryColorHex(styleConfig) }}
                            aria-hidden="true" 
                        />
                        <div 
                            className="font-medium flex-grow"
                            style={{ color: GetPrimaryColorHex(styleConfig, 4) }}
                        >
                            FREE Strength Assessment!
                        </div>
                        <div 
                            className="underline"
                            style={{ color: GetPrimaryColorHex(styleConfig, 4) }}
                        >
                            Get Started
                        </div>
                    </div>
                }
                
                {/* Inactive Membership Card */}
                {!membershipActive && subscriptionStatus !== 'unpaid' && (
                    <div 
                        className="text-xs p-4 flex items-center gap-x-2 rounded-lg bg-yellow-50 border-l-4 border-yellow-400" 
                        onClick={() => navigate('/profile/membership')}
                    >
                        <ExclamationTriangleIcon className="h-4 w-4 text-yellow-400" aria-hidden="true" />
                        <div className="font-medium text-yellow-700 flex-grow">Membership Inactive</div>
                        <div className="text-yellow-700 underline hover:text-yellow-600">{usedFreeTrial ? 'Activate Now' : 'Start Free Trial'}</div>
                    </div>
                )}

                {/* Past Due Warning Card */}
                {membershipActive && subscriptionStatus === 'past_due' && (
                    <div 
                        className="text-xs p-4 flex items-center gap-x-2 rounded-lg bg-red-100 border-l-4 border-red-400" 
                        onClick={() => navigate('/profile/membership')}
                    >
                        <ExclamationTriangleIcon className="h-4 w-4 text-red-600" aria-hidden="true" />
                        <div className="font-medium text-red-700 flex-grow">Subscription Past Due</div>
                        <ChevronRightIcon className="h-4 w-4 text-red-600" />
                    </div>
                )}

                {/* Unpaid Warning Card */}
                {!membershipActive && subscriptionStatus === 'unpaid' && (
                    <div 
                        className="text-xs p-4 flex items-center gap-x-2 rounded-lg bg-red-100 border-l-4 border-red-400" 
                        onClick={() => navigate('/profile/membership')}
                    >
                        <ExclamationTriangleIcon className="h-4 w-4 text-red-600" aria-hidden="true" />
                        <div className="font-medium text-red-700 flex-grow">Subscription Unpaid</div>
                        <ChevronRightIcon className="h-4 w-4 text-red-600" />
                    </div>
                )}
            </div>
        </>
    )
}
