import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { setReduxMonthsOfTraining } from 'redux/slices/onboarding'

import ScreenFrame from 'components/Frame/ScreenFrame'
import StepHeader from 'components/Header/StepHeader'
import BodyFrame from 'components/Frame/BodyFrame'
import TitleText from 'components/Onboarding/TitleText'

export default function MonthsOfTrainingInputView() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const options = [
        { value: 0, text: "I don't do strength training" },
        { value: 0, text: 'I have not been consistent' },
        { value: 1, text: '1 month' },
        { value: 2, text: '2 months' },
        { value: 3, text: '3 months' },
        { value: 4, text: '4 months' },
        { value: 5, text: '5 months' },
        { value: 6, text: '6 months' },
        { value: 7, text: '7 months' },
        { value: 8, text: '8 months' },
        { value: 9, text: '9 months' },
        { value: 10, text: '10 months' },
        { value: 11, text: '11 months' },
        { value: 12, text: '12 months' },
    ]

    const OnNextButton = (value) => {
        dispatch(setReduxMonthsOfTraining(value))

        navigate('/onboarding/yearsOfTraining')
    }

    return (
        <ScreenFrame>
            <StepHeader title='Fitness Profile' startPercentage={20} endPercentage={40} />

            <div className="flex-1 flex flex-col pt-4 pb-12 gap-y-4 overflow-y-auto bg-white">

                <div className='px-4 space-y-2'>
                    <TitleText text={'In the past year, how many months have you consistently strength trained?'} />
                    <div className="text-lg text-gray-600">WhichWeight defines consistency as training at least twice a week</div>
                </div>

                <div className="mt-2 -mx-4">
                    {options.map((option, index) => (
                        <div key={index} className="flex border-b py-4 px-8" onClick={() => OnNextButton(option.value)}>
                            <div className="flex-grow text-xl">{option.text}</div>
                            <ChevronRightIcon className="h-6 text-gray-500" />
                        </div>
                    ))}
                </div>
            </div>
        </ScreenFrame>
    )
}
