import { WeekOfTheYearKey } from "classes/WeekOfTheYearKey"
import strengthIcon from 'media/icons/strength.png';
import mobilityIcon from 'media/icons/mobility.png';
import restIcon from 'media/icons/rest.png';

export const SeasonPhaseNames = {
    "no-season": "No Season",
    "re-introduction": "Re-Introduction",
    "reload": "Reload",
    "off-season": "Off Season",
    "early-season": "Early Season",
    "in-season": "In Season",
    "competition": "Competition",
    "championship": "Championship",
    "post-race-deload": "Post Race Deload"
}

export const SeasonPhaseDescriptions = {
    "re-introduction": {
        "expectation": "Your body is reawakening after a period of rest or lighter activity. We’re easing back into key exercises and movement patterns, starting with lighter loads to help your body remember how to move efficiently.",
        "focus": "<strong>Re-establishing Neuromuscular Coordination:</strong> Neuromuscular coordination refers to the way your brain and muscles communicate to produce movement. After a period of rest, this communication can become less efficient. By starting with lighter loads and focusing on fundamental movements, we allow the brain to rebuild these connections, ensuring that movements are smooth, efficient, and precise.\n\n<strong>Reviewing and Reintroducing Fundamental Exercises:</strong> The goal here is to remind your body of the key exercises and movement patterns that are essential for performance. Think of this as going back to basics—ensuring that the foundation of your movement is strong and that you’re not carrying over any bad habits or inefficiencies from before your break.\n\n<strong>Preparing Tendons and Ligaments for Increased Load:</strong> Tendons and ligaments, the tissues that connect muscles to bones and bones to each other, need time to adapt to increased loads. These tissues don’t get as much blood flow as muscles, so they adapt more slowly. By gradually increasing the load, we help these tissues strengthen and become more resilient, reducing the risk of injury as training intensifies.",
        "highlights": "<strong>Lighter Weight Exercises to Reset Movement Patterns:</strong> Starting with lighter weights helps your body focus on proper form and technique without the strain of heavy loads. This is essential for ensuring that you’re moving correctly, which lays the groundwork for future strength gains.\n\n<strong>Focus on Proper Form and Technique:</strong> Precision is key during this phase. By paying close attention to how you’re moving, we can correct any inefficiencies and ensure that every movement is as effective as possible, setting you up for success as the load increases.\n\n<strong>Gradual Progression to Ensure Safe Re-entry into Training:</strong> We’ll slowly increase the intensity of your workouts to avoid overwhelming your body. This gradual progression helps prevent injury and ensures that your muscles, tendons, and ligaments are ready for the challenges ahead."
    },
    "reload": {
        "expectation": "This phase is about gradually rebuilding your strength to where it was before any downtime. We’re methodically increasing load to ensure your muscles, tendons, and bones can handle the workload without risk of injury.",
        "focus": "<strong>Safely Reintroducing Load and Volume:</strong> After a break, your body has likely lost some of its previous conditioning, a process known as detraining. Reintroducing load and volume methodically allows your body to adapt without overwhelming it, helping to rebuild muscle strength and endurance gradually.\n\n<strong>Rebuilding Muscle Strength and Endurance:</strong> Muscle fibers need time to grow stronger and more resilient after a period of reduced activity. By progressively increasing the load, we stimulate hypertrophy (muscle growth) and rebuild endurance, preparing your muscles to handle greater demands.\n\n<strong>Allowing Your Endocrine System to Adapt and Regulate:</strong> The endocrine system, which includes glands like the adrenal glands that produce hormones like cortisol (a stress hormone) and testosterone (important for muscle growth), needs time to adapt to the increased physical demands. Gradually reintroducing load helps these hormones regulate properly, supporting recovery and growth without causing undue stress.",
        "highlights": "<strong>Progressive Loading to Avoid Overload:</strong> We carefully increase the weight and volume of your exercises to match your body’s readiness, avoiding sudden spikes that could lead to injury. This approach helps your body adapt effectively to increasing demands.\n\n<strong>Targeted Exercises to Rebuild Foundational Strength:</strong>We focus on exercises that target the major muscle groups, particularly those that may have lost strength during downtime. These exercises are chosen to rebuild the foundation of your physical performance.\n\n<strong>Close Monitoring of Recovery and Adaptation:</strong>During this phase, it’s crucial to pay attention to how your body responds to the increased load. We’ll monitor your recovery and make adjustments as needed to ensure you’re progressing safely and effectively."
    },
    "off-season": {
        "expectation": "Based on scientific research and our experience, we've crafted the most effective training program for your offseason. This phase is designed to build a solid foundation, focusing on strength, stability, and plyometric preparation. By understanding the science behind load tolerance and work capacity, you'll be empowered to reach new heights in your athletic performance. This strategic approach ensures that when the season begins, you'll have a strong base to build upon, reducing injury risk and enhancing overall performance.",
        "focus": "<strong>Foundation Building:</strong> We focus on improving your load tolerance, work capacity, and tissue capacity. By raising your ceiling, we also raise your floor, setting a strong base for the season.\n\n<strong>Core & Stability:</strong> Emphasis on the lumbopelvic complex, core stability, and correcting major asymmetries. Think of it as leveling the ground before building a house.\n\n<strong>Plyometric Prep:</strong> Laying the groundwork for plyometric exercises. After six weeks of plyometrics, your muscles handle stress better, reducing soreness and breakdown.",
        "highlights": "<strong>Higher Reps & Intensity:</strong> With less volume in your primary sport, we can push harder in the gym, improving strength and endurance without risking overtraining.\n\n<strong>Adaptation Phase:</strong> The offseason typically lasts around eight weeks, allowing for more aggressive training with performance gains possible, even with less endurance work.\n\n<strong>Targeting Weaknesses:</strong> This period is ideal for addressing any strength deficits or physiological weaknesses, combining hypertrophy, plyometrics, and foundational movements.\n\n<strong>Joint Function & Mobility:</strong> Focus on restoring joint mobility and function, setting the stage for the rest of the season."
    },
    "early-season": {
        "expectation": "Our early season program is grounded in science, ensuring you build on your offseason foundation with a focus on strength and power. By carefully managing intensity and volume, you’ll stimulate necessary adaptations while avoiding the pitfalls of overtraining. This phase is all about smart progression—using your knowledge of hormonal balance and muscle recovery to maintain peak performance throughout the season. You'll be empowered with a strategy that keeps you strong, resilient, and ready for the challenges ahead.",
        "focus": "<strong>Lower Reps & Moderate Intensity:</strong> Focus on lower reps with moderate to low plyometric intensity. This helps stimulate growth without overwhelming your body’s ability to recover.\n\n<strong>Hormonal Balance:</strong> The endocrine response is moderate to moderately high, which promotes adaptation without exhausting your recovery capacity.",
        "highlights": "<strong>In-Season Phase:</strong> Lasts about eight weeks. After six weeks, the impact of plyometrics on muscle soreness and damage decreases, making it a great time to pair them with max strength exercises.\n\n<strong>Fatigue Management:</strong> As training volume increases, be mindful of cortisol and creatine kinase levels to prevent overtraining, injury, and chronic fatigue."
    },
    "in-season": {
        "expectation": "This is the phase where the bulk of your training intensity ramps up. You’ll be combining high load/low rep exercises with low load/high rep exercises to build tolerance and capacity, conditioning your body for race-specific demands.",
        "focus": "<strong>Balancing High and Low Load Exercises:</strong>High load/low rep exercises build maximal strength, which is crucial for powerful movements, while low load/high rep exercises build muscular endurance. This balance ensures that you’re developing both the power needed for sprints and the endurance needed for long-distance events.\n\n<strong>Building Endurance and Race-Specific Strength:</strong> During this phase, your training becomes more specific to the demands of your sport. Whether you’re training for a marathon or a cycling event, the exercises are tailored to improve the exact type of strength and endurance you’ll need on race day.\n\n<strong>Conditioning Soft Tissues to Handle Stress:</strong> As training intensity increases, your muscles, tendons, and ligaments are subjected to more stress. This phase conditions these tissues to tolerate higher loads and repetitive movements, reducing the risk of overuse injuries.",
        "highlights": "<strong>High-Intensity Strength Sessions with Varied Rep Schemes:</strong> We’ll use a mix of heavy lifting with lower reps to build strength, and lighter weights with higher reps to build endurance. This varied approach ensures that all aspects of your performance are improving simultaneously.\n\n<strong>Focus on Endurance and Resilience:</strong> In addition to building strength, we’re also training your body to recover quickly and sustain performance over time. This phase pushes your limits to prepare you for the challenges of your sport.\n\n<strong>Monitoring Fatigue and Adjusting Load as Needed:</strong> Fatigue management is critical during this phase. We’ll keep a close eye on how you’re feeling and adjust the training load accordingly to avoid overtraining and ensure optimal performance."
    },
    "competition": {
        "expectation": "Our competition phase training is scientifically designed to help you maintain the strength you've built while focusing on your primary goal—racing. The plan emphasizes low-endocrine impact and careful load management to keep you performing at your best without adding unnecessary fatigue. By understanding the balance between maintaining strength and avoiding overtraining, you’ll be empowered to navigate this crucial phase with confidence, knowing that your training is optimized for success.",
        "focus": "<strong>Strength & Power Maintenance:</strong> Continue strength and power movements, with a focus on isometric loads lasting 3 to 10 seconds under high load. Keep plyometric intensity low unless you have prior training.\n\n<strong>Low-Endocrine Response:</strong> Exercises should trigger a low to moderately low endocrine response to avoid unnecessary strain and ensure recovery.",
        "highlights": "<strong>Competition Phase:</strong> This phase lasts 16-20+ weeks. The primary focus is on training and racing, with endurance training volume and intensity at their peak. Intentional overreaching is common, so strength work should enhance, not hinder, your performance.\n\n<strong>Maintenance, Not Gain:</strong> The goal is to maintain your strength without adding new training stress. This ensures you stay strong and injury-free while maximizing your racing performance."
    },
    "championship":{
        "expectation": "Our championship season strategy is rooted in science and tailored to meet the unique demands of this critical phase. The focus is on maintaining your hard-earned strength while minimizing any additional stress on your body. With an emphasis on low-impact, effective exercises, you’ll feel confident and empowered, knowing that your training is designed to keep you strong, fresh, and ready to perform at your best when it matters most.",
        "focus": "<strong>Minimal Impact Exercises:</strong> If strength training is necessary, focus on foundational, postural, mobility, and core exercises. These are the \"low-hanging fruit\" that have minimal impact on fatigue and endocrine response.\n\n<strong>Maintaining Strength:</strong> The goal is to maintain your current strength with the least possible stress on your body.",
        "highlights": "<strong>Championship Phase:</strong> This phase includes the major races at the end of the season. It’s a period of high variability, where mental and physical fatigue can peak after a long training season.\n\n<strong>Adaptation & Maintenance:</strong> Focus on maintaining strength with the minimally effective dose. Adjust your training to align with the demands of your schedule and personal fatigue levels."
    },
    "post-race-deload": {
        "expectation": "After the race, this phase focuses on easing off the intensity, promoting blood flow, and maintaining movement through key ranges of motion. The goal is to help your body recover and reset after the stress of competition.",
        "focus": "<strong>Active Recovery and Blood Flow Promotion:</strong> Active recovery involves light exercise that keeps your blood circulating without adding strain. This helps clear out metabolic waste products like lactic acid, which can cause muscle soreness, and promotes nutrient delivery to your muscles, aiding in their repair.\n\n<strong>Reducing Muscle Soreness and Hormonal Stress:</strong> A marathon or intense competition places significant stress on your body, not just physically but also hormonally. The post-race deload phase helps lower cortisol levels (a stress hormone) and reduces muscle soreness, aiding in faster recovery.\n\n<strong>Gradual Return to Normal Training Loads:</strong> Your body needs time to fully recover before you resume full-intensity training. This phase ensures that you’re gradually reintroducing load, allowing your muscles, tendons, and endocrine system to fully recuperate before you start pushing hard again.",
        "highlights": "<strong>Light, Low-Intensity Exercises to Promote Recovery:</strong> Gentle movements help maintain flexibility and mobility while promoting circulation. These exercises are designed to keep your body active without placing additional stress on it.\n\n<strong>Emphasis on Flexibility and Mobility:</strong> Post-race is an ideal time to focus on stretching and mobility work, which helps restore range of motion and prevent stiffness. This is crucial for maintaining long-term joint health and preventing injuries.\n\n<strong>Maintenance of Movement Without Adding Stress:</strong>The goal during this phase is to keep your body moving but at a much lower intensity. This helps prevent the stiffness that can come from inactivity while giving your body the rest it needs to recover fully."
    }
}

export const NoSeasonPhaseJSON = {
    "weekOfTheYearKey": "",
    "programPhaseKey": "no-season",
    "programWeekKey": "no-season",
    "taperData": {
        "taperType": "program"
    }
}

export const WeekNames = {
    "error": "Error",
    "no-season": "No Training",
    "deload": "Deload",
    "deload-override": "Deload",
    "load-increase": "Load Increase",
    "maintain": "Maintain",
    "volume-increase": "Volume Increase",
    "range-of-motion": "Range of Motion",
    "dynamic-movement": "Dynamic Movement"
}

// Workouts

export const WorkoutTemplateNames = {
    "strength-session-a": "Strength Session A",
    "strength-session-b": "Strength Session B",
    "mobility-a": "Mobility A",
    "mobility-b": "Mobility B",
    "rest": "Rest"
}

export const GetWeekDayWorkoutIcon = (selectedWeek, dayOfTheWeekIndex) => {
    if (selectedWeek.weekDaySchedule[dayOfTheWeekIndex].includes("strength")) return strengthIcon
    if (selectedWeek.weekDaySchedule[dayOfTheWeekIndex].includes("mobility")) return mobilityIcon
    if (selectedWeek.weekDaySchedule[dayOfTheWeekIndex].includes("rest")) return restIcon
}

/*
export const GenerateTestWeekOfTheYearKeyData = () => {

    const filePath = path.join(__dirname, '..', '..', 'data', 'weekOfTheYearKeyData.csv');
    const header = 'Current Date,Start of ISO Week,Week of the Year Key\n';
    
    fs.writeFileSync(filePath, header);

    for (let i = 0; i < 1500; i++) {
        var current_date = moment.utc().local().add(i, 'days');
        var start_of_current_iso_week = moment.utc().local().add(i, 'days').startOf('isoWeek');
        var week_of_the_year_key = new WeekOfTheYearKey(moment.utc().local().add(i, 'days').startOf('isoWeek'));
      
        const csvLine = `${current_date.format('GGGG-MM-DD')},${start_of_current_iso_week.format('GGGG-MM-DD')},${week_of_the_year_key}\n`;
        fs.appendFileSync(filePath, csvLine);

    }
}
*/