import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useEffect, useContext } from 'react'
import { ModalContext } from '../ModalContext'

import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'

export default function ExerciseInstructionsModal() {
    const { modalData } = useContext(ModalContext)

    const [loadingExerciseInstructions, setLoadingExerciseInstructions] = useState(false)
    const [exerciseInstructions, setExerciseInstructions] = useState(null)

    const GetInstructionsFromBackend = async () => {

        setLoadingExerciseInstructions(true)

        const jsonBody = {
            exerciseId: modalData.exerciseId,
        }

        const result = await WhichWeightAPI('CORE', 'GET', '/exercise_instructions', jsonBody)

        // console.log(result.data.data);
        
        setLoadingExerciseInstructions(false)

        setExerciseInstructions(result.data.data)

    }

    useEffect(() => {
        GetInstructionsFromBackend()
    }, [])

    return (
        <>
            {loadingExerciseInstructions && <MediumLoadingComponent />}

            {!loadingExerciseInstructions && (
                <>

                    {exerciseInstructions === null && 
                        <div className="pt-10 text-sm space-y-4">
                            <p>Could not load exercise instructions</p>
                            <p>Please try again</p>
                        </div>
                    }

                    {exerciseInstructions !== null && 
                        <>

                            {/* Header */}
                            <div className="mb-4 flex-grow text-lg font-semibold leading-6 text-gray-900">
                                <p>{exerciseInstructions.name}</p>
                            </div>

                            {/* Body */}
                            <div className="h-full overflow-auto space-y-4 pb-20">
                                
                                {/* Video Instructions */}
                                <div className='overflow-hidden rounded-lg'>
                                    <video 
                                        width="720" 
                                        height="400" 
                                        controls
                                        playsInline 
                                        loop
                                        x-webkit-airplay="deny"
                                        onError={(e) => {
                                            console.error('Video playback error:', e);
                                        }}
                                    >
                                        <source src={exerciseInstructions.videoURL} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>

                                {/* Text Instructions */}
                                {exerciseInstructions.textInstructions.split('\n').map((instruction, instructionIndex) => (
                                    <p key={instructionIndex} className="text-left text-sm text-gray-600">
                                        {instruction}
                                    </p>
                                ))}

                            </div>

                        </>
                    }
                    
                </>
            )}
        </>
    )
}
