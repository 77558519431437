import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetExercises } from 'redux/customHooks/useGetExercises'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { CapitalizeFirstLetter } from 'functions/textUtils'

import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import CustomHeader from 'components/Header/CustomHeader'

export default function ExerciseLibrary() {
    const navigate = useNavigate()
    const exercises = useGetExercises()

    const [searchTerm, setSearchTerm] = useState('')

    const { ShowModal } = useContext(ModalContext)

    const OnExerciseCardPressed = (exerciseId) => {
        const modalData = {
            key: ModalKey.ExerciseInstructions,
            fullscreen: true,
            exerciseId: exerciseId,
        }

        ShowModal(modalData)
    }

    return (
        <>
            {/* Header */}
            <CustomHeader text="Exercise Library" backButtonAction={() => navigate('/home')} />

            <div className="p-4">
                
                <div className="mx-auto max-w-7xl">
                    {/* Search bar */}
                    <div className="mb-4 flex rounded-md shadow-sm">
                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>

                            <input
                                type="email"
                                name="email"
                                id="email"
                                className="block w-full rounded-none rounded-l-md rounded-r-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                placeholder="Exercise Name"
                                onChange={(event) => setSearchTerm(event.target.value)}
                            />
                        </div>
                    </div>

                    {exercises.length === 0 && <MediumLoadingComponent />}

                    {exercises.length > 0 && (
                        <ul className="border border-gray-300 rounded-lg divide-y divide-gray-300 cursor-pointer">
                            {exercises
                                .filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                .map((exercise) => (
                                    <div key={exercise.id} onClick={() => OnExerciseCardPressed(exercise.id)} className="flex items-center gap-x-6 p-3">
                                        <img
                                            className="h-10 w-10 rounded-full object-cover"
                                            src={exercise.imageUrl.length > 0 ? exercise.imageUrl : 'https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/dumbbell.png'}
                                            alt=""
                                        />

                                        <div>
                                            <p className="flex-grow text-base font-semibold leading-6 text-gray-900">{exercise.name}</p>
                                            <p className="text-xs text-gray-400">{CapitalizeFirstLetter(exercise.category)}</p>
                                        </div>
                                    </div>
                                ))}
                        </ul>
                    )}
                </div>
            </div>
        </>
    )
}
