import { createSlice } from '@reduxjs/toolkit'
import WhichWeightLogo from 'media/logos/which_weight_logo.png'
import BlueCollarLogo from 'media/logos/blue_collar_logo.png'

// Initial state
const initialState = {
  styleConfig: null
}

// User Slice
const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    setBrandStyleConfig: (state, action) => { state.styleConfig = action.payload },
    resetBrand: () => initialState
  },
});

export const { 
  setBrandStyleConfig,
  resetBrand
} = brandSlice.actions;

export default brandSlice.reducer;

// Branding Options

const availableStyleConfigs = [
  {
      brandKey: "which-weight",
      logo: WhichWeightLogo,
      primaryTailwindColorClass: "orange",
      anchorShade: 400,
      trainingChartTheme: "dark",
      text: {
          appName: "WhichWeight",
          appURL: "app.whichweight.com"
      }
  },
  {
      brandKey: "blue-collar",
      logo: BlueCollarLogo,
      primaryTailwindColorClass: "sky",
      anchorShade: 700,
      trainingChartTheme: "light",
      text: {
          appName: "Blue Collar Strength",
          appURL: "bluecollar.whichweight.com"
      }
  }
]

// Brand Style Util Functions

export const GetBrandStyleConfig = (brandKey) => {
  const matchingConfig = availableStyleConfigs.find(config => config.brandKey === brandKey)
  return matchingConfig || availableStyleConfigs[0]
}

export const GetPrimaryColorHex = (styleConfig, shadeModifier = 0) => {
  // Based on color palette found here: https://tailwindcss.com/docs/customizing-colors#default-color-palette


  const shadeCodes = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950]
  const anchorIndex = shadeCodes.findIndex(shade => shade === styleConfig.anchorShade)
  const targetShade = shadeCodes[Math.min(Math.max(0, anchorIndex + shadeModifier), shadeCodes.length - 1)]

  if (styleConfig.primaryTailwindColorClass === 'orange'){

      switch (targetShade) {
          case 50: return "#fff7ed"
          case 100: return "#ffedd5"
          case 200: return "#fed7aa" 
          case 300: return "#fdba74"
          case 400: return "#fb923c"
          case 500: return "#f97316"
          case 600: return "#ea580c"
          case 700: return "#c2410c"
          case 800: return "#9a3412"
          case 900: return "#7c2d12"
          case 950: return "#431407"
          default:
              console.warn(`Invalid shade number for ${styleConfig.primaryTailwindColorClass}: ${targetShade}!! Defaulting to 400`)
              return "#f97316"
      }

  }

  if (styleConfig.primaryTailwindColorClass === 'sky'){

      switch (targetShade) {
          case 50: return "#f0f9ff"
          case 100: return "#e0f2fe"
          case 200: return "#bae6fd" 
          case 300: return "#7dd3fc"
          case 400: return "#38bdf8"
          case 500: return "#0ea5e9"
          case 600: return "#0284c7"
          case 700: return "#0369a1"
          case 800: return "#075985"
          case 900: return "#0c4a6e"
          case 950: return "#082f49"
          default:
              console.warn(`Invalid shade number for ${styleConfig.primaryTailwindColorClass}: ${targetShade}!! Defaulting to 400`)
              return "#38bdf8"
      }

  }

  console.warn(`Unsupported tailwindColorClass: ${styleConfig.primaryTailwindColorClass}!! Defaulting to orange-400`)
  return "#f97316"

}

export const GetProgramPhaseColorHex = (styleConfig, programPhaseKey) => {

  if (programPhaseKey === "no-season") return "#9ca3af"
  if (programPhaseKey === "re-introduction") return GetPrimaryColorHex(styleConfig, -3)
  if (programPhaseKey === "reload") return GetPrimaryColorHex(styleConfig, -3)
  if (programPhaseKey === "off-season") return GetPrimaryColorHex(styleConfig, -2)
  if (programPhaseKey === "early-season") return GetPrimaryColorHex(styleConfig, -1)
  if (programPhaseKey === "in-season") return GetPrimaryColorHex(styleConfig)
  if (programPhaseKey === "competition") return GetPrimaryColorHex(styleConfig, 1)
  if (programPhaseKey === "championship") return GetPrimaryColorHex(styleConfig, 2)
  if (programPhaseKey === "post-race-deload") return GetPrimaryColorHex(styleConfig, -3)

  return "#fafafa"

}

export const GetProgramPhaseBarGradientHexCodes = (styleConfig, programPhaseKey) => {

  if (programPhaseKey === "no-season") return ["#9ca3af", "#9ca3af"]
  if (programPhaseKey === "re-introduction") return [GetPrimaryColorHex(styleConfig, -2), GetPrimaryColorHex(styleConfig, -3)]
  if (programPhaseKey === "reload") return [GetPrimaryColorHex(styleConfig, -2), GetPrimaryColorHex(styleConfig, -3)]
  if (programPhaseKey === "off-season") return [GetPrimaryColorHex(styleConfig, -1), GetPrimaryColorHex(styleConfig, -2)]
  if (programPhaseKey === "early-season") return [GetPrimaryColorHex(styleConfig), GetPrimaryColorHex(styleConfig, -1)]
  if (programPhaseKey === "in-season") return [GetPrimaryColorHex(styleConfig, 1), GetPrimaryColorHex(styleConfig)]
  if (programPhaseKey === "competition") return [GetPrimaryColorHex(styleConfig, 2), GetPrimaryColorHex(styleConfig, 1)]
  if (programPhaseKey === "championship") return [GetPrimaryColorHex(styleConfig, 3), GetPrimaryColorHex(styleConfig, 2)]
  if (programPhaseKey === "post-race-deload") return [GetPrimaryColorHex(styleConfig, -2), GetPrimaryColorHex(styleConfig, -3)]

  // Default to light color
  return [GetPrimaryColorHex(styleConfig, -2), GetPrimaryColorHex(styleConfig, -3)]

}