import { WhichWeightAPI } from 'WhichWeightAPI';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setWhichWeightCacheExercises } from 'redux/slices/whichWeightCache';

export const useGetExercises = () => {
    
    const dispatch = useDispatch();

    const whichWeightCacheExercises = useSelector(state => state.whichWeightCache.exercises);

    const [exercises, setExercises] = useState(whichWeightCacheExercises);

    useEffect(() => {

        const fetchExercises = async () => {

            if (whichWeightCacheExercises.length === 0) {
                const result = await WhichWeightAPI("CORE", "GET", "/exercises");
                dispatch(setWhichWeightCacheExercises(result.data.data));
                setExercises(result.data.data);
            }
            
        };

        fetchExercises();

    }, [whichWeightCacheExercises, dispatch]);

    return exercises;

};
