import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateWeekDayScheduleWeekDayWorkoutTemplate } from 'redux/slices/onboarding'
import moment from 'moment'

import ScreenFrame from 'components/Frame/ScreenFrame'
import StepHeader from 'components/Header/StepHeader'
import BodyFrame from 'components/Frame/BodyFrame'
import NextButton from 'components/Onboarding/NextButton'

const bulletPoints = [
    // 'Mobility days are easier, do not require equipment, and should be done on days where you are not doing an endurance workout',
    'Mobility days are easier and should be done on days where you are not doing an endurance workout',
    'Strength days are harder, are most effective in a gym with equipment, and should be done on the same days as endurance workouts',
    'Strength days should have at least one day of rest in between them',
]

export default function WeekDayScheduleInputView() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const weekDaySchedule = useSelector((state) => state.onboarding.weekDaySchedule)
    const [isWeekDayScheduleValid, setIsWeekDayScheduleValid] = useState(true)

    const HandleWeekDayScheduleChange = (weekDayIndex, workoutTemplateKey) => {
        setIsWeekDayScheduleValid(true)

        const payload = {
            weekDayIndex: weekDayIndex,
            workoutTemplateKey: workoutTemplateKey,
        }

        // console.log(payload);

        dispatch(updateWeekDayScheduleWeekDayWorkoutTemplate(payload))
    }

    const OnNextButton = () => {
        const assignedWorkoutTemplateKeys = Object.values(weekDaySchedule)

        if (
            !assignedWorkoutTemplateKeys.includes('strength-session-a') ||
            !assignedWorkoutTemplateKeys.includes('strength-session-b') ||
            !assignedWorkoutTemplateKeys.includes('mobility-a') ||
            !assignedWorkoutTemplateKeys.includes('mobility-b')
        ) {
            setIsWeekDayScheduleValid(false)
            return
        }

        navigate('/onboarding/fitnessProfileReview')
    }

    return (
        <ScreenFrame>

            <StepHeader title='Fitness Profile' startPercentage={60} endPercentage={80} />

            <BodyFrame>

                <div className='space-y-1'>
                    <h1 className="text-lg font-bold text-gray-800 text-center">Weekly Schedule</h1>
                    <div className="text-sm text-center text-gray-800">WhichWeight delivers you 4 workouts each week</div>
                </div>

                {/* Bullet List */}
                <ul className="list-disc px-6 py-4 text-gray-800 space-y-2 text-xs border border-gray-400 rounded-xl">
                    {bulletPoints.map((point, index) => (
                        <li key={index}>{point}</li>
                    ))}
                </ul>

                {/* Days of the Week */}
                {Array.from({ length: 7 }, (_, i) => i).map((weekDayIndex) => (
                    <div key={weekDayIndex} className="flex items-center gap-x-4">
                        {/* Day of the Week */}
                        <div className="flex-grow text-lg">{moment.utc().local().startOf('isoWeek').add(weekDayIndex, 'days').format('dddd')}</div>

                        {/* Weekday dropdown */}
                        <select
                            id="workoutTemplateKey"
                            value={weekDaySchedule[weekDayIndex]}
                            onChange={(event) => HandleWeekDayScheduleChange(weekDayIndex, event.target.value)}
                            className="w-52 px-4 py-2 rounded-md text-gray-900 ring-1 ring-gray-300"
                        >
                            <option value="strength-session-a">Strength Session A</option>
                            <option value="strength-session-b">Strength Session B</option>
                            <option value="mobility-a">Mobility A</option>
                            <option value="mobility-b">Mobility B</option>
                            <option value="rest">Rest</option>
                        </select>
                    </div>
                ))}

                {/* Week Day Schedule Error Message */}
                {isWeekDayScheduleValid ? null : <p className="mt-1 text-sm text-red-600">Please make sure all 4 workouts have been assigned a day of the week</p>}

                {/* Next Button */}
                <div className='mt-auto'>
                    <NextButton text="Next" action={() => OnNextButton()} />
                </div>

            </BodyFrame>

        </ScreenFrame>
    )
}
