import bodyweightIcon from 'media/icons/bodyweight.png'
import whichweightIcon from 'media/icons/logo192.png'

import tooEasyIcon from 'media/icons/eds/too_easy.png'
import aLittleEasyIcon from 'media/icons/eds/a_little_easy.png'
import neutralIcon from 'media/icons/eds/neutral.png'
import aLittleHardIcon from 'media/icons/eds/a_little_hard.png'
import tooHardIcon from 'media/icons/eds/too_hard.png'

const EquipmentIcons = {
    "band": "https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/band.png",
    "barbell": "https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/barbell.png",
    "dumbbell": "https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/dumbbell.png",
    "exercise-ball": "https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/exercise-ball.png",
    "foam-roller": "https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/foam-roller.png",
    "hex-bar": "https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/hex-bar.png",
    "kettlebell": "https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/kettlebell.png",
    "light-bar": "https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/light-bar.png",
    "machine": "https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/machine.png",
    "medicine-ball": "https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/medicine-ball.png",
    "slider": "https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/slider.png",
    "trx": "https://which-weight-public.s3.us-east-2.amazonaws.com/equipment_icons/trx.png"
}

export const GetExerciseTypeIcon = (exerciseType) => {

    if (exerciseType === "bodyweight") return bodyweightIcon
    if (exerciseType === "recommendation") return whichweightIcon

}

export const GetEquipmentIcon = (equipmentKey) => {

    return EquipmentIcons[equipmentKey];

}

export const GetDifficultyIconFromValue = (difficulty) => {

    switch (difficulty){

        case -2: return tooEasyIcon
        case -1: return aLittleEasyIcon
        case 0: return neutralIcon
        case 1: return aLittleHardIcon
        case 2: return tooHardIcon
        default:
            console.warn(difficulty + " is not a valid difficulty value!! Defaulting to neutral icon")
            return neutralIcon

    }

}