import { WhichWeightAPI } from 'WhichWeightAPI'
import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import {
    setActiveWorkoutPhaseIndex,
    setActiveWorkoutExerciseIndex,
    setActiveWorkoutSetIndex,
    setActiveWorkoutExerciseSetSavingResultBoolean,
    setActiveWorkoutIncompleteSetInfo,
    setActiveWorkoutCompleteSetInfo,
    setActiveWorkoutPhaseCompletionBoolean,
    updateActiveWorkoutExerciseSetWeightOverride,
    resetActiveWorkoutIncompleteSetInfo,
    resetActiveWorkoutState,
} from 'redux/slices/activeWorkout'
import { setViewWorkoutId } from 'redux/slices/viewWorkout'
import { setWhichWeightCacheEntireWeeklyCalendarJSON } from 'redux/slices/whichWeightCache'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { GetSetWeightRecommendation, AutoOverrideRemainingSets } from 'functions/activeWorkoutUtils'
import { GetPrimaryColorHex } from 'redux/slices/brand'
import useWakeLock from 'redux/customHooks/useWakeLock'

import HeaderDropdownBody from 'components/ActiveWorkout/Body/HeaderDropdownBody'
import ActiveWorkoutUIModeToggle from 'components/ActiveWorkout/ActiveWorkoutUIModeToggle'
import FullscreenLoading from 'components/Loading/FullscreenLoading'
import CompactModeBody from 'components/ActiveWorkout/CompactModeBody'
import ExpandedModeBody from 'components/ActiveWorkout/ExpandedModeBody'

export default function ActiveWorkout() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { wakeLock, requestWakeLock, releaseWakeLock } = useWakeLock()
    const { modalData, ShowModal } = useContext(ModalContext)

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const userId = useSelector((state) => state.user.id)
    const activeWorkout = useSelector((state) => state.activeWorkout)
    const uiMode = activeWorkout.uiMode
    const numberOfPhases = activeWorkout.workoutTemplate !== null ? activeWorkout.workoutTemplate.phases.length : 0
    const phaseIndex = activeWorkout.phaseIndex
    const exerciseIndex = activeWorkout.exerciseIndex
    const setIndex = activeWorkout.setIndex

    const [expandHeaderDropdown, setExpandHeaderDropdown] = useState(false)
    const [showRestScreen, setShowRestScreen] = useState(false)
    const [triggerFinalCompletionAction, setTriggerFinalCompletionAction] = useState(false)
    const [savingWorkout, setSavingWorkout] = useState(false)

    const GetPhase = () => {
        if (activeWorkout.workoutTemplate !== null) return activeWorkout.workoutTemplate.phases[phaseIndex]
    }
    const GetExercise = () => {
        return GetPhase().exercises[exerciseIndex]
    }
    const GetSet = () => {
        return GetExercise().sets[setIndex]
    }

    const InitializeWeightRecommendations = () => {
        // If on an incomplete phase
        if (!GetPhase().completed) {
            // Loop over all exercises in the current phase
            GetPhase().exercises.map((currentExercise, currentExerciseIndex) => {
                // Loop over all sets
                currentExercise.sets.map((currentSet, currentSetIndex) => {
                    // For all incomplete sets that have not been loaded in
                    if (!currentSet.completedSetData.completed && !currentSet.recommendationData.weightRecommendationLoaded) {
                        // Get weight recommendation
                        GetSetWeightRecommendation(
                            dispatch, 
                            userId, 
                            activeWorkout, 
                            parseInt(phaseIndex), 
                            parseInt(currentExerciseIndex), 
                            parseInt(currentSetIndex),
                            setActiveWorkoutIncompleteSetInfo
                        )
                    }
                })
            })
        }
    }

    const ShowEDSModalForCurrentSet = async (currentPhaseIndex, currentExerciseIndex, currentExercise, currentSetIndex) => {
        const modalData = {
            key: ModalKey.WorkoutSetFeedback,
            fullscreen: true,
            currentExercise: currentExercise,
            setIndex: currentSetIndex,
            taperData: activeWorkout.taperData,
            buttonAction: async (payload) => {

                OnEDSOptionSelected(
                    currentPhaseIndex, 
                    currentExerciseIndex, 
                    currentExercise, 
                    currentSetIndex, 
                    payload.repsCompleted,
                    payload.selectedMovementDifficultyScaleOption,
                    payload.selectedLoadDifficultyScaleOption
                )

            },
        }

        ShowModal(modalData)
    }

    const OnEDSOptionSelected = async (currentPhaseIndex, currentExerciseIndex, currentExercise, currentSetIndex, repsCompleted, movementDifficulty, difficulty) => {
        // Save the set to backend
        await SaveCompletedSet(currentPhaseIndex, currentExerciseIndex, currentSetIndex, repsCompleted, movementDifficulty, difficulty)

        // For recommendation exercises
        if (currentExercise.exerciseType === 'recommendation') {
            // For non-taper workouts, recalculate the next sets
            if (!activeWorkout.taperData.useTaper) {
                RecalculateIncompleteExerciseSetRecommendations(currentExerciseIndex, currentSetIndex)
            }

            // TODO Change this??
            // Mimicing EDS on frontend for tapered workout sets
            // If the current set is tapered
            // AND gave a difficulty EDS rating
            if (activeWorkout.taperData.useTaper && 0 < difficulty) {
                AutoOverrideRemainingSets(
                    dispatch, 
                    currentPhaseIndex, 
                    currentExerciseIndex, 
                    currentExercise, 
                    -2, 
                    currentSetIndex,
                    updateActiveWorkoutExerciseSetWeightOverride
                )
            }
        }

        // For expanded mode navigate to the next set
        if (activeWorkout.uiMode === 'expanded') ExpandedModeNavigateToNextSet()
    }

    const SaveCompletedSet = async (currentPhaseIndex, currentExerciseIndex, currentSetIndex, setUnitsCompleted, movementDifficulty, difficulty) => {
        const exercise = activeWorkout.workoutTemplate.phases[currentPhaseIndex].exercises[currentExerciseIndex]
        const set = exercise.sets[currentSetIndex]

        // Showing loading animation for set in list

        const savingResultInfoTrue = {
            phaseIndex: currentPhaseIndex,
            exerciseIndex: currentExerciseIndex,
            setIndex: currentSetIndex,
            savingResult: true,
        }

        dispatch(setActiveWorkoutExerciseSetSavingResultBoolean(savingResultInfoTrue))

        var jsonBody = {
            traineeId: userId,
            workoutId: activeWorkout.id,
            exerciseId: exercise.id,
            exerciseName: exercise.name,
            exerciseType: exercise.exerciseType,
            currentSet: currentSetIndex + 1,
            setsAssigned: exercise.sets.length,
            weightRecommendation: set.recommendationData.weightRecommendation,
            weightRecommendationRounded: set.recommendationData.weightRecommendationRounded,
            setUnitsCompleted: setUnitsCompleted,
            setUnitsAssigned: exercise.setUnit === 'reps' ? set.reps : set.seconds, // WARNING Adding different setUnits could effect this
            setUnit: exercise.setUnit,
            movementDifficulty: movementDifficulty,
            difficulty: difficulty,
            override: set.completedSetData.override,
            taper: activeWorkout.taperData.useTaper ? 1 : 0,
            taperDampener: activeWorkout.taperData.taperDampener,
            formulaTag: set.recommendationData.formulaTag,
        }

        // console.log(jsonBody);

        const result = await WhichWeightAPI('CORE', 'POST', '/workout_exercise', jsonBody)

        // console.log(result);

        // Hiding loading animation for set in list

        const savingResultInfoFalse = {
            phaseIndex: currentPhaseIndex,
            exerciseIndex: currentExerciseIndex,
            setIndex: currentSetIndex,
            savingResult: false,
        }

        dispatch(setActiveWorkoutExerciseSetSavingResultBoolean(savingResultInfoFalse))

        if (result.data.status === 'failure') {
            // TODO Show Modal Here
            console.error('Failed to save data to the backend')
            console.error(result)
            return
        }

        const completedSetInfo = {
            phaseIndex: currentPhaseIndex,
            exerciseIndex: currentExerciseIndex,
            setIndex: currentSetIndex,
            databaseId: result.data.data,
            setUnitsCompleted: setUnitsCompleted,
            movementDifficulty: movementDifficulty,
            difficulty: difficulty,
        }

        // console.log(completedSetInfo);

        // Update redux store
        dispatch(setActiveWorkoutCompleteSetInfo(completedSetInfo))
    }

    const RecalculateIncompleteExerciseSetRecommendations = async (currentExerciseIndex, currentSetIndex) => {
        // For all sets
        for (let recalculateSetIndex = 0; recalculateSetIndex < GetPhase().exercises[currentExerciseIndex].sets.length; recalculateSetIndex++) {
            var set = GetPhase().exercises[currentExerciseIndex].sets[recalculateSetIndex]

            // If a set is incomplete. Make sure to not update current here
            // Redux store has not updated at this point
            if (!set.completedSetData.completed && recalculateSetIndex !== currentSetIndex) {
                // console.log("[COMPACT MODE] Recalculating weight rec for " + GetPhase().exercises[exerciseIndex].name + " set " + (parseInt(setIndex) + 1))

                // Reset set weight recommendation json
                const indices = {
                    phaseIndex: parseInt(phaseIndex),
                    exerciseIndex: currentExerciseIndex,
                    setIndex: recalculateSetIndex,
                }

                dispatch(resetActiveWorkoutIncompleteSetInfo(indices))

                // Load a new recommendation
                await GetSetWeightRecommendation(
                    dispatch, 
                    userId, 
                    activeWorkout, 
                    parseInt(phaseIndex), 
                    parseInt(currentExerciseIndex), 
                    parseInt(recalculateSetIndex),
                    setActiveWorkoutIncompleteSetInfo
                )
            }
        }
    }

    const ExpandedModeNavigateToNextSet = () => {
        // REST SCREEN HACK
        if (GetPhase().type === 'circuit') {
            // If there are rest seconds defined in template AND we are not currently showing the rest screen
            if (GetExercise().restSecondsAfterExercise > 0 && !showRestScreen) {
                setShowRestScreen(true)
                return
            }
        }

        // If we are showing the rest screen make sure to toggle it off when going to next exercise set
        if (showRestScreen) setShowRestScreen(false)

        // For the straight phase type
        if (GetPhase().type === 'straight') {
            // Going to next exercise
            if (exerciseIndex < GetPhase().exercises.length - 1) {
                ActiveWorkoutNavigate('expanded-straight-next-exercise')
            } else {
                // Going to next phase
                if (phaseIndex < numberOfPhases - 1) ActiveWorkoutNavigate('expanded-next-phase')
                // Ending workout. TODO CHANGE HACKY FIX
                else setTriggerFinalCompletionAction(true)
            }

            // Going to next set
            if (setIndex < GetExercise().sets.length - 1) {
                ActiveWorkoutNavigate('expanded-next-set')
            } else {
                // Going to next exercise
                if (exerciseIndex < GetPhase.exercises.length - 1) {
                    ActiveWorkoutNavigate('expanded-straight-next-exercise')
                } else {
                    // Going to next phase
                    if (phaseIndex < numberOfPhases - 1) ActiveWorkoutNavigate('expanded-next-phase')
                    // Ending workout. TODO CHANGE HACKY FIX
                    else setTriggerFinalCompletionAction(true)
                }
            }
        }

        // For Circuit Type
        if (GetPhase().type === 'circuit') {
            // Going to next exercise
            if (exerciseIndex < GetPhase().exercises.length - 1) ActiveWorkoutNavigate('expanded-circuit-next-exercise')
            else {
                // Going to next circuit round
                if (setIndex < GetPhase().circuitRounds - 1) ActiveWorkoutNavigate('expanded-circuit-next-round')
                else {
                    // Going to next phase
                    if (phaseIndex < numberOfPhases - 1) ActiveWorkoutNavigate('expanded-next-phase')
                    // Ending workout. TODO CHANGE HACKY FIX
                    else setTriggerFinalCompletionAction(true)
                }
            }
        }
    }

    const ActiveWorkoutNavigate = (activeWorkoutNavigationKey) => {
        switch (activeWorkoutNavigationKey) {
            case 'complete-phase':
                // Track in redux that the phase was complete

                const dispatchJSON = {
                    phaseIndex: phaseIndex,
                    completed: true,
                }

                dispatch(setActiveWorkoutPhaseCompletionBoolean(dispatchJSON))

                // Navigate to next phase
                // If on last phase, complete workout

                if (phaseIndex < activeWorkout.workoutTemplate.phases.length - 1) dispatch(setActiveWorkoutPhaseIndex(phaseIndex + 1))
                else EndWorkout(navigate, dispatch, setSavingWorkout, userId, activeWorkout)

                break

            case 'compact-next-phase':
                dispatch(setActiveWorkoutPhaseIndex(phaseIndex + 1))
                break

            case 'compact-previous-phase':
                dispatch(setActiveWorkoutPhaseIndex(phaseIndex - 1))
                break

            case 'expanded-next-phase':
                // Track in redux that the phase was complete

                const dispatchJSON2 = {
                    phaseIndex: phaseIndex,
                    completed: true,
                }

                dispatch(setActiveWorkoutPhaseCompletionBoolean(dispatchJSON2))

                dispatch(setActiveWorkoutPhaseIndex(phaseIndex + 1))
                dispatch(setActiveWorkoutExerciseIndex(0))
                dispatch(setActiveWorkoutSetIndex(0))

                break

            case 'expanded-straight-next-exercise':
                dispatch(setActiveWorkoutExerciseIndex(exerciseIndex + 1))
                dispatch(setActiveWorkoutSetIndex(0))
                break

            case 'expanded-circuit-next-exercise':
                dispatch(setActiveWorkoutExerciseIndex(exerciseIndex + 1))
                break

            case 'expanded-next-set':
                dispatch(setActiveWorkoutSetIndex(setIndex + 1))
                break

            case 'expanded-circuit-next-round':
                dispatch(setActiveWorkoutExerciseIndex(0))
                dispatch(setActiveWorkoutSetIndex(setIndex + 1))
                break

            /*
            case "expanded-specific-set":
                
                // TODO WOULD NEED TO PASS IN NEW INDICES HERE
                navigate('/active/workout/phase/' + phaseIndex + '/exercise/' + exerciseIndex + '/set/' + setIndex)

                setExpandHeaderDropdown(false)

                break
            */

            default:
                console.error(activeWorkoutNavigationKey + ' is not a valid activeWorkoutNavigationKey!! Doing nothing')
                break
        }
    }

    const EndWorkout = async () => {
        setSavingWorkout(true)

        const jsonBody = {
            userId: userId,
            completedWorkoutId: activeWorkout.id,
            weekOfTheYearKey: activeWorkout.weekOfTheYearKey,
            workoutTemplateKey: activeWorkout.workoutTemplateKey,
            completedWorkoutTemplateJSON: activeWorkout.workoutTemplate,
            uiMode: activeWorkout.uiMode,
        }

        // console.log(jsonBody)

        const result = await WhichWeightAPI('CORE', 'PATCH', '/complete_workout', jsonBody)

        // console.log(result);

        setSavingWorkout(false)

        if (result.data.status == 'failure') {
            console.error(result)
            return
        }

        const props = {
            state: {
                weekOfTheYearKey: activeWorkout.weekOfTheYearKey,
                workoutTemplateKey: activeWorkout.workoutTemplateKey,
                doneNavigation: 'home',
            },
        }

        dispatch(setViewWorkoutId(activeWorkout.id))
        dispatch(resetActiveWorkoutState())
        dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON(null))

        // Release wake lock when leaving workout screen
        releaseWakeLock()

        navigate('/workout/summary', props)
    }

    // When the component mounts, request a wake lock
    useEffect(() => {
        requestWakeLock()
    }, []);

    useEffect(() => {
        if (activeWorkout.workoutTemplate !== null) InitializeWeightRecommendations()
    }, [phaseIndex, modalData])

    useEffect(() => {
        if (triggerFinalCompletionAction) ActiveWorkoutNavigate('complete-phase')
    }, [triggerFinalCompletionAction])

    if (activeWorkout.workoutTemplate === null) return <></>

    if (savingWorkout) return <FullscreenLoading loadingText="Saving Workout" />

    return (
        <div 
            className="fixed flex flex-col h-full w-full overflow-auto"
            style={{
                background: `linear-gradient(to bottom, ${GetPrimaryColorHex(styleConfig)}, ${GetPrimaryColorHex(styleConfig, 2)})`
            }}
        >
            {/* Header */}
            <div className="flex items-center justify-center py-2 px-4 bg-white border-b border-gray-400" onClick={() => setExpandHeaderDropdown(!expandHeaderDropdown)}>
                {/* Spacer */}
                <div className="flex-1" />

                {/* WhichWeight Logo */}
                <img src={styleConfig.logo} alt="WhichWeight Icon Logo" className="h-8 pointer-events-none" />

                {/* Dropdown Icon */}
                <div className="flex-1">
                    {!expandHeaderDropdown && <ChevronDownIcon className="mt-1 h-6 ml-auto text-gray-600 pointer-events-none" />}
                    {expandHeaderDropdown && <ChevronUpIcon className="mt-1 h-6 ml-auto text-gray-600 pointer-events-none" />}
                </div>
            </div>

            {/* Dropdown Options */}
            {expandHeaderDropdown && (
                <HeaderDropdownBody
                    uiMode={activeWorkout.uiMode}
                    setExpandHeaderDropdown={setExpandHeaderDropdown}
                    numberOfPhases={numberOfPhases}
                    phaseIndex={phaseIndex}
                    ActiveWorkoutNavigate={ActiveWorkoutNavigate}
                    EndWorkout={EndWorkout}
                />
            )}

            {/* Workout Body */}
            {!expandHeaderDropdown && (
                <>
                    {/* UI Mode Toggle */}
                    <ActiveWorkoutUIModeToggle setShowRestScreen={setShowRestScreen} />

                    {/* Compact Mode Body */}
                    {uiMode === 'compact' && (
                        <CompactModeBody
                            numberOfPhases={numberOfPhases}
                            phaseIndex={phaseIndex}
                            phase={GetPhase()}
                            ShowEDSModalForCurrentSet={ShowEDSModalForCurrentSet}
                            SaveCompletedSet={SaveCompletedSet}
                            ActiveWorkoutNavigate={ActiveWorkoutNavigate}
                            setTriggerFinalCompletionAction={setTriggerFinalCompletionAction}
                        />
                    )}

                    {/* Expanded Mode Body */}
                    {uiMode === 'expanded' && (
                        <ExpandedModeBody
                            numberOfPhases={numberOfPhases}
                            phaseIndex={phaseIndex}
                            phase={GetPhase()}
                            exerciseIndex={exerciseIndex}
                            exercise={GetExercise()}
                            setIndex={setIndex}
                            set={GetSet()}
                            ShowEDSModalForCurrentSet={ShowEDSModalForCurrentSet}
                            ExpandedModeNavigateToNextSet={ExpandedModeNavigateToNextSet}
                            showRestScreen={showRestScreen}
                        />
                    )}
                </>
            )}
        </div>
    )
}
