import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { CheckIcon } from '@heroicons/react/24/solid'
import { ConvertCentimetersToInches, ConvertKilogramsToPounds } from 'functions/measurementSystemUtils'
import { GetPrimaryColorHex } from 'redux/slices/brand'
import moment from 'moment'

import ScreenFrame from 'components/Frame/ScreenFrame'
import LogoHeader from 'components/Header/LogoHeader'
import BodyFrame from 'components/Frame/BodyFrame'
import TextInputLine from 'components/Onboarding/TextInputLine'
import NumberInputLine from 'components/Onboarding/NumberInputLine'
import LoadingButton from 'components/LoadingButton'

export default function PersonalInfoInputView() {
    
    const navigate = useNavigate()

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const userId = useSelector((state) => state.user.id)

    const [firstName, setFirstName] = useState("")
    const [isFirstNameValid, setIsFirstNameValid] = useState(true)
    const handleFirstNameChange = (event) => {
        // Validate that only letters were typed
        const inputValue = event.target.value
        const regex = /^[a-zA-Z]*$/ // Regular expression to allow only letters

        if (regex.test(inputValue)) {
            if (!isFirstNameValid) setIsFirstNameValid(true)
            setFirstName(event.target.value)
        }
    }

    const [lastName, setLastName] = useState("")
    const [isLastNameValid, setIsLastNameValid] = useState(true)
    const handleLastNameChange = (event) => {
        // Validate that only letters were typed
        const inputValue = event.target.value
        const regex = /^[a-zA-Z]*$/ // Regular expression to allow only letters

        if (regex.test(inputValue)) {
            if (!isLastNameValid) setIsLastNameValid(true)
            setLastName(event.target.value)
        }
    }

    const [birthMonth, setBirthMonth] = useState("")
    const [isBirthMonthValid, setIsBirthMonthValid] = useState(true)
    const handleBirthMonthChange = (value) => {

        // Cap length of string at 2
        if (value.length < 3) {
            setIsBirthMonthValid(true)
            setFormErrorMessage("")
            setBirthMonth(value)
        }

    }
    const handleBirthMonthBlur = () => {
        if (birthMonth.length === 1) setBirthMonth('0' + birthMonth);
    }

    const [birthDay, setBirthDay] = useState("")
    const [isBirthDayValid, setIsBirthDayValid] = useState(true)
    const handleBirthDayChange = (value) => {

        // Cap length of string at 2
        if (value.length < 3) {
            setIsBirthDayValid(true)
            setFormErrorMessage("")
            setBirthDay(value)
        }

    }
    const handleBirthDayBlur = () => {
        if (birthDay.length === 1) setBirthDay('0' + birthDay);
    }

    const [birthYear, setBirthYear] = useState("")
    const [isBirthYearValid, setIsBirthYearValid] = useState(true)
    const handleBirthYearChange = (value) => {

        // Cap length of string at 4
        if (value.length < 5) {
            setIsBirthYearValid(true)
            setFormErrorMessage("")
            setBirthYear(value)
        }

    }

    const [gender, setGender] = useState("")
    const [genderErrorMessage, setGenderErrorMessage] = useState("")

    const [measurementSystem, setMeasurementSystem] = useState("")
    const handleMeasurementSystemChange = (value) => {
        setMeasurementSystemErrorMessage("");
        setHeightFeet("")
        setHeightInches("")
        setHeightCentimeters("")
        setWeight("")
        setMeasurementSystem(value)
    }
    const [measurementSystemErrorMessage, setMeasurementSystemErrorMessage] = useState("")

    const [heightFeet, setHeightFeet] = useState("")
    const handleHeightFeetChange = (value) => {
        setFormErrorMessage("")
        setHeightFeet(value)
    }

    const [heightInches, setHeightInches] = useState("")
    const handleHeightInchesChange = (value) => {
        setFormErrorMessage("")
        setHeightInches(value)
    }

    const [heightCentimeters, setHeightCentimeters] = useState("")
    const handleHeightCentimetersChange = (value) => {
        setFormErrorMessage("")
        setHeightCentimeters(value)
    }

    const [weight, setWeight] = useState("")
    const [isWeightValid, setIsWeightValid] = useState(true)
    const handleWeightChange = (value) => {
        if (!isWeightValid) setIsWeightValid(true)
        setWeight(value)
    }

    const [agreementOneChecked, setAgreementOneChecked] = useState(false)

    const [agreementTwoChecked, setAgreementTwoChecked] = useState(false)

    // Minor Status variables
    const [isUserMinor, setIsUserMinor] = useState(false)
    const [guardianSignature, setGuardianSignature] = useState("")
    const [isGuardianSignatureValid, setIsGuardianSignatureValid] = useState(true)
    const handleGuardianSignatureChange = (event) => {
        if (!isGuardianSignatureValid) setIsGuardianSignatureValid(true)
        setGuardianSignature(event.target.value)
    }

    const [formErrorMessage, setFormErrorMessage] = useState("")
    const [isFormLoading, setIsFormLoading] = useState(false)

    // Make sure all the form inputs are there
    const validateFormInputs = () => {
        var allVaild = true

        if (firstName.length === 0) {  setIsFirstNameValid(false); allVaild = false } 
        if (lastName.length === 0) { setIsLastNameValid(false); allVaild = false }

        if (birthMonth.length !== 2) { setIsBirthMonthValid(false); allVaild = false }
        if (birthDay.length !== 2) { setIsBirthDayValid(false); allVaild = false }
        if (birthYear.length !== 4) { setIsBirthYearValid(false); allVaild = false }
        const dateString = `${birthYear}-${birthMonth}-${birthDay}`;
        if (!moment(dateString, 'YYYY-MM-DD', true).isValid()) { setFormErrorMessage("Invalid date of birth!"); allVaild = false; }

        if (gender !== 'male' && gender !== 'female' && gender !== 'other'){ setGenderErrorMessage('Please select your gender'); allVaild = false }
        
        if (measurementSystem !== 'imperial' && measurementSystem !== 'metric'){ setMeasurementSystemErrorMessage('Please select your preferred measurement system'); allVaild = false }

        if (measurementSystem === "" || measurementSystem === 'imperial') {
            if (heightFeet.length === 0 || heightInches.length === 0) { setFormErrorMessage("Invalid height!"); allVaild = false }
        }

        if (measurementSystem === "metric"){
            if (heightCentimeters.length === 0){ setFormErrorMessage("Invalid height!"); allVaild = false }
        }

        if (weight.length === 0) { setIsWeightValid(false); allVaild = false }

        if (!agreementOneChecked || !agreementTwoChecked){ setFormErrorMessage("Please accept all agreements before continuing"); allVaild = false }

        if (isUserMinor){
            if (guardianSignature.length === 0) { setIsGuardianSignatureValid(false); allVaild = false; }
        }

        return allVaild
    }

    const OnNextButton = async () => {

        if (validateFormInputs()) {

            setFormErrorMessage("")
            setIsFormLoading(true)

            var fullInchesHeight = 0
            if (measurementSystem === "" || measurementSystem === 'imperial') fullInchesHeight = (parseInt(heightFeet) * 12) + parseInt(heightInches)
            if (measurementSystem === 'metric') fullInchesHeight = Math.round(ConvertCentimetersToInches(heightCentimeters))
                    
            var weightPounds = 0
            if (measurementSystem === "" || measurementSystem === 'imperial') weightPounds = parseInt(weight)
            if (measurementSystem === 'metric') weightPounds = Math.round(ConvertKilogramsToPounds(parseInt(weight)))
            
            const jsonBody = {
                userId: userId,
                brandKey: styleConfig.brandKey,
                firstName: firstName,
                lastName: lastName,
                birthday: birthYear + '-' + birthMonth + '-' + birthDay,
                gender: gender,
                measurementSystem: measurementSystem,
                height: fullInchesHeight,
                weight: weightPounds,
                guardianSignature: guardianSignature
            }

            // console.log(jsonBody)

            const result = await WhichWeightAPI('CORE', 'POST', '/athlete_onboard_personal_info', jsonBody)

            // console.log(result)

            setIsFormLoading(false)

            if (result.data.status === 'failure'){
                setFormErrorMessage("Error saving personal info. Please try again")
                return
            }

            // TODO Do I need to add data to redux store here?

            navigate('/onboarding/sport')

        }
    }

    // Check if user is a minor. Code from ChatGPT
    useEffect(() => {

        const dateString = `${birthYear}-${birthMonth}-${birthDay}`;

        if (moment(dateString, 'YYYY-MM-DD', true).isValid()){

            const today = moment()
            const birthDateObj = moment(dateString, 'YYYY-MM-DD')
            const age = today.diff(birthDateObj, 'years')

            // console.log(age);

            setIsUserMinor(age < 18)
            setGuardianSignature('')
        }

    }, [birthMonth, birthDay, birthYear])

    return (
        <ScreenFrame>
            
            <LogoHeader backButtonAction={() => navigate('/onboarding/welcome')} />
            
            <BodyFrame>

                <div className='text-center text-xl italic font-bold text-gray-600'>Personalize Your Experience</div>
                {/* <div className='text-center text-gray-600'>Personalize your experience</div> */}

                <div className='flex gap-x-4'>

                    {/* First Name */}
                    <TextInputLine 
                        placeholder='First Name'
                        value={firstName} 
                        handleChange={handleFirstNameChange} 
                        isValid={isFirstNameValid} 
                        invalidMessage="Please enter your first name" 
                    />

                    {/* Last Name */}
                    <TextInputLine 
                        placeholder='Last Name'
                        value={lastName} 
                        handleChange={handleLastNameChange} 
                        isValid={isLastNameValid} 
                        invalidMessage="Please enter your last name" 
                    />

                </div>

                <div className='py-2 space-y-1'>
                    <div className='text-center text-xs text-gray-600'>Enter your date of birth</div>
                    <div className='grid grid-cols-4 gap-x-4'>
                        
                        {/* Birth Month */}
                        <NumberInputLine 
                            placeholder='MM'
                            value={birthMonth}
                            handleChange={handleBirthMonthChange}
                            onBlur={handleBirthMonthBlur}
                            isValid={isBirthMonthValid} 
                            invalidMessage="Required"
                        />

                        {/* Birth Day */}
                        <NumberInputLine 
                            placeholder='DD'
                            value={birthDay}
                            handleChange={handleBirthDayChange}
                            onBlur={handleBirthDayBlur}
                            isValid={isBirthDayValid}
                            invalidMessage="Required"
                        />

                        {/* Birth Year */}
                        <div className='col-span-2'>
                            <NumberInputLine 
                                placeholder='YYYY'
                                value={birthYear}
                                handleChange={handleBirthYearChange}
                                isValid={isBirthYearValid}
                                invalidMessage="Required"
                            />
                        </div>
                        
                    </div>
                </div>

                {/* Gender */}
                <div className='space-y-1'>
                    <div className='flex border-2 border-gray-300 rounded-xl text-center divide-x divide-gray-300 overflow-hidden'>
                        <div 
                            className={`
                                flex-1 py-2 
                                ${gender === 'male' ? 'text-white font-semibold' : ''}
                            `}
                            style={{
                                backgroundColor: gender === 'male' ? GetPrimaryColorHex(styleConfig) : ''
                            }}
                            onClick={() => { setGenderErrorMessage(""); setGender('male')}}
                        >
                            Male
                        </div>
                        <div 
                            className={`
                                flex-1 py-2 
                                ${gender === 'female' ? 'text-white font-semibold' : ''}
                            `}
                            style={{
                                backgroundColor: gender === 'female' ? GetPrimaryColorHex(styleConfig) : ''
                            }}
                            onClick={() => { setGenderErrorMessage(""); setGender('female')}}
                        >
                            Female
                        </div>
                        <div 
                            className={`
                                flex-1 py-2 
                                ${gender === 'other' ? 'text-white font-semibold' : ''}
                            `}
                            style={{
                                backgroundColor: gender === 'other' ? GetPrimaryColorHex(styleConfig) : ''
                            }}
                            onClick={() => { setGenderErrorMessage(""); setGender('other')}}
                        >
                            Other
                        </div>
                    </div>
                    {genderErrorMessage !== "" && <p className="text-center text-xs text-red-600">{genderErrorMessage}</p>}
                </div>

                {/* Measurement System */}
                <div className='space-y-1'>
                    <div className='flex border-2 border-gray-300 rounded-xl text-center divide-x divide-gray-300 overflow-hidden'>
                        <div 
                            className={`
                                flex-1 py-2 
                                ${measurementSystem === 'imperial' ? 'text-white font-semibold' : ''}
                            `}
                            style={{
                                backgroundColor: measurementSystem === 'imperial' ? GetPrimaryColorHex(styleConfig) : ''
                            }}
                            onClick={() => handleMeasurementSystemChange('imperial')}
                        >
                            Imperial
                        </div>
                        <div 
                            className={`
                                flex-1 py-2 
                                ${measurementSystem === 'metric' ? 'text-white font-semibold' : ''}
                            `}
                            style={{
                                backgroundColor: measurementSystem === 'metric' ? GetPrimaryColorHex(styleConfig) : ''
                            }}
                            onClick={() => handleMeasurementSystemChange('metric')}
                        >
                            Metric
                        </div>
                    </div>
                    {measurementSystemErrorMessage !== "" && <p className="text-center text-xs text-red-600">{measurementSystemErrorMessage}</p>}
                </div>

                {/* Height */}
                <div className='grid grid-cols-6 gap-x-4'>

                    <div className='flex items-center text-gray-600'>Height</div>

                    <div />

                    {(measurementSystem === "" || measurementSystem === "imperial") && 
                        <>
                            <div className='col-span-2 relative'>
                                <NumberInputLine
                                    placeholder=''
                                    value={heightFeet}
                                    handleChange={handleHeightFeetChange}
                                />
                                <div className="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">ft</div>
                            </div>

                            <div className='col-span-2 relative'>
                                <NumberInputLine
                                    placeholder=''
                                    value={heightInches}
                                    handleChange={handleHeightInchesChange}
                                />
                                <div className="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">in</div>
                            </div>

                        </>
                    }

                    {measurementSystem === "metric" && 
                        <div className='col-span-4 relative'>
                            <NumberInputLine
                                placeholder=''
                                value={heightCentimeters}
                                handleChange={handleHeightCentimetersChange}
                            />
                            <div className="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-400">cm</div>
                        </div>
                    }

                </div>

                {/* Weight */}
                <div className='grid grid-cols-6 gap-x-4'>

                    <div className='flex items-start pt-3 text-gray-600'>Weight</div>

                    <div />

                    <div className='col-span-4 relative'>
                        <NumberInputLine
                            placeholder=''
                            value={weight}
                            handleChange={handleWeightChange}
                            isValid={isWeightValid}
                            invalidMessage="Please input your weight"
                        />
                        <div className="absolute top-3 right-2 text-sm text-gray-400">
                            {(measurementSystem === "" || measurementSystem === "imperial") && <>lbs</>}
                            {(measurementSystem === "metric") && <>kgs</>}
                        </div>
                    </div>

                </div>
                
                <div className='pt-2 flex items-center gap-x-4'> 
                    <div 
                        className='h-5 w-5 p-0.5 border border-gray-400'
                        onClick={() => setAgreementOneChecked(!agreementOneChecked)}
                    >
                        {agreementOneChecked && <CheckIcon className='h-3.5' />}
                    </div>
                    <div className='flex-1 text-xs'>
                        By checking this box you agree to the 
                        {' '}<a href='https://www.whichweight.com/privacy' className='text-blue-500 cursor-pointer hover:underline' target="_blank" rel="noopener noreferrer">WhichWeight Privacy Policy</a>
                        {' '}and
                        {' '}<a href='https://www.whichweight.com/terms' className='text-blue-500 cursor-pointer hover:underline' target="_blank" rel="noopener noreferrer">WhichWeight Terms of Use</a>
                    </div>
                </div>

                <div className='flex items-center gap-x-4'> 
                    <div 
                        className='h-5 w-5 p-0.5 border border-gray-400'
                        onClick={() => setAgreementTwoChecked(!agreementTwoChecked)}
                    >
                        {agreementTwoChecked && <CheckIcon className='h-3.5' />}
                    </div>
                    <div className='flex-1 text-xs'>
                        By checking this box you are agreeing to and signing the 
                        {' '}<a href='https://www.whichweight.com/wavier' className='text-blue-500 cursor-pointer hover:underline' target="_blank" rel="noopener noreferrer">WhichWeight Wavier</a>
                    </div>
                </div>

                {isUserMinor && 
                    <TextInputLine
                        placeholder='Parent/Guardian Wavier Signature'
                        value={guardianSignature} 
                        handleChange={handleGuardianSignatureChange} 
                        isValid={isGuardianSignatureValid} 
                        invalidMessage="If you are under the age of 18, a parent/guardian signature is required for the wavier" 
                    />
                }

                <div className='mt-auto'>
                    {formErrorMessage !== "" && <p className="text-center text-xs text-red-600">{formErrorMessage}</p>}
                    <LoadingButton 
                        buttonText='Next'
                        className="flex items-center justify-center gap-x-2 w-full text-center text-white rounded-full py-2 px-4 text-lg font-semibold"
                        action={() => OnNextButton()}
                        isLoading={isFormLoading}
                    />
                </div>

            </BodyFrame>

        </ScreenFrame>
    )
}