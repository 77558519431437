import { WhichWeightAPI } from 'WhichWeightAPI'
import React, { useState, useEffect, useContext } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import {
    setActiveAssessmentPhaseIndex,
    setActiveAssessmentExerciseIndex,
    setActiveAssessmentSetIndex,
    setActiveAssessmentIncompleteSetInfo,
    setActiveAssessmentCompleteSetInfo,
    setActiveAssessmentPhaseCompletionBoolean,
    resetActiveAssessmentState,
} from 'redux/slices/activeAssessment'
import { setWhichWeightCacheEntireWeeklyCalendarJSON } from 'redux/slices/whichWeightCache'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { InitAssessmentSetWeightRecommendation } from 'functions/activeWorkoutUtils'
import { GetPrimaryColorHex } from 'redux/slices/brand'
import { GetPresentWeekOverview } from 'redux/selectors/PresentWeekOverviewSelector'
import useWakeLock from 'redux/customHooks/useWakeLock'
import moment from 'moment'

import HeaderDropdownBody from 'components/ActiveWorkout/Body/HeaderDropdownBody'
import ActiveWorkoutUIModeToggle from 'components/ActiveWorkout/ActiveWorkoutUIModeToggle'
import FullscreenLoading from 'components/Loading/FullscreenLoading'

import CompactModeBody from 'components/ActiveWorkout/CompactModeBody'
import ExpandedModeBody from 'components/ActiveWorkout/ExpandedModeBody'

export default function AssessmentWorkout() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { requestWakeLock, releaseWakeLock } = useWakeLock()
    const { modalData, ShowModal } = useContext(ModalContext)

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const userId = useSelector((state) => state.user.id)
    const email = useSelector((state) => state.user.email)
    const presentWeekOfTheYearKey = useSelector((state) => state.user.presentWeekOfTheYearKey)
    const presentWeek = GetPresentWeekOverview()
    const activeAssessment = useSelector((state) => state.activeAssessment)
    const assessmentUIFlowKey = activeAssessment.assessmentUIFlowKey
    const uiMode = activeAssessment.uiMode
    const numberOfPhases = activeAssessment.workoutTemplate !== null ? activeAssessment.workoutTemplate.phases.length : 0
    const phaseIndex = activeAssessment.phaseIndex
    const exerciseIndex = activeAssessment.exerciseIndex
    const setIndex = activeAssessment.setIndex

    const [expandHeaderDropdown, setExpandHeaderDropdown] = useState(false)
    const [showRestScreen, setShowRestScreen] = useState(false)
    const [triggerFinalCompletionAction, setTriggerFinalCompletionAction] = useState(false)
    const [savingWorkout, setSavingWorkout] = useState(false)

    const GetPhase = () => {
        if (activeAssessment.workoutTemplate !== null) return activeAssessment.workoutTemplate.phases[phaseIndex]
    }
    const GetExercise = () => {
        return GetPhase().exercises[exerciseIndex]
    }
    const GetSet = () => {
        return GetExercise().sets[setIndex]
    }

    const InitializeWeightRecommendations = () => {
        // If on an incomplete phase
        if (!GetPhase().completed) {
            // Loop over all exercises in the current phase
            GetPhase().exercises.map((currentExercise, currentExerciseIndex) => {
                // Loop over all sets
                currentExercise.sets.map((currentSet, currentSetIndex) => {
                    // For all incomplete sets that have not been loaded in
                    if (!currentSet.completedSetData.completed && !currentSet.recommendationData.weightRecommendationLoaded) {
                        // Get weight recommendation
                        InitAssessmentSetWeightRecommendation(
                            dispatch, 
                            activeAssessment, 
                            parseInt(phaseIndex), 
                            parseInt(currentExerciseIndex), 
                            parseInt(currentSetIndex),
                            setActiveAssessmentIncompleteSetInfo
                        )
                    }
                })
            })
        }
    }

    const ShowEDSModalForCurrentSet = async (currentPhaseIndex, currentExerciseIndex, currentExercise, currentSetIndex) => {
        const modalData = {
            key: ModalKey.WorkoutSetFeedback,
            fullscreen: true,
            currentExercise: currentExercise,
            setIndex: currentSetIndex,
            taperData: activeAssessment.taperData,
            buttonAction: async (payload) => {

                OnEDSOptionSelected(
                    currentPhaseIndex, 
                    currentExerciseIndex, 
                    currentExercise, 
                    currentSetIndex, 
                    payload.repsCompleted, 
                    payload.selectedMovementDifficultyScaleOption,
                    payload.selectedLoadDifficultyScaleOption
                )

            },
        }

        ShowModal(modalData)
    }

    const OnEDSOptionSelected = async (currentPhaseIndex, currentExerciseIndex, currentExercise, currentSetIndex, repsCompleted, movementDifficulty, difficulty) => {
        
        // Save the set to backend
        await SaveCompletedSet(currentPhaseIndex, currentExerciseIndex, currentSetIndex, repsCompleted, movementDifficulty, difficulty)

        // For expanded mode navigate to the next set
        if (activeAssessment.uiMode === 'expanded') ExpandedModeNavigateToNextSet()

    }

    const SaveCompletedSet = async (currentPhaseIndex, currentExerciseIndex, currentSetIndex, setUnitsCompleted, movementDifficulty, difficulty) => {
        
        const completedSetInfo = {
            phaseIndex: currentPhaseIndex,
            exerciseIndex: currentExerciseIndex,
            setIndex: currentSetIndex,
            databaseId: 0,
            setUnitsCompleted: setUnitsCompleted,
            movementDifficulty: movementDifficulty,
            difficulty: difficulty
        }

        // console.log(completedSetInfo);

        // Update redux store
        dispatch(setActiveAssessmentCompleteSetInfo(completedSetInfo))

    }

    const ExpandedModeNavigateToNextSet = () => {
        // REST SCREEN HACK
        if (GetPhase().type === 'circuit') {
            // If there are rest seconds defined in template AND we are not currently showing the rest screen
            if (GetExercise().restSecondsAfterExercise > 0 && !showRestScreen) {
                setShowRestScreen(true)
                return
            }
        }

        // If we are showing the rest screen make sure to toggle it off when going to next exercise set
        if (showRestScreen) setShowRestScreen(false)

        // For the straight phase type
        if (GetPhase().type === 'straight') {
            // Going to next exercise
            if (exerciseIndex < GetPhase().exercises.length - 1) {
                ActiveWorkoutNavigate('expanded-straight-next-exercise')
            } else {
                // Going to next phase
                if (phaseIndex < numberOfPhases - 1) ActiveWorkoutNavigate('expanded-next-phase')
                // Ending workout. TODO CHANGE HACKY FIX
                else setTriggerFinalCompletionAction(true)
            }

            // Going to next set
            if (setIndex < GetExercise().sets.length - 1) {
                ActiveWorkoutNavigate('expanded-next-set')
            } else {
                // Going to next exercise
                if (exerciseIndex < GetPhase.exercises.length - 1) {
                    ActiveWorkoutNavigate('expanded-straight-next-exercise')
                } else {
                    // Going to next phase
                    if (phaseIndex < numberOfPhases - 1) ActiveWorkoutNavigate('expanded-next-phase')
                    // Ending workout. TODO CHANGE HACKY FIX
                    else setTriggerFinalCompletionAction(true)
                }
            }
        }

        // For Circuit Type
        if (GetPhase().type === 'circuit') {
            // Going to next exercise
            if (exerciseIndex < GetPhase().exercises.length - 1) ActiveWorkoutNavigate('expanded-circuit-next-exercise')
            else {
                // Going to next circuit round
                if (setIndex < GetPhase().circuitRounds - 1) ActiveWorkoutNavigate('expanded-circuit-next-round')
                else {
                    // Going to next phase
                    if (phaseIndex < numberOfPhases - 1) ActiveWorkoutNavigate('expanded-next-phase')
                    // Ending workout. TODO CHANGE HACKY FIX
                    else setTriggerFinalCompletionAction(true)
                }
            }
        }
    }

    const ActiveWorkoutNavigate = (activeWorkoutNavigationKey) => {
        switch (activeWorkoutNavigationKey) {
            case 'complete-phase':
                // Track in redux that the phase was complete

                const dispatchJSON = {
                    phaseIndex: phaseIndex,
                    completed: true,
                }

                dispatch(setActiveAssessmentPhaseCompletionBoolean(dispatchJSON))

                // Navigate to next phase
                // If on last phase, complete workout

                if (phaseIndex < activeAssessment.workoutTemplate.phases.length - 1) dispatch(setActiveAssessmentPhaseIndex(phaseIndex + 1))
                else EndWorkout() //(navigate, dispatch, setSavingWorkout, userId, activeAssessment)

                break

            case 'compact-next-phase':
                dispatch(setActiveAssessmentPhaseIndex(phaseIndex + 1))
                break

            case 'compact-previous-phase':
                dispatch(setActiveAssessmentPhaseIndex(phaseIndex - 1))
                break

            case 'expanded-next-phase':
                // Track in redux that the phase was complete

                const dispatchJSON2 = {
                    phaseIndex: phaseIndex,
                    completed: true,
                }

                dispatch(setActiveAssessmentPhaseCompletionBoolean(dispatchJSON2))

                dispatch(setActiveAssessmentPhaseIndex(phaseIndex + 1))
                dispatch(setActiveAssessmentExerciseIndex(0))
                dispatch(setActiveAssessmentSetIndex(0))

                break

            case 'expanded-straight-next-exercise':
                dispatch(setActiveAssessmentExerciseIndex(exerciseIndex + 1))
                dispatch(setActiveAssessmentSetIndex(0))
                break

            case 'expanded-circuit-next-exercise':
                dispatch(setActiveAssessmentExerciseIndex(exerciseIndex + 1))
                break

            case 'expanded-next-set':
                dispatch(setActiveAssessmentSetIndex(setIndex + 1))
                break

            case 'expanded-circuit-next-round':
                dispatch(setActiveAssessmentExerciseIndex(0))
                dispatch(setActiveAssessmentSetIndex(setIndex + 1))
                break

            /*
            case "expanded-specific-set":
                
                // TODO WOULD NEED TO PASS IN NEW INDICES HERE
                navigate('/active/workout/phase/' + phaseIndex + '/exercise/' + exerciseIndex + '/set/' + setIndex)

                setExpandHeaderDropdown(false)

                break
            */

            default:
                console.error(activeWorkoutNavigationKey + ' is not a valid activeWorkoutNavigationKey!! Doing nothing')
                break
        }
    }

    const EndWorkout = async () => {

        setSavingWorkout(true)

        const jsonBody = {
            email: email,
            uiMode: uiMode,
            assessmentUIFlowKey: assessmentUIFlowKey,
            assessmentTemplateData: activeAssessment.workoutTemplate,
        }

        // console.log(jsonBody)

        const result = await WhichWeightAPI('CORE', 'POST', '/assessment_complete', jsonBody)

        // console.log(result);

        if (result.data.status == 'failure') {
            console.error(result)
            return
        }

        // If we are prompting for an assessment, that means a user is at the beginning of a new program
        // and has not assessed in a while.
        // If this is the case, we should clear the program cache so that the assessment gets applied
        if (
            presentWeek && 
            (
                presentWeek.programWeekIndex === 0 
                || presentWeek.programWeekIndex === 1
            )
        ) {

            const jsonBody2 = {
                userId: userId,
                presentWeekOfTheYearKey: presentWeekOfTheYearKey,
                localDate: moment.utc().local().format('YYYY-MM-DD')
            }

            const clear_cache_result = await WhichWeightAPI('PROGRAM', 'POST', '/delete_entire_program_cache', jsonBody2)

            if (clear_cache_result.data.status == 'failure') console.error(clear_cache_result)

        }
        
        const props = {
            state: {
                assessmentUIFlowKey: assessmentUIFlowKey,
                assessmentWorkoutTemplate: activeAssessment.workoutTemplate
            }
        }

        setSavingWorkout(false)

        dispatch(resetActiveAssessmentState())
        dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON(null)) // Reset calendar data

        // Release wake lock when leaving workout screen
        releaseWakeLock()

        // console.log(props)

        navigate('/assessment/summary', props)

    }

    // When the component mounts, request a wake lock
    useEffect(() => {
        requestWakeLock()
    }, []);

    useEffect(() => {
        if (activeAssessment.workoutTemplate !== null) InitializeWeightRecommendations()
    }, [phaseIndex, modalData])

    useEffect(() => {
        if (triggerFinalCompletionAction) ActiveWorkoutNavigate('complete-phase')
    }, [triggerFinalCompletionAction])

    if (activeAssessment.workoutTemplate === null) return <></>

    if (savingWorkout) return <FullscreenLoading loadingText="Saving Workout" />

    return (
        <div 
            className="fixed flex flex-col h-full w-full overflow-auto"
            style={{
                background: `linear-gradient(to bottom, ${GetPrimaryColorHex(styleConfig)}, ${GetPrimaryColorHex(styleConfig, 2)})`
            }}
        >
            {/* Header */}
            <div className="flex items-center justify-center py-2 px-4 bg-white border-b border-gray-400" onClick={() => setExpandHeaderDropdown(!expandHeaderDropdown)}>
                {/* Spacer */}
                <div className="flex-1" />

                {/* WhichWeight Logo */}
                <img src={styleConfig.logo} alt="WhichWeight Icon Logo" className="h-8 pointer-events-none" />

                {/* Dropdown Icon */}
                <div className="flex-1">
                    {!expandHeaderDropdown && <ChevronDownIcon className="mt-1 h-6 ml-auto text-gray-600 pointer-events-none" />}
                    {expandHeaderDropdown && <ChevronUpIcon className="mt-1 h-6 ml-auto text-gray-600 pointer-events-none" />}
                </div>
            </div>

            {/* Dropdown Options */}
            {expandHeaderDropdown && (
                <HeaderDropdownBody
                    uiMode={activeAssessment.uiMode}
                    setExpandHeaderDropdown={setExpandHeaderDropdown}
                    numberOfPhases={numberOfPhases}
                    phaseIndex={phaseIndex}
                    ActiveWorkoutNavigate={ActiveWorkoutNavigate}
                    EndWorkout={EndWorkout}
                />
            )}

            {/* Workout Body */}
            {!expandHeaderDropdown && (
                <>
                    {/* UI Mode Toggle */}
                    <ActiveWorkoutUIModeToggle setShowRestScreen={setShowRestScreen} />

                    {/* Compact Mode Body */}
                    {uiMode === 'compact' && (
                        <>
                            <div className="p-4">
                                <div className="rounded-xl border-2 border-l-8 border-white flex p-2 mt-2 gap-x-4 items-center">
                                    <InformationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />  
                                    <div className="flex-1 text-sm font-medium text-white flex-grow">
                                        <div>Click the gray circle on the right of each set to complete it</div>
                                    </div>
                                </div>
                            </div>

                            <CompactModeBody
                                numberOfPhases={numberOfPhases}
                                phaseIndex={phaseIndex}
                                phase={GetPhase()}
                                ShowEDSModalForCurrentSet={ShowEDSModalForCurrentSet}
                                SaveCompletedSet={SaveCompletedSet}
                                ActiveWorkoutNavigate={ActiveWorkoutNavigate}
                                setTriggerFinalCompletionAction={setTriggerFinalCompletionAction}
                            />
                        </>
                    )}

                    {/* Expanded Mode Body */}
                    {uiMode === 'expanded' && (
                        <>

                            <div className="p-4">
                                <div className="rounded-xl border-2 border-l-8 border-white flex p-2 mt-2 gap-x-4 items-center">
                                    <InformationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />  
                                    <div className="flex-1 text-sm font-medium text-white flex-grow">
                                        <div>Complete each set by using the button at the bottom of the screen</div>
                                    </div>
                                </div>
                            </div>

                            <ExpandedModeBody
                                numberOfPhases={numberOfPhases}
                                phaseIndex={phaseIndex}
                                phase={GetPhase()}
                                exerciseIndex={exerciseIndex}
                                exercise={GetExercise()}
                                setIndex={setIndex}
                                set={GetSet()}
                                ShowEDSModalForCurrentSet={ShowEDSModalForCurrentSet}
                                ExpandedModeNavigateToNextSet={ExpandedModeNavigateToNextSet}
                                showRestScreen={showRestScreen}
                            />
                            
                        </>
                    )}
                </>
            )}
        </div>
    )
    
}
