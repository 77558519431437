import React from 'react'
import { useSelector } from 'react-redux'
import { GetPrimaryColorHex } from 'redux/slices/brand'

export default function SetInfoPill({ 
    sets, 
    setIndex 
}) {

    const styleConfig = useSelector((state) => state.brand.styleConfig)

    return (
        <div className="flex w-full h-8 border-2 border-white rounded-xl divide-x overflow-y-hidden shadow-lg">
            {Array.from({ length: sets }, (_, i) => i + 1).map((set, mappedSetIndex) => (
                <React.Fragment key={mappedSetIndex}>
                    
                    {/* Current Styling */}
                    {mappedSetIndex === parseInt(setIndex) &&
                        <div 
                            className="flex flex-grow h-full bg-white font-semibold items-center justify-center"
                            style={{
                                color: GetPrimaryColorHex(styleConfig, 2)
                            }}
                        >
                            Set {mappedSetIndex + 1}
                        </div>
                    }
                    
                    {/* Incomplete Styling */}
                    {mappedSetIndex !== parseInt(setIndex) && <div className="flex flex-grow h-full bg-gray-300 text-gray-500 items-center justify-center">Set {mappedSetIndex + 1}</div>}
                
                </React.Fragment>
            ))}
        </div>
    )

}
