import LoadingButton from 'components/LoadingButton'

export default function RetryPaymentButton({ subscriptionData, OnRetryPastDuePayment, retryingPayment }) {
    if (subscriptionData.status === 'past_due' && !subscriptionData.cancel_at_period_end) {
        return (
            <LoadingButton
                className="w-full flex items-center justify-center gap-x-2 text-center rounded-full py-2 text-md font-semibold text-gray-500 border border-gray-500"
                buttonText="Retry Payment"
                action={() => OnRetryPastDuePayment()}
                isLoading={retryingPayment}
            />
        )
    }
}
