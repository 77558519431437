import { useNavigate } from 'react-router-dom'
import LoadingButton from 'components/LoadingButton'

export default function ResumeSubscriptionButton({ subscriptionData, OnResumeSubscription, isResumeSubscriptionLoading }) {
    const navigate = useNavigate()

    // Real resume button
    if (subscriptionData.cancel_at_period_end) {
        return (
            <LoadingButton
                buttonText="Resume Subscription"
                action={() => OnResumeSubscription()}
                className="w-full flex items-center justify-center gap-x-2 text-center text-white rounded-full py-2 px-4 text-lg font-semibold bg-gradient-to-r from-orange-600 to-orange-400"
                isLoading={isResumeSubscriptionLoading}
            />
        )
    }

    // Fake unpaid subscription resume button
    // Basically just gets the user to resubscribe
    if (subscriptionData.status === 'unpaid' && !subscriptionData.cancel_at_period_end) {
        return (
            <button
                className="w-full flex items-center justify-center gap-x-2 text-center text-white rounded-full py-2 px-4 text-lg font-semibold bg-gradient-to-r from-orange-600 to-orange-400"
                onClick={() => navigate('/subscription/options')}
            >
                Resume Subscription
            </button>
        )
    }
}
