import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { useGetFitnessProfile } from 'redux/customHooks/useGetFitnessProfile'
import { setWhichWeightCacheEntireWeeklyCalendarJSON } from 'redux/slices/whichWeightCache'
import { GetEventTerrainOptionsBasedOnSport, GetEventDistanceOptionsBasedOnSport } from 'functions/eventUtils'
import { GetPrimaryColorHex } from 'redux/slices/brand'
import moment from 'moment'

import CustomHeader from 'components/Header/CustomHeader'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import TextInputBox from 'components/Onboarding/TextInputBox'
import LoadingButton from 'components/LoadingButton'

export default function AddEvent() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const fitnessProfile = useGetFitnessProfile()

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const userId = useSelector((state) => state.user.id)
    const presentWeekOfTheYearKey = useSelector(state => state.user.presentWeekOfTheYearKey);

    // Event Name
    const [eventName, setEventName] = useState('')
    const [isEventNameValid, setIsEventNameValid] = useState(true)
    const handleEventNameChange = (event) => {
        if (!isEventNameValid) setIsEventNameValid(true)
        setEventName(event.target.value)
    }

    // Event Type
    const eventTypeRadioOptions = [
        {
            key: 'a-race',
            title: 'A Race',
            description: 'The main race you are training for. This is the race you want to compete your best at.',
            taperDetails: 'Heavy tapering will begin 3 weeks leading up to this event',
            warning: 'At least 16 weeks of training leading up to an A Race is recommended!',
        },
        {
            key: 'b-race',
            title: 'B Race',
            description: 'Your additional races.',
            taperDetails: 'Moderate tapering will begin 2 weeks leading up to these events',
            warning: '',
        },
        {
            key: 'c-race',
            title: 'C Race',
            description: 'Races for fun.',
            taperDetails: 'Light tapering will be applied the week of these events',
            warning: '',
        },
    ]
    const [selectedEventType, setSelectedEventType] = useState(eventTypeRadioOptions[0].key)

    // Event Terrain
    const [eventTerrainOptions, setEventTerrainOptions] = useState([])
    const [selectedEventTerrain, setSelectedEventTerrain] = useState('')
    const [isEventTerrainValid, setIsEventTerrainValid] = useState(true)
    const handleEventTerrainChange = (value) => {
        if (!isEventTerrainValid) setIsEventTerrainValid(true)
        setSelectedEventTerrain(value)

        // For all other sports, do a dynamic default
        if (fitnessProfile.sportId !== 10) {

            // Reset distance inputs if terrain changes
            if (value !== 'other') {
                handleEventDistanceChange('')
                handleEventDistanceTextInputChange('')
            }

            if (value === 'other') {
                handleEventDistanceChange('other')
                handleEventDistanceTextInputChange('')
            }

        }

    }

    // Event Terrain Input Box
    const [eventTerrainTextInput, setEventTerrainTextInput] = useState('')
    const [isEventTerrainTextInputValid, setIsEventTerrainTextInputValid] = useState(true)
    const handleEventTerrainTextInputChange = (value) => {
        if (!isEventTerrainTextInputValid) setIsEventTerrainTextInputValid(true)
        setEventTerrainTextInput(value)
    }

    // Event Distance
    const [eventDistanceOptions, setEventDistanceOptions] = useState([])
    const [selectedEventDistance, setSelectedEventDistance] = useState('')
    const [isEventDistanceValid, setIsEventDistanceValid] = useState(true)
    const handleEventDistanceChange = (value) => {
        if (!isEventDistanceValid) setIsEventDistanceValid(true)
        setSelectedEventDistance(value)
    }

    // Event Distance Input Box
    const [eventDistanceTextInput, setEventDistanceTextInput] = useState('')
    const [isEventDistanceTextInputValid, setIsEventDistanceTextInputValid] = useState(true)
    const handleEventDistanceTextInputChange = (value) => {
        if (!isEventDistanceTextInputValid) setIsEventDistanceTextInputValid(true)
        setEventDistanceTextInput(value)
    }
    
    // Event training start week
    const [eventTrainingStartDate, setEventTrainingStartDate] = useState(moment().utc().local().format('YYYY-MM-DD'))
    const [isEventTrainingStartDateDateValid, setIsEventTrainingStartDateDateValid] = useState(true)
    const handleEventTrainingStartDateChange = (event) => {
        if (!isEventTrainingStartDateDateValid) setIsEventTrainingStartDateDateValid(true)
        setEventTrainingStartDate(event.target.value)
    }

    // Event Date
    const [eventDate, setEventDate] = useState('')
    const [isEventDateValid, setIsEventDateValid] = useState(true)
    const handleEventDateChange = (event) => {
        if (!isEventDateValid) setIsEventDateValid(true)
        setEventDate(event.target.value)
    }

    const [saveErrorMessage, setSaveErrorMessage] = useState('')
    const [isSaveEventButtonLoading, setIsSaveEventButtonLoading] = useState(false)

    const validateFormInputs = () => {
        var allVaild = true

        // Check event name input
        if (eventName.length === 0) {
            setIsEventNameValid(false)
            allVaild = false
        } else setIsEventNameValid(true)

        // Check event terrain input

        // For non-other event terrain
        if (selectedEventTerrain !== 'other') {
            
            // The input box should be valid
            setIsEventTerrainTextInputValid(true)

            if (selectedEventTerrain.length === 0) {
                setIsEventTerrainValid(false)
                allVaild = false
            } else {
                setIsEventTerrainValid(true)
            }

        }

        // For other event terrain
        if (selectedEventTerrain === 'other') {

            setIsEventTerrainValid(true)

            if (eventTerrainTextInput.length === 0) {
                setIsEventTerrainTextInputValid(false)
                allVaild = false
            } else {
                setIsEventTerrainTextInputValid(true)
            }

        }

        // Check event distance input

        // For non-other event distance
        if (selectedEventDistance !== 'other') {
            
            // The input box should be valid
            setIsEventDistanceTextInputValid(true)

            if (selectedEventDistance.length === 0) {
                setIsEventDistanceValid(false)
                allVaild = false
            } else {
                setIsEventDistanceValid(true)
            }

        }

        // For other event terrain
        if (selectedEventDistance === 'other') {

            setIsEventDistanceValid(true)

            if (eventDistanceTextInput.length === 0) {
                setIsEventDistanceTextInputValid(false)
                allVaild = false
            } else {
                setIsEventDistanceTextInputValid(true)
            }

        }

        // Check event training start date (a race only)
        if (selectedEventType === 'a-race' && eventTrainingStartDate.length === 0) {
            setIsEventTrainingStartDateDateValid(false)
            allVaild = false
        } else {
            setIsEventTrainingStartDateDateValid(true)
        }

        // Check event date input
        if (eventDate.length === 0) {
            setIsEventDateValid(false)
            allVaild = false
        } else setIsEventDateValid(true)

        if (selectedEventType === 'a-race') {

            // Make sure eventTrainingStartDate is before eventDate
            if (moment(eventTrainingStartDate).isAfter(moment(eventDate))) {
                setSaveErrorMessage('Event training start date must be before event date')
                allVaild = false
            }

            // Make sure eventTrainingStartDate is not on the same ISO week as eventDate
            if (moment(eventTrainingStartDate).isoWeek() === moment(eventDate).isoWeek()) {
                setSaveErrorMessage('Event training start date and event date must be on different weeks')
                allVaild = false
            }

            // Make sure the number of days between eventTrainingStartDate and eventDate is less than 360
            if (moment(eventDate).diff(moment(eventTrainingStartDate), 'days') > 360) {
                setSaveErrorMessage('Event training start date and event date must be within 360 days of each other')
                allVaild = false
            }
        }

        return allVaild
    }

    const OnSaveEventButton = async () => {

        setSaveErrorMessage('')

        if (validateFormInputs()) {
            setIsSaveEventButtonLoading(true)

            // Save event to backend
            
            const jsonBody = {
                traineeId: userId,
                name: eventName,
                eventTrainingStartDate: selectedEventType === 'a-race' ? moment(eventTrainingStartDate).startOf('isoWeek').format('YYYY-MM-DD') : eventDate,
                eventType: selectedEventType,
                eventTerrain: selectedEventTerrain,
                eventTerrainTextInput: eventTerrainTextInput,
                eventDistance: selectedEventDistance,
                eventDistanceTextInput: eventDistanceTextInput,
                eventDate: eventDate,
            }

            // console.log(jsonBody);

            const result = await WhichWeightAPI('CORE', 'POST', '/event', jsonBody)

            // console.log(result);

            if (result.data.status === 'failure') {
                setSaveErrorMessage('Failed to save event. Please try again')
                setIsSaveEventButtonLoading(false)
                console.error(result)
                return
            }

            // Clear active a race caches

            const jsonBody2 = {
                userId: userId,
                presentWeekOfTheYearKey: presentWeekOfTheYearKey,
                localDate: moment.utc().local().format('YYYY-MM-DD')
            }
    
            const clear_cache_result = await WhichWeightAPI('PROGRAM', 'POST', '/delete_active_a_race_program_table', jsonBody2)
    
            // console.log(clear_cache_result)

            if (clear_cache_result.data.status === 'failure') {
                console.error(clear_cache_result)
            }

            setIsSaveEventButtonLoading(false)

            dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON(null)) // Reset calendar data

            navigate('/events')
        }
    }

    useEffect(() => {

        if (fitnessProfile === null) return

        setEventTerrainOptions(GetEventTerrainOptionsBasedOnSport(fitnessProfile.sportId))

        // For cycling, default to other
        if (fitnessProfile.sportId === 10) {
            handleEventDistanceChange('other')
            handleEventDistanceTextInputChange('')
        }

    }, [fitnessProfile])

    useEffect(() => {

        if (fitnessProfile === null) return

        setEventDistanceOptions(GetEventDistanceOptionsBasedOnSport(fitnessProfile.sportId, selectedEventTerrain))

    }, [selectedEventTerrain])

    return (
        <>

            <CustomHeader text="Add A New Event" backButtonAction={() => navigate('/events')} />

            {fitnessProfile === null && <MediumLoadingComponent />}

            {fitnessProfile !== null && 
                <div className="p-4 space-y-4">

                    {/* Event Name */}
                    <TextInputBox 
                        label="Event Name" 
                        value={eventName} 
                        handleChange={handleEventNameChange} 
                        isValid={isEventNameValid} 
                        invalidMessage="Please enter an event name" 
                    />

                    {/* Event Type */}
                    <div>
                        <div className="pl-2 text-sm text-gray-600 mb-1">Event Priority</div>

                        {/* Radio Group */}
                        <div className="grid grid-cols-3 gap-x-2 mb-2">
                            {eventTypeRadioOptions.map((eventTypeRadioOption, eventTypeRadioIndex) => (
                                <div
                                    key={eventTypeRadioIndex}
                                    onClick={() => setSelectedEventType(eventTypeRadioOption.key)}
                                    className='flex items-center p-2 gap-x-2 rounded-lg bg-white shadow-sm cursor-pointer'
                                    style={{
                                        borderWidth: eventTypeRadioOption.key === selectedEventType ? 3 : 2,
                                        borderColor: eventTypeRadioOption.key === selectedEventType ? GetPrimaryColorHex(styleConfig) : '#d1d5db',
                                    }}
                                >

                                    {/* Empty Circle Icon */}
                                    {eventTypeRadioOption.key !== selectedEventType && (
                                        <div className="h-3 w-4 rounded-full bg-gray-200" />
                                    )}

                                    {/* Selected Check Icon */}
                                    {eventTypeRadioOption.key === selectedEventType && (
                                        <CheckCircleIcon 
                                            className={`
                                                h-5 w-5
                                            `} 
                                            style={{
                                                color: GetPrimaryColorHex(styleConfig),
                                            }}
                                            aria-hidden="true" 
                                        />
                                    )}

                                    {/* Text */}
                                    <div className="w-full text-sm font-medium text-gray-900">{eventTypeRadioOption.title}</div>
                                    
                                </div>
                            ))}
                        </div>

                        {/* Description */}
                        <div className='bg-gray-100 p-4 rounded-lg'>
                            <div className="text-sm text-gray-500">{eventTypeRadioOptions.find(eventTypeRadioOption => eventTypeRadioOption.key === selectedEventType).description}</div>
                            <div className="text-sm text-gray-500">{eventTypeRadioOptions.find(eventTypeRadioOption => eventTypeRadioOption.key === selectedEventType).taperDetails}</div>
                            {eventTypeRadioOptions.find(eventTypeRadioOption => eventTypeRadioOption.key === selectedEventType).warning != '' && <div className="pt-1 text-sm font-medium text-gray-600 italic">{eventTypeRadioOptions.find(eventTypeRadioOption => eventTypeRadioOption.key === selectedEventType).warning}</div>}
                        </div>

                    </div>

                    {/* Event Terrain */}
                    <div className="space-y-1">
                        <div className="pl-2 text-sm text-gray-600">Event Type</div>

                        <select
                            id="eventTerrain"
                            value={selectedEventTerrain}
                            onChange={(e) => handleEventTerrainChange(e.target.value)}
                            className="w-full px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300"
                        >
                            <option disabled value="">Select Event Type</option>
                            {eventTerrainOptions.map((eventTerrainOption, eventTerrainIndex) => (
                                <option key={eventTerrainIndex} value={eventTerrainOption.key}>
                                    {eventTerrainOption.title}
                                </option>
                            ))}
                        </select>
                        {/* Error Message */}
                        {isEventTerrainValid ? null : <p className="mt-1 text-sm text-red-600">Please select an event type</p>}
                    </div>

                    {/* Event Terrain Input Box */}
                    {selectedEventTerrain === 'other' && (
                        <div className="space-y-1">
                            <TextInputBox 
                                label="Event Type Details" 
                                value={eventTerrainTextInput} 
                                placeholder="Enter Event Type Here"
                                handleChange={(e) => handleEventTerrainTextInputChange(e.target.value)} 
                                invalidMessage="Please enter an event type" 
                            />
                            {/* Error Message */}
                            {isEventTerrainTextInputValid  ? null : <p className="mt-1 text-sm text-red-600">Please enter an event type</p>}
                        </div>
                    )}

                    {/* Event Distance */}
                    {fitnessProfile.sportId !== 10 && 
                        <div className="space-y-1">
                            <div className="pl-2 text-sm text-gray-600">Event Distance</div>

                            <select
                                id="eventDistance"
                                value={selectedEventDistance}
                                onChange={(e) => handleEventDistanceChange(e.target.value)}
                                className="w-full px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300"
                            >
                                <option disabled value="">Select Event Distance</option>
                                {eventDistanceOptions.map((eventDistanceOption, eventDistanceIndex) => (
                                    <option key={eventDistanceIndex} value={eventDistanceOption.key}>
                                        {eventDistanceOption.title}
                                    </option>
                                ))}
                            </select>
                            {/* Error Message */}
                            {isEventDistanceValid ? null : <p className="mt-1 text-sm text-red-600">Please select an event distance</p>}
                        </div>
                    }

                    {/* Event Distance Input Box */}
                    {selectedEventDistance === 'other' && (
                        <div className="space-y-1">
                            <TextInputBox 
                                label="Event Distance Details" 
                                value={eventDistanceTextInput} 
                                placeholder='Enter Event Distance Here'
                                handleChange={(event) => handleEventDistanceTextInputChange(event.target.value)} 
                                invalidMessage="Please enter an event distance" 
                            />
                            {/* Error Message */}
                            {isEventDistanceTextInputValid  ? null : <p className="mt-1 text-sm text-red-600">Please enter an event distance</p>}
                        </div>
                    )}

                    {/* Event Training Start Week (A Race Only) */}
                    {selectedEventType === 'a-race' && (
                        <div className="space-y-1">
                            <div className="pl-2 text-sm text-gray-600">Event Training Start Date</div>

                            <div className="pb-2 pl-2 text-xs text-gray-400">Which day would you like to begin your strength programming for this event?</div>

                            <input
                                type="date"
                                name="eventDate"
                                id="eventDate"
                                className="w-full px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                                placeholder="Event Date*"
                                value={eventTrainingStartDate}
                                onChange={handleEventTrainingStartDateChange}
                                min={moment().local().format('YYYY-MM-DD')}
                            />

                            {/* Error Message */}
                            {isEventTrainingStartDateDateValid ? null : <p className="mt-1 text-sm text-red-600">Please select an event training start date</p>}
                        </div>
                    )}

                    {/* Event Date */}
                    <div className="space-y-1">
                        <div className="pl-2 text-sm text-gray-600">Event Date</div>

                        <input
                            type="date"
                            name="eventDate"
                            id="eventDate"
                            className="w-full px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                            placeholder="Event Date*"
                            value={eventDate}
                            onChange={handleEventDateChange}
                            min={moment().local().format('YYYY-MM-DD')}
                        />

                        {/* Error Message */}
                        {isEventDateValid ? null : <p className="mt-1 text-sm text-red-600">Please select an event date</p>}
                    </div>

                    {/* Save Event Button */}
                    <div className='space-y-4'>

                        {/* Error Message */}
                        {saveErrorMessage !== '' && <p className="mt-1 text-sm text-center text-red-600">{saveErrorMessage}</p>}

                        {/* Save Event Button */}
                        <LoadingButton 
                            buttonText="Save Event" 
                            isLoading={isSaveEventButtonLoading} 
                            action={() => OnSaveEventButton()} 
                        />

                    </div>

                </div>
            }

        </>
    )
}
