import { useContext } from 'react'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { GetActiveWorkoutSliceKey } from 'functions/sliceUtils'
import { useSelector } from 'react-redux'

export default function HeaderDropdownBody({ uiMode, setExpandHeaderDropdown, numberOfPhases, phaseIndex, ActiveWorkoutNavigate, EndWorkout }) {
    const { ShowModal } = useContext(ModalContext)

    const activeWorkout = useSelector((state) => state.activeWorkout)
    const activeAssessment = useSelector((state) => state.activeAssessment)
    var workoutTypeKey = GetActiveWorkoutSliceKey(activeWorkout, activeAssessment)

    const ShowSkipPhaseModal = () => {
        const modalData = {
            key: ModalKey.ActionWarning,
            fullscreen: false,
            titleText: 'Skip to next workout phase?',
            bodyText: ['Skipping to the next phase will discard any incomplete sets in this phase. Continue?'],
            buttonText: 'Skip to next phase',
            buttonAction: () => OnSkipPhase(),
        }

        ShowModal(modalData)
    }

    const OnSkipPhase = () => {
        setExpandHeaderDropdown(false)

        // Going to next phase
        if (phaseIndex < numberOfPhases - 1) ActiveWorkoutNavigate('expanded-next-phase')
        else EndWorkout()
    }

    // Shows the end workout modal
    const ShowEndWorkoutModal = () => {
        const modalData = {
            key: ModalKey.ActionWarning,
            fullscreen: false,
            titleText: 'End Workout?',
            bodyText: ['Ending the workout now will discard any incomplete sets. Continue?'],
            buttonText: 'End Workout',
            buttonAction: () => EndWorkout(),
        }

        ShowModal(modalData)
    }

    return (
        <div className="flex flex-col w-full min-h-screen bg-gray-100 p-4 space-y-4 overflow-auto">
            {uiMode === 'expanded' && workoutTypeKey === 'active-workout' && (
                <button
                    type="button"
                    className="w-full text-center text-gray-500 border border-gray-400 rounded-full bg-white py-2 px-4 text-md font-semibold shadow-sm"
                    onClick={() => ShowSkipPhaseModal()}
                >
                    Skip To Next Phase
                </button>
            )}

            <button
                type="button"
                className="w-full text-center text-gray-500 border border-gray-400 rounded-full bg-white py-2 px-4 text-md font-semibold shadow-sm"
                onClick={() => ShowEndWorkoutModal()}
            >
                End Workout
            </button>
        </div>
    )
}
