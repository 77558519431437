import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import CustomHeader from 'components/Header/CustomHeader'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import LoadingButton from 'components/LoadingButton'

const bulletPoints = [
    'Mobility days are easier, do not require equipment, and should be done on days where you are not doing an endurance workout',
    'Strength days are harder, are most effective in a gym with equipment, and should be done on the same days as endurance workouts',
    'Strength days should have at least one day of rest in between them',
]

export default function EditWeeklySchedule() {
    // Function used to navigate to new pages
    let navigate = useNavigate()
    const OnNavigationButtonPressed = (route, props) => {
        navigate(route, props)
    }

    const userId = useSelector((state) => state.user.id)

    // Weekly Schedule JSON variables
    const [weekDaySchedule, setWeekDaySchedule] = useState(null)
    const [isWeekDayScheduleValid, setIsWeekDayScheduleValid] = useState(true)
    const [isSaveChangesLoading, setIsSaveChangesLoading] = useState(false)

    const HandleWeekDayScheduleChange = (weekDayIndex, workoutTemplateKey) => {
        setIsWeekDayScheduleValid(true)

        const tempWeekDaySchedule = JSON.parse(JSON.stringify(weekDaySchedule))

        // Remove all instances of the workout template key from the schedule array first
        // This prevents us from having duplicates
        Object.keys(tempWeekDaySchedule).map((key) => {
            if (tempWeekDaySchedule[key] === workoutTemplateKey) tempWeekDaySchedule[key] = 'rest'
        })

        tempWeekDaySchedule[weekDayIndex] = workoutTemplateKey

        setWeekDaySchedule(tempWeekDaySchedule)
    }

    const GetWeeklyScheduleFromTheBackend = async () => {
        const jsonBody = {
            userId: userId,
        }

        // console.log(jsonBody)

        const result = await WhichWeightAPI('PROGRAM', 'GET', '/default_week_day_schedule_json', jsonBody)

        // console.log(result)

        setWeekDaySchedule(result.data.data)
    }

    const OnSaveChangesButton = async () => {
        // Verifying input

        const assignedWorkoutTemplateKeys = Object.values(weekDaySchedule)

        if (
            !assignedWorkoutTemplateKeys.includes('strength-session-a') ||
            !assignedWorkoutTemplateKeys.includes('strength-session-b') ||
            !assignedWorkoutTemplateKeys.includes('mobility-a') ||
            !assignedWorkoutTemplateKeys.includes('mobility-b')
        ) {
            setIsWeekDayScheduleValid(false)
            return
        }

        setIsSaveChangesLoading(true)

        // Saving to backend

        const jsonBody = {
            userId: userId,
            weekDaySchedule: weekDaySchedule,
        }

        // console.log(jsonBody)

        const result = await WhichWeightAPI('PROGRAM', 'POST', '/default_week_day_schedule_json', jsonBody)

        // console.log(result)

        setIsSaveChangesLoading(false)

        if (result.data.status === 'failure') return

        OnNavigationButtonPressed('/')
    }

    useEffect(() => {
        GetWeeklyScheduleFromTheBackend()
    }, [])

    return (
        <>
            {/* Header */}
            <CustomHeader text="Edit Weekly Schedule" backButtonAction={() => OnNavigationButtonPressed('/home')} />

            {weekDaySchedule === null && <MediumLoadingComponent />}

            {weekDaySchedule !== null && (
                <div className="p-4 space-y-4">
                    <div className="text-center text-gray-800">WhichWeight programs consist of 4 workouts per week. Here are some things to consider when setting your weekly schedule</div>

                    {/* Bullet List */}
                    <ul className="list-disc px-6 py-4 text-gray-800 space-y-2 text-sm border border-gray-400 rounded-xl">
                        {bulletPoints.map((point, index) => (
                            <li key={index}>{point}</li>
                        ))}
                    </ul>

                    {/* Days of the Week */}
                    {Array.from({ length: 7 }, (_, i) => i).map((weekDayIndex) => (
                        <div key={weekDayIndex} className="flex items-center gap-x-4">
                            {/* Day of the Week */}
                            <div className="flex-grow text-lg">{moment.utc().local().startOf('isoWeek').add(weekDayIndex, 'days').format('dddd')}</div>

                            {/* Weekday dropdown */}
                            <select
                                id="workoutTemplateKey"
                                value={weekDaySchedule[weekDayIndex]}
                                onChange={(event) => HandleWeekDayScheduleChange(weekDayIndex, event.target.value)}
                                className="w-52 px-4 py-2 rounded-md text-gray-900 ring-1 ring-gray-300"
                            >
                                <option value="strength-session-a">Strength Session A</option>
                                <option value="strength-session-b">Strength Session B</option>
                                <option value="mobility-a">Mobility A</option>
                                <option value="mobility-b">Mobility B</option>
                                <option value="rest">Rest</option>
                            </select>
                        </div>
                    ))}

                    <div className="italic text-center font-semibold text-gray-400">Changes to your schedule will go into effect for future weeks</div>

                    {/* Week Day Schedule Error Message */}
                    {isWeekDayScheduleValid ? null : <p className="mt-1 text-sm text-red-600">Please make sure all 4 workouts have been assigned a day of the week</p>}

                    {/* Save Changes Button */}
                    <LoadingButton buttonText="Save Changes" isLoading={isSaveChangesLoading} action={() => OnSaveChangesButton()} />
                </div>
            )}
        </>
    )
}
