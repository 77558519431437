export default function ExerciseInfoPill({ phase, exerciseIndex }) {
    const GetPillItemStyle = (mappedExerciseIndex) => {
        var style = 'flex-grow'

        if (mappedExerciseIndex < parseInt(exerciseIndex)) style += ' bg-gray-100'
        else if (mappedExerciseIndex === parseInt(exerciseIndex)) style += ' bg-orange'
        else style += ' bg-gray-300'

        return style
    }

    return (
        <div className="flex h-4 rounded-b-xl border-2 border-white divide-x overflow-x-auto">
            {phase.exercises.map((mappedExercise, mappedExerciseIndex) => (
                <div key={mappedExerciseIndex} className={GetPillItemStyle(mappedExerciseIndex)} />
            ))}
        </div>
    )
}
