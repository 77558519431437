import { useNavigate } from 'react-router-dom'

export default function UpdatePaymentMethod({ subscriptionData }) {
    const navigate = useNavigate()

    if (!subscriptionData.cancel_at_period_end && subscriptionData.status !== 'unpaid') {
        return (
            <button
                type="button"
                className="w-full text-center rounded-full py-2 text-md font-semibold text-gray-500 border border-gray-500"
                onClick={() => navigate('/subscription/paymentMethod/update')}
            >
                Update Payment Method
            </button>
        )
    }
}
