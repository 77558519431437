import { createSlice } from '@reduxjs/toolkit'

// Initial state
const initialState =  {
    sportId: 0,
    sportName: '',
    daysOfTraining: 0,
    monthsOfTraining: 0,
    yearsOfTraining: 0,
    weekDaySchedule: {
      0: "strength-session-a",
      1: "mobility-a",
      2: "strength-session-b",
      3: "mobility-b",
      4: "rest",
      5: "rest",
      6: "rest"
    }
}

// Onboarding Slice
const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setReduxSportId: (state, action) => { state.sportId = action.payload; },
    setReduxSportName: (state, action) => { state.sportName = action.payload; },
    setReduxDaysOfTraining: (state, action) => { state.daysOfTraining = action.payload; },
    setReduxMonthsOfTraining: (state, action) => { state.monthsOfTraining = action.payload; },
    setReduxYearsOfTraining: (state, action) => { state.yearsOfTraining = action.payload; },
    updateWeekDayScheduleWeekDayWorkoutTemplate: (state, action) => {

      const weekDayIndex = action.payload.weekDayIndex
      const workoutTemplateKey = action.payload.workoutTemplateKey

      // Remove all instances of the workout template key from the schedule array first
      // This prevents us from having duplicates
      Object.keys(state.weekDaySchedule).map(key => {
        if (state.weekDaySchedule[key] === workoutTemplateKey) state.weekDaySchedule[key] = 'rest';
      })

      state.weekDaySchedule[weekDayIndex] = workoutTemplateKey

    },
    resetOnboardingState: () => initialState
  },
});

export const { 
    setReduxSportId,
    setReduxSportName,
    setReduxDaysOfTraining,
    setReduxMonthsOfTraining,
    setReduxYearsOfTraining,
    updateWeekDayScheduleWeekDayWorkoutTemplate,
    resetOnboardingState
} = onboardingSlice.actions;

export default onboardingSlice.reducer;