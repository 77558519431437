export default function NumberInputLine({ 
    placeholder = 'Enter Here', 
    value = '', 
    handleChange = () => {}, 
    onBlur = () => {},
    isValid = true, 
    invalidMessage = 'Invalid', 
    isDisabled = false 
}) {
    
    const handleWheel = (e) => {
        e.target.blur();
    };

    const internalHandleChange = (value) => {

        // Only allow digits 0-9
        const regex = /^[0-9]*$/
        if (!regex.test(value)) return
        
        handleChange(value);

    }

    return (
        <div className="space-y-1">
            <input
                type="tel"
                pattern="[0-9]*"
                className="w-full px-1 py-2 text-gray-900 placeholder:text-gray-400 border-b border-gray-900 rounded-none focus:outline-none"
                placeholder={placeholder}
                value={value}
                onChange={(event) => internalHandleChange(event.target.value)}
                disabled={isDisabled}
                onWheel={handleWheel}
                onBlur={onBlur}
            />

            {/* Error Message */}
            {isValid ? null : <p className="text-xs text-red-600">{invalidMessage}</p>}
        </div>
    )
}