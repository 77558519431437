import { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleUIMode } from 'redux/slices/activeWorkout'
import { toggleUIModeAssessment } from 'redux/slices/activeAssessment'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Switch } from '@headlessui/react'
import { ModalKey, ModalContext } from 'components/Modals/ModalContext'
import { GetPrimaryColorHex } from 'redux/slices/brand'

export default function ActiveWorkoutUIModeToggle({ setShowRestScreen }) {
    const dispatch = useDispatch()
    const { ShowModal } = useContext(ModalContext)

    const styleConfig = useSelector((state) => state.brand.styleConfig)

    const activeWorkout = useSelector((state) => state.activeWorkout)
    const activeAssessment = useSelector((state) => state.activeAssessment)

    var workoutTypeKey = 'activeWorkout'
    var activeReduxSlice = null

    // Bias towards active workout
    if (activeWorkout.id !== null){ 
        workoutTypeKey = 'activeWorkout'
        activeReduxSlice = activeWorkout
    }

    // If no active workout, use active assessment
    if (activeWorkout.id === null && activeAssessment.id !== null){ 
        workoutTypeKey = 'activeAssessment'
        activeReduxSlice = activeAssessment
    }

    const uiMode = activeReduxSlice.uiMode

    const OnToggleUIMode = () => {
        setShowRestScreen(false)

        if (workoutTypeKey === 'activeWorkout') dispatch(toggleUIMode())
        if (workoutTypeKey === 'activeAssessment') dispatch(toggleUIModeAssessment())

    }

    const ShowCompactModeInfoModal = () => {
        const modalData = {
            key: ModalKey.Text,
            fullscreen: false,
            titleText: 'Introducing Compact Mode!',
            bodyText: [
                'Spend less time tapping through your phone during a workout and more time training',
                'Compact mode shows all your exercise sets for each phase in a list making it quicker to check off what has been complete',
                'Give it a try! If you would like to return to the old UI just switch off compact mode',
            ],
        }

        ShowModal(modalData)
    }

    return (
        <div className="flex items-center px-4 py-2 bg-white border-b border-gray-400 text-gray-800">
            {/* Left Hand Side */}
            <div className="w-40 flex items-center gap-x-1 pr-4" onClick={() => ShowCompactModeInfoModal()}>
                <div>Compact Mode</div>
                <InformationCircleIcon className="h-4 mt-0.5" />
            </div>

            {/* Right Hand Side */}
            <div className="flex-grow flex justify-end" onClick={() => OnToggleUIMode()}>
                <Switch
                    checked={uiMode === 'compact'}
                    className='relative inline-flex h-4 w-8 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
                    style={{
                        backgroundColor: uiMode === 'compact' ? GetPrimaryColorHex(styleConfig) : '#e5e7eb',
                    }}
                >
                    <span className="sr-only">Use setting</span>
                    <span
                        aria-hidden="true"
                        className={`
                            pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out
                            ${uiMode === 'compact' ? 'translate-x-4' : 'translate-x-0'}
                        `}
                    />
                </Switch>
            </div>
        </div>
    )
}
