import { createSlice } from '@reduxjs/toolkit'

// Initial state
const initialState =  {
    programOverviewLoading: true,
    programOverview: null,
    organizationTrainees: null,
    organizationTraineeCompletedWorkoutRecords: null
}

// Trainer Slice
const userSlice = createSlice({
  name: 'trainer',
  initialState,
  reducers: {
    setTrainerProgramOverviewLoading: (state, action) => { state.programOverviewLoading = action.payload; },
    setTrainerProgramOverview: (state, action) => { state.programOverview = action.payload; },
    updateTrainerProgramWorkoutTemplate: (state, action) => {

        const weekIndex = action.payload.weekIndex;
        const programWorkoutTemplateIndex = action.payload.programWorkoutTemplateIndex;

        state.programOverview.programFileContent.weeks[weekIndex].programWorkoutTemplates[programWorkoutTemplateIndex].workoutTemplateFileContent = action.payload.programWorkoutTemplate;

    },
    setTrainerOrganizationTrainees: (state, action) => { state.organizationTrainees = action.payload },
    setTrainerOrganizationTraineeCompletedWorkoutRecords: (state, action) => { state.organizationTraineeCompletedWorkoutRecords = action.payload },
    resetTrainerState: () => initialState
  },
});

export const { 
  setTrainerProgramOverviewLoading,
  setTrainerProgramOverview,
  updateTrainerProgramWorkoutTemplate,
  setTrainerOrganizationTrainees,
  setTrainerOrganizationTraineeCompletedWorkoutRecords,
  resetTrainerState 
} = userSlice.actions;

export default userSlice.reducer;