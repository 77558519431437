import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setNewFitnessProfileVariablesPopulated } from 'redux/slices/user'
import { setWhichWeightCacheEntireWeeklyCalendarJSON } from 'redux/slices/whichWeightCache'

import LoadingButton from 'components/LoadingButton'

const inputLabelStyle = 'ml-2 mb-1 text-sm font-medium leading-6 text-gray-600'
const inputBoxStyle =
    'w-full px-4 py-2 rounded-md text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6'

export default function NewFitnessProfileVariablesInput() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const userId = useSelector((state) => state.user.id)

    const [daysOfTraining, setDaysOfTraining] = useState(0)
    const HandleDaysTrainingChange = (event) => setDaysOfTraining(parseInt(event.target.value))

    const [monthsOfTraining, setMonthsOfTraining] = useState(0)
    const HandleMonthsTrainingChange = (event) => setMonthsOfTraining(parseInt(event.target.value))

    const [yearsOfTraining, setYearsOfTraining] = useState(0)
    const HandleYearsTrainingChange = (event) => setYearsOfTraining(parseInt(event.target.value))

    const [saveLoading, setSaveLoading] = useState(false)

    const OnSaveButton = async () => {
        setSaveLoading(true)

        var jsonBody = {
            traineeId: userId,
            daysOfTraining: daysOfTraining,
            monthsOfTraining: monthsOfTraining,
            yearsOfTraining: yearsOfTraining,
        }

        // console.log(jsonBody); return;

        const result = await WhichWeightAPI('CORE', 'PATCH', '/trainee_fitness_profile_new_variables', jsonBody)

        setSaveLoading(false)

        if (result == 'failure') {
            console.error(result)
            return
        }

        dispatch(setNewFitnessProfileVariablesPopulated(true))
        dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON(null)) // Reset calendar data
        navigate('/')
    }

    return (
        <div className="w-full flex flex-col">
            {/* Header */}
            <div className="flex items-center bg-gray-100 shadow p-4">
                {/* Back Button */}
                <div className="flex-1" />

                {/* Logo */}
                <img src={styleConfig.logo} alt="WhichWeight" className="h-12 -m-2" />

                <div className="flex-1" />
            </div>

            <div className="p-4 space-y-4 ">
                <div className="text-xl text-center font-semibold">{styleConfig.text.appName} has been updated!</div>

                <div className="text-center">Please fill out the additional questions below so we can deliver you more accurate and personalized programming</div>

                {/* Days of Training */}
                <div className="pb-4">
                    <p className={inputLabelStyle}>In the past 2 months, how many days on average have you strength trained each week?</p>
                    <select id="daysOfTraining" value={daysOfTraining} onChange={HandleDaysTrainingChange} className={inputBoxStyle}>
                        <option value={0}>I don't do strength training</option>
                        <option value={1}>1 day a week</option>
                        <option value={2}>2 days a week</option>
                        <option value={3}>3 days a week</option>
                        <option value={4}>4+ days a week</option>
                    </select>
                </div>

                {/* Months of Training */}
                <div className="pb-4">
                    <p className={inputLabelStyle}>In the past year, how many months have you consistently strength trained?</p>
                    <p className={inputLabelStyle}>WhichWeight defines consistency as training at least twice a week</p>
                    <select id="monthsOfTraining" value={monthsOfTraining} onChange={HandleMonthsTrainingChange} className={inputBoxStyle}>
                        <option value={0}>I don't do consistent strength training</option>
                        <option value={1}>1 month</option>
                        <option value={2}>2 months</option>
                        <option value={3}>3 months</option>
                        <option value={4}>4 months</option>
                        <option value={5}>5 months</option>
                        <option value={6}>6 months</option>
                        <option value={7}>7 months</option>
                        <option value={8}>8 months</option>
                        <option value={9}>9 months</option>
                        <option value={10}>10 months</option>
                        <option value={11}>11 months</option>
                        <option value={12}>12 months</option>
                    </select>
                </div>

                {/* Years of Training */}
                <div className="pb-4">
                    <p className={inputLabelStyle}>How many years have you been consistently strength training for?</p>
                    <p className={inputLabelStyle}>WhichWeight defines consistency as training at least twice a week for at least 10 months out of the year</p>
                    <select id="yearsOfTraining" value={yearsOfTraining} onChange={HandleYearsTrainingChange} className={inputBoxStyle}>
                        <option value={0}>I don't do consistent strength training</option>
                        <option value={1}>1 year</option>
                        <option value={2}>2 years</option>
                        <option value={3}>3 years</option>
                        <option value={4}>4 years</option>
                        <option value={5}>5+ years</option>
                    </select>
                </div>

                {/* Save button */}
                <LoadingButton buttonText="Save" isLoading={saveLoading} action={OnSaveButton} />
            </div>
        </div>
    )
}
