import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline'

export default function Note({ text }) {
    return (
        <p className="flex items-center border border-gray-200 p-2 rounded-lg text-gray-200">
            <Bars3BottomLeftIcon className="h-4 ml-1 mr-3 text-gray-200" />
            {text}
        </p>
    )
}
