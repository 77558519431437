import { useSelector } from 'react-redux'
import { GetPrimaryColorHex } from 'redux/slices/brand'

export default function LinearDial({ 
    fillPercentage = 50, 
    fillLabel = 'Fill Label', 
    emptyLabel = 'Empty Label' 
}) {

    const styleConfig = useSelector((state) => state.brand.styleConfig)

    return (
        <div>
            {/* Bar */}
            <div className="bg-gray-300 w-full h-6 rounded-lg overflow-hidden">
                <div 
                    className="h-6 w-1/3" 
                    style={{ 
                        width: fillPercentage + '%',
                        backgroundColor: GetPrimaryColorHex(styleConfig)
                    }} 
                />
            </div>

            {/* Labels */}
            <div className="flex pt-1 px-1 font-bold text-sm">
                
                <div 
                    className="flex-grow" 
                    style={{ color: GetPrimaryColorHex(styleConfig) }}
                >
                    {fillLabel}
                </div>

                <div className="text-gray-400">{emptyLabel}</div>

            </div>
        </div>
    )

}