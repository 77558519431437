import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { setReduxDaysOfTraining } from 'redux/slices/onboarding'

import ScreenFrame from 'components/Frame/ScreenFrame'
import StepHeader from 'components/Header/StepHeader'
import BodyFrame from 'components/Frame/BodyFrame'
import TitleText from 'components/Onboarding/TitleText'

export default function DaysOfTrainingInputView() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const options = [
        { value: 0, text: "I don't do strength training" },
        { value: 1, text: '1 day a week' },
        { value: 2, text: '2 days a week' },
        { value: 3, text: '3 days a week' },
        { value: 4, text: '4+ days a week' },
    ]

    const OnNextButton = (value) => {
        dispatch(setReduxDaysOfTraining(value))

        navigate('/onboarding/monthsOfTraining')
    }

    return (
        <ScreenFrame>
            <StepHeader title='Fitness Profile' startPercentage={0} endPercentage={20} />

            <div className="flex-1 flex flex-col pt-4 pb-12 gap-y-4 overflow-y-auto bg-white">
                
                <div className='px-4'>
                    <TitleText text={'In the past 2 months, how many days on average have you strength trained each week?'} />
                </div>

                <div className="mt-2 -mx-4">
                    {options.map((option, index) => (
                        <div key={index} className="flex border-b py-4 px-8" onClick={() => OnNextButton(option.value)}>
                            <div className="flex-grow text-xl">{option.text}</div>
                            <ChevronRightIcon className="h-6 text-gray-500" />
                        </div>
                    ))}
                </div>
            </div>
        </ScreenFrame>
    )
}
