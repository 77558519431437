import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { ModalKey, ModalContext } from '../ModalContext'

import LoadingButton from 'components/LoadingButton'

export default function FeedbackModal() {
    const userId = useSelector((state) => state.user.id)

    const { ShowModal } = useContext(ModalContext)

    const [feedbackNote, setFeedbackNote] = useState('')
    const [errorText, setErrorText] = useState('')
    const [isFeedbackSubmitLoading, setIsFeedbackSubmitLoading] = useState(false)

    const HandleFeedbackNotesChange = (event) => {
        setErrorText('')
        if (event.target.value.length < 200) setFeedbackNote(event.target.value)
    }

    const OnSubmitFeedback = async () => {
        setErrorText('')
        setIsFeedbackSubmitLoading(true)

        if (feedbackNote.length === 0) {
            setIsFeedbackSubmitLoading(false)
            setErrorText('Please add some feedback text')
            return
        }

        const jsonBody = {
            userId: userId,
            source: 'feedback-modal',
            additionalNote: feedbackNote,
        }

        // console.log(jsonBody)

        const result = await WhichWeightAPI('CORE', 'POST', '/user_feedback_item', jsonBody)

        // console.log(result)

        if (result.data.status === 'failure') {
            setIsFeedbackSubmitLoading(false)
            console.error(result)
            setErrorText('Error saving feedback. Please try again')
            return
        }

        setIsFeedbackSubmitLoading(false)

        const confirmationModalData = {
            key: ModalKey.Text,
            fullscreen: false,
            titleText: 'Feedback recieved!',
            bodyText: ['Thank you for submitting feedback', 'We will review as quickly as possible.'],
        }

        ShowModal(confirmationModalData)
    }

    return (
        <div className="space-y-4 text-center text-gray-900">
            <div className="text-xl font-semibold ">Submit Feedback</div>

            <ul className="list-disc pl-8 text-left">
                <li>What do you like?</li>
                <li>What do you dislike?</li>
                <li>What you are having issues with?</li>
                <li>What features do you want added?</li>
                <li>Anything else on your mind!</li>
            </ul>

            <div className="text-sm italic text-gray-600">If you have a more urgent matter (broken app, refund request, etc) please email our support team directly at support@whichweight.com</div>

            {/* Additional Notes Box */}
            <textarea
                type="text"
                min="0"
                name="additionalNotes"
                id="additionalNotes"
                className="h-60 w-full py-2 px-4 text-sm rounded-lg border border-gray-400 placeholder:text-gray-400"
                placeholder={'Enter Feedback Here.. '}
                value={feedbackNote}
                onChange={(event) => HandleFeedbackNotesChange(event)}
            />

            {errorText !== '' && <div className="text-sm text-red-400">{errorText}</div>}

            <LoadingButton buttonText="Submit" isLoading={isFeedbackSubmitLoading} action={OnSubmitFeedback} />
        </div>
    )
}
