import { useState, useEffect, useContext } from 'react'
import { ModalContext } from '../ModalContext'

import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'

export default function ListSelectionModal() {
    const { modalData } = useContext(ModalContext)

    const [listItems, setListItems] = useState(null)

    const GetListItems = async () => {
        setListItems(null)

        const createdListItems = await modalData.getListItems()

        setListItems(createdListItems)
    }

    useEffect(() => {
        GetListItems()
    }, [modalData])

    return (
        <div className="space-y-4">
            {/* Header */}
            <div className="mt-1 text-lg font-semibold leading-6 text-gray-900 flex-grow">{modalData.headerText}</div>

            {/* Exercise List */}
            <div className="h-96 border rounded-lg overflow-hidden">
                <div className="w-full h-full overflow-auto">
                    {/* Loading Animation */}
                    {listItems === null && <MediumLoadingComponent />}

                    {listItems !== null && (
                        <>
                            {/* No Results Found */}
                            {listItems.length === 0 && (
                                <div className="text-center p-4 space-y-4 text-gray-500">
                                    <div className="text-lg text-gray-900">{modalData.noListItemsText}</div>
                                    <div className="space-y-1">
                                        <div>Please reach out at</div>
                                        <div className="font-semibold">support@whichweight.com</div>
                                        <div>for further support</div>
                                    </div>
                                </div>
                            )}

                            {/* List Items */}
                            {listItems.length > 0 && (
                                <>
                                    {listItems.map((item, itemIndex) => (
                                        <a key={itemIndex} onClick={() => modalData.selectAction(item)}>
                                            <li className="flex border-b-2 border-stone-100 justify-between p-4 items-center cursor-pointer hover:bg-stone-100">
                                                <p className="text-gray-900">{item.name}</p>
                                            </li>
                                        </a>
                                    ))}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
