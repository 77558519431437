import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { InformationCircleIcon, FlagIcon, TrophyIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { setWhichWeightCacheEntireWeeklyCalendarJSON } from 'redux/slices/whichWeightCache'
import moment from 'moment'

import CustomHeader from 'components/Header/CustomHeader'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import EventCard from 'components/Events/EventCard'

// function classNames(...classes) { return classes.filter(Boolean).join(' ') }

export default function Events() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userId = useSelector((state) => state.user.id)
    const presentWeekOfTheYearKey = useSelector((state) => state.user.presentWeekOfTheYearKey);
    
    /*
    const tabs = [
        { name: 'Upcoming', icon: FlagIcon },
        { name: 'Completed', icon: TrophyIcon }
    ]
    */

    const { ShowModal } = useContext(ModalContext)

    // var events = useGetEvents();
    const [events, setEvents] = useState(null)
    const [selectedTab, setSelectedTab] = useState('Upcoming')
    const [deleteEventLoading, setDeleteEventLoading] = useState(false)

    const ShowDeleteEventModal = (event, whichWeightEvent) => {
        const modalData = {
            key: ModalKey.ActionWarning,
            fullscreen: false,
            titleText: 'Delete ' + whichWeightEvent.name + '?',
            bodyText: ['Programming will no longer be tapered leading up to this event'],
            buttonText: 'Delete',
            buttonAction: () => {
                DeleteEvent(whichWeightEvent.id)
            },
        }

        ShowModal(modalData)

        event.stopPropagation()
    }

    const FetchEventsFromBackend = async () => {
        const jsonBody = {
            traineeId: userId,
        }

        // console.log(jsonBody);

        const result = await WhichWeightAPI('CORE', 'GET', '/events', jsonBody)

        // console.log(result.data.data);

        setEvents(result.data.data)
    }

    const DeleteEvent = async (eventId) => {

        setDeleteEventLoading(true)

        const jsonBody = {
            eventId: eventId,
        }

        const result = await WhichWeightAPI('CORE', 'PATCH', '/archive_event', jsonBody)

        const jsonBody2 = {
            userId: userId,
            presentWeekOfTheYearKey: presentWeekOfTheYearKey,
            localDate: moment.utc().local().format('YYYY-MM-DD')
        }

        const clear_cache_result = await WhichWeightAPI('PROGRAM', 'POST', '/delete_active_a_race_program_table', jsonBody2)

        // console.log(clear_cache_result)

        if (clear_cache_result.data.status === 'failure') {
            console.error(clear_cache_result)
        }

        setDeleteEventLoading(false)

        FetchEventsFromBackend()

        dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON(null)) // Reset calendar data
    }

    // Empty dependency array means this useEffect runs once when component mounts
    useEffect(() => {
        FetchEventsFromBackend()
    }, [])

    return (
        <>
            {/* Header */}
            <CustomHeader text="Events" backButtonAction={() => navigate('/home')} />

            {/* Loading Animation */}
            {events === null && <MediumLoadingComponent />}

            {deleteEventLoading && <MediumLoadingComponent />}

            {/* Events */}
            {deleteEventLoading === false && events !== null && (
                <div>
                    {/* Tab Options
                    <div className="flex w-full border-gray-300 ">
                            {tabs.map((tab) => (
                                <a
                                    key={tab.name}
                                    onClick={() => setSelectedTab(tab.name) }
                                    className={classNames(
                                        tab.name === selectedTab
                                            ? 'border-orange-600 text-orange-500'
                                            : 'border-gray-300 text-gray-500',
                                        'w-1/2 border-b py-3 text-center font-medium flex justify-center items-center gap-x-2'
                                    )}
                                >
                                    <tab.icon className="w-5" />
                                    <div>{tab.name}</div>
                                </a>
                            ))}
                    </div>
                    */}

                    {selectedTab === 'Upcoming' && (
                        <div className="p-4 space-y-4">
                            {/* Info reminder */}
                            <div className="flex items-center justify-center gap-x-1">
                                <InformationCircleIcon className="h-4" />
                                <div className="text-center text-sm italic">A Races are required to generate programming</div>
                            </div>

                            {/* Add New Event */}
                            <button
                                type="button"
                                className="w-full flex justify-center items-center py-2 gap-x-2 bg-white text-gray-900 rounded-full border border-gray-400"
                                onClick={() => navigate('/events/add')}
                            >
                                <p>New Event</p>
                                <PlusCircleIcon className="h-5 text-stone-500" />
                            </button>

                            {/* Event Cards */}
                            {events.map((event) => (
                                <EventCard key={event.id} event={event} ShowDeleteEventModal={ShowDeleteEventModal} />
                            ))}
                        </div>
                    )}

                    {selectedTab === 'Completed' && (
                        <div className="p-4 space-y-4">
                            {/* A Races */}
                            <div className="border rounded-xl">
                                {/* Header */}
                                <div className="px-4 py-2 bg-gray-50 shadow rounded-t-xl">
                                    <div className="flex items-center">
                                        <div className="text-xl font-semibold flex-grow">A Races</div>
                                        <div
                                            className="bg-white border rounded-full px-4 py-1 text-sm"
                                            onClick={() => {
                                                navigate('/events/add', { state: { eventType: 'a-race' } })
                                            }}
                                        >
                                            Add
                                        </div>
                                    </div>
                                    <div className="text-sm text-gray-500">Your main races. Programming will be tapered heavily starting 3 weeks before these events</div>
                                </div>

                                {/* No events text */}
                                {events.filter((event) => event.eventType === 'a-race').length === 0 && <div className="p-4 space-y-4 text-gray-400">No Events</div>}

                                {/* Map over events */}
                                <div className="divide-y text-lg">
                                    {events
                                        .filter((event) => event.eventType === 'a-race')
                                        .map((event) => (
                                            <div key={event.id} className="p-4 flex items-center gap-x-4">
                                                <div className="flex-grow">{event.name}</div>
                                                <div className="text-gray-400">{moment(event.eventDate).format('MMMM DD, YYYY')}</div>
                                                <TrashIcon onClick={(e) => ShowDeleteEventModal(e, event)} className="h-5 text-gray-500" />
                                            </div>
                                        ))}
                                </div>
                            </div>

                            {/* B Races */}
                            <div className="border rounded-xl">
                                {/* Header */}
                                <div className="px-4 py-2 bg-gray-50 shadow rounded-t-xl">
                                    <div className="flex items-center">
                                        <div className="text-xl font-semibold flex-grow">B Races</div>
                                        <div
                                            className="bg-white border rounded-full px-4 py-1 text-sm"
                                            onClick={() => {
                                                navigate('/events/add', { state: { eventType: 'b-race' } })
                                            }}
                                        >
                                            Add
                                        </div>
                                    </div>
                                    <div className="text-sm text-gray-500">Additional races. Programming will be tapered moderately starting 2 weeks before these events</div>
                                </div>

                                {/* No events text */}
                                {events.filter((event) => event.eventType === 'b-race').length === 0 && <div className="p-4 space-y-4 text-gray-400">No Events</div>}

                                {/* Map over events */}
                                <div className="divide-y text-lg">
                                    {events
                                        .filter((event) => event.eventType === 'b-race')
                                        .map((event) => (
                                            <div key={event.id} className="p-4 flex items-center gap-x-4">
                                                <div className="flex-grow">{event.name}</div>
                                                <div className="text-gray-400">{moment(event.eventDate).format('MMMM DD, YYYY')}</div>
                                                <TrashIcon onClick={(e) => ShowDeleteEventModal(e, event)} className="h-5 text-gray-500" />
                                            </div>
                                        ))}
                                </div>
                            </div>

                            {/* C Races */}
                            <div className="border rounded-xl">
                                {/* Header */}
                                <div className="px-4 py-2 bg-gray-50 shadow rounded-t-xl">
                                    <div className="flex items-center">
                                        <div className="text-xl font-semibold flex-grow">C Races</div>
                                        <div
                                            className="bg-white border rounded-full px-4 py-1 text-sm"
                                            onClick={() => {
                                                navigate('/events/add', { state: { eventType: 'c-race' } })
                                            }}
                                        >
                                            Add
                                        </div>
                                    </div>
                                    <div className="text-sm text-gray-500">Races for fun. Programming will be tapered lightly starting 1 week before these events</div>
                                </div>

                                {/* No events text */}
                                {events.filter((event) => event.eventType === 'c-race').length === 0 && <div className="p-4 space-y-4 text-gray-400">No Events</div>}

                                {/* Map over events */}
                                <div className="divide-y text-lg">
                                    {events
                                        .filter((event) => event.eventType === 'c-race')
                                        .map((event) => (
                                            <div key={event.id} className="p-4 flex items-center gap-x-4">
                                                <div className="flex-grow">{event.name}</div>
                                                <div className="text-gray-400">{moment(event.eventDate).format('MMMM DD, YYYY')}</div>
                                                <TrashIcon onClick={(e) => ShowDeleteEventModal(e, event)} className="h-5 text-gray-500" />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
