import { useSelector } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import Checkout from 'components/Billing/Checkout'

export default function CheckoutWrapper() {
    // Load publishable key from env variables
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

    const clientSecret = useSelector((state) => state.subscription.stripeClientSecret)

    const options = {
        clientSecret: clientSecret,
    }

    return (
        <>
            {/* Stipe Payment element */}
            <Elements stripe={stripePromise} options={options}>
                <Checkout />
            </Elements>
        </>
    )
}
