export default function NextButton({ text = 'Next', subtext = '', action = () => {} }) {
    return (
        <div>
            {/* Subtext will render HTML tags */}
            {subtext !== '' && <p className="text-sm mb-2 text-gray-500" dangerouslySetInnerHTML={{ __html: subtext }} />}

            <button type="button" className="w-full text-center text-white rounded-full py-2 px-4 text-lg font-semibold bg-gradient-to-r from-orange-600 to-orange-400" onClick={action}>
                {text}
            </button>
        </div>
    )
}
