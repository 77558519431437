import { WhichWeightAPI } from 'WhichWeightAPI'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useGetExercises } from 'redux/customHooks/useGetExercises'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import logo192 from 'media/icons/logo192.png'

import CustomHeader from 'components/Header/CustomHeader'
import VolumeLiftedChart from 'components/Home/HighLevelStats/VolumeLiftedChart'
import WeeklyRepsChart from 'components/Home/HighLevelStats/WeeklyRepsChart'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'

export default function Reports() {
    const navigate = useNavigate()

    const userId = useSelector((state) => state.user.id)
    const exercises = useGetExercises()

    const [completedExercises, setCompletedExercises] = useState(null)
    const [searchTerm, setSearchTerm] = useState('')

    const OnViewExerciseReport = (exercise) => {
        const navigationProps = {
            state: {
                exerciseId: exercise.exerciseId,
                exerciseName: exercise.exerciseName,
                exerciseType: exercise.exerciseType,
            },
        }

        // console.log(navigationProps)

        navigate('/reports/exerciseWeightHistory', navigationProps)
    }

    const FetchExercisesFromBackend = async () => {
        const jsonBody = {
            traineeId: userId,
        }

        const result = await WhichWeightAPI('CORE', 'GET', '/user_recommendation_exercises', jsonBody)

        // console.log(result.data.data);

        setCompletedExercises(result.data.data)
    }

    // Empty dependency array means this useEffect runs once when component mounts
    useEffect(() => {
        FetchExercisesFromBackend()
    }, [])

    return (
        <>
            {/* Header */}
            <CustomHeader text="Reports" backButtonAction={() => navigate('/home')} />

            <div className="p-4 space-y-4">
                <div className="border border-gray-600 rounded-xl overflow-hidden">
                    <VolumeLiftedChart height={150} />
                </div>

                <div className="border border-gray-600 rounded-xl overflow-hidden">
                    <WeeklyRepsChart height={150} />
                </div>

                {/* Search bar */}
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                        type="text"
                        name="text"
                        id="text"
                        className="block w-full rounded-none rounded-l-md rounded-r-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Exercise Name"
                        onChange={(event) => setSearchTerm(event.target.value)}
                    />
                </div>

                {completedExercises === null && <MediumLoadingComponent />}

                {completedExercises !== null && (
                    <div className="grid grid-cols-2 gap-x-2">
                        {completedExercises
                            .filter((exercise) => exercise.exerciseName.toLowerCase().includes(searchTerm.toLowerCase()))
                            .map((exercise, index) => (
                                <div key={index} className="mb-2 p-2 border border-gray-400 rounded flex items-center gap-x-2" onClick={() => OnViewExerciseReport(exercise)}>
                                    {exercises.find((item) => item.id === exercise.exerciseId) === undefined && (
                                        <img src={logo192} alt="Exercise Thumbnail" className="w-6 h-6 object-cover rounded-md" />
                                    )}

                                    {/* Thumbnail */}
                                    {exercises.length > 0 && exercises.find((item) => item.id === exercise.exerciseId) !== undefined && (
                                        <img src={exercises.find((item) => item.id === exercise.exerciseId).imageUrl} alt="Exercise Thumbnail" className="w-6 h-6 object-cover rounded-md" />
                                    )}

                                    <div className="flex-grow text-xs truncate">{exercise.exerciseName}</div>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </>
    )
}
