import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserMeasurementSystem } from 'redux/slices/user'
import { setWhichWeightCacheEntireWeeklyCalendarJSON } from 'redux/slices/whichWeightCache'
import { ConvertKilogramsToPounds, ConvertCentimetersToInches } from 'functions/measurementSystemUtils'
import { setReduxFitnessProfile } from 'redux/slices/user'
import moment from 'moment'

import CustomHeader from 'components/Header/CustomHeader'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import LoadingButton from 'components/LoadingButton'

const inputLabelStyle = 'ml-2 mb-1 text-sm font-medium leading-6 text-gray-400'
const inputBoxStyle =
    'w-full px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6'
const warningLabelStyle = 'mt-1 text-sm text-center text-red-600'

export default function EditFitnessProfile() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userId = useSelector((state) => state.user.id)
    const presentWeekOfTheYearKey = useSelector((state) => state.user.presentWeekOfTheYearKey);

    // Props passed through navigation
    const location = useLocation()
    const { passedFitnessProfile } = location.state

    const [fitnessProfile, setFitnessProfile] = useState(passedFitnessProfile)
    const [heightCentimeters, setHeightCentimeters] = useState(0)
    const [weightKilograms, setWeightKilograms] = useState(0)
    const [sportOptions, setSportOptions] = useState([])
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [saveChangesLoading, setSaveChangesLoading] = useState(false)

    // Fetches sports data from the backend
    const GetSportsFromBackend = async () => {
        const result = await WhichWeightAPI('CORE', 'GET', '/sports')

        try {
            // Format data to fit into select component
            var formatArray = []
            result.data.data.filter((item) => formatArray.push({ value: item.id, label: item.name }))

            setSportOptions(formatArray)
        } catch (error) {
            console.error('There was an error fetching the data:', error)
        }
    }

    const HandleFitnessProfileChange = (key, value) => {
        var temp_fitnessProfile = JSON.parse(JSON.stringify(fitnessProfile))

        switch (key) {
            case 'birthday':
                temp_fitnessProfile.birthday = value
                break

            case 'gender':
                temp_fitnessProfile.gender = value
                break

            case 'measurement-system':
                temp_fitnessProfile.measurementSystem = value

                if (value === 'metric') {
                    setHeightCentimeters(0)
                    setWeightKilograms(0)
                    temp_fitnessProfile.height = 0
                    temp_fitnessProfile.weight = 0
                }

                break

            case 'height-feet':
                var current_inches = temp_fitnessProfile.height % 12
                temp_fitnessProfile.height = value * 12 + current_inches
                break

            case 'height-inches':
                var current_feet = Math.floor(temp_fitnessProfile.height / 12)
                temp_fitnessProfile.height = current_feet * 12 + value
                break

            case 'height-centimeters':
                setHeightCentimeters(value)
                temp_fitnessProfile.height = Math.round(ConvertCentimetersToInches(value))
                break

            case 'weight':
                if (temp_fitnessProfile.measurementSystem === 'imperial') {
                    temp_fitnessProfile.weight = parseInt(value)
                }

                if (temp_fitnessProfile.measurementSystem === 'metric') {
                    setWeightKilograms(parseInt(value))
                    temp_fitnessProfile.weight = Math.round(ConvertKilogramsToPounds(parseInt(value)))
                }

                break

            case 'sport':
                temp_fitnessProfile.sportId = value.value
                temp_fitnessProfile.sportName = value.label
                break

            case 'days-of-training':
                temp_fitnessProfile.daysOfTraining = value
                break

            case 'months-of-training':
                temp_fitnessProfile.monthsOfTraining = value
                break

            case 'years-of-training':
                temp_fitnessProfile.yearsOfTraining = value
                break

            default:
                console.error(key + ' is not a valid key')
                break
        }

        setShowErrorMessage(false)
        setFitnessProfile(temp_fitnessProfile)
        // console.log(weightKilograms)
        // console.log(temp_fitnessProfile.height + ' ' + temp_fitnessProfile.weight)
    }

    // Make sure all the form inputs are there
    const validateFormInputs = () => {
        var allVaild = true

        if (fitnessProfile.birthday.length === 0) allVaild = false // Check birthday input
        if (fitnessProfile.gender !== 'male' && fitnessProfile.gender !== 'female' && fitnessProfile.gender !== 'other') allVaild = false // Check biological gender input
        if (fitnessProfile.height.length === 0) allVaild = false // Check height input
        if (fitnessProfile.weight < 1 || isNaN(fitnessProfile.weight)) allVaild = false // Check weight input
        if (fitnessProfile.sportId === undefined || fitnessProfile.sportName.length === 0) allVaild = false // Check sport input

        setShowErrorMessage(!allVaild)
        return allVaild
    }

    const OnSaveChangesButton = async () => {
        if (validateFormInputs()) {
            setSaveChangesLoading(true)

            var jsonBody = {
                traineeId: userId,
                birthday: fitnessProfile.birthday,
                gender: fitnessProfile.gender,
                measurementSystem: fitnessProfile.measurementSystem,
                height: fitnessProfile.height,
                weight: fitnessProfile.weight,
                sportId: fitnessProfile.sportId,
                sportName: fitnessProfile.sportName,
                daysOfTraining: fitnessProfile.daysOfTraining,
                monthsOfTraining: fitnessProfile.monthsOfTraining,
                yearsOfTraining: fitnessProfile.yearsOfTraining,
            }

            // console.log(jsonBody); return;

            const result = await WhichWeightAPI('CORE', 'POST', '/trainee_fitness_profile', jsonBody)

            if (result == 'failure') {
                console.error(result)
                setSaveChangesLoading(false)
                return
            }

            const jsonBody2 = {
                userId: userId,
                presentWeekOfTheYearKey: presentWeekOfTheYearKey,
                localDate: moment.utc().local().format('YYYY-MM-DD')
            }
    
            const clear_cache_result = await WhichWeightAPI('PROGRAM', 'POST', '/delete_entire_program_cache', jsonBody2)
    
            // console.log(clear_cache_result)
    
            if (clear_cache_result.data.status === 'failure') {
                console.error(clear_cache_result)
            }

            setSaveChangesLoading(false)

            dispatch(updateUserMeasurementSystem(fitnessProfile.measurementSystem))
            dispatch(setWhichWeightCacheEntireWeeklyCalendarJSON(null)) // Reset calendar data
            dispatch(setReduxFitnessProfile(null))

            navigate('/fitnessProfile')
        }
    }

    useEffect(() => {
        GetSportsFromBackend()
    }, [])

    return (
        <>
            {/* Header */}
            <CustomHeader text="Edit Fitness Profile" backButtonAction={() => navigate('/fitnessProfile')} />

            <div className="p-4 space-y-4">
                {/* Birthday */}
                <div>
                    <p className={inputLabelStyle}>Birthday:</p>
                    <input
                        type="date"
                        name="birthday"
                        id="birthday"
                        className={inputBoxStyle}
                        placeholder="Birthday*"
                        value={fitnessProfile.birthday}
                        onChange={(event) => HandleFitnessProfileChange('birthday', event.target.value)}
                    />
                </div>

                {/* Biological Gender */}
                <div>
                    <p className={inputLabelStyle}>Biological Gender:</p>
                    <select id="gender" value={fitnessProfile.gender} onChange={(event) => HandleFitnessProfileChange('gender', event.target.value)} className={inputBoxStyle}>
                        <option value="" disabled>
                            {' '}
                            Select an option
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select>
                </div>

                {/* Measurement System */}
                <div>
                    <p className={inputLabelStyle}>Measurement System:</p>
                    <select
                        id="measurementSystem"
                        value={fitnessProfile.measurementSystem}
                        onChange={(event) => HandleFitnessProfileChange('measurement-system', event.target.value)}
                        className={inputBoxStyle}
                    >
                        <option value="" disabled>
                            Select an option
                        </option>
                        <option value="imperial">Imperial</option>
                        <option value="metric">Metric</option>
                    </select>
                </div>

                {/* Height */}
                <div>
                    <p className={inputLabelStyle}>Height:</p>

                    {fitnessProfile.measurementSystem === 'imperial' && (
                        <div className="flex gap-x-4">
                            {/* Height Feet */}
                            <select
                                id="heightFeet"
                                value={parseInt(parseInt(fitnessProfile.height) / 12)}
                                onChange={(event) => HandleFitnessProfileChange('height-feet', parseInt(event.target.value))}
                                className="flex-grow px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300"
                            >
                                <option value={6}>6 feet</option>
                                <option value={5}>5 feet</option>
                                <option value={4}>4 feet</option>
                                <option value={3}>3 feet</option>
                                <option value={2}>2 feet</option>
                                <option value={1}>1 feet</option>
                            </select>

                            {/* Height Inches */}
                            <select
                                id="heightInches"
                                value={parseInt(parseInt(fitnessProfile.height) % 12)}
                                onChange={(event) => HandleFitnessProfileChange('height-inches', parseInt(event.target.value))}
                                className="flex-grow px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300"
                            >
                                <option value={11}>11 inches</option>
                                <option value={10}>10 inches</option>
                                <option value={9}>9 inches</option>
                                <option value={8}>8 inches</option>
                                <option value={7}>7 inches</option>
                                <option value={6}>6 inches</option>
                                <option value={5}>5 inches</option>
                                <option value={4}>4 inches</option>
                                <option value={3}>3 inches</option>
                                <option value={2}>2 inches</option>
                                <option value={1}>1 inches</option>
                                <option value={0}>0 inches</option>
                            </select>
                        </div>
                    )}

                    {fitnessProfile.measurementSystem === 'metric' && (
                        <div className="flex items-center gap-x-4">
                            <input
                                type="tel"
                                pattern="[0-9]*"
                                min="0"
                                name="heightCentimeters"
                                id="heightCentimeters"
                                className="flex-grow text-center py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                                placeholder="Enter Here"
                                value={heightCentimeters}
                                onChange={(event) => HandleFitnessProfileChange('height-centimeters', event.target.value)}
                            />
                            <div>cm</div>
                        </div>
                    )}
                </div>

                {/* Weight Pounds */}
                {fitnessProfile.measurementSystem === 'imperial' && (
                    <div>
                        <p className={inputLabelStyle}>Weight:</p>
                        <div className="flex items-center gap-x-4">
                            <input
                                type="tel"
                                pattern="[0-9]*"
                                name="weight"
                                id="weight"
                                className={inputBoxStyle}
                                placeholder="Enter Here"
                                value={fitnessProfile.weight}
                                onChange={(event) => HandleFitnessProfileChange('weight', parseInt(event.target.value))}
                            />
                            <div>lbs</div>
                        </div>
                    </div>
                )}

                {/* Weight Kilograms */}
                {fitnessProfile.measurementSystem === 'metric' && (
                    <div>
                        <p className={inputLabelStyle}>Weight:</p>
                        <div className="flex items-center gap-x-4">
                            <input
                                type="tel"
                                pattern="[0-9]*"
                                name="weight"
                                id="weight"
                                className={inputBoxStyle}
                                placeholder="Enter Here"
                                value={weightKilograms}
                                onChange={(event) => HandleFitnessProfileChange('weight', parseInt(event.target.value))}
                            />
                            <div>kgs</div>
                        </div>
                    </div>
                )}

                {/* Sport */}
                <div>
                    <p className={inputLabelStyle}>Sport:</p>
                    <CustomSelect value={{ label: fitnessProfile.sportName, value: fitnessProfile.sportId }} options={sportOptions} onChange={(event) => HandleFitnessProfileChange('sport', event)} />
                </div>

                {/* Days of Training */}
                <div>
                    <p className={inputLabelStyle}>In the past 2 months, how many days on average have you strength trained each week?</p>
                    <select
                        id="daysOfTraining"
                        value={parseInt(fitnessProfile.daysOfTraining)}
                        onChange={(event) => HandleFitnessProfileChange('days-of-training', parseInt(event.target.value))}
                        className={inputBoxStyle}
                    >
                        <option value={0}>I don't do strength training</option>
                        <option value={1}>1 day a week</option>
                        <option value={2}>2 days a week</option>
                        <option value={3}>3 days a week</option>
                        <option value={4}>4+ days a week</option>
                    </select>
                </div>

                {/* Months of Training */}
                <div>
                    <p className={inputLabelStyle}>In the past year, how many months have you consistently strength trained?</p>
                    <p className={inputLabelStyle}>WhichWeight defines consistency as training at least twice a week</p>
                    <select
                        id="monthsOfTraining"
                        value={parseInt(fitnessProfile.monthsOfTraining)}
                        onChange={(event) => HandleFitnessProfileChange('months-of-training', parseInt(event.target.value))}
                        className={inputBoxStyle}
                    >
                        <option value={0}>I don't do consistent strength training</option>
                        <option value={1}>1 month</option>
                        <option value={2}>2 months</option>
                        <option value={3}>3 months</option>
                        <option value={4}>4 months</option>
                        <option value={5}>5 months</option>
                        <option value={6}>6 months</option>
                        <option value={7}>7 months</option>
                        <option value={8}>8 months</option>
                        <option value={9}>9 months</option>
                        <option value={10}>10 months</option>
                        <option value={11}>11 months</option>
                        <option value={12}>12 months</option>
                    </select>
                </div>

                {/* Years of Training */}
                <div>
                    <p className={inputLabelStyle}>How many years have you been consistently strength training for?</p>
                    <p className={inputLabelStyle}>WhichWeight defines consistency as training at least twice a week for at least 10 months out of the year</p>
                    <select
                        id="yearsOfTraining"
                        value={parseInt(fitnessProfile.yearsOfTraining)}
                        onChange={(event) => HandleFitnessProfileChange('years-of-training', parseInt(event.target.value))}
                        className={inputBoxStyle}
                    >
                        <option value={0}>I don't do consistent strength training</option>
                        <option value={1}>1 year</option>
                        <option value={2}>2 years</option>
                        <option value={3}>3 years</option>
                        <option value={4}>4 years</option>
                        <option value={5}>5+ years</option>
                    </select>
                </div>

                {/* Error message */}
                {showErrorMessage && <div className={warningLabelStyle}>Please fill out all fitness profile parameters</div>}

                {/* Save Changes button */}
                <LoadingButton buttonText="Save Changes" isLoading={saveChangesLoading} action={OnSaveChangesButton} />
            </div>
        </>
    )
}
