import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { TrashIcon } from '@heroicons/react/24/outline'
import { removeProgramAssignmentEvent } from 'redux/slices/programAssignment'
import { GetPrimaryColorHex } from 'redux/slices/brand'
import moment from 'moment'

const Titles = {
    'a-race': 'A Races',
    'b-race': 'B Races',
    'c-race': 'C Races',
}

const Descriptions = {
    'a-race': 'The races you want to PR at! Heavy tapering will begin 3 weeks leading up to these events',
    'b-race': 'Your additional races. Moderate tapering will begin 2 weeks leading up to these events',
    'c-race': 'Races for fun. Light tapering will be applied the week of these events',
}

export default function EventsHolder({ eventType = 'a-race' }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const events = useSelector((state) => state.programAssignment.events)

    const OnAddButton = () => {
        const navigationProps = {
            state: {
                eventType: eventType,
            },
        }

        navigate('/program/assignment/events/add', navigationProps)
    }

    const OnDeleteEventButton = (eventIndex) => {
        dispatch(removeProgramAssignmentEvent(eventIndex))
    }

    return (
        <div className="border border-gray-400 rounded-xl">
            {/* Header */}
            <div className="px-4 py-2 bg-gray-50 shadow rounded-t-xl">
                <div className="flex items-center">
                    {/* Title */}
                    <div className="text-xl font-semibold flex-grow">{Titles[eventType]}</div>

                    {/* Add Button */}
                    <div className="bg-white border border-gray-400 rounded-lg px-6 py-1 -mr-2 text-sm" onClick={() => OnAddButton()}>
                        Add
                    </div>
                </div>

                {/* Description */}
                <div className="mt-1 text-sm text-gray-500">{Descriptions[eventType]}</div>

                {/* A Race Required Text */}
                {eventType === 'a-race' && 
                    <div 
                        className="mt-1 text-sm font-semibold italic"
                        style={{
                            color: GetPrimaryColorHex(styleConfig)
                        }}
                    >
                        We recommend at least 16 weeks of training leading up to an A Race!
                    </div>
                }
            </div>

            {/* No events text */}
            {events.filter((event) => event.eventType === eventType).length === 0 && <div className="p-4 space-y-4 text-gray-400">No Events</div>}

            {/* Map over events */}
            <div className="divide-y text-lg">
                {events.map((event, eventIndex) => (
                    <Fragment key={eventIndex}>
                        {event.eventType === eventType && (
                            <div className="p-4 flex items-center gap-x-4 text-sm">
                                <div className="flex-grow">{event.name}</div>

                                <div className="text-gray-400">{moment(event.eventDate).format('MMMM DD, YYYY')}</div>

                                <TrashIcon onClick={(e) => OnDeleteEventButton(eventIndex)} className="h-5 text-gray-500" />
                            </div>
                        )}
                    </Fragment>
                ))}
            </div>
        </div>
    )
}
