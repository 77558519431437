import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { addProgramAssignmentEvent } from 'redux/slices/programAssignment'
import { useGetFitnessProfile } from 'redux/customHooks/useGetFitnessProfile'
import { GetEventTerrainOptionsBasedOnSport, GetEventDistanceOptionsBasedOnSport } from 'functions/eventUtils'
import moment from 'moment'

import ScreenFrame from 'components/Frame/ScreenFrame'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import LogoHeader from 'components/Header/LogoHeader'
import BodyFrame from 'components/Frame/BodyFrame'
import TextInputBox from 'components/Onboarding/TextInputBox'
import NextButton from 'components/Onboarding/NextButton'

const Titles = {
    'a-race': 'Add an A Race',
    'b-race': 'Add a B Race',
    'c-race': 'Add a C Race',
}

export default function ProgramAssignmentAddEvent() {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const fitnessProfile = useGetFitnessProfile()

    const { eventType } = location.state

    // Event Name
    const [eventName, setEventName] = useState('')
    const [isEventNameValid, setIsEventNameValid] = useState(true)
    const handleEventNameChange = (event) => {
        if (!isEventNameValid) setIsEventNameValid(true)
        setEventName(event.target.value)
    }

    // Event Terrain
    const [eventTerrainOptions, setEventTerrainOptions] = useState([])
    const [selectedEventTerrain, setSelectedEventTerrain] = useState('')
    const [isEventTerrainValid, setIsEventTerrainValid] = useState(true)
    const handleEventTerrainChange = (value) => {
        if (!isEventTerrainValid) setIsEventTerrainValid(true)
        setSelectedEventTerrain(value)

        // For all other sports, do a dynamic default
        if (fitnessProfile.sportId !== 10) {

            // Reset distance inputs if terrain changes
            if (value !== 'other') {
                handleEventDistanceChange('')
                handleEventDistanceTextInputChange('')
            }

            if (value === 'other') {
                handleEventDistanceChange('other')
                handleEventDistanceTextInputChange('')
            }

        }

    }

    // Event Terrain Input Box
    const [eventTerrainTextInput, setEventTerrainTextInput] = useState('')
    const [isEventTerrainTextInputValid, setIsEventTerrainTextInputValid] = useState(true)
    const handleEventTerrainTextInputChange = (value) => {
        if (!isEventTerrainTextInputValid) setIsEventTerrainTextInputValid(true)
        setEventTerrainTextInput(value)
    }

    // Event Distance
    const [eventDistanceOptions, setEventDistanceOptions] = useState([])
    const [selectedEventDistance, setSelectedEventDistance] = useState('')
    const [isEventDistanceValid, setIsEventDistanceValid] = useState(true)
    const handleEventDistanceChange = (value) => {
        if (!isEventDistanceValid) setIsEventDistanceValid(true)
        setSelectedEventDistance(value)
    }

    // Event Distance Input Box
    const [eventDistanceTextInput, setEventDistanceTextInput] = useState('')
    const [isEventDistanceTextInputValid, setIsEventDistanceTextInputValid] = useState(true)
    const handleEventDistanceTextInputChange = (value) => {
        if (!isEventDistanceTextInputValid) setIsEventDistanceTextInputValid(true)
        setEventDistanceTextInput(value)
    }

    // Event training start week
    const [eventTrainingStartWeekOffset, setEventTrainingStartWeekOffset] = useState(moment.utc().local().startOf('isoWeek').format('YYYY-MM-DD'))
    const handleWeekOffsetChange = (event) => {
        setEventTrainingStartWeekOffset(event.target.value)
    }

    // Event Date
    const [eventDate, setEventDate] = useState(moment().local().format('YYYY-MM-DD'))
    const [isEventDateValid, setIsEventDateValid] = useState(true)
    const handleEventDateChange = (event) => {
        if (!isEventDateValid) setIsEventDateValid(true)
        setEventDate(event.target.value)
    }

    const [saveErrorMessage, setSaveErrorMessage] = useState('')

    // Make sure all the form inputs are there
    const validateFormInputs = () => {
        var allVaild = true

        // Check event name input
        if (eventName.length === 0) {
            setIsEventNameValid(false)
            allVaild = false
        } else setIsEventNameValid(true)

        // Check event terrain input

        // For non-other event terrain
        if (selectedEventTerrain !== 'other') {
            
            // The input box should be valid
            setIsEventTerrainTextInputValid(true)

            if (selectedEventTerrain.length === 0) {
                setIsEventTerrainValid(false)
                allVaild = false
            } else {
                setIsEventTerrainValid(true)
            }

        }

        // For other event terrain
        if (selectedEventTerrain === 'other') {

            setIsEventTerrainValid(true)

            if (eventTerrainTextInput.length === 0) {
                setIsEventTerrainTextInputValid(false)
                allVaild = false
            } else {
                setIsEventTerrainTextInputValid(true)
            }

        }

        // Check event distance input

        // For non-other event distance
        if (selectedEventDistance !== 'other') {
            
            // The input box should be valid
            setIsEventDistanceTextInputValid(true)

            if (selectedEventDistance.length === 0) {
                setIsEventDistanceValid(false)
                allVaild = false
            } else {
                setIsEventDistanceValid(true)
            }

        }

        // For other event terrain
        if (selectedEventDistance === 'other') {

            setIsEventDistanceValid(true)

            if (eventDistanceTextInput.length === 0) {
                setIsEventDistanceTextInputValid(false)
                allVaild = false
            } else {
                setIsEventDistanceTextInputValid(true)
            }

        }

        // Check event date input
        if (eventDate.length === 0) {
            setIsEventDateValid(false)
            allVaild = false
        } else setIsEventDateValid(true)

        if (eventType === 'a-race') {

            // Make sure eventTrainingStartDate is before eventDate
            if (moment(eventTrainingStartWeekOffset).isAfter(moment(eventDate))) {
                setSaveErrorMessage('Event date must be after event training start week')
                allVaild = false
            }

            // Make sure eventTrainingStartDate is not on the same ISO week as eventDate
            if (moment(eventTrainingStartWeekOffset).isoWeek() === moment(eventDate).isoWeek()) {
                setSaveErrorMessage('Event date cannot be during the event training start week')
                allVaild = false
            }

            // Make sure the number of days between eventTrainingStartDate and eventDate is less than 360
            if (moment(eventDate).diff(moment(eventTrainingStartWeekOffset), 'days') > 360) {
                setSaveErrorMessage('Event training start week and event date must be within 360 days of each other')
                allVaild = false
            }

        }

        return allVaild
    }

    const OnSaveEventButton = () => {

        setSaveErrorMessage('')

        if (validateFormInputs()) {
            const newEvent = {
                id: 1,
                name: eventName,
                eventType: eventType,
                eventTerrain: selectedEventTerrain,
                eventTerrainTextInput: eventTerrainTextInput,
                eventDistance: selectedEventDistance,
                eventDistanceTextInput: eventDistanceTextInput,
                eventTrainingStartDate: eventTrainingStartWeekOffset,
                eventDate: eventDate,
            }

            dispatch(addProgramAssignmentEvent(newEvent))

            navigate('/program/assignment/events')
        }
    }

    useEffect(() => {

        if (fitnessProfile === null) return

        setEventTerrainOptions(GetEventTerrainOptionsBasedOnSport(fitnessProfile.sportId))

        // For cycling, default to other
        if (fitnessProfile.sportId === 10) {
            handleEventDistanceChange('other')
            handleEventDistanceTextInputChange('')
        }

    }, [fitnessProfile])

    useEffect(() => {

        if (fitnessProfile === null) return

        setEventDistanceOptions(GetEventDistanceOptionsBasedOnSport(fitnessProfile.sportId, selectedEventTerrain))

    }, [selectedEventTerrain])

    return (
        <ScreenFrame>
            
            <LogoHeader backButtonAction={() => navigate('/program/assignment/events')} />

            {fitnessProfile === null && <MediumLoadingComponent />}

            {fitnessProfile !== null && (
                <BodyFrame>

                    {/* Title */}
                    <div className="text-xl text-center font-semibold">{Titles[eventType]}</div>

                    {/* Event Name */}
                    <TextInputBox 
                        label="Event Name" 
                        value={eventName} 
                        handleChange={handleEventNameChange} 
                        isValid={isEventNameValid} 
                        invalidMessage="Please enter an event name" 
                    />

                    {/* Event Terrain */}
                    <div className="space-y-1">
                        <div className="pl-2 text-sm text-gray-600">Event Type</div>

                        <select
                            id="eventTerrain"
                            value={selectedEventTerrain}
                            onChange={(e) => handleEventTerrainChange(e.target.value)}
                            className="w-full px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300"
                        >
                            <option disabled value="">Select Event Type</option>
                            {eventTerrainOptions.map((eventTerrainOption, eventTerrainIndex) => (
                                <option key={eventTerrainIndex} value={eventTerrainOption.key}>
                                    {eventTerrainOption.title}
                                </option>
                            ))}
                        </select>
                        {/* Error Message */}
                        {isEventTerrainValid ? null : <p className="mt-1 text-sm text-red-600">Please select an event type</p>}
                    </div>

                    {/* Event Terrain Input Box */}
                    {selectedEventTerrain === 'other' && (
                        <div className="space-y-1">
                            <TextInputBox 
                                label="Event Type Details" 
                                value={eventTerrainTextInput} 
                                placeholder="Enter Event Type Here"
                                handleChange={(e) => handleEventTerrainTextInputChange(e.target.value)} 
                                invalidMessage="Please enter an event type" 
                            />
                            {/* Error Message */}
                            {isEventTerrainTextInputValid  ? null : <p className="mt-1 text-sm text-red-600">Please enter an event type</p>}
                        </div>
                    )}

                    {/* Event Distance */}
                    {fitnessProfile.sportId !== 10 && 
                        <div className="space-y-1">
                            <div className="pl-2 text-sm text-gray-600">Event Distance</div>

                            <select
                                id="eventDistance"
                                value={selectedEventDistance}
                                onChange={(e) => handleEventDistanceChange(e.target.value)}
                                className="w-full px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300"
                            >
                                <option disabled value="">Select Event Distance</option>
                                {eventDistanceOptions.map((eventDistanceOption, eventDistanceIndex) => (
                                    <option key={eventDistanceIndex} value={eventDistanceOption.key}>
                                        {eventDistanceOption.title}
                                    </option>
                                ))}
                            </select>
                            {/* Error Message */}
                            {isEventDistanceValid ? null : <p className="mt-1 text-sm text-red-600">Please select an event distance</p>}
                        </div>
                    }

                    {/* Event Distance Input Box */}
                    {selectedEventDistance === 'other' && (
                        <div className="space-y-1">
                            <TextInputBox 
                                label="Event Distance Details" 
                                value={eventDistanceTextInput} 
                                placeholder='Enter Event Distance Here'
                                handleChange={(event) => handleEventDistanceTextInputChange(event.target.value)} 
                                invalidMessage="Please enter an event distance" 
                            />
                            {/* Error Message */}
                            {isEventDistanceTextInputValid  ? null : <p className="mt-1 text-sm text-red-600">Please enter an event distance</p>}
                        </div>
                    )}

                    {/* Event Training Start Week (A Race Only) */}
                    {eventType === 'a-race' && (
                        <div className="space-y-1">
                            <div className="pl-2 text-sm text-gray-600">Event Training Start Week</div>

                            <div className="pb-2 pl-2 text-xs text-gray-400">Which week would you like to begin your strength programming?</div>

                            <select
                                id="startWeek"
                                value={eventTrainingStartWeekOffset}
                                onChange={handleWeekOffsetChange}
                                className="w-full px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                            >
                                {Array.from({ length: 7 }, (_, i) => i).map((i) => (
                                    <option key={i} value={moment.utc().local().add(i, 'isoWeeks').startOf('isoWeek').format('YYYY-MM-DD')}>
                                        {moment.utc().local().add(i, 'isoWeeks').startOf('isoWeek').format('MMMM D')} - {moment.utc().local().add(i, 'isoWeeks').endOf('isoWeek').format('MMMM D')}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {/* Event Date */}
                    <div className="space-y-1">
                        <div className="pl-2 text-sm text-gray-600">Event Date</div>

                        <input
                            type="date"
                            name="eventDate"
                            id="eventDate"
                            className="w-full px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                            placeholder="Event Date*"
                            value={eventDate}
                            onChange={handleEventDateChange}
                            min={moment().local().format('YYYY-MM-DD')}
                        />

                        {/* Error Message */}
                        {isEventDateValid ? null : <p className="mt-1 text-sm text-red-600">Please select an event date</p>}
                    </div>

                    <div className='mt-auto'>
                        {saveErrorMessage !== '' && <p className="mt-1 text-sm text-center text-red-600">{saveErrorMessage}</p>}
                        <NextButton 
                            text="Add Event" 
                            action={() => OnSaveEventButton()} 
                        />
                    </div>

                </BodyFrame>
            )}

        </ScreenFrame>
    )
}
