import { useSelector } from 'react-redux'
import { GetPrimaryColorHex } from 'redux/slices/brand'
import moment from 'moment'

export default function CountdownBar({ races, programStartDate }) {
    
    const styleConfig = useSelector((state) => state.brand.styleConfig)

    const today = moment()
    const start = moment(programStartDate)
    const end = moment.max(...races.map((race) => moment(race.eventDate)))

    const totalDuration = end.diff(start, 'days')

    // Get current progress as a percentage. Ceiling at 100
    const currentProgress = Math.min((today.diff(start, 'days') / totalDuration) * 100, 100)

    const raceMarkers = races.map((race) => {
        // Get position as a percentage. Ceiling at 100
        const position = Math.min((moment(race.eventDate).diff(start, 'days') / totalDuration) * 100, 100)
        const isCompleted = moment(race.eventDate).isBefore(today)

        return {
            position,
            backgroundColor: isCompleted ? GetPrimaryColorHex(styleConfig) : '#f3f4f6',
            color: isCompleted ? 'text-white' : 'text-zinc-900',
            raceType: race.eventType.charAt(0).toUpperCase(),
        }
    })

    return (
        <div className="relative w-full mx-3 mr-5 h-2 bg-gray-100 rounded-full">
            
            {/* Filled portion of the progress bar */}
            <div 
                className='absolute h-full rounded-full'
                style={{ 
                    width: `${currentProgress}%`,
                    backgroundColor: GetPrimaryColorHex(styleConfig)
                }} 
            />

            {/* Race markers */}
            {raceMarkers.map((marker, index) => (
                <div
                    key={index}
                    className={`
                        absolute flex items-center justify-center w-5 h-5 rounded-full text-xs transform -translate-x-1/2 -translate-y-1/4 
                        ${marker.color}
                    `}
                    style={{ 
                        left: `${marker.position}%`, 
                        backgroundColor: marker.backgroundColor
                    }}
                >
                    {marker.raceType}
                </div>
            ))}

        </div>
    )
}
