import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { initializeActiveWorkout } from 'redux/slices/activeWorkout'
import { resetActiveAssessmentState } from 'redux/slices/activeAssessment'
import { useGetExercises } from 'redux/customHooks/useGetExercises'
import { useGetFitnessProfile } from 'redux/customHooks/useGetFitnessProfile'
import { useNavigate, useLocation } from 'react-router-dom'
import { InformationCircleIcon, ExclamationTriangleIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { ModalContext, ModalKey } from 'components/Modals/ModalContext'
import { IsActiveWorkoutSliceInInitialState } from 'redux/slices/activeWorkout'
import { equipmentMedia } from 'functions/equipmentUtils'
import { GetPrimaryColorHex } from 'redux/slices/brand'

import CustomHeader from 'components/Header/CustomHeader'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import WorkoutPreview from 'components/ActiveWorkout/WorkoutPreview'
import LoadingButton from 'components/LoadingButton'

const workoutLengthOptions = [30, 40, 60]

export default function ActiveWorkoutPreview() {
    const navigate = useNavigate()
    const location = useLocation()
    const { weekOfTheYearKey, taperData, workoutTemplateKey } = location.state

    /*
    const taperData = {
        progressionLevelRegression: 0,
        taperDampener: 0,
        taperLevel: 'taper-none',
        taperType: 'program',
        useTaper: false
    }
    */

    // console.log(taperData)

    const dispatch = useDispatch()

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    const userId = useSelector((state) => state.user.id)
    const activeWorkout = useSelector((state) => state.activeWorkout)
    const exercises = useGetExercises()
    const fitnessProfile = useGetFitnessProfile()

    const { ShowModal } = useContext(ModalContext)

    const [loadingWorkoutTemplate, setLoadingWorkoutTemplate] = useState(true)
    const [selectedWorkoutLength, setSelectedWorkoutLength] = useState(60)
    const [workoutTemplate, setWorkoutTemplate] = useState(null)
    const [isStartWorkoutLoading, setIsStartWorkoutLoading] = useState(false)

    const FetchWorkoutTemplateFromTheBackend = async () => {
        setLoadingWorkoutTemplate(true)

        const jsonBody = {
            userId: userId,
            weekOfTheYearKey: weekOfTheYearKey,
            workoutTemplateKey: workoutTemplateKey,
        }

        // console.log(jsonBody);

        const result = await WhichWeightAPI('PROGRAM', 'GET', '/workout_template', jsonBody)

        // console.log(result);

        setLoadingWorkoutTemplate(false)

        if (result.data.status === 'failure') {
            setWorkoutTemplate(null)
            return
        }

        // console.log(result.data.data)

        setWorkoutTemplate(result.data.data)
    }

    const ShowWorkoutLengthInfoModal = () => {
        const modalData = {
            key: ModalKey.Text,
            fullscreen: false,
            titleText: 'Choose your workout length',
            bodyText: ['Select approximately how long you want to work out for', 'Shorter workouts will have less prep and accessory exercises but will still make sure you get your main lifts in'],
        }

        ShowModal(modalData)
    }

    const FilterWorkoutTemplateByWorkoutLength = () => {
        const reduxWorkoutTemplate = JSON.parse(JSON.stringify(workoutTemplate))

        // Store selected workout length
        reduxWorkoutTemplate.workoutLength = selectedWorkoutLength

        // Filter JSON by workout length if applicable
        if (selectedWorkoutLength < 60) {
            // For each phase
            reduxWorkoutTemplate.phases.map((phase) => {
                // Filter by those with supported length
                phase.exercises = phase.exercises.filter((exercise) => exercise.supportedWorkoutLengths.includes(selectedWorkoutLength))
            })

            // Get rid of any phases that no longer have exercises
            reduxWorkoutTemplate.phases = reduxWorkoutTemplate.phases.filter((phase) => phase.exercises.length > 0)
        }

        // Filter out any missing exercises
        reduxWorkoutTemplate.phases.map((phase) => {
            phase.exercises = phase.exercises.filter((exercise) => exercise.id !== -1)
        })

        return reduxWorkoutTemplate
    }

    const GetRequiredEquipment = (reduxWorkoutTemplate) => {
        var requiredEquipment = []

        // For each phase
        reduxWorkoutTemplate.phases.map((phase) => {
            // For each exercise
            phase.exercises.map((exercise) => {
                // console.log(exercise.equipment)

                // Edge case for band and machine equipment. Only push band as required
                if (exercise.equipment === 'band,machine') {
                    if (!requiredEquipment.includes('band')) requiredEquipment.push('band')
                }
                // Edge case for dumbbell and kettlebell equipment. Only push dumbbell as required
                else if (exercise.equipment === 'dumbbell,kettlebell') {
                    if (!requiredEquipment.includes('dumbbell')) requiredEquipment.push('dumbbell')
                }
                // Edge case for band, dumbbell, and kettlebell equipment. Only push dumbbell as required
                else if (exercise.equipment === 'band,dumbbell,kettlebell') {
                    if (!requiredEquipment.includes('band')) requiredEquipment.push('band')
                    if (!requiredEquipment.includes('dumbbell')) requiredEquipment.push('dumbbell')
                } else {
                    var currentExerciseEquipment = exercise.equipment.split(',')

                    currentExerciseEquipment.map((equipment) => {
                        if (equipment.length > 0) {
                            if (!requiredEquipment.includes(equipment)) requiredEquipment.push(equipment)
                        }
                    })
                }
            })
        })

        // console.log(requiredEquipment)

        return requiredEquipment
    }

    // Post workout to the backend
    const StartWorkout = async () => {
        setIsStartWorkoutLoading(true)

        // Start by getting the json filtered by workout length
        const reduxWorkoutTemplate = FilterWorkoutTemplateByWorkoutLength(workoutTemplate)

        // For each phase
        reduxWorkoutTemplate.phases.map((phase) => {
            // Add a completed boolean
            phase.completed = false

            // For each exercise
            phase.exercises.map((exercise) => {
                // Cache the exercise video URL
                exercise.videoUrl = exercises.find((item) => item.id === exercise.id).videoUrl

                // For each set
                exercise.sets.map((set) => {
                    // Add default values for recommendation data
                    set.recommendationData = {
                        weightRecommendationLoaded: false,
                        weightRecommendation: 0,
                        weightRecommendationRounded: 0,
                        formulaTag: '',
                        equipmentDetails: null,
                    }

                    // Add default values for completed set data
                    set.completedSetData = {
                        savingResult: false,
                        completed: false,
                        setUnitsCompleted: 0,
                        movementDifficulty: 0,
                        difficulty: 0,
                        override: 0,
                        databaseId: 0,
                    }
                })
            })
        })

        // console.log(reduxWorkoutTemplate); return;

        const jsonBody = {
            traineeId: userId,
            name: workoutTemplate.name,
            uiMode: 'compact',
        }

        // console.log(jsonBody);

        const result = await WhichWeightAPI('CORE', 'POST', '/workout', jsonBody)

        // console.log(result);

        setIsStartWorkoutLoading(false)

        if (result.data.status == 'failure') {
            console.error(result)
            return
        }

        const activeWorkoutPayload = {
            id: result.data.data,
            weekOfTheYearKey: weekOfTheYearKey,
            workoutTemplateKey: workoutTemplateKey,
            taperData: taperData,
            workoutTemplate: reduxWorkoutTemplate,
        }

        dispatch(initializeActiveWorkout(activeWorkoutPayload))

        navigate('/active/workout')
    }

    useEffect(() => {
        // Make sure active assessment is set to default before starting a active workout
        dispatch(resetActiveAssessmentState())
        FetchWorkoutTemplateFromTheBackend()
    }, [])

    return (
        <>
            {/* Header */}
            <CustomHeader text={'Workout Preview'} backButtonAction={() => navigate('/home')} />

            {(exercises.length === 0 || fitnessProfile === null || loadingWorkoutTemplate) && <MediumLoadingComponent />}

            {(exercises.length > 0 && fitnessProfile !== null && loadingWorkoutTemplate === false) && (
                <>
                    {/* Error Component */}
                    {workoutTemplate === null && (
                        <div className="p-10 space-y-4 text-center">
                            <ExclamationTriangleIcon className="mx-auto h-16 text-orange-400" />

                            <div className="text-xl font-semibold text-gray-600">Workout Error</div>

                            <div className="text-gray-600">
                                There was an error grabbing your workout. Please try restarting the app and logging back in. If issues persist please contact our support team at
                                support@whichweight.com
                            </div>
                        </div>
                    )}

                    {workoutTemplate !== null && (
                        <div className="p-4 space-y-4">
                            {/* Workout Length Selector */}
                            {workoutTemplateKey.includes('strength') && (
                                <div className="flex items-center border border-gray-400 rounded-lg h-12 overflow-hidden">
                                    <div className="pl-4 py-2 flex-grow flex gap-x-2 items-center" onClick={() => ShowWorkoutLengthInfoModal()}>
                                        <div>Workout Length</div>
                                        <InformationCircleIcon className="h-4" />
                                    </div>

                                    {workoutLengthOptions.map((option) => (
                                        <div
                                            key={option}
                                            className={`
                                                h-12 w-14 flex flex-col items-center justify-center border-l border-gray-400
                                                ${selectedWorkoutLength === option ? 'text-white font-bold' : ''}
                                            `}
                                            style={{
                                                backgroundColor: selectedWorkoutLength === option ? GetPrimaryColorHex(styleConfig) : '',
                                            }}
                                            onClick={() => setSelectedWorkoutLength(option)}
                                        >
                                            <div className="text-sm">{option}</div>
                                            <div className="-mt-1 text-xs">mins</div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* Taper Details */}
                            <div className="shadow border border-gray-400 rounded-lg p-4 space-y-2 items-center">
                                <div className="flex">
                                    <div className="flex-grow">Taper</div>

                                    {/* Taper Dampener Info */}
                                    {!taperData.useTaper && <div className="text-gray-500">Full Load</div>}
                                    {taperData.useTaper && <div className="text-gray-500">{taperData.taperDampener * 100}% of Full Load</div>}
                                </div>

                                <div className="flex">
                                    <div className="flex-grow">Difficulty Feedback</div>

                                    {/* Active Tag */}
                                    {!taperData.useTaper && (
                                        <div className="w-32 flex items-center justify-center gap-x-2 py-0.5 text-sm rounded-full bg-green-400">
                                            <CheckCircleIcon className="h-3" />
                                            <div>Active</div>
                                        </div>
                                    )}

                                    {/* Limited Tag */}
                                    {taperData.useTaper && (
                                        <div className="w-32 flex items-center justify-center gap-x-2 py-0.5 text-sm rounded-full bg-yellow-300">
                                            <ExclamationTriangleIcon className="h-3" />
                                            <div>Limited</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Required Equipment */}
                            {GetRequiredEquipment(FilterWorkoutTemplateByWorkoutLength(workoutTemplate)).length > 0 && (
                                <div 
                                    onClick={() => navigate("/equipment")}
                                    className="shadow border border-gray-400 rounded-lg px-4 pt-4 pb-2 space-y-4 items-center"
                                >
                                    <div className='flex'>
                                        <div className='flex-grow'>Equipment For This Workout</div>
                                        <div className='text-xs border border-gray-400 rounded-md py-1 px-4'>Edit</div>
                                    </div>
                                    <div className="grid grid-cols-2 gap-x-2">
                                        {GetRequiredEquipment(FilterWorkoutTemplateByWorkoutLength(workoutTemplate)).map((equipment, index) => (
                                            <div key={index} className="flex items-center gap-x-2 bg-gray-100 py-1 px-2 rounded-xl mb-2">
                                                <img src={equipmentMedia[equipment].imageURL} alt="Equipment Icon" className="h-6" />
                                                <div className="text-xs text-gray-600">{equipmentMedia[equipment].name}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Start workout button */}
                            {IsActiveWorkoutSliceInInitialState(activeWorkout) && <LoadingButton buttonText="Start Workout" isLoading={isStartWorkoutLoading} action={() => StartWorkout()} />}

                            {/* Resume workout button */}
                            {!IsActiveWorkoutSliceInInitialState(activeWorkout) && <LoadingButton buttonText="Resume Workout" action={() => navigate('/active/workout')} />}

                            {/* Workout Preview */}
                            <WorkoutPreview workoutTemplate={FilterWorkoutTemplateByWorkoutLength(workoutTemplate)} setWorkoutTemplate={setWorkoutTemplate} />
                        </div>
                    )}
                </>
            )}
        </>
    )
}
