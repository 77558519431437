import moment from "moment";

export class WeekOfTheYearKey {
    
    constructor(input) {

        // Default case. Beginning of epoch time
        this.yearComponent = 1970
        this.weekComponent = 1

        // If constructing class with string
        if (typeof input === 'string') {

            // TODO Add checks here to make sure weekOfTheYearKeyString is of correct format
            // YYYY-week-WW

            this.yearComponent = parseInt(input.split('-week-')[0]);
            this.weekComponent = parseInt(input.split('-week-')[1]);
            
        }  
        
        // If constructing class with date
        if (input instanceof Date) {

            this.yearComponent = parseInt(moment(input).startOf('isoWeek').format('GGGG')); // Using ISO year
            this.weekComponent = parseInt(moment(input).startOf('isoWeek').format('WW'));

        }

        // If constructing class with moment
        if (input instanceof moment) {

            this.yearComponent = parseInt(moment(input).startOf('isoWeek').format('GGGG')); // Using ISO year
            this.weekComponent = parseInt(moment(input).startOf('isoWeek').format('WW'));

        }

        // If we ever pass the same class in, just reconstruct
        if (input instanceof WeekOfTheYearKey){

            this.yearComponent = input.getYear()
            this.weekComponent = input.getWeek()

        }

    }

    static constructWithWeekOffset(weekOffset) {
        return new WeekOfTheYearKey(moment.utc().local().add(weekOffset, 'isoWeeks').startOf('isoWeek'))
    }

    // Converts class to string
    toString() {
        const weekString = this.weekComponent.toString().padStart(2, '0');
        return `${this.yearComponent}-week-${weekString}`;
    }

    // Converts class to date
    // AI generated
    toDate() {

        // Create a date object for January 4th of the year
        // (which is always in week 1 of the ISO calendar)
        const jan4th = moment().year(this.yearComponent).month(0).date(4);
        
        // Calculate the Monday of week 1 by subtracting days to get to Monday
        const week1Monday = jan4th.clone().subtract(jan4th.isoWeekday() - 1, 'days');
        
        // Add the necessary weeks to get to our target week
        const targetDate = week1Monday.add(this.weekComponent - 1, 'weeks');
        
        return targetDate

    }

    toShortString() {
        const weekString = this.weekComponent.toString().padStart(2, '0');
        return `${this.yearComponent.toString().slice(-2)}-${weekString}`;
    }

    toDateString(formatString) {
        return moment.utc().year(this.yearComponent).isoWeek(this.weekComponent).startOf('isoWeek').format(formatString)
    }

    toDateRangeString(formatString){
        const startDate = moment.utc().year(this.yearComponent).isoWeek(this.weekComponent).startOf('isoWeek').format(formatString)
        const endDate = moment.utc().year(this.yearComponent).isoWeek(this.weekComponent).endOf('isoWeek').format(formatString)

        return `${startDate} - ${endDate}`
    }

    getYear() { return this.yearComponent; }

    getWeek() { return this.weekComponent; }

    lessThan(other){

        if (!(other instanceof WeekOfTheYearKey)) 
            throw new TypeError(`Unsupported operand type(s) for '==': '${typeof this}' and '${typeof other}'`);

        if (this.yearComponent < other.yearComponent) return true

        if (this.yearComponent > other.yearComponent) return false

        if (this.yearComponent === other.yearComponent) return this.weekComponent < other.weekComponent

    }
    
    equals(other) {
        
        if (!(other instanceof WeekOfTheYearKey)) 
            throw new TypeError(`Unsupported operand type(s) for '==': '${typeof this}' and '${typeof other}'`);
        
        return this.yearComponent === other.yearComponent && this.weekComponent === other.weekComponent;

    }

    // Addition (+) operator
    add(other) {
        
        if (typeof other !== 'number') {
            throw new TypeError(`Unsupported operand type(s) for '+': '${typeof this}' and '${typeof other}'`);
        }

        // Create duplicate to return
        const newWeekOfTheYearKey = new WeekOfTheYearKey(this.toString());

        // Convert to date and add weeks
        const newDate = newWeekOfTheYearKey.toDate().add(other, 'weeks')

        // Convert back to week of the year key
        return new WeekOfTheYearKey(newDate);
    }
    
}