import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

import { setReduxSportId, setReduxSportName } from 'redux/slices/onboarding'

import ScreenFrame from 'components/Frame/ScreenFrame'
import StepHeader from 'components/Header/StepHeader'
import TitleText from 'components/Onboarding/TitleText'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'

export default function SportInputView() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Sport dropdown variables
    const [sportOptions, setSportOptions] = useState(null)

    // Fetches sports data from the backend
    const getSportsFromBackend = async () => {
        const result = await WhichWeightAPI('CORE', 'GET', '/sports')

        // console.log(result.data.data);

        setSportOptions(result.data.data)
    }

    const OnNextButton = (sportId, sportName) => {
        dispatch(setReduxSportId(sportId))
        dispatch(setReduxSportName(sportName))
        navigate('/onboarding/daysOfTraining')
    }

    useEffect(() => {
        getSportsFromBackend()
    }, [])

    return (
        <ScreenFrame>
            <StepHeader title='Fitness Profile' startPercentage={0} endPercentage={0} />

            <div className="flex-1 flex flex-col pt-4 pb-12 gap-y-4 overflow-y-auto bg-white">
                
                <div className='px-4'>
                    <TitleText text="What sport do you compete in?" />
                </div>

                {sportOptions === null && <MediumLoadingComponent />}

                {sportOptions !== null && (
                    <div className="mt-2">
                        {sportOptions.map((option, index) => (
                            <div key={index} className="flex border-b py-4 px-8" onClick={() => OnNextButton(option.id, option.name)}>
                                <div className="flex-grow text-xl">{option.name}</div>
                                <ChevronRightIcon className="h-6 text-gray-500" />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </ScreenFrame>
    )
}
