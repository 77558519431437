export default function TextInputBox({ label = 'Label', placeholder = 'Enter Here', value = '', handleChange = () => {}, isValid = true, invalidMessage = 'Invalid', isDisabled = false }) {
    return (
        <div className="space-y-1">
            {/* Label */}
            <div className="pl-2 text-sm text-gray-600">{label}</div>

            {/* Text Input */}
            <input
                type="text"
                min="0"
                name="firstName"
                id="firstName"
                className="w-full px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                disabled={isDisabled}
            />

            {/* Error Message */}
            {isValid ? null : <p className="pl-2 text-sm text-red-600">{invalidMessage}</p>}
        </div>
    )
}
