import { useContext } from "react"
import { ModalKey, ModalContext } from "components/Modals/ModalContext"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { getExerciseInfo } from 'functions/assessmentSummaryUtils'

export default function AssessmentSummaryExerciseSetListItem({
    exerciseId,
    exerciseName,
    percentageScore,
    summaryTag
}) {

    const { ShowModal } = useContext(ModalContext)

    const ShowInfoModal = () => {

        const exerciseInfo = getExerciseInfo(exerciseId)
        const modalData = {
            key: ModalKey.ExerciseAssessmentInfo,
            fullscreen: true,
            ...exerciseInfo
        }

        ShowModal(modalData)
    }

    return (
        <div 
            className="px-2 py-1 space-y-1 text-sm" 
            onClick={() => ShowInfoModal()}
        >

            <div className="flex items-center">

                <div className="flex-grow truncate">{exerciseName}</div>

                {summaryTag === "strong" && (<div className="text-center font-semibold text-green-600">STRONG</div>)}
                {summaryTag === "weak" && (<div className="text-center font-semibold text-red-600">WEAK</div>)}

                <InformationCircleIcon className="ml-1 text-gray-600 w-4 h-4" />

            </div>

            <div className='relative space-y-1'>

                <div 
                    className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-center font-bold text-xs'
                    style={{ textShadow: '1px 1px 0 #666, -1px -1px 0 #666, 1px -1px 0 #666, -1px 1px 0 #666, 1px 1px 0 #666' }}
                >
                    {Math.round(percentageScore)}%
                </div>

                <div className="!mb-1 bg-gray-300 w-full h-4 rounded-md overflow-hidden">
                    <div 
                        className={`
                            h-6
                            ${Math.round(percentageScore) < 75 ? 'bg-red-400' : 'bg-green-400'}
                        `} 
                        style={{ 
                            width: Math.round(percentageScore) + '%',
                        }} 
                    />
                </div>

            </div>

        </div>
    )

}
