import { WhichWeightAPI } from 'WhichWeightAPI'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { GetDifficultyIconFromValue } from 'functions/iconUtils'
import { ConvertPoundsToNearestFiveKilograms } from 'functions/measurementSystemUtils'
import moment from 'moment'

import CustomHeader from 'components/Header/CustomHeader'
import MediumLoadingComponent from 'components/Loading/MediumLoadingComponent'
import ExerciseWeightChart from 'components/WorkoutSummary/ExerciseWeightChart'

export default function ExerciseWeightHistory() {
    const navigate = useNavigate()
    const location = useLocation()

    const userId = useSelector((state) => state.user.id)
    const measurementSystem = useSelector((state) => state.user.measurementSystem)
    const { exerciseId, exerciseName, exerciseType } = location.state

    const [completedExericseRecords, setCompletedExerciseRecords] = useState(null)

    const GetReportDataFromTheBackend = async () => {
        const jsonBody = {
            traineeId: userId,
            exerciseId: exerciseId,
        }

        // console.log(jsonBody)

        const result = await WhichWeightAPI('CORE', 'GET', '/user_exercise_weight_recommendation_history', jsonBody)

        // console.log(result.data.data)

        setCompletedExerciseRecords(result.data.data)
    }

    useEffect(() => {
        GetReportDataFromTheBackend()
    }, [])

    return (
        <>
            {/* Header */}
            <CustomHeader text={exerciseName} backButtonAction={() => navigate(-1)} />

            {/* Loading Animation */}
            {completedExericseRecords === null && <MediumLoadingComponent />}

            {/* Exercise History */}
            {completedExericseRecords !== null && (
                <div className="p-4 space-y-4">
                    {/* Line Chart */}
                    <ExerciseWeightChart completedExericseRecords={completedExericseRecords} exerciseType={exerciseType} />

                    {/* Date Picker 
                    <div className="text-sm space-y-2">
                        <div className="flex justify-center text-xs gap-x-2">
                            <button className="flex-grow px-4 py-2 border rounded-md border-gray-600 text-gray-600" onClick={() => handlePresetClick(30)}>
                                Last 30 Days
                            </button>
                            <button className="flex-grow px-4 py-2 border rounded-md border-gray-600 text-gray-600" onClick={() => handlePresetClick(60)}>
                                Last 60 Days
                            </button>
                            <button className="flex-grow px-4 py-2 border rounded-md border-gray-600 text-gray-600" onClick={() => handlePresetClick(90)}>
                                Last 90 Days
                            </button>
                        </div>

                        <div className="flex gap-x-2 items-center justify-center rounded">
                            <input
                                type="date"
                                name="startDate"
                                id="startDate"
                                className="w-full px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                                value={moment(startDate).format('YYYY-MM-DD')}
                                onChange={(e) => setStartDate(e.target.value)}
                            />

                            <div>to</div>

                            <input
                                type="date"
                                name="endDate"
                                id="endDate"
                                className="w-full px-4 py-2 block rounded-md text-gray-900 shadow-sm ring-1 ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                                value={moment(endDate).format('YYYY-MM-DD')}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                    </div>
                    */}

                    {/* Weight Recommendation Table */}
                    <div className="border border-gray-400 rounded-lg overflow-hidden text-sm">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-100 text-left font-medium p-10">
                                    <th className="pl-4 py-2">Date</th>
                                    <th className="py-2">Weight</th>
                                    <th className="py-2">Reps</th>
                                    <th className="py-2 text-center">Difficulty</th>
                                </tr>
                            </thead>

                            <tbody>
                                {completedExericseRecords.map((record) => (
                                    <tr key={record.id} className="border-b border-gray-200">
                                        <td className="pl-4 py-2">{moment(record.completeDate).format('MMM DD, YYYY')}</td>

                                        {exerciseType === 'recommendation' && (
                                            <>
                                                {measurementSystem === 'imperial' && <td className="py-2">{record.weightRecommendationRounded} lbs</td>}

                                                {measurementSystem === 'metric' && <td className="py-2">{ConvertPoundsToNearestFiveKilograms(record.weightRecommendation)} kgs</td>}
                                            </>
                                        )}

                                        {exerciseType === 'bodyweight' && <td className="py-2">Body</td>}

                                        <td className="py-2">
                                            {record.setUnitsCompleted}/{record.setUnitsAssigned}
                                        </td>

                                        <td className="py-2">
                                            <img src={GetDifficultyIconFromValue(record.difficulty)} className="h-6 w-6 mx-auto" alt="Difficulty Icon" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    )
}
