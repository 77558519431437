import linkIcon from 'media/icons/linkIcon.png'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

export default function PaymentMethod({ subscriptionData, defaultPaymentMethod }) {
    if (subscriptionData.cancel_at_period_end) return <></>

    if (subscriptionData.status === 'unpaid') return <></>

    if (defaultPaymentMethod === undefined || defaultPaymentMethod === null) {
        return (
            <div className="flex border-b py-3">
                <div className="pl-2 flex-grow text-gray-600">Payment Method</div>
                <div className="flex gap-x-2 items-center">
                    <ExclamationTriangleIcon className="mt-0.5 h-5 text-yellow-400" />
                    <div className="pr-2 text-right">None</div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex border-b py-3">
            <div className="pl-2 flex-grow text-gray-600">Payment Method</div>

            {defaultPaymentMethod.type === 'link' && (
                <div className="flex items-center gap-x-2">
                    <img src={linkIcon} alt="Link" className="h-5" />
                    <div className="pr-2 text-right">Link</div>
                </div>
            )}

            {defaultPaymentMethod.type === 'card' && <div className="pr-2 text-right">Card ending in {defaultPaymentMethod.card.last4}</div>}

            {/* TODO Add support for other payment method types here */}
            {defaultPaymentMethod.type !== 'link' && defaultPaymentMethod.type !== 'card' && <div className="pr-2 text-right">Other</div>}
        </div>
    )
}
