import { useNavigate } from 'react-router-dom'
import { GetPresentWeekOverview } from 'redux/selectors/PresentWeekOverviewSelector'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { TrophyIcon } from '@heroicons/react/20/solid'
import runningIcon from 'media/icons/sports/running.png'
import moment from 'moment'

import CountdownBar from './CountdownBar'

export default function RaceCountdown() {
    const navigate = useNavigate()
    const presentWeek = GetPresentWeekOverview()

    const races = presentWeek ? presentWeek.races : []
    const aRace = races.find((race) => race.eventType === 'a-race')
    const daysTilARace = aRace ? moment(aRace.eventDate).diff(moment(), 'days') : null
    const daysTilProgramEndDate = aRace ? moment(aRace.programEndDate).diff(moment(), 'days') : null

    return (
        <div 
            className="p-4 space-y-1 border-b border-gray-600" 
            onClick={() => navigate('/program/overview')}
        >

            {daysTilARace === null && <div>No A-Race Scheduled</div>}

            {daysTilARace !== null && 
                <>

                    {/* Race Countdown */}
                    {daysTilARace > 0 && 
                        <>

                            <div className="flex items-center justify-center gap-x-2">
                                <div className="text-center text-zinc-900 italic">
                                    {daysTilARace} days until {aRace.name}!
                                </div>
                                <InformationCircleIcon className="mt-0.5 h-3 text-gray-600" />
                            </div>

                            <div className="flex flex-row items-center justify-center gap-x-1">
                                <img src={runningIcon} className="h-5 w-5 " />

                                <CountdownBar 
                                    races={races.filter((race) => moment(race.eventDate) <= moment(aRace.eventDate))} 
                                    programStartDate={aRace.eventTrainingStartDate} 
                                />

                                <TrophyIcon className="ml-1.5 h-6 w-6 " />
                            </div>

                        </>
                    }

                    {/* Program Cool Down */}
                    {daysTilARace <= 0 &&
                        <div className="flex items-center justify-center gap-x-2">
                            <div className="text-center text-zinc-900 italic">
                                {daysTilProgramEndDate} days of deload after {aRace.name}
                            </div>
                            <InformationCircleIcon className="mt-0.5 h-3 text-gray-600" />
                        </div>
                    }

                </>
            }

            
        </div>
    )
}
