import { useNavigate } from "react-router-dom"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"

export default function RouteNotFoundView() {

    const navigate = useNavigate()

    return (
        <div className='p-4 mx-auto space-y-4'>

            <div className="p-2 space-y-2">
                <MagnifyingGlassIcon className="mt-20 mb-10 mx-auto w-40 text-gray-500" />

                <div className="text-center font-bold text-2xl">Whoops!</div>
                
                <div className="text-center text-lg">You appear to be lost. For more support reach out to <strong>support@whichweight.com</strong></div>

            </div>

            <button 
                type="button" 
                className="w-full text-center rounded-full border border-gray-600 py-2 text-md font-semibold text-gray-500 shadow-sm" 
                onClick={() => navigate('/')}
            >
                Return Home
            </button>
    
        </div>
    )
}