import React from 'react'
import { useSelector } from 'react-redux'
import { GetPrimaryColorHex } from 'redux/slices/brand'

export default function RoundInfoPill({
    circuitRounds,
    setIndex
}) {

    const styleConfig = useSelector((state) => state.brand.styleConfig)
    
    return (
        <div
            className="flex w-full h-8 border-t-2 border-x-2 border-white rounded-t-xl divide-x overflow-y-hidden shadow-lg"
        >
            {Array.from({ length: circuitRounds }, (_, i) => i + 1).map((round, roundIndex) => (
                <React.Fragment key={roundIndex}>
                    
                    {/* Current Styling */}
                    {roundIndex === parseInt(setIndex) && 
                        <div 
                            className="flex flex-grow h-full bg-white font-semibold items-center justify-center"
                            style={{
                                color: GetPrimaryColorHex(styleConfig, 2)
                            }}
                        >
                            Set {roundIndex + 1}
                        </div>
                    }

                    {/* Incomplete Styling */}
                    {roundIndex !== parseInt(setIndex) && (
                        <div className="flex flex-grow h-full bg-gray-300 text-gray-500 items-center justify-center">
                            Set {roundIndex + 1}
                        </div>
                    )}

                </React.Fragment>
            ))}
        </div>
    )

}
