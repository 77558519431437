import { createSlice } from '@reduxjs/toolkit'

// Initial state
const initialState =  {
  exercises: [],
  entireWeeklyCalendarJSON: null
}

// whichWeightCache Slice
const whichWeightCache = createSlice({
  name: 'whichWeightCache',
  initialState,
  reducers: {
    setWhichWeightCacheExercises: (state, action) => { state.exercises = action.payload; },
    setWhichWeightCacheEntireWeeklyCalendarJSON: (state, action) => { state.entireWeeklyCalendarJSON = action.payload },
    clearWhichWeightCache: () => initialState
  },
});

export const {
  setWhichWeightCacheExercises,
  setWhichWeightCacheEntireWeeklyCalendarJSON,
  clearWhichWeightCache 
} = whichWeightCache.actions;

export default whichWeightCache.reducer;