import moment from 'moment'

export default function RenewalDate({ subscriptionData }) {
    switch (subscriptionData.status) {
        case 'active':
            return (
                <div className="flex border-b py-3">
                    {!subscriptionData.cancel_at_period_end && <div className="pl-2 flex-grow text-gray-600">Renewal Date</div>}
                    {subscriptionData.cancel_at_period_end && <div className="pl-2 flex-grow text-gray-600">Expiration Date</div>}
                    <div className="pr-2 text-right">{moment.unix(subscriptionData.current_period_end).format('MMMM DD, YYYY')}</div>
                </div>
            )

        case 'trialing':
            return (
                <div className="flex border-b py-3">
                    <div className="pl-2 flex-grow text-gray-600">Trial End Date</div>
                    <div className="pr-2 text-right">{moment.unix(subscriptionData.trial_end).format('MMMM DD, YYYY')}</div>
                </div>
            )

        default:
            return <></>
    }
}
