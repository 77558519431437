import { useContext } from 'react'
import { ModalContext } from '../ModalContext'

export default function TrainingStatus() {
    const { modalData } = useContext(ModalContext)

    /*
    const GetDetrainingPillStyle = (completionStatusKey) => {
        var style = 'text-xs border-r border-gray-400 overflow-hidden '

        switch (completionStatusKey) {
            case 'complete':
                style += 'bg-green-400 '
                break
            case 'half-complete':
                style += 'bg-yellow-300 '
                break
            case 'incomplete':
                style += 'bg-red-400 '
                break
            default:
                style += 'bg-gray-400 '
        }

        return style
    }
    */

    return (
        <>
            <div className="space-y-1">
                <div className="text-lg font-semibold">How is my program going?</div>
                <div className="px-2 text-xs italic text-gray-600">
                    WhichWeight keeps track of what you complete and what you do not complete. Training status is a measurement of your training consistency
                </div>
            </div>

            <div className="border border-gray-400 divide-y divide-gray-400 rounded-xl text-sm">
                <div className={`py-3 px-6 flex flex-col items-center space-y-2 ${modalData.trainingStatusKey === 'healthy' ? '' : 'bg-gray-200 opacity-50'}`}>
                    <div className="w-32 py-0.5 text-sm text-center font-semibold rounded-full bg-green-200 text-green-600">Healthy</div>
                    <div>You consistently complete your WhichWeight workouts. Keep it up!</div>
                </div>

                <div className={`py-3 px-6 flex flex-col items-center space-y-2 ${modalData.trainingStatusKey === 'maintaining' ? '' : 'bg-gray-200 opacity-50'}`}>
                    <div className="w-32 py-0.5 text-sm text-center font-semibold rounded-full bg-yellow-100 text-yellow-600">Maintaining</div>
                    <div>You occasionally miss your WhichWeight workouts. Remember consistency is key!</div>
                </div>

                <div className={`py-3 px-6 flex flex-col items-center space-y-2 ${modalData.trainingStatusKey === 'unhealthy' ? '' : 'bg-gray-200 opacity-50'}`}>
                    <div className="w-32 py-0.5 text-sm text-center font-semibold rounded-full bg-red-200 text-red-500">Unhealthy</div>
                    <div>You have not completed a WhichWeight workout in a while. Your program is at risk of resetting</div>
                </div>
            </div>

            {/* Short Term History 
            {overviewData.detrainingEightWeekHistory !== undefined && (
                <>
                    <div className="h-4 grid grid-cols-8 border border-gray-400 rounded-full overflow-hidden">
                        {overviewData.detrainingEightWeekHistory
                            .slice()
                            .reverse()
                            .map((week, weekIndex) => (
                                <div key={weekIndex} className={GetDetrainingPillStyle(week)} />
                            ))}
                    </div>
                </>
            )}
            */}
        </>
    )
}
