import { useContext } from 'react'
import { ModalKey, ModalContext } from 'components/Modals/ModalContext'
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector } from 'react-redux'
import { GetPrimaryColorHex } from 'redux/slices/brand'

import ScreenFrame from 'components/Frame/ScreenFrame'
import BodyFrame from 'components/Frame/BodyFrame'

export default function Login() {

    const { loginWithRedirect } = useAuth0()

    const { ShowModal } = useContext(ModalContext)

    const OnAuthButtonPressed = (buttonType) => {

        const modalData = {
            key: ModalKey.AddToHomeScreen,
            fullscreen: true,
            buttonText: "I am ready to begin!",
            buttonAction: () => {

                if (buttonType === 'signup') loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })
                else loginWithRedirect()

            }
        }

        ShowModal(modalData)

    }

    const styleConfig = useSelector((state) => state.brand.styleConfig)

    return (
        <ScreenFrame>
            
            {styleConfig.brandKey === "which-weight" && 
                <>

                    {/* Background Image */}
                    <div className='absolute z-[-1] bg-center bg-cover bg-no-repeat h-full w-full bg-gradient-to-b from-orange-400 to-orange-600' />

                    {/* Icon Logo */}
                    <div className='w-20 mx-auto mt-8 bg-white shadow-lg p-4 rounded-xl'>
                        <img 
                            src={styleConfig.logo} 
                            alt="WhichWeight Logo" 
                            className="w-12" 
                        />
                    </div>     

                    {/* Gradient Text */}
                    <div className="mt-4 text-center text-2xl text-white font-extrabold px-4 mb-4">
                        WhichWeight
                    </div>
                   
                    {/* Add to Home Screen */}
                    <BodyFrame>

                        <div className="text-orange-300 text-center text-6xl italic font-extrabold my-auto space-y-4">
                            <div>Assess</div>
                            <div>Progress</div>
                            <div>Success</div>
                        </div>

                        {/* Sign Up Button */}
                        <button
                            className="mt-auto w-full rounded-full border border-gray-500 py-3 text-xl font-bold text-gray-500 bg-white"
                            onClick={() => OnAuthButtonPressed('signup')}
                        >
                            Create Account
                        </button>

                        {/* Login Button */}
                        <button 
                            className="w-full rounded-full py-3 text-xl font-bold text-white" 
                            style={{ backgroundColor: GetPrimaryColorHex(styleConfig) }}
                            onClick={() => OnAuthButtonPressed()}
                        >
                            Log In
                        </button>

                    </BodyFrame>

                </>
            }

            {styleConfig.brandKey === "blue-collar" && 
                <>
                    {/* Background Image */}
                    <div className='absolute z-[-1] bg-login-background bg-center bg-cover bg-no-repeat h-full w-full' />

                    {/* Icon Logo */}
                    <div className="mt-8 mx-auto flex items-center justify-center bg-white w-24 h-24 rounded-xl shadow">
                        <img src={styleConfig.logo} alt="WhichWeight Icon Logo" className="w-20 h-20" />
                    </div>      

                    {/* Gradient Text */}
                    <div className="text-center text-3xl pb-2 font-extrabold px-4 mt-4">
                        <div className="text-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)]">
                            Blue Collar Strength
                        </div>
                    </div>
                    
                    {/* Add to Home Screen */}
                    <BodyFrame>

                        <div className="text-center text-2xl pb-2 font-extrabold px-4 mt-auto">
                            <span className="text-white italic text-transparent bg-clip-text drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)]">
                                No Trade Secrets
                            </span>
                        </div>

                        {/* Sign Up Button */}
                        <button
                            className="w-full rounded-full border border-gray-500 py-3 text-xl font-bold text-gray-500 bg-white"
                            onClick={() => OnAuthButtonPressed('signup')}
                        >
                            Create Account
                        </button>

                        {/* Login Button */}
                        <button 
                            className="w-full rounded-full py-3 text-xl font-bold text-white" 
                            style={{ backgroundColor: GetPrimaryColorHex(styleConfig) }}
                            onClick={() => OnAuthButtonPressed()}
                        >
                            Log In
                        </button>

                    </BodyFrame>

                </>
            }

        </ScreenFrame>
    )
}
